<div class="input-wrapper">
  <ng-container *ngIf="mobileWithCountryCode; else customPhoneInput">
    <tui-input-phone-international
      formControlName="{{ fieldName }}"
      tuiTextfieldSize="l"
      [countries]="(countries | tuiSortCountries | async) || []"
      [(countryIsoCode)]="countryIsoCode"
    >
      <span *ngIf="required" class="tui-required"></span>
      {{ hint }}
    </tui-input-phone-international>
  </ng-container>
  <ng-template #customPhoneInput>
    <tui-input
      formControlName="{{ fieldName }}"
      [tuiTextfieldIcon]="tuiIconPhone"
      tuiTextfieldSize="l"
    >
      {{ hint }}
      <span *ngIf="required" class="tui-required"></span>
      <input tuiTextfield type="tel" />
    </tui-input>
  </ng-template>
  <div class="error-wrapper">
    <tui-error
      formControlName="{{ fieldName }}"
      [error]="[] | tuiFieldError | async"
    ></tui-error>
  </div>
</div>
