import { BaseModel } from './base-model';
import { ObjectWithLocation } from '../interfaces/object-with-location';
import { User } from './user';
import { Student } from './student';
import { Location } from './location';
import { LessonCategory } from './lesson-category';
import { LessonStatus } from '../enums/lesson-status';
import { LicenceType } from './licence-type';

export class Lesson extends BaseModel implements ObjectWithLocation {
  teacher?: string | null;

  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    tenantId: string,
    startDate: Date,
    endDate: Date,
    studentId: string,
    student: Student,
    userId: string,
    user: User,
    categoryId: string,
    status: LessonStatus,
    price: number,
    cancelReason: string,
    locationId: string,
    licenceTypeId: string,
    location: Location,
    lessonCategory: LessonCategory,
    licenceType: LicenceType,
    count: number,
    note: string | null,
    studentSignId: string | null,
    vcLogEntities?: Lesson[],
    vcLogDate?: Date | null,
  ) {
    super(id, createdAt, updatedAt);
    this._tenantId = tenantId;
    this._startDate = startDate;
    this._endDate = endDate;
    this._studentId = studentId;
    this._student = student;
    this._userId = userId;
    this._user = user;
    this._lessonCategoryId = categoryId;
    this._status = status;
    this._price = price;
    this._cancelReason = cancelReason;
    this._locationId = locationId;
    this._licenceTypeId = licenceTypeId;
    this._location = location;
    this._lessonCategory = lessonCategory;
    this._licenceType = licenceType;
    this._count = count;
    this._note = note;
    this._studentSignId = studentSignId;
    this._vcLogEntities = vcLogEntities;
    this._vcLogDate = vcLogDate;
  }

  private _vcLogEntities?: Lesson[];
  private _tenantId: string;

  get tenantId(): string {
    return this._tenantId;
  }

  get vcLogEntities(): Lesson[] | undefined {
    return this._vcLogEntities;
  }

  set tenantId(value: string) {
    this._tenantId = value;
  }

  private _startDate: Date;

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;
  }

  private _endDate: Date;

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    this._endDate = value;
  }

  private _studentId: string;

  get studentId(): string {
    return this._studentId;
  }

  set studentId(value: string) {
    this._studentId = value;
  }

  private _student: Student;

  get student(): Student {
    return this._student;
  }

  set student(value: Student) {
    this._student = value;
  }

  private _userId: string;

  get userId(): string {
    return this._userId;
  }

  set userId(value: string) {
    this._userId = value;
  }

  private _user: User;

  get user(): User {
    return this._user;
  }

  set user(value: User) {
    this._user = value;
  }

  private _lessonCategoryId: string;

  get lessonCategoryId(): string {
    return this._lessonCategoryId;
  }

  set lessonCategoryId(value: string) {
    this._lessonCategoryId = value;
  }

  private _status: LessonStatus;

  get status(): LessonStatus {
    return this._status;
  }

  set status(value: LessonStatus) {
    this._status = value;
  }

  private _price: number;

  get price(): number {
    return this._price;
  }

  set price(value: number) {
    this._price = value;
  }

  private _cancelReason: string;

  get cancelReason(): string {
    return this._cancelReason;
  }

  set cancelReason(value: string) {
    this._cancelReason = value;
  }

  private _locationId: string;

  get locationId(): string {
    return this._locationId;
  }

  set locationId(value: string) {
    this._locationId = value;
  }

  private _licenceTypeId: string;

  get licenceTypeId(): string {
    return this._licenceTypeId;
  }

  set licenceTypeId(value: string) {
    this._licenceTypeId = value;
  }

  private _location: Location;

  get location(): Location {
    return this._location;
  }

  set location(value: Location) {
    this._location = value;
  }

  private _lessonCategory: LessonCategory;

  get lessonCategory(): LessonCategory {
    return this._lessonCategory;
  }

  set lessonCategory(value: LessonCategory) {
    this._lessonCategory = value;
  }

  private _licenceType: LicenceType;

  get licenceType(): LicenceType {
    return this._licenceType;
  }

  set licenceType(value: LicenceType) {
    this._licenceType = value;
  }

  private _count: number;

  get count(): number {
    return this._count;
  }

  set count(value: number) {
    this._count = value;
  }

  private _note: string | null;

  get note(): string | null {
    return this._note;
  }

  set note(value: string | null) {
    this._note = value;
  }

  private _vcLogDate: Date | null | undefined;

  get vcLogDate(): Date | null | undefined {
    return this._vcLogDate;
  }

  private _studentSignId: string | null;

  get studentSignId(): string | null {
    return this._studentSignId;
  }

  set studentSignId(value: string | null) {
    this._studentSignId = value;
  }

  set vcLogDate(value: Date | null) {
    this._vcLogDate = value;
  }

  static fromJson(json: any): Lesson {
    return new Lesson(
      json.id,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.tenantId,
      new Date(json.startDate),
      new Date(json.endDate),
      json.studentId,
      json.student,
      json.userId,
      json.user,
      json.lessonCategoryId,
      json.status,
      json.price,
      json.cancelReason,
      json.locationId,
      json.licenceTypeId,
      json.location,
      json.lessonCategory,
      json.licenceType,
      json.count,
      json.note,
      json.studentSignId,
      json.vcLogEntities,
      json.vcLogDate,
    );
  }
}
