import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PushService } from '../../../services/push.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from '../../../services/location.service';
import { pushTypes } from '../../../enums/push-types';
import { LocationBody } from '../../../interfaces/body/location-body';
import { SuperAdminService } from '../../../services/super-admin.service';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../models/user';
import { phoneValidator } from '../../../validators/phone-validator';

@Component({
  selector: 'app-new-location',
  templateUrl: './new-location.component.html',
  styleUrls: ['./new-location.component.scss'],
})
export class NewLocationComponent {
  @Input() isOpen: boolean = false;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() isCreatedEvent = new EventEmitter<boolean>();

  sendRequest = false;

  readonly newLocationForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    street: new FormControl('', [Validators.required]),
    houseNo: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [
      Validators.required,
      Validators.maxLength(5),
    ]),
    city: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required, phoneValidator()]),
  });

  // strings for i18n
  pushNotificationSuccess: string = '';
  pushNotificationError: string = '';

  constructor(
    private locationService: LocationService,
    private pushService: PushService,
    private translateService: TranslateService,
    private authService: AuthService,
  ) {}

  ngOnChanges(): void {
    this.newLocationForm.markAsUntouched();
  }

  ngOnInit(): void {
    // translated strings for push-notifications
    let pushMessage = this.translateService
      .get([
        'location.new-location.push-location-successful-created',
        'location.new-location.push-location-error-created',
      ])
      .subscribe((translations) => {
        this.pushNotificationSuccess =
          translations[
            'location.new-location.push-location-successful-created'
          ];
        this.pushNotificationError =
          translations['location.new-location.push-location-error-created'];
      });
  }

  /**
   * Emits the close event to the parent component and closes the dialog.
   */
  setCloseEvent() {
    this.sendRequest = false;
    this.closeEvent.emit(true);
    this.isOpen = false;
  }

  createNewLocation() {
    if (this.sendRequest) return;
    this.sendRequest = true;
    const tenantId = this.authService.getTenantId();
    if (tenantId == null) {
      this.pushService.sendPush(pushTypes.ERROR, this.pushNotificationError);
      return;
    }
    const locationBody: LocationBody = {
      name: this.newLocationForm.value.name!,
      tenantId: tenantId,
      address: {
        tenantId: tenantId,
        houseNo: this.newLocationForm.value.houseNo!,
        street: this.newLocationForm.value.street!,
        postalCode: this.newLocationForm.value.postalCode!,
        city: this.newLocationForm.value.city!,
      },
      phoneNumber: this.newLocationForm.value.phone!,
    };

    this.locationService
      .createLocation(locationBody)
      .subscribe((isCreated: boolean) => {
        if (isCreated) {
          this.pushService.sendPush(
            pushTypes.SUCCESS,
            this.pushNotificationSuccess,
          );
          // If created, emit the event to the parent component and close the dialog
          this.isCreatedEvent.emit(true);
          this.setCloseEvent();
          this.newLocationForm.reset();
          // If not created, stay in the dialog
        } else {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationError,
          );
          this.setCloseEvent();
        }
      });
  }
}
