import { Component } from '@angular/core';
import { NavRoutes } from '../../enums/nav-routes';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
})

/**
 * Settings page component
 * It serves as a container for the settings page.
 * All subpages (currently: profile, explanation, password) are displayed in the router-outlet as tabs.
 */
export class SettingsPageComponent {
  protected readonly NavRoutes = NavRoutes;

  constructor(private authService: AuthService) {}

  public isGlobalUser() {
    return this.authService.getLoggedInUser()?.role?.global;
  }
}
