import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-licence-typ-select',
  templateUrl: './licence-typ-select.component.html',
  styleUrls: ['./licence-typ-select.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class LicenceTypSelectComponent {
  @Input() optionsArray: any[] | undefined;
  @Input() selectedOptionsArray: any[] | undefined;
  @Input() fieldName: string = '';
  @Output() valueChanges = new EventEmitter();

  previousValue: any;

  onChange(event: any) {
    this.valueChanges.emit({ previousValue: this.previousValue, id: event });
    this.previousValue = event;
  }

  stringify(
    items: readonly any[],
  ): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(
      items.map(({ id, label }) => [id, label] as [number, string]),
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }
}
