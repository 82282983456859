<div class="input-wrapper">
  <tui-input-date
    [min]="deactivatePastDates ? todaysDate : null"
    formControlName="{{ fieldName }}"
    [tuiTextfieldSize]="textFieldSize"
    [tuiTextfieldCleaner]="textFieldCleaner"
    (tuiValueChanges)="dateChange.emit($event)"
  >
    {{ hint }}
  </tui-input-date>

  <div *ngIf="showError" class="error-wrapper">
    <tui-error
      formControlName="{{ fieldName }}"
      [error]="[] | tuiFieldError | async"
    ></tui-error>
  </div>
</div>
