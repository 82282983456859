<app-menubar
  (closingEvent)="setCloseEvent()"
  *ngIf="lesson"
  [blur]="false"
  [description]="'event-detail.description' | translate"
  [open]="isOpen"
  [title]="'event-detail.title' | translate"
>
  <tui-loader
    [overlay]="true"
    [showLoader]="!!(isLoading$ | async)"
    [size]="'xl'"
  >
    <!-- form to edit a lesson   -->
    <form *ngIf="!isCanceled" [formGroup]="lessonForm">
      <div class="">
        <app-dropdown [dropDownItems]="dropDownStudentItems" [formFieldName]="'assignedToStudent'"
                      [hint]="'event-detail.input-student' | translate"></app-dropdown>

        <div class="link-wrapper">
          <a tuiLink [routerLink]="['/student', lesson.student.id]">
            {{ "event-detail.button-student" | translate }}
          </a>
        </div>

        <div class="error-wrapper">
          <tui-error
            [error]="[] | tuiFieldError | async"
            formControlName="assignedToStudent"
          ></tui-error>
        </div>
      </div>



      <div>
        <app-dropdown
          [dropDownItems]="licenceTypeDropdownItems"
          [formFieldName]="'licenceType'"
          [hint]="'students.input-licence-hint' | translate"
          [showError]="true"
        >
        </app-dropdown>
      </div>

      <div class="category-dropdown">
        <app-dropdown
          (emitChangeEvent)="onCategoryChanges($event)"
          [dropDownItems]="categoryDropdownItems"
          [formFieldName]="'category'"
          [hint]="'new-event.input-type' | translate"
        >
        </app-dropdown>
      </div>

      <div>
        <app-dropdown [dropDownItems]="dropDownUserItems" [formFieldName]="'assignedToTeacher'"
                      [hint]="'event-detail.input-teacher' | translate"></app-dropdown>
        <div class="link-wrapper">
          <a tuiLink [routerLink]="['/user', lesson.userId]">
            {{ "event-detail.button-teacher" | translate }}
          </a>
        </div>
        <div class="error-wrapper">
          <tui-error
            [error]="[] | tuiFieldError | async"
            formControlName="assignedToTeacher"
          ></tui-error>
        </div>
      </div>
      <div class="date-time-wrapper">
        <div class="date-input-wrapper">
          <tui-input-date class="input-element" formControlName="startDate" [min]="today" (tuiValueChanges)="onStartDateTimeChange($event)" >
            {{ "event-detail.input-start-date" | translate }}
            <span class="tui-required"></span>
          </tui-input-date>
          <div class="error-wrapper">
            <tui-error
              [error]="[] | tuiFieldError | async"
              formControlName="startDate"
            ></tui-error>
          </div>
        </div>
      </div>
      <div class="date-time-wrapper">
        <div class="date-input-wrapper">
          <app-input-time (timeChange)="onStartDateTimeChange($event)" [fieldName]="'startTime'"
                          [hint]="'event-detail.input-start-time'  | translate"
                          [required]="true"
                          class="input-element"
                          tuiTextfieldSize="l"
          >
          </app-input-time>
        </div>
        <div class="counter-input">
          <tui-select (tuiValueChanges)="onCounterChange($event)" [formControlName]="'counter'" tuiTextfieldSize="l">
            {{ 'new-event.input-counter' | translate }}
            <input tuiTextfield/>
            <tui-data-list-wrapper
              *tuiDataList
              [items]="counterItems"
            ></tui-data-list-wrapper>
          </tui-select>
        </div>
      </div>
      <div class="date-input-wrapper">
        <app-input-time [fieldName]="'endTime'" [hint]="'event-detail.input-end-time' | translate" [required]="true"
                        class="input-element"
                        tuiTextfieldSize="l">
        </app-input-time>
      </div>
      <h3 *ngIf="isActive && !this.isSigned || isSigned" class="price-title"><span
        class="light-grey-not-bold">{{ ('event-detail.label-price' | translate) }}</span> {{ lessonPrice }} €</h3>
      <div *ngIf="isActive && !this.isSigned" class="tui-space_top-5">
        <tui-checkbox-labeled
          class="tui-space_bottom-3"
          formControlName="userNotification"
          size="l"
        >{{ "new-event.user-notification-label" | translate }}
        </tui-checkbox-labeled>
        <tui-checkbox-labeled
          class="tui-space_bottom-3"
          formControlName="studentNotification"
          size="l"
        >{{ "new-event.student-notification-label" | translate }}
        </tui-checkbox-labeled>
      </div>
      <tui-notification
        *ngIf="!isActive && !this.isSigned"
        class="tui-space_vertical-4"
        status="warning"
      >
        {{ 'event-detail.lesson-has-been-canceled' | translate }}
        {{ lesson.cancelReason }}
      </tui-notification>

      <div *ngIf="isSigned" class="signed-detail-wrapper">
        <hr>
        <div class="sign-wrapper">
          <img [src]="signUrl" alt="sign">
        </div>
        <tui-textarea
          [formControl]="comment"
          class="note-wrapper"
        >
          {{ "event-detail.label-comment" | translate }}
        </tui-textarea>
      </div>


      <div class="button-wrapper">
        <button
          (click)="submitForm()"
          *ngIf="(isActive  || this.isSigned) "
          [disabled]="!lessonForm.valid && !this.isSigned"
          size="m"
          tuiButton
          type="button"
        >
          {{ "event-detail.button-save" | translate }}
        </button>
        <button
          (click)="isCanceled = true"
          *ngIf="isActive && !this.isSigned"
          [disabled]="isStartDateBeforeCurrentDate"
          appearance="secondary-destructive"
          size="m"
          tuiButton
          type="button"
        >
          {{ "event-detail.button-cancellation" | translate }}
        </button>
        <button (click)="setCloseEvent()" appearance="secondary" size="m" tuiButton type="button">
          {{ "common.button-cancel" | translate }}
        </button>
      </div>
    </form>
    <!-- END of form to edit a lesson   -->


    <!-- form to cancel a lesson -->
    <form *ngIf="isCanceled" [formGroup]="cancelForm">
      <h2>{{ "event-detail.cancellation-info-text" | translate }}</h2>

      <div class="info-wrapper">
        <tui-svg [src]="tuiIconUserLarge"></tui-svg>
        <app-info-bit [info]="studentName" [title]="'Fahrschüler/in'"></app-info-bit>
      </div>

      <div class="info-wrapper">
        <tui-svg [src]="tuiIconCalendarLarge"></tui-svg>
        <app-info-bit [info]="lessonDate" [title]="'Termin'"></app-info-bit>
      </div>

      <div class="info-wrapper">
        <tui-svg [src]="tuiIconTagLarge"></tui-svg>
        <app-info-bit [info]="lesson.lessonCategory.name" [title]="'Typ'"></app-info-bit>
      </div>

      <tui-textarea formControlName="cancelReason">
        {{ "event-detail.input-cancel-reason" | translate }}
      </tui-textarea>
      <div class="tui-space_top-5">
        <tui-checkbox-labeled
          class="tui-space_bottom-3"
          formControlName="userNotification"
          size="l"
        >{{ "new-event.user-notification-label" | translate }}
        </tui-checkbox-labeled>
        <tui-checkbox-labeled
          class="tui-space_bottom-3"
          formControlName="studentNotification"
          size="l"
        >{{ "new-event.student-notification-label" | translate }}
        </tui-checkbox-labeled>
      </div>
      <div class="button-wrapper">
        <button
          (click)="submitCancelForm()"
          [disabled]="!cancelForm.valid"
          appearance="secondary-destructive"
          size="m"
          tuiButton
          type="button"
        >
          {{ "event-detail.save-cancellation" | translate }}
        </button>
        <button (click)="isCanceled = false" appearance="secondary" size="m" tuiButton type="button">
          {{ "event-detail.cancel-cancellation" | translate }}
        </button>
      </div>
    </form>
    <!-- END of form to cancel a lesson -->
  </tui-loader>
</app-menubar>


