import { Component, Input } from '@angular/core';
import { CarIcons } from '../../enums/car-icons';
import { UserRole } from '../../enums/user-role';

@Component({
  selector: 'app-icon-dropdown',
  templateUrl: './icon-dropdown.component.html',
  styleUrls: ['./icon-dropdown.component.scss'],
})
export class IconDropdownComponent {
  @Input({ required: true }) formFieldName!: string;
  @Input() required: boolean = true;
  @Input({ required: true }) formGroup!: any;

  open: boolean = false;

  ngOnInit(): void {
    this.formGroup.controls[this.formFieldName].setValue(this.icons[0]);
  }

  get icons() {
    return Object.values(CarIcons);
  }

  get selectedIcon() {
    return this.formGroup.controls[this.formFieldName].value;
  }

  iconClicked(icon: string) {
    this.formGroup.controls[this.formFieldName].setValue(icon);
    this.open = false;
  }

  protected readonly CarIcons = CarIcons;
}
