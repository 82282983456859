<div [ngClass]="alternativeColorHeader ? 'alternateColorHeader' : 'standardColorHeader'" id="table-component">
  <tui-elastic-container class="visible">
    <tui-loader [overlay]="true" [showLoader]="!!(isLoading$ | async)" [size]="'m'">
      <ng-container *ngIf="tableData.length == 0; else showTable">
        <p *ngIf="!paymentTable">{{ 'table-header.no-entries' | translate }}</p>
        <p *ngIf="paymentTable">{{ 'table-header.no-entries-payment' | translate }}</p>
      </ng-container>
      <ng-template #showTable>
        <table
          (directionChange)="onDirectionChange($event)"
          [columns]="columns"
          class="tui-table"
          tuiTable
        >
          <thead>
          <tr tuiThGroup>
            <ng-container *ngFor="let columnName of tableColumnNames; let i = index">
              <!-- Check if sorting should be disabled for the column -->
              <ng-container *ngIf="disabledSortColumns.includes(columns[i]); else other">
                <th *tuiHead="columns[i]" [sorter]="null" tuiTh>
                  {{ columnName }}
                </th>
              </ng-container>

              <!-- Enables Sorting for all other Columns -->
              <ng-template #other>
                <th (click)="onSortChange(columns[i])" *tuiHead="columns[i]" tuiTh>
                  {{ columnName }}
                </th>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="showDeleteButton">
              <th
                *tuiHead="'delete'"
                [sorter]="null"
                tuiTh
              ></th>
            </ng-container>
          </tr>
          </thead>
          <tbody *tuiLet="tableData" [data]="tableData" tuiTbody>
          <tr
            (click)="rowClickEvent.emit(item.id)"
            *ngFor="let item of tableData"
            [style.cursor]="cursor"
            tuiTr
          >

            <ng-container *ngFor="let column of columns">
              <!-- if the column is 'personName', display item[column] -->
              <ng-container
                *ngIf="column === 'personName' || column === 'userName'; else handleWithNestedProperty"
              >
                {{ item[column] || '-' }}
              </ng-container>

              <ng-template #handleWithNestedProperty>
                <td *tuiCell="column" tuiTd>
                  <!-- if the input is a iso string, format it -->
                  <ng-container
                    *ngIf="
                      isIsoOrConvertible(extractNestedProperty(item, column));
                      else notDate
                    "
                  >
                    <!-- default: can handle nested, like address.street in column array -->
                    {{
                      extractNestedProperty(item, column) | date: "dd.MM.yyyy - HH:mm"
                    }}
                  </ng-container>

                  <!-- default case: if cellTemplatesMap[column] and isIsoDateString are falsy. Display the value in raw -->
                  <ng-template #notDate>
                    <!-- can handle nested, like address.street in column array -->
                    {{
                      (extractNestedProperty(item, column) | translate) || "-"
                    }}
                  </ng-template>
                </td>
              </ng-template>
            </ng-container>

            <!-- Customize specific Columns ACTIVE-INACTIVE -->
            <td *tuiCell="'active'" tuiTd>
              <ng-container *ngIf="item.active == true; else inActive">
                <tui-badge [value]="'table.active' | translate" status="success"></tui-badge>
              </ng-container>
              <ng-template #inActive>
                <tui-badge [value]="'table.inactive' | translate" status="error"></tui-badge>
              </ng-template>
            </td>

            <td *tuiCell="'status'" tuiTd>
              <ng-container *ngIf="item.status == 'active'; else inActiveStatus">
                <tui-badge [value]="'table.active' | translate" status="success"></tui-badge>
              </ng-container>
              <ng-template #inActiveStatus>
                <tui-badge [value]="'table.inactive' | translate" status="error"></tui-badge>
              </ng-template>
            </td>

            <!-- Customize specific Columns ICON -->
            <td *tuiCell="'icon'" tuiTd>
              <app-car-icon [icon]="item.icon"></app-car-icon>
            </td>


            <!-- Customize specific Columns CREATED_AT -->
            <td *tuiCell="'createdAt'" tuiTd>
              {{ item.createdAt | date: 'dd.MM.yyyy - HH:mm' }} {{ "table.time-indicator" | translate }}
            </td>

            <!-- Customize specific Columns AMOUNT -->
            <td *tuiCell="'amount'" tuiTd>
              <span
                *ngIf="item.transactionInOutCashRegister === TransactionInOut.INCOMING_TRANSACTION && !(item.transactionType === TransactionType.BOSS_INVENTORY)"
                class="green-text">
                +{{ item.amount }} €
              </span>
              <span
                *ngIf="item.transactionInOutCashRegister === TransactionInOut.OUTGOING_TRANSACTION && !(item.transactionType === TransactionType.BOSS_INVENTORY)"
                class="red-text">
                {{ item.amount }} €
              </span>
              <span *ngIf="item.transactionType === TransactionType.BOSS_INVENTORY" class="white-text">
                  {{ item.amount }} €
              </span>
            </td>

            <!-- Customize specific Columns TRANSACTIONTYPE -->
            <td *tuiCell="'transactionTypeString'" tuiTd>
              {{ item.transactionTypeAbbreviation }}
              <tui-tooltip
                [content]="(item.transactionTypeString | translate)"
                direction="right"
              ></tui-tooltip>
            </td>

            <!--  Delete Button   -->
            <ng-container *ngIf="showDeleteButton && !isManager()">
              <td
                *tuiCell="'delete'"
                class="delete"
                tuiTd
              >
                <button
                  (click)="deleteButtonClicked($event); deleteEvent.emit(item.id)"
                  appearance="flat"
                  class="remove"
                  icon="tuiIconTrash"
                  shape="rounded"
                  size="s"
                  title="Remove"
                  tuiIconButton
                  type="button"
                ></button>
              </td>
            </ng-container>
          </tr>
          </tbody>
        </table>
      </ng-template>
    </tui-loader>
  </tui-elastic-container>

  <tui-table-pagination
    (paginationChange)="this.paginationEvent.emit($event)"
    *ngIf="tableData.length > 0 && showPagination"
    [page]="currentPage"
    [size]="pageSize"
    [total]="totalElements"
  >
  </tui-table-pagination>

</div>
