<tui-combo-box
  (tuiValueChanges)="emitChange($event)"
  [stringify]="stringify"
  class="dropdown"
  formControlName="{{formFieldName}}"
  [tuiTextfieldSize]="textFieldSize"
>
  {{hint}}
  <span *ngIf="required" class="tui-required"></span>
  <tui-tooltip *ngIf="tooltipText.length > 0" [content]="tooltipText" direction="right"></tui-tooltip>
  <tui-data-list-wrapper
    *tuiDataList
    [itemContent]="stringify | tuiStringifyContent"
    [items]="dropDownItems | tuiFilterByInputWith : stringify "
  ></tui-data-list-wrapper>
</tui-combo-box>
<div *ngIf="showError" class="error-wrapper">
  <tui-error
    [error]="[] | tuiFieldError | async"
    [formControlName]="formFieldName"
  ></tui-error>
</div>
