import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';
import { UserBody } from '../../../interfaces/body/user-body';
import { PushService } from '../../../services/push.service';
import { RoleService } from '../../../services/role.service';
import { Role } from '../../../models/role';
import { TranslateService } from '@ngx-translate/core';
import { pushTypes } from '../../../enums/push-types';
import { SuperAdminService } from '../../../services/super-admin.service';
import { Subscription } from 'rxjs';
import { User } from '../../../models/user';
import { HttpStatusCode } from '@angular/common/http';
import { UserRole } from '../../../enums/user-role';
import { phoneValidator } from '../../../validators/phone-validator';
import { Location } from '../../../models/location';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})

/**
 * The side modal component for creating a new user.
 * It contains a form for the user data and a button to create the user.
 *
 * @param isOpen: boolean - whether the sidebar is open or not
 * @param closeEvent: EventEmitter<boolean> - emits the close event to the parent component
 * @param isCreatedEvent: EventEmitter<boolean> - emits the isCreated event to the parent component
 */
export class NewUserComponent {
  @Input() isOpen: boolean = false;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() isCreatedEvent = new EventEmitter<boolean>();

  // To display the available explanation in the dropdown
  roles: Role[] = [];
  locations!: Location[];

  errorPushDescription!: string;
  errorUniqueEmailDescription!: string;
  successPushDescription!: string;
  selectedRole: Role | undefined;

  sendRequest: boolean = false;

  readonly newUserForm = new FormGroup({
    email: new FormControl(``, [Validators.required, Validators.email]),
    firstName: new FormControl(``),
    lastName: new FormControl(``),
    telephone: new FormControl(``, phoneValidator()),
    role: new FormControl(null, [Validators.required]),
    locationId: new FormControl(null),
  });

  private selectedTenantSubscription: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private pushService: PushService,
    private roleService: RoleService,
    private translate: TranslateService,
    private superAdminService: SuperAdminService,
  ) {}

  // Subscribes to the translations for the push message
  errorPushMessage = this.translate
    .get([
      'create-user.push-error-description',
      'create-user.push-success-description',
      'create-user.push-error-unique-email-description',
    ])
    .subscribe((translations) => {
      this.errorPushDescription =
        translations['create-user.push-error-description'];
      this.successPushDescription =
        translations['create-user.push-success-description'];
      this.errorUniqueEmailDescription =
        translations['create-user.push-error-unique-email-description'];
    });

  ngOnInit(): void {
    this.listenToRoleChange();
  }

  ngOnChanges(): void {
    this.newUserForm.markAsUntouched();
  }

  ngOnDestroy(): void {
    this.selectedTenantSubscription.unsubscribe();
  }

  listenToRoleChange() {
    this.newUserForm.get('role')?.valueChanges.subscribe((value: any) => {
      if (value == null) return;
      this.selectedRole = this.roles.find((role) => role.id === value.id);
    });
  }

  /**
   * Creates a new user with the data from the new user form
   */
  createNewUser() {
    if (this.sendRequest) return;
    this.sendRequest = true;

    const tenantId = this.authService.getTenantId();
    if (tenantId == null) return;
    // Construct the user body from the form data
    const userBody: UserBody = {
      tenantId: tenantId,
      email: this.newUserForm.get('email')?.value!,
      firstName: this.newUserForm.get('firstName')?.value ?? '',
      lastName: this.newUserForm.get('lastName')?.value ?? '',
      phone: this.newUserForm.get('telephone')?.value ?? '',
      active: true,
      roleId: this.newUserForm.get('role')?.value!,
      locationId: this.newUserForm.get('locationId')?.value!,
    };

    // Create the user with the user service
    this.userService.createUser(userBody).subscribe((status) => {
      if (status === HttpStatusCode.Ok) {
        this.pushService.sendPush(
          pushTypes.SUCCESS,
          this.successPushDescription,
        );
        // If created, emit the event to the parent component and close the dialog
        this.isCreatedEvent.emit(true);
        this.setCloseEvent();
        this.newUserForm.reset();
      } else {
        // If not created, stay in the dialog and show an error message
        if (status === HttpStatusCode.Conflict) {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.errorUniqueEmailDescription,
          );
        } else {
          this.pushService.sendPush(pushTypes.ERROR, this.errorPushDescription);
        }
        this.isCreatedEvent.emit(true);
        this.setCloseEvent();
      }
    });
  }

  /**
   * Emits the close event to the parent component and closes the dialog.
   */
  setCloseEvent() {
    this.sendRequest = false;
    this.closeEvent.emit(true);
    this.isOpen = false;
  }

  getLoggedInUser(): User | null {
    return this.authService.getLoggedInUser();
  }

  // getAvailableRoles
  setRoles(roles: Role[]) {
    this.roles = roles;
  }

  protected readonly UserRole = UserRole;
}
