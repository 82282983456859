<div class="bar-wrapper">
  <nav class="sidenav-wrapper">
    <tui-scrollbar>
          <div class="center-helper">
            <div class="logo-wrapper">
              <img
                class="logo"
                src="assets/img/citydrive_logo.svg"
                alt="citydrive-logo"/>
            </div>
          </div>
          <div class="main-button-wrapper">
            <!-- Different items for navigation -->
            <app-button
              (isClicked)="setSelectedItem($event)"
              [type]="MenuItems.OVERVIEW"
            ></app-button>
            <app-button
              (isClicked)="setSelectedItem($event)"
              [type]="MenuItems.USERS"
            ></app-button>
            <app-button
              (isClicked)="setSelectedItem($event)"
              [type]="MenuItems.CUSTOMERS"
            ></app-button>
            <app-button
              (isClicked)="setSelectedItem($event)"
              [type]="MenuItems.CALENDAR"
            ></app-button>
            <app-button
              (isClicked)="setSelectedItem($event)"
              [type]="MenuItems.PAYMENTS"
            ></app-button>
            <app-button
              (isClicked)="setSelectedItem($event)"
              [type]="MenuItems.SETTINGS"
            ></app-button>
            </div>
    </tui-scrollbar>
      <!-- The bottom, which is always displayed at the bottom -->
        <div class="bottom-wrapper">

          <tui-hosted-dropdown
            tuiDropdownAlign="right"
            [sided]="true"
            [content]="dropdown"
            [(open)]="isOpen"
          >
            <button
              tuiIconButton
              type="button"
              appearance="flat"
              size="m"
              iconRight="tuiIconUserLarge"
              [pseudoActive]="isOpen || null"
            ></button>
          </tui-hosted-dropdown>
      </div>
  </nav>
  <div class="header-wrapper">
    <ng-content></ng-content>
  </div>
</div>

<!-- Dropup content on the bottom for settings and logout -->
<ng-template #dropdown let-close="close">
  <tui-data-list>
    <tui-opt-group id="user-name">
      {{userName}}
    </tui-opt-group>
    <tui-opt-group>
      <button tuiOption [routerLink]="'/'+NavRoutes.SETTINGS">{{'sidenav.bottom-settings' | translate }}</button>
      <button tuiOption (click)="logOut()">
        <tui-svg [src]="tuiIconLogOut"></tui-svg>
        {{'sidenav.bottom-logout' | translate}}
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-template>
