import { BaseModel } from './base-model';
import { LessonCategory } from './lesson-category';

export class LicenceTarget extends BaseModel {
  private _tenantId: string;
  private _licenceTypeId: string;
  private _lessonCategoryId: string;
  private _lessonCount: number;
  private _price: number;
  private _lessonCategory?: LessonCategory;

  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    tenantId: string,
    licenceTypeId: string,
    lessonCategoryId: string,
    lessonCount: number,
    price: number,
    lessonCategory: LessonCategory,
  ) {
    super(id, createdAt, updatedAt);
    this._tenantId = tenantId;
    this._licenceTypeId = licenceTypeId;
    this._lessonCategoryId = lessonCategoryId;
    this._lessonCount = lessonCount;
    this._price = price;
    this._lessonCategory = lessonCategory;
  }

  get tenantId(): string {
    return this._tenantId;
  }

  set tenantId(value: string) {
    this._tenantId = value;
  }

  get licenceTypeId(): string {
    return this._licenceTypeId;
  }

  set licenceTypeId(value: string) {
    this._licenceTypeId = value;
  }

  get lessonCategoryId(): string {
    return this._lessonCategoryId;
  }

  set lessonCategoryId(value: string) {
    this._lessonCategoryId = value;
  }

  get lessonCount(): number {
    return this._lessonCount;
  }

  set lessonCount(value: number) {
    this._lessonCount = value;
  }

  get price(): number {
    return this._price;
  }

  set price(value: number) {
    this._price = value;
  }

  get lessonCategory(): LessonCategory | undefined {
    return this._lessonCategory;
  }

  set lessonCategory(value: LessonCategory) {
    this._lessonCategory = value;
  }

  static fromJson(json: any): any {
    return new LicenceTarget(
      json.id,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.tenantId,
      json.licenceTypeId,
      json.lessonCategoryId,
      json.lessonCount,
      json.price,
      json.lessonCategory,
    );
  }
  // todo implement
  toJSON(): any {}
}
