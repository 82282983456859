export enum CarIcons {
  CAR = 'CAR',
  CAR_SIDE = 'CAR_SIDE',
  BUS = 'BUS',
  MOPED = 'MOPED',
  MOTORCYCLE = 'MOTORCYCLE',
  TRUCK = 'TRUCK',
  VAN = 'VAN',
  TRAILER = 'TRAILER',
}
