<div class="calendar-wrapper wrapper">
  <div class="title-wrapper">
    <div clasS="left-header">
      <app-tenant-selector-dropdown *ngIf="isSuperAdmin()"></app-tenant-selector-dropdown>
      <h1>{{ "calendar.page-title" | translate }}</h1>
    </div>
    <button
      (click)="createNewClickEvent()"
      appearance="primary"
      size="m"
      tuiButton
      type="button"
    >
      {{ "calendar.button-new-appointment" | translate }}
    </button>
  </div>

  <div class="calendar-section">
    <div class="event-left-side">
      <full-calendar #calendar [deepChangeDetection]="true" [options]="calendarOptions">
        <ng-template #eventContent let-arg>
          <app-event-item [lesson]="arg"></app-event-item>
        </ng-template>
      </full-calendar>
    </div>
    <div class="event-right-side">
      <div class="filter-card">
        <tui-calendar
          (dayClick)="onDayClick($event)"
          [value]="value"
        ></tui-calendar>
      </div>
      <div class="filter-card dropdown-filter">
        <h2>{{ "calendar.filter-title" | translate }}</h2>
        <form [formGroup]="filterForm" class="filter-form">
          <!-- location filter TODO: dropdown implemented, wip logic after select -->
          <span class="dropdown-row">
            <app-dropdown
              [dropDownItems]="dropDownLocationItems"
              [formFieldName]="'location'"
              [hint]="'location.location-detail.breadcrumb-item-caption' | translate"
            ></app-dropdown>
            <button (click)="resetLocationFilter()" [disabled]="!isFilteredByLocation" [icon]="tuiIconRotateCw"
                    appearance="secondary"
                    size="s" title="Zurücksetzen" tuiIconButton>
            </button>
          </span>

          <!-- teacher filter -->
          <span class="dropdown-row">
            <app-dropdown
              [dropDownItems]="dropDownUserItems"
              [formFieldName]="'teacher'"
              [hint]="'calendar.filter-label-teacher' | translate"
            ></app-dropdown>
          <button (click)="resetTeacherFilter()" [disabled]="!isFilteredByTeacher" [icon]="tuiIconRotateCw"
                  appearance="secondary"
                  size="s" title="Zurücksetzen" tuiIconButton>
          </button>
          </span>

          <!-- student filter -->
          <span class="dropdown-row">
            <app-dropdown
              [dropDownItems]="dropDownStudentItems"
              [formFieldName]="'student'"
              [hint]="'calendar.filter-label-student' | translate"
            ></app-dropdown>
            <button (click)="resetStudentFilter()" [disabled]="!isFilteredByStudent" [icon]="tuiIconRotateCw"
                    appearance="secondary"
                    size="s" title="Zurücksetzen" tuiIconButton>
            </button>
          </span>
        </form>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<!--<app-new-event-->
<!--  [isOpen]="isOpen"-->
<!--  [startTime]="startTime"-->
<!--  [endTime]="endTime"-->
<!--  (closeEvent)="closeEvent()"-->
<!--  (createdLesson)="closeEvent()"-->
<!--&gt;</app-new-event>-->
