<div class="wrapper">
  <tui-loader
    [overlay]="true"
    [showLoader]="!!(isLoading$ | async)"
    [size]="'xl'"
  >
    <ng-container *ngIf="user">
      <!--    breadcrumbs  -->
      <tui-breadcrumbs *ngIf="!urlContainsGlobalUsers" [size]="'l'">
        <ng-container *ngFor="let item of breadcrumbItems">
          <a *tuiItem [routerLink]="item.routerLink" tuiLink>
            {{ item.caption | translate }}
          </a>
        </ng-container>
      </tui-breadcrumbs>

      <!--   title   -->
      <div class="header-container">
        <div class="name-container">
          <tui-svg [src]="tuiIconSmileLarge"></tui-svg>
          <h1>{{ user.name }}</h1>

          <div *ngIf="!user.inviteAcceptedAt">
            Einladung wurde noch nicht angenommen:
            <a (click)="sendInviteMessageAgain(user.id)" style="cursor: pointer">
              Einladung erneut senden
            </a>
          </div>
        </div>

        <!-- edit bar -->
        <app-edit-button-bar
          (deleteClicked)="showDeleteDialog = true"
          (saveClicked)="submitForm()"
          [deleteButtonText]="'user-profile.button-delete' | translate"
          [saveButtonDisabled]="!userForm.valid"
          [saveButtonText]="'user-profile.button-save' | translate"
        >
        </app-edit-button-bar>
      </div>

      <!-- form -->
      <div class="form-group">
        <form [formGroup]="userForm">
          <h2>
            {{ "user-profile.title" | translate }}
          </h2>

          <div class="form-section-wrapper">
            <!--   firstName   -->
            <app-input-text
              [fieldName]="'firstName'"
              [hint]="'create-user.input-label-first-name' | translate"
              [required]="false"
              class="fx-grow-1"
            ></app-input-text>

            <!-- lastName -->
            <app-input-text
              [fieldName]="'lastName'"
              [hint]="'create-user.input-label-last-name' | translate"
              [required]="false"
              class="fx-grow-1"
            ></app-input-text>
          </div>

          <div class="form-section-wrapper">
            <!--   email   -->
            <app-input-text
              [fieldName]="'email'"
              [hint]="'create-user.input-label-mail' | translate"
              class="fx-grow-1"
            ></app-input-text>

            <!-- phone -->
            <app-input-phone
              [fieldName]="'phone'"
              [hint]="'create-user.input-label-telephone' | translate"
              [required]="false"
              class="fx-grow-1"
            ></app-input-phone>
          </div>

          <div class="form-section-wrapper">
            <!-- role -->
            <app-role-dropdown
              [fieldName]="'role'"
              class="fx-grow-1"
              style="margin-bottom: 0"
            >
            </app-role-dropdown>

            <!-- location -->
            <app-location-dropdown
              *ngIf="
                selectedRole?.userRole !== UserRole.TENANT_ADMIN &&
                selectedRole?.global == false
              "
              [fieldName]="'locationId'"
              [formGroup]="userForm"
              [objectWithLocation]="user"
              [required]="false"
              class="fx-grow-1"
              style="margin-bottom: 0"
            >
            </app-location-dropdown>
          </div>

          <!-- status: active | inactive -->
          <div style="display: flex; gap: 1rem; margin-top: 1rem">
            <tui-radio-labeled
              [item]="radioListActive[0]"
              [size]="'l'"
              formControlName="active"
            >
              {{ "common.radio-active" | translate }}
            </tui-radio-labeled>
            <tui-radio-labeled
              [item]="radioListActive[1]"
              [size]="'l'"
              formControlName="active"
            >
              {{ "common.radio-inactive" | translate }}
            </tui-radio-labeled>
          </div>
          <!--     wallet      -->

          <div
            *ngIf="user && user.role?.userRole === UserRole.TEACHER"
            class="wallet"
          >
            <hr />

            <button
              [routerLink]="['/payments/teacher/', user.id]"
              appearance="primary"
              size="m"
              style="width: 41.5%; margin-top: 1rem"
              tuiButton
              type="button"
            >
              Zahlung erstellen
            </button>
            <div class="wallet-content">
              <app-wallet-overview
                [id]="user.id"
                [walletType]="WalletType.TEACHER"
                class="wallet-content"
              >
              </app-wallet-overview>
            </div>
          </div>
        </form>

        <!-- Teacher lessons table -->
        <app-teacher-lessons-table
          *ngIf="hasUserRole(UserRole.TEACHER)"
          [userId]="userId"
        />
      </div>
    </ng-container>
  </tui-loader>

  <app-delete-dialog
    (cancelEvent)="showDeleteDialog = false"
    (deleteEvent)="showDeleteDialog = false; deleteUser()"
    [isOpen]="showDeleteDialog"
  ></app-delete-dialog>
</div>
