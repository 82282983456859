<div class="bubble-gradient-top"></div>
<div class="stop-sign">
  <svg width="426" height="346" viewBox="0 0 426 346" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-272.333 -641.229L136.209 -641.229L425.092 -352.346L425.092 56.1966L136.209 345.08L-272.333 345.08L-561.216 56.1966L-561.216 -352.346L-272.333 -641.229Z" fill="#AFCA0B"/>
  </svg>
</div>

<div class="login-wrapper">
  <div class="card-wrapper-login">
    <div class="login-header-wrapper">
      <img
        class="logo"
        ngSrc="assets/img/citydrive_logo.svg"
        alt="citydrive-logo" height="16" width="16"/>
      <h1>
        {{ "login.title" | translate }}
      </h1>
      <p>
        {{ "login.description" | translate }}
      </p>
    </div>

    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="submitLogin()">
      <app-input-email
        [fieldName]="'emailValue'"
        [hint]="'login.input-mail' | translate"
      >
      </app-input-email>

      <app-input-password
        [fieldName]="'passwordValue'"
        [hint]="'login.input-password' | translate"
      ></app-input-password>

      <span>
        <button
          tuiLink
          class="forgot-pw-button"
          (click)="isOpen = true"
          type="button"
        >
          <label class="tui-text_body-s">{{ "login.forgot-password" | translate }}</label>
        </button>
      </span>
      <button
        tuiButton
        type="submit"
        [disabled]="!loginForm.valid"
        appearance="primary"
        class="submit-button"
      >
        {{ "login.button-login" | translate }}
      </button>
    </form>
  </div>

  <div class="hero-image-container">
    <img
      src="assets/img/hero.png"
    />
  </div>


</div>



<div class="bubble-gradient-bottom"></div>

<app-forgot-password
  (closingEvent)="isOpen = false"
  [isOpen]="isOpen"
></app-forgot-password>
