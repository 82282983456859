<div class="table-header" id="table-header">
  <div id="left-table-header">
    <app-tenant-selector-dropdown
      *ngIf="isSuperAdmin() && tenantSelector"
    ></app-tenant-selector-dropdown>
    <h1>{{ tableTitle }}</h1>
  </div>
  <div id="right-table-header">
    <app-table-search-input
      (searchChangedDebounced)="onSearch($event)"
      *ngIf="hasSearch"
      style="min-width: 10rem"
    />

    <tui-tabs
      (click)="filterTable()"
      *ngIf="showActiveTab"
      [(activeItemIndex)]="activeItemIndex"
    >
      <button tuiTab>{{ "table-header.filter-all" | translate }}</button>
      <button tuiTab>{{ "table-header.filter-active" | translate }}</button>
      <button tuiTab>{{ "table-header.filter-inactive" | translate }}</button>
    </tui-tabs>

    <button
      (click)="createNewClick()"
      *ngIf="showCreateButton"
      appearance="primary"
      id="new-company"
      size="m"
      tuiButton
      type="button"
    >
      {{ buttonText }}
    </button>
  </div>
</div>
