<app-menubar
  (closingEvent)="setCloseEvent()"
  [description]="'students.new-student.description' | translate"
  [open]="isOpen"
  [title]="'students.new-student.title' | translate"
>
  <form [formGroup]="newStudentForm">
    <app-input-number [fieldName]="'studentNumber'" [hint]="'students.input-label-student-number' | translate"></app-input-number>
    <app-input-text [fieldName]="'firstName'" [hint]="'students.input-label-first-name' | translate"></app-input-text>
    <app-input-text [fieldName]="'lastName'" [hint]="'students.input-label-last-name' | translate"></app-input-text>
   <tui-notification
      size="m"
      status="info"
      style="margin-bottom: 1.5rem; margin-top: 0"
      *ngIf="hasEmailOrPhoneError"
    >
      {{'students.error-email-or-phone-required' | translate}}
    </tui-notification>
    <app-input-email [fieldName]="'email'" [hint]="'students.input-label-mail' | translate" [required]="false"></app-input-email>
    <app-input-phone [fieldName]="'phone'" [formGroup]="newStudentForm" [hint]="'students.input-label-telephone' | translate"
                     [required]="false" [mobileWithCountryCode]="true"></app-input-phone>
    <div class="inline-inputs">
      <div class="big-input">
        <app-input-text [fieldName]="'street'" [hint]="'address.input-label-street' | translate"
                        [required]="false"></app-input-text>
      </div>
      <div class="small-input">
        <app-input-text [fieldName]="'houseNo'" [hint]="'address.input-label-house-number' | translate"
                        [required]="false"></app-input-text>
      </div>
    </div>

    <app-input-text [fieldName]="'postalCode'" [hint]="'address.input-label-zip-code' | translate"
                    [required]="false"></app-input-text>
    <app-input-text [fieldName]="'city'" [hint]="'address.input-label-city' | translate"
                    [required]="false"></app-input-text>
    <!--    <app-input-password [fieldName]="'password'" [hint]="'students.input-label-password' | translate"></app-input-password>-->
    <app-location-dropdown
      [fieldName]="'locationId'"
      [formGroup]="newStudentForm"
    >
    </app-location-dropdown>
    <app-licence-type-dropdown
      [fieldName]="'licenceTypeId'"
      [formGroup]="newStudentForm"

    >
    </app-licence-type-dropdown>
    <div class="button-wrapper">
      <button
        (click)="createNewStudent()"
        [disabled]="!newStudentForm.valid"
        size="m"
        tuiButton
        type="submit"
      >
        {{ 'common.button-add' | translate }}
      </button>
      <button
        (click)="setCloseEvent()"
        appearance="secondary"
        size="m"
        tuiButton
      >
        {{ 'common.button-cancel' | translate }}
      </button>
    </div>
  </form>
</app-menubar>
