<ng-template let-observer
             [tuiDialogOptions]="{ label: 'common.delete-title' | translate, size: 'm' , dismissible: false, closeable: false}"
             [(tuiDialog)]="isOpen"
>
  <div class="hint-container">
    <div class="hint-container-top">
      <tui-svg [src]="tuiIconAlertOctagonLarge"></tui-svg>
      <p> {{deleteHint}}</p>
    </div>
    <tui-notification
      *ngIf="highlightedHint.length > 0"
      status="error"
      class="tui-space_vertical-4"
    >
      {{ highlightedHint}}
    </tui-notification>

  </div>
  <hr>
  <div class="button-wrapper">
      <button
        tuiButton
        appearance="flat"
        size="l"
        type="button"
        (click)="cancelEvent.emit()"
      >
        {{ 'common.button-cancel' | translate }}
      </button>
      <button
        tuiButton
        size="l"
        appearance="secondary-destructive"
        type="button"
        (click)="deleteEvent.emit()"
      >
        {{ 'common.button-delete' | translate }}
      </button>
  </div>
</ng-template>
