<ng-template let-observer
  [tuiDialogOptions]="{ label: dialogTitle, size: 's' , dismissible: false, closeable: false}"
  [(tuiDialog)]="isOpen"
>
  <tui-elastic-container>
    <!-- The default screen that is shown when the user opens the push -->
    <ng-container *ngIf="!isSent">
      <form [formGroup]="forgotPasswordForm">
        <p class="instruction-text">
          {{ 'forgot-pw.description' | translate }}
        </p>
        <app-input-text
          [fieldName]="'emailValue'"
          [hint]="'forgot-pw.input-mail' | translate"
        >
        </app-input-text>
        <div class="button-wrapper">
          <button
            tuiButton
            [disabled]="!forgotPasswordForm.valid"
            type="submit"
            (click)="submitPasswordReset()"
          >
            {{ 'forgot-pw.button-get-link' | translate }}
          </button>
          <button
            tuiButton
            appearance="secondary"
            (click)="closeDialog()"
            type="button"
          >
            {{ 'common.button-cancel' | translate }}
          </button>
        </div>
      </form>
    </ng-container>

    <!-- The message that is shown after the user has successfully requested a password reset -->
    <ng-container *ngIf="isSent">
      <p class="instruction-text">
        {{ 'forgot-pw.email-sent-title' | translate }}
        <b>{{ forgotPasswordForm.controls.emailValue.value }}.</b>
        {{ 'forgot-pw.email-sent-description' | translate }}
      </p>
      <div class="button-wrapper">
        <button
          tuiButton
          appearance="secondary"
          (click)="isSent = false"
          type="button"
        >
          {{ 'forgot-pw.email-sent-button' | translate }}
        </button>
        <button
          tuiButton
          appearance="primary"
          (click)="closeDialog()"
          (click)="closeDialog()"
          type="submit"
        >
          {{ 'common.button-cancel' | translate }}
        </button>
      </div>
    </ng-container>
  </tui-elastic-container>
</ng-template>
