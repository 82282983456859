import { Component, HostListener } from "@angular/core";
import { Student } from "../../models/student";
import { LoadingService } from "../../services/loading.service";
import { tuiIconSave, tuiIconSmileLarge } from "@taiga-ui/icons";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { StudentBody } from "../../interfaces/body/student-body";
import { AuthService } from "../../services/auth.service";
import { StudentService } from "../../services/student.service";
import { PushService } from "../../services/push.service";
import { pushTypes } from "../../enums/push-types";
import { NavRoutes } from "../../enums/nav-routes";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { lastValueFrom } from "rxjs";
import { HttpStatusCode } from "@angular/common/http";
import { LicenceType } from "../../models/licence-type";
import { phoneValidator } from "../../validators/phone-validator";
import { WalletType } from "../../enums/wallet-type";
import { phoneOrEmailValidator } from "../../validators/phone-or-email-validator";
import { phoneCountryCodeValidator } from "../../validators/phone-country-code-validator";

@Component({
  selector: "app-student-detail-page",
  templateUrl: "./student-detail-page.component.html",
  styleUrls: ["./student-detail-page.component.scss"],
})
export class StudentDetailPageComponent {
  studentId = "";
  student!: Student;
  isLoading$ = this.loadingService.getLoadingState();

  titleString: string = "students.student-detail.title-edit";

  // oldLicenceIds: string[] = []; // used to determine which targets are new
  showDeleteDialog: boolean = false;
  studentsLicenceDetails: LicenceType[] = [];

  pushNotificationPatchedSuccess: string = "";
  pushNotificationPatchedError: string = "";
  pushNotificationDeletedSuccess: string = "";
  pushNotificationDeletedError: string = "";
  pushNotificationLicenceError: string = "";

  radioListActive: any[] = [{ active: true }, { active: false }];

  sendRequest: boolean = false;

  /**
   * BreadcrumbItems for the breadcrumb navigation from the detail to the general student page
   */
  breadcrumbItems = [
    {
      caption: "students.student-detail.breadcrumb-item-caption",
      routerLink: `/${NavRoutes.STUDENT}`,
    },
    {
      caption: "students.student-detail.breadcrumb-item-caption-detail",
      routerLink: null,
      routerLinkActiveOptions: { exact: true },
    },
  ];
  readonly studentForm: FormGroup = new FormGroup(
    {
      firstName: new FormControl(``, [Validators.required]),
      lastName: new FormControl(``, [Validators.required]),
      phone: new FormControl(``, [Validators.minLength(12)]),
      email: new FormControl(``, [Validators.email]),
      houseNo: new FormControl(``),
      street: new FormControl(``),
      postalCode: new FormControl(``),
      city: new FormControl(``),
      locationId: new FormControl(null, [Validators.required]),
      licenceTypes: new FormArray([]),
      active: new FormControl(``),
      studentNumber: new FormControl(null, [Validators.required]),
    },
    phoneOrEmailValidator(),
  );
  protected readonly tuiIconSmileLarge = tuiIconSmileLarge;
  protected readonly tuiIconSave = tuiIconSave;
  protected readonly WalletType = WalletType;

  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
    private studentService: StudentService,
    private pushService: PushService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private snack: PushService,
  ) {}

  get licenceTypes(): FormArray {
    return this.studentForm.get("licenceTypes") as FormArray;
  }

  ngOnInit(): void {
    //this.studentForm.disable();
    this.getStudentWithRouteParam();

    // translated strings for push-notifications
    this.translateService
      .get([
        "students.student-detail.push-success-patched",
        "students.student-detail.push-error-patched",
        "students.student-detail.push-success-deleted",
        "students.student-detail.push-error-deleted",
        "students.student-detail.push-error-patched-licence",
      ])
      .subscribe((translations) => {
        this.pushNotificationPatchedSuccess =
          translations["students.student-detail.push-success-patched"];
        this.pushNotificationPatchedError =
          translations["students.student-detail.push-error-patched"];
        this.pushNotificationDeletedSuccess =
          translations["students.student-detail.push-success-deleted"];
        this.pushNotificationDeletedError =
          translations["students.student-detail.push-error-deleted"];
        this.pushNotificationLicenceError =
          translations["students.student-detail.push-error-patched-licence"];
      });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    // Check if the page has been scrolled
    if (window.scrollY > 50) {
      this.titleString = "";
    } else {
      this.titleString = "students.student-detail.title-edit";
    }
  }

  addTargetFormRow() {
    const targetForm = new FormGroup({
      licenceTypeId: new FormControl("", [Validators.required]),
    });

    this.licenceTypes.push(targetForm);
  }

  deleteTargetFormRow(index: number) {
    this.licenceTypes.removeAt(index);
  }

  /**
   * Submits the form values to the api in order to update the student
   * Sends a push notification if the student was updated successfully or not
   */
  async submitForm() {
    if (this.studentForm.valid) {
      if (this.sendRequest) return;
      this.sendRequest = true;

      const studentBody: StudentBody = {
        firstName: this.studentForm.controls["firstName"].value,
        lastName: this.studentForm.controls["lastName"].value,
        email: this.studentForm.controls["email"].value,
        phone: this.studentForm.controls["phone"].value ?? "",
        active: this.studentForm.controls["active"].value.active,
        tenantId: this.authService.getLoggedInUser()?.tenantId ?? "",
        address: {
          street: this.studentForm.controls["street"].value,
          houseNo: this.studentForm.controls["houseNo"].value,
          postalCode: this.studentForm.controls["postalCode"].value,
          city: this.studentForm.controls["city"].value,
        },
        locationId: this.studentForm.controls["locationId"].value,
        studentNumber: this.studentForm.controls["studentNumber"].value,
      };
      for (let licenceType of this.licenceTypes.value) {
        const addedLicenceTypeStatus = await lastValueFrom(
          this.studentService.addLicenceTypeToStudent({
            studentId: this.studentId,
            licenceTypeId: licenceType.licenceTypeId.id,
          }),
        );
        if (addedLicenceTypeStatus !== HttpStatusCode.Created) {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationLicenceError,
          );
          return;
        }
      }
      this.studentService
        .updateStudent(studentBody, this.studentId)
        .subscribe((isPatched) => {
          if (isPatched) {
            this.pushService.sendPush(
              pushTypes.SUCCESS,
              this.pushNotificationPatchedSuccess,
            );
            this.student.firstName = studentBody.firstName;
            this.student.lastName = studentBody.lastName;
            this.router.navigate([NavRoutes.STUDENT]);
          } else {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationPatchedError,
            );
          }
        });
      this.sendRequest = false;
    } else {
      this.pushService.sendPush(
        pushTypes.ERROR,
        this.pushNotificationPatchedError,
      );
    }
  }

  /**
   * Deletes the student with the id from the route parameter
   * Sends a push notification if the student was deleted successfully or not
   */
  deleteStudent() {
    if (this.sendRequest) return;
    this.sendRequest = true;
    this.studentService.deleteStudent(this.studentId).subscribe((isDeleted) => {
      this.sendRequest = false;
      if (isDeleted) {
        this.pushService.sendPush(
          pushTypes.SUCCESS,
          this.pushNotificationDeletedSuccess,
        );
        this.router.navigate([NavRoutes.STUDENT]);
      } else {
        this.pushService.sendPush(
          pushTypes.ERROR,
          this.pushNotificationDeletedError,
        );
      }
    });
  }

  /**
   * Gets the user from the api with the id from the route parameter
   * Sets the form values to the user values in order to edit them
   */
  public getStudentWithRouteParam(): void {
    this.route.paramMap.subscribe((params) => {
      this.studentId = params.get("id")!;
      if (this.studentId != null) {
        this.studentService.getStudentById(this.studentId).subscribe(
          (student) => {
            this.student = student;
            this.studentForm.controls["email"].setValue(this.student.email);
            this.studentForm.controls["firstName"].setValue(
              this.student.firstName,
            );
            this.studentForm.controls["lastName"].setValue(
              this.student.lastName,
            );
            this.studentForm.controls["phone"].setValue(this.student.phone);
            this.setActiveRadioValue(this.student.active);
            this.studentForm.controls["studentNumber"].setValue(
              this.student.studentNumber,
            );
            if (this.student.address != null) {
              this.studentForm.controls["street"].setValue(
                this.student.address.street,
              );
              this.studentForm.controls["houseNo"].setValue(
                this.student.address.houseNo,
              );
              this.studentForm.controls["postalCode"].setValue(
                this.student.address.postalCode,
              );
              this.studentForm.controls["city"].setValue(
                this.student.address.city,
              );
            }

            if (this.student.licences != null) {
              for (let licence of this.student.licences) {
                this.studentsLicenceDetails.push(licence);
              }
            }
            this.studentForm.markAllAsTouched();
          },
          (error) => {
            this.router.navigate([NavRoutes.ERROR], {
              queryParams: {
                code: error.error.code,
                key: error.error.key,
                url: error.url,
              },
            });
          },
        );
      }
    });
  }

  /**
   * Sets the text and boolean value for the radio buttons depending on the received active value
   * @param active the active value of the user
   */
  setActiveRadioValue(active: boolean) {
    if (active) {
      this.studentForm.controls["active"].setValue(this.radioListActive[0]);
    } else {
      this.studentForm.controls["active"].setValue(this.radioListActive[1]);
    }
  }

  getFormGroupFromAbstractControl(abstractControl: AbstractControl): FormGroup {
    return abstractControl as FormGroup;
  }

  public licenceTypeIdDuplicates(): boolean {
    if (this.licenceTypes.controls.length === 0) {
      return false;
    }

    const licenceTargetIds = new Set<string>();

    for (let studentLicenceDetail of this.studentsLicenceDetails) {
      licenceTargetIds.add(studentLicenceDetail.id);
    }

    for (let licenceType of this.licenceTypes.controls) {
      const licenceTypeId = licenceType.value.licenceTypeId;
      if (licenceTypeId != null && licenceTargetIds.has(licenceTypeId.id)) {
        return true;
      }
      if (licenceTypeId) {
        licenceTargetIds.add(licenceTypeId.id);
      }
    }

    return false;
  }

  sendInviteMessageAgain(id: string) {
    this.authService.resendInviteMessage(id).subscribe({
      next: () => {
        this.snack.sendPush(
          pushTypes.SUCCESS,
          "Einladung wurde erneut gesendet!",
        );
      },
      error: () => {
        this.snack.sendPush(
          pushTypes.ERROR,
          "Einladung konnte nicht erneut gesendet werden!",
        );
      },
    });
  }
}
