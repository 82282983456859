import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams, HttpStatusCode } from "@angular/common/http";
import { PaginationFilterService } from "./pagination-filter.service";
import { catchError, map, Observable, of } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/body/response-with-recors-body";
import { ApiRoutes } from "../enums/api-routes";
import { StudentQueryParams } from "../interfaces/query-param/student-query-params";
import { Student } from "../models/student";
import { StudentBody } from "../interfaces/body/student-body";
import { LessonProgressCountQueryParams } from "../interfaces/query-param/lesson-progress-count-query-params";
import { LessonProgressCountBody } from "../interfaces/body/lesson-progess-count-body";

type LicenceAndStudentIds = {
  studentId: string;
  licenceTypeId: string;
};

@Injectable({
  providedIn: "root",
})
/**
 * Service Class for the getting or manipulating student data
 */
export class StudentService extends PaginationFilterService {
  selectedStudentForTransaction: Student | undefined;
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * get the student data from the api
   * sets query params for the api call if they are passed in
   * sets the totalAmount property of the parent PaginationFilterService if data is returned
   * maps the response to an array of observable students objects with the Student.fromJson method
   * @param queryParams optional query params for the api call
   */
  getStudent(queryParams?: StudentQueryParams): Observable<Student[]> {
    let params: HttpParams = new HttpParams();

    if (this.filter.tabIndex === 1) {
      params = params.set("active", "true");
    } else if (this.filter.tabIndex === 2) {
      params = params.set("active", "false");
    }

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    if (this.sort !== undefined && this.sort !== "") {
      params = params.set("sort", this.sort);
    }

    if (this.endIndex !== "") {
      params = params.set("limit", this.endIndex);
    }

    if (this.startIndex !== "") {
      params = params.set("skip", this.startIndex);
    }

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + ApiRoutes.STUDENT, {
        params,
      })
      .pipe(
        map((response) => {
          this.totalAmount = response.total;
          return response.records.map((student: any) =>
            Student.fromJson(student),
          );
        }),
      );
  }

  /**
   * get  student count from the api
   */
  getCustomerCount(): number {
    return this.totalAmount;
  }

  /**
   * get detailed student data from the api with a user id
   * @param id the id of the student to get
   */
  getStudentById(id: string): Observable<Student> {
    return this.http.get<any>(this.baseUrl + ApiRoutes.STUDENT + "/" + id).pipe(
      map((response) => {
        return Student.fromJson(response);
      }),
    );
  }

  /**
   * Sends a request to the api to create a new student
   * @param student the student to create as StudentBody
   */
  createStudent(student: StudentBody): Observable<HttpStatusCode | Student> {
    return this.http
      .post<any>(this.baseUrl + ApiRoutes.STUDENT, student, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          if (response.status === HttpStatusCode.Ok) {
            return Student.fromJson(response.body);
          }
          return response.status;
        }),
        catchError((err) => {
          return of(err.status);
        }),
      );
  }

  /**
   * Sends a request to the api to patch a student
   * @param student the student to update as StudentBody
   * @param id the id of the student to update
   */
  updateStudent(student: StudentBody, id: string): Observable<boolean> {
    return this.http
      .patch<any>(this.baseUrl + ApiRoutes.STUDENT + "/" + id, student, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError((error) => {
          return of(false);
        }),
      );
  }

  /**
   * Deletes a student from the API
   * @param id the id of the student to delete
   * @returns true if the student was deleted successfully, false if not
   */
  deleteStudent(id: string): Observable<boolean> {
    return this.http
      .delete<any>(this.baseUrl + ApiRoutes.STUDENT + "/" + id, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  addLicenceTypeToStudent(
    licenceAndStudentIds: LicenceAndStudentIds,
  ): Observable<number> {
    return this.http
      .post<any>(
        `${this.baseUrl}${ApiRoutes.STUDENT}/licenceType`,
        licenceAndStudentIds,
        { observe: "response" },
      )
      .pipe(
        map((response) => {
          return response.status;
        }),
        catchError((error) => {
          return of(error.status);
        }),
      );
  }

  getLessonProgressCount(
    queryParams: LessonProgressCountQueryParams,
  ): Observable<LessonProgressCountBody[]> {
    const params = new HttpParams()
      .set(
        queryParams?.tenantId !== undefined ? "tenantId" : "",
        queryParams?.tenantId ?? "",
      )
      .set(
        queryParams?.studentId !== undefined ? "studentId" : "",
        queryParams?.studentId ?? "",
      )
      .set("licenceTypeId", queryParams?.licenceTypeId);

    return this.http.get<LessonProgressCountBody[]>(
      this.baseUrl +
        ApiRoutes.KPI +
        "/" +
        ApiRoutes.USER +
        "/" +
        ApiRoutes.LESSON_PROGRESS_COUNT,
      {
        params,
      },
    );
  }
}
