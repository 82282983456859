import { BaseModel } from './base-model';

export class LessonCategory extends BaseModel {
  private _tenantId: string;
  private _name: string;

  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    tenantId: string,
    name: string,
  ) {
    super(id, createdAt, updatedAt);
    this._tenantId = tenantId;
    this._name = name;
  }

  get tenantId(): string {
    return this._tenantId;
  }

  set tenantId(value: string) {
    this._tenantId = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
  static fromJson(json: any): any {
    return new LessonCategory(
      json.id,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.tenantId,
      json.name,
    );
  }

  // todo implement
  toJSON(): any {}
}
