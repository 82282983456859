import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { tuiIconDollarSign } from "@taiga-ui/icons";

@Component({
  selector: 'app-input-payment-amount',
  templateUrl: './input-payment-amount.component.html',
  styleUrls: ['./input-payment-amount.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class InputPaymentAmountComponent {
  @Input() minValue: number = 0;
  @Input() hint: string = '';
  @Input() fieldName: string = '';
  @Input() postfix: string = '€';
  protected readonly tuiIconDollarSign = tuiIconDollarSign;

  constructor() {
  }

  ngOnInit() {
  }
}
