<div class="">
  <h2 class="headline-credit"> <span *ngIf="walletType===WalletType.STUDENT">{{"common.credit" | translate}}:</span> <span *ngIf="walletType===WalletType.TEACHER">{{"common.credit-teacher" | translate}}:</span> &nbsp;<span [ngClass]="this.wallet >= 0 ? 'primary-text' : 'red-text'">{{wallet}} €</span></h2>
  <app-table
    class="dark-header"
    (paginationEvent)="paginationEvent($event)"
    (rowClickEvent)="rowClickEvent($event)"
    (sortEvent)="sortTable($event)"
    [tableColumnNames]="[
          'payments.table.date' | translate,
          'payments.table.amount' | translate,
          'payments.table.payment-type' | translate,
          ]"
    [columns]="transactionTableShownColumns"
    [tableData]="transactionTableData"
    [totalElements]="totalTransactions"
    [currentPage]="tablePage"
    [pageSize]="tablePageSize"
    [alternativeColorHeader]="true"
    [paymentTable]="true"
    [showPagination]="showPagination"
  ></app-table>
</div>
