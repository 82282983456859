import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DropDownItem } from '../../interfaces/drop-down-item';
import { LicenceType } from '../../models/licence-type';
import { SuperAdminService } from '../../services/super-admin.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { LicenceTypeService } from '../../services/licence-type.service';

type ObjectWithLicenceId = {
  licenceTypeId: string;
};

@Component({
  selector: 'app-licence-type-dropdown',
  templateUrl: './licence-type-dropdown.component.html',
  styleUrls: ['./licence-type-dropdown.component.scss'],
})
export class LicenceTypeDropdownComponent {
  @Input() fieldName: string = '';
  @Input() formGroup: FormGroup | undefined;
  @Input() required = true;
  @Input() objectWithLicenceId: ObjectWithLicenceId | undefined;

  selectedTenantSubscription = new Subscription();
  licenceTypeDropdownItems: DropDownItem[] = [];
  licenceTypes: LicenceType[] = [];

  constructor(
    private superAdminService: SuperAdminService,
    private licenceTypeService: LicenceTypeService,
    private authService: AuthService,
    private utilService: UtilService,
  ) {}

  ngOnInit() {
    this.getLicenceTypes();
  }

  ngOnDestroy() {
    this.selectedTenantSubscription.unsubscribe();
  }

  getLicenceTypes() {
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe((id) => {
          if (id != null) {
            this.getLicenceTypesForTenant(id);
          }
        });
    } else {
      const tenantId = this.authService.getTenantId();
      if (tenantId == null) return;
      this.getLicenceTypesForTenant(tenantId);
    }
  }

  getLicenceTypesForTenant(tenantId: string) {
    this.licenceTypeService
      .getLicenceType({ tenantId: tenantId })
      .subscribe((data) => {
        this.licenceTypes = data;
        this.licenceTypeDropdownItems =
          this.utilService.generateDropdownItems(data);
        if (this.objectWithLicenceId == null) return;
        this.formGroup?.controls[this.fieldName].setValue({
          id: this.objectWithLicenceId?.licenceTypeId,
          label: this.licenceTypes.find(
            (category) =>
              category.id === this.objectWithLicenceId?.licenceTypeId,
          )?.name,
        });
      });
  }
}
