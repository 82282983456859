import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import { pushTypes } from '../enums/push-types';
import { TranslateService } from '@ngx-translate/core';
import { TuiAlertService } from '@taiga-ui/core';

@Injectable({
  providedIn: 'root',
})
/**
 * Service Class for displaying push-notifications
 */
export class PushService {
  topLine!: string;
  icon!: string;
  color!: string;
  success!: string;
  error!: string;
  info!: string;
  warning!: string;
  close!: string;

  constructor(
    @Inject(TuiAlertService) protected readonly alert: TuiAlertService,
    private translateService: TranslateService,
  ) {
  }

  sendPush(type: pushTypes, additonalInfo?: string) {
    // translated strings for push-notifications
    this.translateService
      .get(['alert.success', 'alert.error', 'alert.info', 'alert.warning'])

      .subscribe((translations) => {
        this.success = translations['alert.success'];
        this.error = translations['alert.error'];
        this.info = translations['alert.info'];
        this.warning = translations['alert.warning'];

        if (type === pushTypes.ERROR) {
          this.topLine = this.error;
        } else if (type === pushTypes.INFO) {
          this.topLine = this.info;
        } else if (type === pushTypes.SUCCESS) {
          this.topLine = this.success;
        } else if (type === pushTypes.WARNING) {
          this.topLine = this.warning;
        }
      });

    this.alert
      .open(additonalInfo || '', {
        //@ts-ignore
        status: type,
        label: this.topLine,
        autoClose: true,
        hasCloseButton: true,
        hasIcon: true,
      })
      .pipe(take(1))
      .subscribe();
  }
}
