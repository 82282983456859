<app-menubar
  (closingEvent)="setCloseEvent()"
  [blur]="false"
  [description]="'new-event.description' | translate"
  [open]="isOpen"
  [title]="'new-event.title' | translate"
>
  <form [formGroup]="eventForm">

    <app-dropdown
      (emitChangeEvent)="onLocationChange($event)"
      [dropDownItems]="locationDropdownItems"
      [formFieldName]="'location'"
      [hint]="'students.input-label-location' | translate"
      [required]="true"
      [showError]="true"
    >
    </app-dropdown>

    <div>
      <app-dropdown
        (emitChangeEvent)="onStudentChange($event)"
        [dropDownItems]="dropDownStudentItems"
        [formFieldName]="'assignedToStudent'"
        [hint]="'new-event.input-student' | translate"
      />

      <div *ngIf="selectedStudentId != ''" class="link-wrapper">
        <a [routerLink]="['/student', selectedStudentId]" tuiLink>
          {{ "event-detail.button-student" | translate }}
        </a>

        <span *ngIf="studentWalletValue">
         {{ 'students.input-label-credit' | translate }}:  {{ studentWalletValue }}€
        </span>
      </div>
      <div class="error-wrapper">
        <tui-error
          [error]="[] | tuiFieldError | async"
          formControlName="assignedToStudent"
        ></tui-error>
      </div>
    </div>

    <div>
      <app-dropdown
        (emitChangeEvent)="onLicenceTypeChange($event)"
        [dropDownItems]="licenceTypeDropdownItems"
        [formFieldName]="'licenceType'"
        [hint]="'students.input-licence-hint' | translate"
        [showError]="true"
      >
      </app-dropdown>
    </div>
    <div class="category-dropdown">
      <app-dropdown
        (emitChangeEvent)="onCategoryChange($event)"
        [dropDownItems]="categoryDropdownItems"
        [formFieldName]="'category'"
        [hint]="'new-event.input-type' | translate"
      >
      </app-dropdown>
    </div>

    <div>
      <app-dropdown (emitChangeEvent)="onTeacherChange($event)" [dropDownItems]="dropDownUserItems"
                    [formFieldName]="'assignedToTeacher'" [hint]="'new-event.input-teacher' | translate"></app-dropdown>
      <div *ngIf="selectedTeacherId != ''" class="link-wrapper">
        <a [routerLink]="['/user', selectedTeacherId]" tuiLink>
          {{ "event-detail.button-teacher" | translate }}
        </a>
      </div>
      <div class="error-wrapper">
        <tui-error
          [error]="[] | tuiFieldError | async"
          formControlName="assignedToTeacher"
        ></tui-error>
      </div>
    </div>

    <div class="date-time-wrapper">
      <div class="date-input-wrapper">
        <app-input-date (dateChange)="onStartDateChange($event)" [deactivatePastDates]="true" [fieldName]="'startDate'"
                        [hint]="'new-event.input-start-date' | translate" class="input-element">
          <span class="tui-required"></span>
        </app-input-date>
      </div>
    </div>

    <div class="date-time-wrapper">
      <div class="date-input-wrapper">
        <app-input-time (timeChange)="onStartTimeChange($event)" [fieldName]="'startTime'"
                        [hint]="'new-event.input-start-time' | translate"
                        class="input-element" tuiTextfieldSize="l">
          <span class="tui-required"></span>
        </app-input-time>
      </div>
      <div class="counter-input">

        <tui-select (tuiValueChanges)="onCounterChange($event)" [formControlName]="'counter'" tuiTextfieldSize="l">
          {{ 'new-event.input-counter' | translate }}
          <input tuiTextfield/>
          <tui-data-list-wrapper
            *tuiDataList
            [items]="counterItems"
          ></tui-data-list-wrapper>
        </tui-select>

        <!--        <app-input-number [fieldName]="'counter'" [hint]="'new-event.input-counter' | translate">-->
        <!--        </app-input-number>-->
      </div>
    </div>

    <div class="date-input-wrapper">
      <app-input-time [fieldName]="'endTime'" [hint]="'new-event.input-end-time' | translate" class="input-element"
                      tuiTextfieldSize="l">
        <span class="tui-required"></span>
      </app-input-time>
    </div>
    <h3 class="price-title"><span
      class="light-grey-not-bold">{{ ('event-detail.label-price' | translate) }}</span> {{ lessonPrice }} €</h3>
    <div class="tui-space_top-3">
      <tui-checkbox-labeled
        class="tui-space_bottom-3"
        formControlName="userNotification"
        size="l"
      >{{ "new-event.user-notification-label" | translate }}
      </tui-checkbox-labeled>
      <tui-checkbox-labeled
        class="tui-space_bottom-3"
        formControlName="studentNotification"
        size="l"
      >{{ "new-event.student-notification-label" | translate }}
      </tui-checkbox-labeled>
    </div>

    <div class="button-wrapper">
      <button
        (click)="createNewLesson()"
        [disabled]="!eventForm.valid"
        size="m"
        tuiButton
        type="button"
      >
        {{ "new-event.button-save" | translate }}
      </button>
      <button (click)="setCloseEvent()" appearance="secondary" size="m" tuiButton type="button">
        {{ "common.button-cancel" | translate }}
      </button>
    </div>
  </form>
</app-menubar>
