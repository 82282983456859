import { BaseModel } from './base-model';
import { User } from './user';
import { UserRole } from '../enums/user-role';

/**
 * Model Class for creating Role instances
 * extends the BaseModel
 */
export class Role extends BaseModel {
  private _tenantId: string;
  private _name: string;
  private _description: string;
  private _global: boolean;
  private _draft: boolean;
  private _userRole: UserRole;

  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    tenantId: string,
    name: string,
    description: string,
    global: boolean,
    draft: boolean,
    userRole: UserRole,
  ) {
    super(id, createdAt, updatedAt);
    this._tenantId = tenantId;
    this._name = name;
    this._description = description;
    this._global = global;
    this._draft = draft;
    this._userRole = userRole;
  }

  get tenantId(): string {
    return this._tenantId;
  }

  set tenantId(value: string) {
    this._tenantId = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get global(): boolean {
    return this._global;
  }

  set global(value: boolean) {
    this._global = value;
  }

  get draft(): boolean {
    return this._draft;
  }

  set draft(value: boolean) {
    this._draft = value;
  }

  get userRole(): UserRole {
    return this._userRole;
  }

  set userRole(value: UserRole) {
    this._userRole = value;
  }

  static fromJson(json: any): any {
    return new Role(
      json.id,
      json.createdAt,
      json.updatedAt,
      json.tenantId,
      json.name,
      json.description,
      json.global,
      json.draft,
      json.userRole,
    );
  }

  toJson(): any {}
}
