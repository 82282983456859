import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

type SelectedTime = {
  startTime: Date;
  endTime: Date;
};

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  // selected calendar time / date for communication with the new event component
  private _selectedStartTime!: Date;
  private _selectedEndTime!: Date;
  public readonly maxMinutes = 135;
  public readonly maxLessonCounterValue = 3;

  private _selectedTime$ = new BehaviorSubject<SelectedTime | undefined>(
    undefined,
  );

  constructor() {}

  get selectedStartTime(): Date {
    return this._selectedStartTime;
  }

  set selectedStartTime(value: Date) {
    this._selectedStartTime = value;
  }

  get selectedEndTime(): Date {
    return this._selectedEndTime;
  }

  set selectedEndTime(value: Date) {
    this._selectedEndTime = value;
  }

  get selectedTime$(): BehaviorSubject<SelectedTime | undefined> {
    return this._selectedTime$;
  }
}
