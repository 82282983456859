<h2>{{ 'dashboard.tile-appointments' | translate }}</h2>

<app-table
  (paginationEvent)="paginationEvent($event)"
  (sortEvent)="sortTable($event)"
  (rowClickEvent)="rowClickEvent($event)"
  [columns]="columns"
  [currentPage]="tablePage"
  [noSortColumns]="noSortColumns"
  [pageSize]="tablePageSize"
  [tableColumnNames]="headers"
  [tableData]="lessons ??  []"
  [totalElements]="totalLessons"
/>

