import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { ApiRoutes } from '../enums/api-routes';
import { catchError, map, Observable, of, throwError, } from 'rxjs';
import { PaginationFilterService } from './pagination-filter.service';
import { environment } from '../../environments/environment';
import { LessonQueryParams } from '../interfaces/query-param/lesson-query-params';
import { ResponseWithRecordsBody } from '../interfaces/body/response-with-recors-body';
import { LessonBody } from '../interfaces/body/lesson-body';
import { Lesson } from '../models/lesson';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class LessonService extends PaginationFilterService {
  readonly baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
  ) {
    super();
  }

  getLessons(queryParams?: LessonQueryParams) {
    const params = new HttpParams()
      .set(
        queryParams?.vcIsLogEntity !== undefined ? 'vcIsLogEntity' : '',
        queryParams?.vcIsLogEntity ?? '',
      )
      .set(
        queryParams?.locationId !== undefined ? 'locationId' : '',
        queryParams?.locationId ?? '',
      )
      .set(
        queryParams?.tenantId !== undefined ? 'tenantId' : '',
        queryParams?.tenantId ?? '',
      )
      .set(
        queryParams?.studentId !== undefined ? 'studentId' : '',
        queryParams?.studentId ?? '',
      )
      .set(
        queryParams?.userId !== undefined ? 'userId' : '',
        queryParams?.userId ?? '',
      )
      .set(
        queryParams?.limitDate !== undefined ? 'startDateTo' : '',
        queryParams?.limitDate ?? '',
      )
      .set(
        queryParams?.skipDate !== undefined ? 'startDateFrom' : '',
        queryParams?.skipDate ?? '',
      )
      .set(
        queryParams?.status !== undefined ? 'status' : '',
        queryParams?.status ?? '',
      )
      .set(
        queryParams?.category !== undefined ? 'category' : '',
        queryParams?.category ?? '',
      )
      .set(
        queryParams?.name !== undefined ? 'name' : '',
        queryParams?.name ?? '',
      )
      .set(this.sort !== undefined ? 'sort' : '', this.sort ?? '')
      .set(this.endIndex !== '' ? 'limit' : '', this.endIndex)
      .set(this.startIndex !== '' ? 'skip' : '', this.startIndex);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + ApiRoutes.LESSON, {
        params,
      })
      .pipe(
        map((response) => {
          this.totalAmount = response.total;
          return response.records.map((lesson: any) => {
            return Lesson.fromJson(lesson);
          });
        }),
      );
  }

  /**
   * get  student count from the api
   */
  getLessonCount(): number {
    return this.totalAmount;
  }

  /**
   * get detailed student data from the api with a user id
   * @param id the id of the student to get
   */
  getLessonById(id: string, queryParams?: LessonQueryParams): Observable<Lesson> {
    const params = new HttpParams()
      .set(
        queryParams?.vcIsLogEntity !== undefined ? 'vcIsLogEntity' : '',
        queryParams?.vcIsLogEntity ?? '',
      )

    return this.http.get<any>(this.baseUrl + ApiRoutes.LESSON + '/' + id, {
      params,
    }).pipe(
      map((response) => {
        return Lesson.fromJson(response);
      }),
    );
  }

  /**
   * Sends a request to the api to create a new lesson
   * @param lesson everything needed to create a new lesson
   * @param notifyUser if the user should be notified
   * @param notifyStudent if the student should be notified
   */
  createLesson(
    lesson: LessonBody,
    notifyUser: boolean,
    notifyStudent: boolean,
  ): Observable<Lesson> {
    const searchParams = new URLSearchParams();
    searchParams.append('notifyUser', String(notifyUser));
    searchParams.append('notifyStudent', String(notifyStudent));

    return this.http
      .post<any>(this.baseUrl + ApiRoutes.LESSON + '?' + searchParams, lesson, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.body;
        }),
        catchError((error) => {
          return throwError(error);
        }),
      );
  }

  updateLesson(
    lesson: LessonBody,
    id: string,
    notifyUser: boolean,
    notifyStudent: boolean,
  ): Observable<Lesson> {
    const searchParams = new URLSearchParams();
    searchParams.append('notifyUser', String(notifyUser));
    searchParams.append('notifyStudent', String(notifyStudent));

    return this.http
      .patch<any>(
        this.baseUrl + ApiRoutes.LESSON + '/' + id + '?' + searchParams,
        lesson,
        {
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          return response.body;
        }),
        catchError((error) => {
          return throwError(error);
        }),
      );
  }

  deleteLesson(id: string): Observable<boolean> {
    return this.http
      .delete<any>(this.baseUrl + ApiRoutes.LESSON + '/' + id, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  getSignFilesById(fileId: string) {
    return this.http.get<any>(
      this.baseUrl + `${ ApiRoutes.LESSON }/${ ApiRoutes.SIGN }/${ fileId }`,
      {
        responseType: 'blob' as 'json',
      },
    );
  }
}
