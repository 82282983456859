import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DropDownItem } from '../interfaces/drop-down-item';

export function emptyDropdownItemValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dropdownItem = control.value as DropDownItem;

    if (
      dropdownItem === null ||
      dropdownItem === undefined ||
      (dropdownItem.id === '' && dropdownItem.label === '')
    ) {
      return { emptyDropdownItem: true };
    }

    return null;
  };
}
