import { Component } from "@angular/core";
import { FileType, Transaction } from "../../models/transaction";
import { LoadingService } from "../../services/loading.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TransactionService } from "../../services/transaction.service";
import { Location } from "@angular/common";
import { PushService } from "../../services/push.service";
import { TranslateService } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";
import { TransactionBody } from "../../interfaces/body/transaction-body";
import { pushTypes } from "../../enums/push-types";
import { HttpStatusCode } from "@angular/common/http";
import { UserRole } from "../../enums/user-role";
import { AuthService } from "../../services/auth.service";
import { catchError, of } from "rxjs";

@Component({
  selector: "app-transaction-detail-page",
  templateUrl: "./transaction-detail-page.component.html",
  styleUrls: ["./transaction-detail-page.component.scss"],
})
export class TransactionDetailPageComponent {
  transactionId!: string;
  transaction: Transaction | undefined;

  isLoading$ = this.loadingService.getLoadingState();
  imageFile: FileType | undefined;
  otherFile: FileType | undefined;

  openImageDialog = false;
  shownImageUrl = "";

  sendRequest: boolean = false;
  commentControl = new FormControl("");

  // strings for i18n
  pushNotificationSuccess: string = "";
  pushNotificationError: string = "";
  showDeleteDialog: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private transactionService: TransactionService,
    private location: Location,
    private pushService: PushService,
    private translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
  ) {}

  get isSuperAdminOrTenantAdmin() {
    const userRole = this.authService.getUserRole();
    return (
      userRole === UserRole.SUPER_ADMIN || userRole === UserRole.TENANT_ADMIN
    );
  }

  ngOnInit(): void {
    this.getTransactionWithRouteParam();

    // translated strings for push-notifications
    this.translateService
      .get([
        "payments.payment-details.push-notification-success",
        "payments.payment-details.push-notification-error",
      ])
      .subscribe((translations) => {
        this.pushNotificationSuccess =
          translations["payments.payment-details.push-notification-success"];
        this.pushNotificationError =
          translations["payments.payment-details.push-notification-error"];
      });
  }

  getTransactionWithRouteParam(): void {
    this.route.paramMap.subscribe((params) => {
      this.transactionId = params.get("paymentId")!;
      if (this.transactionId === null) return;
      this.transactionService
        .getTransactionById(this.transactionId)
        .subscribe((transaction) => {
          this.transaction = transaction;
          this.commentControl.setValue(this.transaction.comment);
          if (this.transaction.file != null) {
            if (
              this.isImage(
                this.transaction.file.mimetype,
                this.transaction.file.fileName,
              )
            ) {
              this.imageFile = this.transaction.file;
              this.getImageFileWithFileId(this.imageFile);
            } else {
              this.otherFile = this.transaction.file;
            }
          }
        });
    });
  }

  goBack(): void {
    this.location.back();
  }

  isImage(mimeType: string, fileName: string): boolean {
    return (
      mimeType.includes("image") ||
      fileName.includes(".jpg") ||
      fileName.includes(".png")
    );
  }

  getImageFileWithFileId(file: FileType): void {
    this.transactionService
      .getTransactionFilesById(this.transactionId, file.id)
      .subscribe((response: any) => {
        const reader = new FileReader();
        reader.onload = () => {
          file.url = reader.result as string;
        };
        reader.readAsDataURL(response);
      });
  }

  // download file and open in new tab
  getOtherFileWithFileId(file: FileType): void {
    this.transactionService
      .getTransactionFilesById(this.transactionId, file.id)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: file.mimetype });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
        window.URL.revokeObjectURL(url);
      });
  }

  getPositiveAmount(num: number): number {
    return Math.abs(num);
  }

  // removeFile({ name }: File) {
  //   this.fileControl.setValue(
  //     this.fileControl.value!.filter((file: File) => file.name !== name) ?? [],
  //   );
  // }
  //
  // get hasFiles(): boolean {
  //   return (
  //     this.fileControl.value != null && this.fileControl.value?.length !== 0
  //   );
  // }

  // uploadFiles() {
  //   this.sendRequest = true;
  //   if (this.fileControl.value == null || this.fileControl.value?.length === 0)
  //     return;
  //
  //   const tenantId = this.authService.getTenantId();
  //   if (!tenantId) return;
  //
  //   const body: FileBody = {
  //     id: this.transactionId,
  //     tenantId: tenantId,
  //   };
  //
  //   for (let file of this.fileControl.value) {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     formData.append('tenantId', tenantId);
  //
  //     this.transactionService
  //       .createTransactionFile(formData, body)
  //       .subscribe((response: any) => {
  //         if (response.status === HttpStatusCode.Ok) {
  //           this.imageFile = undefined;
  //           this.otherFile = undefined;
  //           this.fileControl.reset();
  //           this.getTransactionWithRouteParam();
  //           this.sendRequest = false;
  //
  //           this.pushService.sendPush(
  //             pushTypes.SUCCESS,
  //             this.pushNotificationSuccess,
  //           );
  //         } else {
  //           this.sendRequest = false;
  //           this.pushService.sendPush(
  //             pushTypes.ERROR,
  //             this.pushNotificationError,
  //           );
  //         }
  //       });
  //   }
  // }

  openImageDialogWithFile(file: FileType): void {
    this.shownImageUrl = file.url!;
    this.openImageDialog = true;
  }

  updateComment(): void {
    this.sendRequest = true;
    const transactionBody: TransactionBody = {
      amount: this.transaction?.amount!,
      transactionType: this.transaction?.transactionType!,
      locationId: this.transaction?.locationId!,
      studentId: this.transaction?.studentId,
      teacherId: this.transaction?.teacherId,
      tenantId: this.transaction?.tenantId!,
      comment: this.commentControl.value ?? "",
    };
    this.transactionService
      .updateTransaction(transactionBody, this.transactionId)
      .subscribe({
        next: (response) => {
          this.sendRequest = false;
          this.goBack();
          if (response === HttpStatusCode.Ok) {
            this.pushService.sendPush(
              pushTypes.SUCCESS,
              this.pushNotificationSuccess,
            );
          } else {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationError,
            );
          }
        },
      });
  }

  openDeleteDialog() {
    this.showDeleteDialog = true;
  }

  deleteEvent() {
    if (!this.transactionId) return;

    this.transactionService
      .deleteTransactionById(this.transactionId)
      .pipe(
        catchError(() => {
          this.deleteTransactionErrorMsg();
          return of(null);
        }),
      )
      .subscribe(() => {
        this.deleteTransactionSuccessfulMsg();
        this.routeToTransactionsTable();
      });
  }

  routeToTransactionsTable() {
    this.router.navigate(["/payments"]).then();
  }

  deleteTransactionSuccessfulMsg() {
    this.pushService.sendPush(pushTypes.SUCCESS, "Zahlung wurde gelöscht!");
  }

  deleteTransactionErrorMsg() {
    this.pushService.sendPush(
      pushTypes.ERROR,
      "Zahlung konnte nicht gelöscht werden!",
    );
  }
}
