<!-- First Section -->
<ng-container *ngIf="diff && Object.keys(diff).length > 0">
  <h2>
    {{ "vc-latest-changes" | translate }}:
    <ng-container *ngIf="latestLessonTimestamp">
      {{ latestLessonTimestamp | date: "dd.MM.yyyy HH:mm" }}
    </ng-container>
  </h2>

  <div class="changes-container" style="margin-bottom: 2rem">
    <div *ngFor="let key of Object.keys(diff)" class="change-container">
      <strong>{{ key | translate }}: </strong>
      <div class="old-value">
        {{
          key === "startDate" || key === "endDate"
            ? (diff[key].old | date: "dd.MM.yyyy HH:mm")
            : key === "price"
              ? diff[key].old + " €"
              : diff[key].old
        }}
      </div>
      <tui-svg src="tuiIconArrowRightLarge"></tui-svg>
      <div class="new-value">
        {{
          key === "startDate" || key === "endDate"
            ? (diff[key].new | date: "dd.MM.yyyy HH:mm")
            : key === "price"
              ? diff[key].new + " €"
              : diff[key].new
        }}
      </div>
    </div>
  </div>
</ng-container>

<!-- Second Section -->
<div *ngIf="allDiffs && allDiffs.length > 0" class="flex-col-g1">
  <div *ngFor="let diff of allDiffs; let i = index">
    <h2>
      {{ "vc.change" | translate }}
      {{ "from" | translate }}
      <ng-container *ngIf="allDiffsTimestamps[i]">
        {{ allDiffsTimestamps[i] | date: "dd.MM.yyyy HH:mm" }}
      </ng-container>
    </h2>
    <div class="changes-container">
      <div *ngFor="let key of Object.keys(diff)" class="change-container">
        <strong>{{ key | translate }}: </strong>
        <div class="old-value">
          {{
            key === "startDate" || key === "endDate"
              ? (diff[key].old | date: "dd.MM.yyyy HH:mm")
              : key === "price"
                ? diff[key].old + " €"
                : diff[key].old
          }}
        </div>
        <tui-svg src="tuiIconArrowRightLarge"></tui-svg>
        <div class="new-value">
          {{
            key === "startDate" || key === "endDate"
              ? (diff[key].new | date: "dd.MM.yyyy HH:mm")
              : key === "price"
                ? diff[key].new + " €"
                : diff[key].new
          }}
        </div>
      </div>
    </div>
  </div>
</div>
