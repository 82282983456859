import { Injectable } from '@angular/core';
import { PaginationFilterService } from './pagination-filter.service';
import { TenantQueryParams } from '../interfaces/query-param/tenant-query-params';
import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { ApiRoutes } from '../enums/api-routes';
import { environment } from '../../environments/environment';
import { catchError, map, Observable, of } from 'rxjs';
import { ResponseWithRecordsBody } from '../interfaces/body/response-with-recors-body';
import { Tenant } from '../models/tenant';
import { TuiPoint } from '@taiga-ui/core';
import { TenantBody } from '../interfaces/body/tenant-body';

@Injectable({
  providedIn: 'root',
})
/**
 * Service Class for the getting or manipulating tenant data
 */
export class TenantService extends PaginationFilterService {
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * get the tenant data from the api
   * sets query params for the api call if they are passed in
   * sets the totalAmount property of the parent PaginationFilterService if data is returned
   * maps the response to an array of observable user objects with the Tenant.fromJson method
   * @param queryParams optional query params for the api call
   */
  getTenants(queryParams?: TenantQueryParams): Observable<Tenant[]> {
    const params = new HttpParams()
      .set(
        queryParams?.name !== undefined ? 'name' : '',
        queryParams?.name ?? '',
      )
      .set(this.sort !== undefined ? 'sort' : '', this.sort ?? '')
      .set(this.endIndex !== '' ? 'limit' : '', this.endIndex)
      .set(this.startIndex !== '' ? 'skip' : '', this.startIndex);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + ApiRoutes.TENANT, { params })
      .pipe(
        map((response) => {
          this.totalAmount = response.total;
          return response.records.map((tenant) => {
            return Tenant.fromJson(tenant);
          });
        }),
      );
  }

  /**
   * get detailed tenant data from the api with a tenant
   * maps the response to a tenant object with the Tenant.fromJson method
   * @param id the id of the tenant to get
   */
  getTenantById(id: string): Observable<Tenant> {
    return this.http.get<any>(this.baseUrl + ApiRoutes.TENANT + '/' + id).pipe(
      map((response) => {
        return Tenant.fromJson(response);
      }),
    );
  }
  /**
   * Creates a new tenant with a post request to the api
   * @param tenantBody the tenant body to create the tenant containing the name
   * */
  createTenant(tenantBody: TenantBody): Observable<boolean> {
    return this.http
      .post<any>(this.baseUrl + ApiRoutes.TENANT, tenantBody, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  /**
   * Updates a tenant with a patch request to the api by id
   *
   * @param tenantId the id of the tenant to patch
   */
  updateTenant(tenantId: string, tenantBody: TenantBody): Observable<boolean> {
    return this.http
      .patch<any>(
        this.baseUrl + ApiRoutes.TENANT + '/' + tenantId,
        tenantBody,
        {
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  deleteTenant() {}

  // dummy data for tenant dashboard
  // todo: delete
  public getJobsForYear(tenantId: string, year: string): TuiPoint[] {
    return [
      [0, 50],
      [1, 75],
      [2, 50],
      [3, 150],
      [4, 155],
      [5, 190],
      [6, 90],
      [7, 10],
      [8, 30],
      [9, 120],
      [10, 10],
      [11, 50],
    ];
  }
}
