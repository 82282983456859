import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsPageComponent } from './views/settings-page/settings-page.component';
import { TenantPageComponent } from './views/tenant-page/tenant-page.component';
import { LocationsPageComponent } from './views/settings-page/locations-page/locations-page.component';
import { CalendarPageComponent } from './views/calendar-page/calendar-page.component';
import { TransactionsPageComponent } from './views/transactions-page/transactions-page.component';
import { UserPageComponent } from './views/user-page/user-page.component';
import { StudentsPageComponent } from './views/students-page/students-page.component';
import { LogInPageComponent } from './views/log-in-page/log-in-page.component';
import { DashboardPageComponent } from './views/dashboard-page/dashboard-page.component';
import { ExplanationComponent } from './views/settings-page/explanation/explanation.component';
import { authGuard } from './guards/auth.guard';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { loginPageAccessGuard } from './guards/login-page-access.guard';
import { ResetPasswordPageComponent } from './views/reset-password-page/reset-password-page.component';
import { UserDetailPageComponent } from './views/user-detail-page/user-detail-page.component';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { superAdminDashboardGuard } from './guards/super-admin-dashboard.guard';
import { NavRoutes } from './enums/nav-routes';
import { GlobalUsersComponent } from './views/settings-page/global-users/global-users.component';
import { globalUserGuard } from './guards/global-user.guard';
import { LocationDetailPageComponent } from './views/settings-page/location-detail-page/location-detail-page.component';
import { StudentDetailPageComponent } from './views/student-detail-page/student-detail-page.component';
import { EventDetailPageComponent } from './views/calendar-page/event-detail-page/event-detail-page.component';
import { LicenceTypePageComponent } from './views/settings-page/licence-type-page/licence-type-page.component';
import { NewLessonCategoryComponent } from './views/settings-page/new-lesson-category/new-lesson-category.component';
import {
  LicenceTypeDetailPageComponent
} from './views/settings-page/licence-type-detail-page/licence-type-detail-page.component';
import { LessonCategoryPageComponent } from './views/settings-page/lesson-category-page/lesson-category-page.component';
import { NewLicenceTypeComponent } from './views/settings-page/new-licence-type/new-licence-type.component';
import {
  LessonCategoryDetailPageComponent
} from './views/settings-page/lesson-category-detail-page/lesson-category-detail-page.component';
import { NewTransactionComponent } from './views/transactions-page/new-transaction/new-transaction.component';
import { NewEventComponent } from './views/calendar-page/new-event/new-event.component';
import { TransactionDetailPageComponent } from './views/transaction-detail-page/transaction-detail-page.component';
import { AccountComponent } from './views/settings-page/account/account.component';
import { LessonHistoryPageComponent } from "./views/lesson-history-page/lesson-history-page.component";
import {
  PhoneCodeValidationLayoutComponent
} from "./views/reset-password-page/phone-code-validation/phone-code-validation-layout/phone-code-validation-layout.component";


const routes: Routes = [
  // standard route
  { path: '', redirectTo: NavRoutes.TENANT, pathMatch: 'full' },

  // auth protected routes
  {
    path: '',
    canActivate: [authGuard],
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: NavRoutes.TENANT,
            children: [
              {
                path: '',
                component: TenantPageComponent,
                canActivate: [superAdminDashboardGuard],
              },
              {
                path: `:id/${ NavRoutes.DASHBOARD }`,
                component: DashboardPageComponent,
              },
            ],
          },
        ],
      },
      {
        path: NavRoutes.SETTINGS,
        component: SettingsPageComponent,
        children: [
          { path: '', redirectTo: NavRoutes.PROFILE, pathMatch: 'full' },
          { path: NavRoutes.PROFILE, component: AccountComponent },
          { path: NavRoutes.ROLES, component: ExplanationComponent },
          // { path: NavRoutes.PASSWORD, component: PasswordComponent },
          {
            path: NavRoutes.LOCATION,
            children: [
              {
                path: '',
                component: LocationsPageComponent,
                children: [
                  {
                    path: ':id',
                    component: LocationDetailPageComponent,
                  },
                ],
              },
            ],
          },
          {
            path: NavRoutes.LICENCE_TYPE,
            children: [
              {
                path: '',
                component: LicenceTypePageComponent,
              },
              {
                path: NavRoutes.NEW_LICENCE_TYPE,
                component: NewLicenceTypeComponent,
                children: [
                  {
                    path: NavRoutes.NEW_LESSON_CATEGORY,
                    component: NewLessonCategoryComponent,
                  },
                ],
              },
              {
                path: ':id' + '/' + NavRoutes.NEW_LESSON_CATEGORY,
                component: NewLessonCategoryComponent,
              },
              {
                path: ':id',
                component: LicenceTypeDetailPageComponent,
              },
            ],
          },
          {
            path: NavRoutes.LESSON_CATEGORY,
            component: LessonCategoryPageComponent,
            children: [
              {
                path: NavRoutes.NEW_LESSON_CATEGORY,
                component: NewLessonCategoryComponent,
              },
              {
                path: ':id',
                component: LessonCategoryDetailPageComponent,
              },
            ],
          },
          {
            path: NavRoutes.GLOBAL_USERS,
            canActivate: [globalUserGuard],
            children: [
              {
                path: '',
                component: GlobalUsersComponent,
              },
              {
                path: ':id',
                component: UserDetailPageComponent,
              },
            ],
          },
        ],
      },
      {
        path: NavRoutes.USER,
        children: [
          { path: '', component: UserPageComponent },
          {
            path: ':id',
            component: UserDetailPageComponent,
          },
        ],
      },

      {
        path: NavRoutes.PAYMENT,
        children: [
          { path: '', component: TransactionsPageComponent },
          {
            path: NavRoutes.PAYMENT_STUDENT,
            component: NewTransactionComponent,
          },
          {
            path: `${ NavRoutes.PAYMENT_STUDENT }/:id`,
            component: NewTransactionComponent,
          },
          {
            path: NavRoutes.PAYMENT_TEACHER,
            component: NewTransactionComponent,
          },
          {
            path: `${ NavRoutes.PAYMENT_TEACHER }/:id`,
            component: NewTransactionComponent,
          },
          {
            path: NavRoutes.PAYMENT_OFFICE,
            component: NewTransactionComponent,
          },
          { path: NavRoutes.PAYMENT_BOSS, component: NewTransactionComponent },
          { path: ':paymentId', component: TransactionDetailPageComponent },
        ],
      },
      // Payment detail routes for student and teacher
      {
        path: `${ NavRoutes.STUDENT }/:id/${ NavRoutes.PAYMENT }/:paymentId`,
        component: TransactionDetailPageComponent,
      },
      {
        path: `${ NavRoutes.USER }/:id/${ NavRoutes.PAYMENT }/:paymentId`,
        component: TransactionDetailPageComponent,
      },
      {
        path: NavRoutes.CALENDAR,
        component: CalendarPageComponent,
        children: [
          {
            path: NavRoutes.NEW_LESSON,
            component: NewEventComponent,
          },
          { path: ':id', component: EventDetailPageComponent },
        ],
      },
      {
        path: `${ NavRoutes.STUDENT }/:id/${ NavRoutes.LESSON_HISTORY }/:lessonId`,
        component: LessonHistoryPageComponent,
      },
      {
        path: NavRoutes.STUDENT,
        children: [
          { path: '', component: StudentsPageComponent },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: StudentDetailPageComponent,
              },
            ],
          },
        ],
      },
      {
        path: NavRoutes.ERROR,
        component: ErrorPageComponent,
      },
    ],
  },

  // non-auth protected routes
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: NavRoutes.LOGIN,
        component: LogInPageComponent,
        canActivate: [loginPageAccessGuard],
      },
      {
        path: `${ NavRoutes.SET_NEW_PASSWORD }/:hash`,
        component: ResetPasswordPageComponent,
        canActivate: [loginPageAccessGuard],
      },
      {
        path: `${ NavRoutes.SET_NEW_PASSWORD }`,
        component: PhoneCodeValidationLayoutComponent,
        canActivate: [loginPageAccessGuard],
      },
      {
        path: NavRoutes.ERROR_OUT,
        component: ErrorPageComponent,
        canActivate: [loginPageAccessGuard],
      },
    ],
  },

  // wildcard route
  { path: '**', redirectTo: NavRoutes.ERROR, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
