<div class="wrapper">
  <div class="">
    <h1>{{ "settings.page-title" | translate }}</h1>
  </div>

  <tui-tabs>
    <a [routerLink]="NavRoutes.PROFILE" routerLinkActive tuiTab>
      {{ "settings.tabs.my_account" | translate }}
    </a>
    <a [routerLink]="NavRoutes.LOCATION" routerLinkActive tuiTab>
      {{ "location.page-title" | translate }}
    </a>
    <a [routerLink]="NavRoutes.LICENCE_TYPE" routerLinkActive tuiTab>
      {{ "licence-administration.nav-element-licence-types" | translate }}
    </a>
    <a [routerLink]="NavRoutes.LESSON_CATEGORY" routerLinkActive tuiTab>
      {{ "licence-administration.nav-element-lesson-types" | translate }}
    </a>
    <a [routerLink]="NavRoutes.ROLES" routerLinkActive tuiTab>
      {{ "settings.tabs.explanation" | translate }}
    </a>
    <a *ngIf="isGlobalUser()" [routerLink]="NavRoutes.GLOBAL_USERS" routerLinkActive tuiTab>
      {{ "settings.tabs.global-user" | translate }}
    </a>
    <!--    <a tuiTab [routerLink]="NavRoutes.PASSWORD" routerLinkActive>-->
    <!--      {{ "settings.tabs.password" | translate }}-->
    <!--    </a>-->
  </tui-tabs>

  <!-- nested route for tabs -->
  <router-outlet></router-outlet>

  <!--  <span class="legal-wrapper tui-text_body-xs">-->
  <!--    <a href="/" title="Zu den Datenschutzhinweisen">-->
  <!--      {{ "settings.legal.data-protection" | translate }} </a-->
  <!--    >/-->
  <!--    <a href="/" title="Zum den AGB">-->
  <!--      {{ "settings.legal.terms-of-use" | translate }} </a-->
  <!--    >/-->
  <!--    <a href="/" title="Zum Impressum">-->
  <!--      {{ "settings.legal.imprint" | translate }}-->
  <!--    </a>-->
  <!--  </span>-->
</div>
