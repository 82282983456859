export enum TransactionType {
  // Student
  STUDENT_PAYMENT = 'STUDENT_PAYMENT',
  STUDENT_PAYMENT_ONLINE = 'STUDENT_PAYMENT_ONLINE',
  STUDENT_PAYOUT = 'STUDENT_PAYOUT',
  STUDENT_OTHER_COSTS = 'STUDENT_OTHER_COSTS',

  // Teacher
  TEACHER_PAYOUT = 'TEACHER_PAYOUT',

  // Management
  MANAGEMENT_PAYOUT = 'MANAGEMENT_PAYOUT',

  // Boss
  BOSS_PAYOUT = 'BOSS_PAYOUT',
  BOSS_PAYIN = 'BOSS_PAYIN',
  BOSS_INVENTORY = 'BOSS_INVENTORY',

  // Not needed for Web: !!!
  STUDENT_LESSON = 'STUDENT_LESSON',
  TEACHER_EXPENSES = 'TEACHER_EXPENSES',
}

export function transactionTypeToString(
  transactionType: TransactionType,
): string {
  switch (transactionType) {
    case TransactionType.STUDENT_PAYMENT:
      return 'payments.payment-type-student-payin';
    case TransactionType.STUDENT_PAYMENT_ONLINE:
      return 'payments.payment-type-student-online';
    case TransactionType.STUDENT_PAYOUT:
      return 'payments.payment-type-student-payout';
    case TransactionType.TEACHER_PAYOUT:
      return 'payments.payment-type-teacher-payout';
    case TransactionType.MANAGEMENT_PAYOUT:
      return 'payments.payment-type-office-payout';
    case TransactionType.BOSS_PAYOUT:
      return 'payments.payment-type-boss-payout';
    case TransactionType.BOSS_PAYIN:
      return 'payments.payment-type-boss-payin';
    case TransactionType.BOSS_INVENTORY:
      return 'payments.payment-type-boss-inventory';
    case TransactionType.STUDENT_OTHER_COSTS:
      return 'payments.payment-type-student-other-costs';
    case TransactionType.STUDENT_LESSON:
      return 'payments.payment-type-student-lesson';
    case TransactionType.TEACHER_EXPENSES:
      return 'payments.payment-type-teacher-expenses';
    default:
      return '';
  }
}

export function transactionTypeToAbbreviation(
  transactionType: TransactionType,
) {
  switch (transactionType) {
    case TransactionType.STUDENT_PAYMENT:
      return 'SE';
    case TransactionType.STUDENT_PAYMENT_ONLINE:
      return 'SEO';
    case TransactionType.STUDENT_PAYOUT:
      return 'SA';
    case TransactionType.TEACHER_PAYOUT:
      return 'LA';
    case TransactionType.MANAGEMENT_PAYOUT:
      return 'BA';
    case TransactionType.BOSS_PAYOUT:
      return 'GA';
    case TransactionType.BOSS_PAYIN:
      return 'GE';
    case TransactionType.BOSS_INVENTORY:
      return 'K';
    case TransactionType.STUDENT_OTHER_COSTS:
      return 'SES';
    case TransactionType.STUDENT_LESSON:
      return 'SF';
    case TransactionType.TEACHER_EXPENSES:
      return 'LS';
    default:
      return '';
  }
}
