import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SuperAdminService } from '../../services/super-admin.service';
import { RoleService } from '../../services/role.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { DropDownItem } from '../../interfaces/drop-down-item';
import { Role } from '../../models/role';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-role-dropdown',
  templateUrl: './role-dropdown.component.html',
  styleUrls: ['./role-dropdown.component.scss'],
})
export class RoleDropdownComponent {
  @Input() fieldName: string = '';
  @Input() formGroup: FormGroup | undefined;
  @Output() rolesFetchEvent = new EventEmitter<Role[]>();

  selectedTenantSubscription = new Subscription();
  roleDropdownItems: DropDownItem[] = [];
  roles: Role[] = [];

  constructor(
    private superAdminService: SuperAdminService,
    private roleService: RoleService,
    private authService: AuthService,
    private utilService: UtilService,
  ) {}

  ngOnInit() {
    this.getRoles();
  }

  ngOnDestroy() {
    this.selectedTenantSubscription.unsubscribe();
  }

  /**
   * Get Roles with the tenantId
   * gets the tenantId from the superAdminService if the user is a super-admin
   * otherwise gets the tenantId from the authService from the logged-in user
   */
  getRoles() {
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe((id) => {
          if (id != null) {
            this.getRolesForTenant(id);
          }
        });
    } else {
      const tenantId = this.authService.getTenantId();
      if (tenantId == null) return;
      this.getRolesForTenant(tenantId);
    }
  }

  /**
   * Gets all explanation from the api to fill the dropdown menu
   */
  getRolesForTenant(tenantId: string) {
    this.roleService.getRoles({ tenantId: tenantId }).subscribe((data) => {
      this.roles = data;
      this.roleDropdownItems = this.utilService.generateDropdownItems(data);
      this.rolesFetchEvent.emit(data);
    });
  }
}
