
<div class="setting-wrapper">
<tui-loader
  [overlay]="true"
  [showLoader]="!!(isLoading$ | async)"
  [size]="'xl'">

  <tui-breadcrumbs [size]="'l'">
    <ng-container *ngFor="let item of breadcrumbItems">
      <a
        *tuiItem
        [routerLink]="item.routerLink"
        tuiLink>
        {{ item.caption | translate }}
      </a>
    </ng-container>
  </tui-breadcrumbs>
  <span class="name-wrapper">
      <app-car-icon [icon]="licenceType?.icon ?? CarIcons.CAR"></app-car-icon>
      <h1>{{ licenceType?.name ?? ""}}</h1>
  </span>
  <div class="card-wrapper">
    <div class="form-wrapper">
      <form [formGroup]="licenceTypeForm" class="form-group">
        <div class="form-section-wrapper">
          <span class="text-field-wrapper">
              <app-input-text
                [fieldName]="'name'"
                [hint]="'licence-type-detail.input-label-name' | translate"
              ></app-input-text>
          </span>
          <span class="icon-field-wrapper">
             <app-icon-dropdown
               [formFieldName]="'icon'"
               [formGroup]="licenceTypeForm"
             ></app-icon-dropdown>
          </span>
        </div>

        <ng-container
          *ngFor="let target of targets.controls; let i = index"
          [formArrayName]="'targets'"
        >
          <div
            [formGroup]="getFormGroupFromAbstractControl(target)"
            class="target-container"
          >
            <div class="dropdown-field-wrapper bottom-space-wrapper">
              <!--              <app-category-dropdown [fieldName]="'lessonCategoryId'"-->
              <!--                                     class="dropdown">-->
              <!--              </app-category-dropdown>-->
              <!--              <a [routerLink]="'/'+NavRoutes.LICENCE_TYPE+'/'+NavRoutes.NEW_LESSON_CATEGORY" >{{ "licence-type-detail.new-lesson-category-link" | translate }}</a>-->
              <app-licence-typ-select
                (valueChanges)="filterOptions($event)"
                [fieldName]="'lessonCategoryId'"
                [optionsArray]="categoryDropdownItems"
                [selectedOptionsArray]="selectedCategories"
              ></app-licence-typ-select>
            </div>

            <!-- lesson price -->
            <app-input-payment-amount
              [fieldName]="'price'"
              [hint]="'licence-type-detail.input-label-lesson-price' | translate"
              [minValue]="1"
              class="medium-input-item"
            >
            </app-input-payment-amount>

            <!-- lesson count -->
            <app-input-number
              [fieldName]="'lessonCount'"
              [hint]="'licence-type-detail.input-label-lesson-count' | translate"
              class="small-input-item"
            ></app-input-number>


            <div class="center-button">
              <button
                (click)="deleteTargetFormRow(i, target)"
                [disabled]="isManager()"
                appearance="icon"
                class="delete-button"
                icon="tuiIconClose"
                size="l"
                tuiButton
              >
              </button>
            </div>
          </div>
        </ng-container>
        <div class="center-button">
          <button
            (click)="addTargetFormRow()"
            *ngIf="targets.controls.length < 6"
            [disabled]="isManager()"
            appearance="secondary"
            icon="tuiIconPlus"
            id="addButton"
            size="m"
            tuiButton
          >
            {{ "licence-type-detail.button-new-lesson" | translate }}
          </button>
          <tui-error *ngIf="targets.controls.length == 6"
                     [error]="'new-licence-type.max-categories-error' | translate"></tui-error>
        </div>

        <a
          *ngIf="targets.controls.length < 6"
          [routerLink]="'/'+NavRoutes.SETTINGS+'/'+NavRoutes.LICENCE_TYPE+'/'+licenceTypeId+'/'+NavRoutes.NEW_LESSON_CATEGORY"
          style="padding-bottom: 1rem">{{ "new-licence-type.new-lesson-category-link" | translate }}</a>

        <div class="button-wrapper">
          <button
            (click)="editLicenceType()"
            [disabled]="!licenceTypeForm.valid || isManager()"
            size="m"
            tuiButton
            type="submit"
          >
            {{ "common.button-save" | translate }}
          </button>
          <button
            (click)="showDeleteDialog = true"
            [disabled]="isManager()"
            [icon]="tuiIconTrash"
            appearance="secondary-destructive"
            size="m"
            tuiButton
            type="button"
          >
            {{ "licence-type-detail.button-delete" | translate }}
          </button>
          <button
            (click)="setCloseEvent()"
            appearance="secondary"
            size="m"
            tuiButton
            type="button"
          >
            {{ "common.button-cancel" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</tui-loader>

<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="showDeleteDialog = false; deleteLicenceType()"
  [isOpen]="showDeleteDialog"
></app-delete-dialog>

</div>
<!-- OLD Menubar for Licence Type Detail-->
<!--<app-menubar-->
<!--  [title]="'licence-type-detail.title' | translate"-->
<!--  [description]="'licence-type-detail.description' | translate"-->
<!--  [open]="isOpen"-->
<!--  (closingEvent)="setCloseEvent()"-->
<!--&gt;-->
<!--  <tui-loader-->
<!--    [showLoader]="!!(isLoading$ | async)"-->
<!--    [size]="'xl'"-->
<!--    [overlay]="true"-->
<!--  >-->
<!--    <form [formGroup]="licenceTypeForm">-->
<!--      <app-icon-dropdown-->
<!--        [formFieldName]="'icon'"-->
<!--        [formGroup]="licenceTypeForm"-->
<!--      ></app-icon-dropdown>-->
<!--      <app-input-text-->
<!--        [fieldName]="'name'"-->
<!--        [hint]="'licence-type-detail.input-label-name' | translate"-->
<!--      ></app-input-text>-->
<!--      <ng-container-->
<!--        [formArrayName]="'targets'"-->
<!--        *ngFor="let target of targets.controls; let i = index"-->
<!--      >-->
<!--        <div-->
<!--          class="target-container"-->
<!--          [formGroup]="getFormGroupFromAbstractControl(target)"-->
<!--        >-->
<!--          <hr class="tui-space_bottom-5" />-->
<!--          <div class="bottom-space-wrapper">-->
<!--            <app-category-dropdown [fieldName]="'lessonCategoryId'" [reloadCategories]="reloadLessonCategories">-->
<!--            </app-category-dropdown>-->
<!--&lt;!&ndash;            <a [routerLink]="'/'+NavRoutes.LICENCE_TYPES+'/'+NavRoutes.NEW_LESSON_CATEGORY" >{{ "licence-type-detail.new-lesson-category-link" | translate }}</a>&ndash;&gt;-->
<!--          </div>-->
<!--          <app-input-number-->
<!--            class="small-input-item"-->
<!--            [fieldName]="'price'"-->
<!--            [postfix]="'€'"-->
<!--            [hint]="'licence-type-detail.input-label-lesson-price' | translate"-->
<!--          ></app-input-number>-->
<!--          <app-input-number-->
<!--            class="small-input-item"-->
<!--            [fieldName]="'lessonCount'"-->
<!--            [hint]="'licence-type-detail.input-label-lesson-count' | translate"-->
<!--          ></app-input-number>-->
<!--          <div class="center-button">-->
<!--            <button-->
<!--              tuiButton-->
<!--              size="m"-->
<!--              appearance="secondary-destructive"-->
<!--              icon="tuiIconClose"-->
<!--              (click)="deleteTargetFormRow(i)"-->
<!--            >-->
<!--              {{ "common.remove" | translate }}-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-container>-->

<!--      <div class="center-button">-->
<!--        <button-->
<!--          tuiButton-->
<!--          size="m"-->
<!--          icon="tuiIconPlus"-->
<!--          (click)="addTargetFormRow()"-->
<!--        >-->
<!--          {{ "licence-type-detail.button-new-lesson" | translate }}-->
<!--        </button>-->
<!--      </div>-->
<!--      <hr class="tui-space_bottom-5" />-->
<!--      <tui-error *ngIf="lessonCategoryIdDuplicate()" [error]="'licence-type-detail.lesson-category-duplicate-error' | translate"></tui-error>-->
<!--      <div class="button-wrapper">-->
<!--        <button-->
<!--          tuiButton-->
<!--          size="m"-->
<!--          [disabled]="!licenceTypeForm.valid || lessonCategoryIdDuplicate()"-->
<!--          type="submit"-->
<!--          (click)="editLicenceType()"-->
<!--        >-->
<!--          {{ "common.button-save" | translate }}-->
<!--        </button>-->
<!--        <button-->
<!--          tuiButton-->
<!--          size="m"-->
<!--          type="button"-->
<!--          [icon]="tuiIconTrash"-->
<!--          (click)="showDeleteDialog = true"-->
<!--          appearance="secondary-destructive"-->
<!--        >-->
<!--          {{ "licence-type-detail.button-delete" | translate }}-->
<!--        </button>-->
<!--        <button-->
<!--          tuiButton-->
<!--          size="m"-->
<!--          appearance="secondary"-->
<!--          type="button"-->
<!--          (click)="setCloseEvent()"-->
<!--        >-->
<!--          {{ "common.button-cancel" | translate }}-->
<!--        </button>-->
<!--      </div>-->
<!--    </form>-->
<!--  </tui-loader>-->
<!--</app-menubar>-->

<!--<app-delete-dialog-->
<!--  [isOpen]="showDeleteDialog"-->
<!--  (cancelEvent)="showDeleteDialog = false"-->
<!--  (deleteEvent)="showDeleteDialog = false; deleteLicenceType()"-->
<!--&gt;</app-delete-dialog>-->


