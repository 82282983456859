import { Component } from '@angular/core';
import { TableFilterEvent } from '../../../interfaces/table-filter-event';
import { NavRoutes } from '../../../enums/nav-routes';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { SuperAdminService } from '../../../services/super-admin.service';
import { UtilService } from '../../../services/util.service';
import { LessonCategoryService } from '../../../services/lesson-category.service';
import { LessonCategory } from '../../../models/lesson-category';
import { TuiTablePagination } from '@taiga-ui/addon-table';
import { TableSortEvent } from '../../../interfaces/table-sort-event';
import { PushService } from '../../../services/push.service';
import { TranslateService } from '@ngx-translate/core';
import { pushTypes } from '../../../enums/push-types';
import { UserRole } from '../../../enums/user-role';

@Component({
  selector: 'app-lesson-category-page',
  templateUrl: './lesson-category-page.component.html',
  styleUrls: ['./lesson-category-page.component.scss'],
})
export class LessonCategoryPageComponent {
  lessonCategoryTableData: any[] = [];
  readonly lessonCategoryTableShownColumns: string[] = ['name'];
  tablePage: number = 0;
  tablePageSize: number = 10;
  totalLessonCategories: number = 0;
  selectedTenantSubscription: Subscription = new Subscription();
  showDeleteDialog: boolean = false;
  deleteId: string = '';
  pushNotificationDeletedSuccess: string = '';
  pushNotificationDeletedError: string = '';
  private backToParentSubscription: Subscription = new Subscription();

  constructor(
    private lessonCategoryService: LessonCategoryService,
    private authService: AuthService,
    private router: Router,
    private superAdminService: SuperAdminService,
    private utilService: UtilService,
    private pushService: PushService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.deleteId = '';
    this.lessonCategoryService.setPaginationIndices({ start: 0, limit: 10 });

    // subscribe to router events to refresh the calendar when the user navigates back to the calendar page
    this.backToParentSubscription = this.router.events.subscribe((event) => {
      if (
        this.utilService.isNavigatedBackToParent(
          event,
          `${NavRoutes.SETTINGS}/${NavRoutes.LESSON_CATEGORY}`,
        )
      ) {
        this.populateTable();
      }
    });

    // populate table again if selected tenant changes
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe((id) => {
          this.populateTable();
        });
    } else {
      this.populateTable();
    }

    let pushMessage = this.translateService
      .get([
        'lesson-category.push-success-deleted',
        'lesson-category.push-error-deleted',
      ])
      .subscribe((translations) => {
        this.pushNotificationDeletedSuccess =
          translations['lesson-category.push-success-deleted'];
        this.pushNotificationDeletedError =
          translations['lesson-category.push-error-deleted'];
      });
  }

  ngOnDestroy(): void {
    this.resetTablePagination();
    this.selectedTenantSubscription.unsubscribe();
    this.backToParentSubscription.unsubscribe();
  }

  public filterTable(filterEvent: TableFilterEvent): void {
    // this.locationService.setFilter(filterEvent);
    // this.resetTablePagination();
    // this.populateTable();
  }

  /**
   * when the pagination event is triggered:
   * sets the table pagination to the selected page and size and updates the table data
   */
  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.lessonCategoryService.setPaginationIndices({
      start: this.tablePage * this.tablePageSize,
      limit: this.tablePageSize,
    });
    this.populateTable();
  }

  public routeToNewLicenceType(): void {
    this.router.navigateByUrl(
      `${NavRoutes.SETTINGS}/${NavRoutes.LESSON_CATEGORY}/${NavRoutes.NEW_LESSON_CATEGORY}`,
    );
  }

  /**
   * resets the table pagination to the first page with 10 entries
   */
  public resetTablePagination() {
    this.lessonCategoryService.setPaginationIndices({ start: 0, limit: 10 });
    this.tablePage = 0;
    this.tablePageSize = 10;
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    this.lessonCategoryService.setSorting(tableSort);
    this.populateTable();
  }

  public populateTable() {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();
      if (selectedTenantId == null) return;

      this.getLessonCategoryForTenant(selectedTenantId);
    } else {
      this.getLessonCategoryForTenant(
        this.authService.getLoggedInUser()?.tenantId,
      );
    }
  }

  public licenceTypeRowClickEvent(id: string): void {
    this.router.navigateByUrl(
      `${NavRoutes.SETTINGS}/${NavRoutes.LESSON_CATEGORY}/${id}`,
    );
  }

  public getLessonCategoryForTenant(tenantId?: string) {
    this.lessonCategoryService
      .getCategory({ tenantId: tenantId })
      .subscribe((lessonCategories: LessonCategory[]) => {
        this.lessonCategoryTableData = lessonCategories;
        this.totalLessonCategories = this.lessonCategoryService.getAmount();
      });
  }

  public deleteRowClickEvent(id: string): void {
    this.deleteId = id;
    this.showDeleteDialog = true;
  }

  public closeDeleteDialog(): void {
    this.showDeleteDialog = false;
    this.deleteId = '';
  }

  public deleteCategory() {
    this.lessonCategoryService
      .deleteCategory(this.deleteId)
      .subscribe((deleted) => {
        if (deleted) {
          this.populateTable();
          this.pushService.sendPush(
            pushTypes.SUCCESS,
            this.pushNotificationDeletedSuccess,
          );
        } else {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationDeletedError,
          );
        }
        this.deleteId = '';
      });
  }

  public isManager(): boolean {
    return this.authService.getUserRole() === UserRole.MANAGER;
  }
}
