import { BaseModel } from './base-model';
import { User } from './user';

/**
 * Model Class for creating Tenant instances
 * extends the BaseModel
 */
export class Tenant extends BaseModel {
  private _name: string;
  private _user?: User[];

  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    name: string,
    user?: User[],
  ) {
    super(id, createdAt, updatedAt);
    this._name = name;
    this._user = user;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get user(): User[] | undefined {
    return this._user;
  }

  set user(value: User[]) {
    this._user = value;
  }

  static fromJson(json: any): Tenant {
    return new Tenant(
      json.id,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.name,
    );
  }

  toJson(): any {}
}
