import { Address } from './address';
import { BaseModel } from './base-model';
import { ObjectWithLocation } from '../interfaces/object-with-location';
import { LicenceType } from './licence-type';

export class Student extends BaseModel implements ObjectWithLocation {
  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    tenantId: string,
    password: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    active: boolean,
    address: Address,
    locationId: string,
    licences: LicenceType[],
    inviteAcceptedAt: string,
    studentNumber: number,
  ) {
    super(id, createdAt, updatedAt);
    this._password = password;
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._phone = phone;
    this._active = active;
    this._address = address;
    this._tenantId = tenantId;
    this._locationId = locationId;
    this._licences = licences;
    this._inviteAcceptedAt = inviteAcceptedAt;
    this._studentNumber = studentNumber;
  }

  private _inviteAcceptedAt: string;

  get inviteAcceptedAt() {
    return this._inviteAcceptedAt;
  }

  set inviteAcceptedAt(value: string) {
    this._inviteAcceptedAt = value;
  }

  private _tenantId: string;

  get tenantId(): string {
    return this._tenantId;
  }

  set tenantId(value: string) {
    this._tenantId = value;
  }

  private _password: string;

  get password(): string {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
  }

  private _firstName: string;

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  private _lastName: string;

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  private _email: string;

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  private _phone: string;

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  private _active: boolean;

  get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  private _address: Address;

  get address(): Address {
    return this._address;
  }

  set address(value: Address) {
    this._address = value;
  }

  private _locationId: string;

  get locationId(): string {
    return this._locationId;
  }

  set locationId(value: string) {
    this._locationId = value;
  }

  private _licences: LicenceType[];

  get licences(): LicenceType[] {
    return this._licences;
  }

  set licences(value: LicenceType[]) {
    this._licences = value;
  }

  get name(): string {
    return `${this._firstName} ${this._lastName}`;
  }

  private _studentNumber: number;

  get studentNumber(): number {
    return this._studentNumber;
  }

  set studentNumber(value: number) {
    this._studentNumber = value;
  }

  static fromJson(json: any): Student {
    return new Student(
      json.id,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.tenantId,
      json.password,
      json.firstName,
      json.lastName,
      json.email,
      json.phone,
      json.active,
      json.address,
      json.locationId,
      json.licences,
      json.inviteAcceptedAt,
      json.studentNumber,
    );
  }
}
