import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Lesson } from "../../../models/lesson";

@Component({
  selector: "app-log-entity-card",
  templateUrl: "./log-entity-card.component.html",
  styleUrls: ["./log-entity-card.component.scss"],
})
export class LogEntityCardComponent implements OnChanges {
  @Input() logEntityLessons: Lesson[] = [];
  @Input() currentLesson!: Lesson;

  diff: { [key: string]: { old: any; new: any } } = {};
  allDiffs: Array<{ [key: string]: { old: any; new: any } }> = [];

  latestLessonTimestamp: Date | null | undefined = undefined;
  allDiffsTimestamps: Date[] = [];

  protected readonly Object = Object;

  // Sort the logEntityLessons array
  sortLessons() {
    this.logEntityLessons.sort(
      (a, b) =>
        new Date(b.vcLogDate!).getTime() - new Date(a.vcLogDate!).getTime(),
    );
  }

  // Compare objects and return the difference
  compareObj(oldObj: any, newObj: any) {
    let diff: { [key: string]: { old: any; new: any } } = {};
    let keysToCompare = [
      "startDate",
      "endDate",
      "user.firstName",
      "lessonCategory.name",
      "lesson-type",
      "price",
      "count",
    ];

    for (let key of keysToCompare) {
      let oldKey = oldObj[key];
      let newKey = newObj[key];

      // Access nested properties if key contains a '.' like 'user.firstName'
      if (key.includes(".")) {
        let properties = key.split(".");
        oldKey = oldObj[properties[0]][properties[1]];
        newKey = newObj[properties[0]][properties[1]];
      }

      // Compare attributes
      if (oldKey !== newKey) {
        diff[key] = {
          old: oldKey,
          new: newKey,
        };
      }
    }

    return diff;
  }

  compareAllObj() {
    let diffs: { [key: string]: { old: any; new: any } }[] = [];

    for (let i = 0, len = this.logEntityLessons.length - 1; i < len; i++) {
      diffs.push(
        this.compareObj(this.logEntityLessons[i], this.logEntityLessons[i + 1]),
      );
    }

    return diffs;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["logEntityLessons"] || changes["currentLesson"]) {
      this.sortLessons();

      if (this.logEntityLessons.length > 0) {
        // current vs latest vc
        let latestLesson = this.logEntityLessons[0];
        this.diff = this.compareObj(latestLesson, this.currentLesson);
        this.latestLessonTimestamp = latestLesson.vcLogDate;

        // all other vc
        this.allDiffs = this.compareAllObj();
        this.allDiffsTimestamps = this.logEntityLessons.map(
          (lesson) => new Date(lesson.vcLogDate!),
        );
      }
    }
  }
}
