import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { tuiIconMail } from '@taiga-ui/icons';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';

@Component({
  selector: 'app-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.', // todo translate
        phoneOrEmailRequired: 'Bitte geben Sie eine E-Mail-Adresse oder Telefonnummer ein.', // todo translate
      },
    },
  ],
})
/**
 * A component that displays an input field for entering an email address
 * @Input hint - The hint to display on top of the input field
 * @Input fieldName - The name of the field in the FormGroup
 */
export class InputEmailComponent {
  protected readonly tuiIconMail = tuiIconMail;

  @Input() hint?: string = 'E-Mail';
  @Input() fieldName?: string = 'email';
  @Input() required?: boolean = true;
}
