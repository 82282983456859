<div class="setting-wrapper">
  <app-table-header
    [tableTitle]="'location.page-title' | translate"
    [buttonText]="'location.button-new-location' | translate"
    [tenantSelector]="true"
    [showActiveTab]="false"
    [showCreateButton] = "!isManager()"
    (filterEvent)="filterTable($event)"
    (createNewClickEvent)="isOpen = true"
  ></app-table-header>
  <app-table
    [tableColumnNames]="['location.table-name' | translate, 'location.table-phone' | translate]"
    [columns]="locationTableShownColumns"
    [tableData]="locationTableData"
    [totalElements]="totalLocations"
    [currentPage]="tablePage"
    [pageSize]="tablePageSize"
    (paginationEvent)="paginationEvent($event)"
    (rowClickEvent)="locationRowClickEvent($event)"
    (sortEvent)="sortTable($event)"
  ></app-table>
  <!-- injecting children -->
  <router-outlet></router-outlet>
</div>


<!-- Slidethingy on the right -->
<app-new-location [isOpen]="isOpen" (isCreatedEvent)="createdLocationEvent()" (closeEvent)="isOpen = false"></app-new-location>
