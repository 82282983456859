import { Injectable } from "@angular/core";
import { PaginationFilterService } from "./pagination-filter.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { TransactionBody } from "../interfaces/body/transaction-body";
import { catchError, map, Observable, of } from "rxjs";
import { ApiRoutes } from "../enums/api-routes";
import { Transaction } from "../models/transaction";
import { ResponseWithRecordsBody } from "../interfaces/body/response-with-recors-body";
import { FileBody } from "../interfaces/body/file-body.interface";
import { TransactionType } from "../enums/transaction-type";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class TransactionService extends PaginationFilterService {
  transactionFilter:
    | Partial<{ filterDate: DateTime; transactionType: TransactionType }>
    | undefined = undefined;
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    super();
    this.sort = "createdAt+DESC";
  }

  /**
   * Sends a request to the api to create a new transaction
   * @param transaction to create as TransactionBody
   */
  createTransaction(transaction: TransactionBody | FormData) {
    return this.http
      .post<any>(this.baseUrl + "transaction", transaction, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  createTransactionFile(formData: FormData, body: FileBody) {
    return this.http.post(
      this.baseUrl + `${ApiRoutes.TRANSACTION}/${body.id}/${ApiRoutes.FILE}`,
      formData,
      {
        observe: "response",
      },
    );
  }

  getLocationWalletValue(locationId: string, tenantId?: string) {
    let params = new HttpParams().set(
      tenantId !== undefined ? "tenantId" : "",
      tenantId ?? "",
    );

    // apply filter if set
    if (this.transactionFilter !== undefined) {
      params = params
        .set(
          this.transactionFilter.filterDate !== undefined ? "filterDate" : "",
          this.transactionFilter.filterDate?.toISODate() ?? "",
        )
        .set(
          this.transactionFilter.transactionType !== undefined
            ? "transactionType"
            : "",
          this.transactionFilter.transactionType?.toString() ?? "",
        );
    }

    return this.http.get<any>(
      this.baseUrl + `${ApiRoutes.WALLET}/${ApiRoutes.LOCATION}/${locationId}`,
      { params },
    );
  }

  getLocationTransactions(
    locationId: string,
    tenantId?: string,
  ): Observable<Transaction[]> {
    let params = new HttpParams()
      .set(tenantId !== undefined ? "tenantId" : "", tenantId ?? "")
      .set(this.sort !== undefined ? "sort" : "", this.sort ?? "")
      .set(this.endIndex !== "" || this.endIndex !== undefined ? "limit" : "", this.endIndex)
      .set(this.startIndex !== "" || this.endIndex !== undefined ? "skip" : "", this.startIndex);
    // apply filter if set
    if (this.transactionFilter !== undefined) {
      params = params
        .set(
          this.transactionFilter.filterDate !== undefined ? "filterDate" : "",
          this.transactionFilter.filterDate?.toISODate() ?? "",
        )
        .set(
          this.transactionFilter.transactionType !== undefined
            ? "transactionType"
            : "",
          this.transactionFilter.transactionType?.toString() ?? "",
        );
    }

    return this.http
      .get<ResponseWithRecordsBody>(
        this.baseUrl +
          `${ApiRoutes.WALLET}/${ApiRoutes.LOCATION_TRANSACTIONS}/${locationId}`,
        {
          params,
        },
      )
      .pipe(
        map((response) => {
          this.totalAmount = response.total;
          return response.records.map((transaction: any) => {
            return Transaction.fromJson(transaction);
          });
        }),
      );
  }

  getStudentWalletValue(studentId: string, tenantId?: string) {
    const params = new HttpParams().set(
      tenantId !== undefined ? "tenantId" : "",
      tenantId ?? "",
    );

    return this.http.get<any>(
      this.baseUrl + `${ApiRoutes.WALLET}/${ApiRoutes.STUDENT}/${studentId}`,
      {
        params,
      },
    );
  }

  getStudentTransactions(
    studentId: string,
    tenantId?: string,
  ): Observable<Transaction[]> {
    const params = new HttpParams()
      .set(this.sort !== undefined ? "sort" : "", this.sort ?? "")
      .set(tenantId !== undefined ? "tenantId" : "", tenantId ?? "")
      .set(this.endIndex !== "" || this.endIndex !== undefined ? "limit" : "", this.endIndex)
      .set(this.startIndex !== "" || this.endIndex !== undefined ? "skip" : "", this.startIndex);

    return this.http
      .get<ResponseWithRecordsBody>(
        this.baseUrl +
          `${ApiRoutes.WALLET}/${ApiRoutes.STUDENT_TRANSACTION}/${studentId}`,
        {
          params,
        },
      )
      .pipe(
        map((response) => {
          this.totalAmount = response.total;
          return response.records.map((transaction: any) => {
            return Transaction.fromJson(transaction);
          });
        }),
      );
  }

  getTeacherWalletValue(teacherId: string, tenantId?: string) {
    const params = new HttpParams().set(
      tenantId !== undefined ? "tenantId" : "",
      tenantId ?? "",
    );

    return this.http.get<any>(
      this.baseUrl + `${ApiRoutes.WALLET}/${ApiRoutes.TEACHER}/${teacherId}`,
      {
        params,
      },
    );
  }

  getTeacherTransactions(teacherId: string, tenantId?: string) {
    const params = new HttpParams()
      .set(tenantId !== undefined ? "tenantId" : "", tenantId ?? "")
      .set(this.sort !== undefined ? "sort" : "", this.sort ?? "")
      .set(this.endIndex !== "" ? "limit" : "", this.endIndex)
      .set(this.startIndex !== "" ? "skip" : "", this.startIndex);

    return this.http
      .get<ResponseWithRecordsBody>(
        this.baseUrl +
          `${ApiRoutes.WALLET}/${ApiRoutes.TEACHER_TRANSACTION}/${teacherId}`,
        {
          params,
        },
      )
      .pipe(
        map((response) => {
          this.totalAmount = response.total;
          return response.records.map((transaction: any) => {
            return Transaction.fromJson(transaction);
          });
        }),
      );
  }

  getTransactionById(transactionId: string) {
    return this.http
      .get<any>(this.baseUrl + `${ApiRoutes.TRANSACTION}/${transactionId}`)
      .pipe(
        map((response) => {
          return Transaction.fromJson(response);
        }),
      );
  }

  getTransactionFilesById(transactionId: string, fileId: string) {
    return this.http.get<any>(
      this.baseUrl +
        `${ApiRoutes.TRANSACTION}/{id}/${ApiRoutes.FILES}/${fileId}`,
      {
        responseType: "blob" as "json",
      },
    );
  }

  updateTransaction(user: TransactionBody, id: string): Observable<number> {
    return this.http
      .patch<any>(this.baseUrl + ApiRoutes.TRANSACTION + "/" + id, user, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status;
        }),
        catchError((error) => {
          return of(error.status);
        }),
      );
  }

  deleteTransactionById(id: string) {
    return this.http.delete<Transaction>(
      this.baseUrl + ApiRoutes.TRANSACTION + "/" + id,
    );
  }
}
