<div class="wrapper">
  <app-table-header
    (createNewClickEvent)="isOpen = !isOpen"
    (filterEvent)="filterTable($event)"
    (searchEvent)="onSearchChange($event)"
    [buttonText]="'students.new-button-label' | translate"
    [hasSearch]="true"
    [tableTitle]="'students.page-title' | translate"
    [tenantSelector]="true"
  ></app-table-header>
  <app-table
    (paginationEvent)="paginationEvent($event)"
    (rowClickEvent)="studentRowClickEvent($event)"
    (sortEvent)="sortTable($event)"
    [columns]="studentsTableShownColumns"
    [currentPage]="tablePage"
    [pageSize]="tablePageSize"
    [tableColumnNames]="[
      'students.table.student-number' | translate,
      'students.table.first-name' | translate,
      'students.table.last-name' | translate,
      'students.table.telephone' | translate,
      'students.table.mail' | translate,
      'students.table.active' | translate
    ]"
    [tableData]="studentsTableData"
    [totalElements]="totalStudents"
  ></app-table>
</div>
<router-outlet></router-outlet>
<!-- slide modal from right -->
<app-new-student
  (closeEvent)="isOpen = false"
  (isCreatedEvent)="createdStudentEvent()"
  [isOpen]="isOpen"
></app-new-student>
