<h3>  {{ licenceType.name }}:</h3>

<div *ngIf="lessonProgressForLicenceType.length > 0; else noContent" class="container">
  <ng-container *ngFor="let progressCount of lessonProgressForLicenceType">
    <div class="chart_with_label">
      {{ progressCount.name }}:

      <!--     arcChart     -->
      <tui-arc-chart
        [maxLabel]="progressCount.count.toString()"
        [minLabel]="progressCount.done.lessonCount?.toString() ?? '0'"
        [value]="getPercentageForValue(progressCount.done.lessonCount?? 0, progressCount.count)"
      >
        <!--       inner label, progress  x / y       -->
        <div class="inner_label">
          <h1>{{ progressCount.done.lessonCount ?? 0 }} / {{ progressCount.count }} </h1>
        </div>
      </tui-arc-chart>
    </div>
  </ng-container>
</div>
<ng-template #noContent>
  {{("students.student-detail.no-licence-category" | translate)}}
</ng-template>



