import { Component, inject, Input } from "@angular/core";
import { LessonService } from "../../../services/lesson.service";
import { TuiTablePagination } from "@taiga-ui/addon-table";
import { TableSortEvent } from "../../../interfaces/table-sort-event";
import { Lesson } from "../../../models/lesson";

@Component({
  selector: "app-teacher-lessons-table",
  templateUrl: "./teacher-lessons-table.component.html",
  styleUrls: ["./teacher-lessons-table.component.scss"],
})
export class TeacherLessonsTableComponent {
  @Input() userId: string | undefined;
  lessons: Lesson[] | undefined;
  tablePage: number = 0;
  tablePageSize: number = 10;
  totalLessons: number = 0;
  columns: string[] = [
    "createdAt",
    "startDate",
    "endDate",
    "lessonCategory.name",
    "count",
    "price",
    "status",
    "licenceType.name",
    "location.name",
    "teacher",
  ];
  headers: string[] = [
    "Erstellt am",
    "Startzeit",
    "Endzeit",
    "Fahrstundentyp",
    "Anzahl Fahrstunden",
    "Preis",
    "Status",
    "Lizenz",
    "Standort",
    "Fahrschüler",
  ];
  noSortColumns: string[] = [
    "lessonCategory.name",
    "licenceType.name",
    "location.name",
    "teacher",
  ];
  private lessonService = inject(LessonService);

  ngOnInit(): void {
    this.lessonService.setPaginationIndices({ start: 0, limit: 10 });
    this.getLessonsOfStudent();
  }

  getLessonsOfStudent() {
    if (!this.userId) return;

    this.lessonService
      .getLessons({
        userId: this.userId,
      })
      .subscribe((lessons) => {
        this.lessons = lessons;
        lessons.forEach((lesson) => {
          // teacher is student of teacher in this case
          lesson.teacher =
            lesson.student.firstName + " " + lesson.student.lastName;
        });

        this.totalLessons = this.lessonService.getAmount();
      });
  }

  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.lessonService.setPaginationIndices({
      start: this.tablePage * this.tablePageSize,
      limit: this.tablePageSize,
    });

    this.getLessonsOfStudent();
  }

  public sortTable(tableSort: TableSortEvent) {
    this.lessonService.setSorting(tableSort);
    this.getLessonsOfStudent();
  }
}
