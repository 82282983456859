/**
 * Enum for routes used for navigation in the frontend.
 */
export enum NavRoutes {
  // basic routes
  TENANT = 'tenant',
  DASHBOARD = 'dashboard',
  USER = 'user',
  PAYMENT = 'payments',
  LOCATION = 'location',
  STUDENT = 'student',
  LESSON_HISTORY = 'lesson-history',

  // licence administration routes
  LICENCE = 'licence',
  LICENCE_TYPE = 'licence-type',
  LESSON_CATEGORY = 'lesson-category',
  NEW_LESSON_CATEGORY = 'new-lesson-category',
  NEW_LICENCE_TYPE = 'new-licence-type',

  // calendar routes
  CALENDAR = 'calendar',
  NEW_LESSON = 'new-lesson',

  // settings routes
  SETTINGS = 'settings',
  PROFILE = 'profile',
  ROLES = 'roles',
  PASSWORD = 'password',
  GLOBAL_USERS = 'global-users',

  // auth routes
  LOGIN = 'login',
  SET_NEW_PASSWORD = 'setPassword',

  // error routes
  ERROR = 'error',
  ERROR_OUT = 'error-out',

  // new payments
  PAYMENT_STUDENT = 'student',
  PAYMENT_TEACHER = 'teacher',
  PAYMENT_OFFICE = 'office',
  PAYMENT_BOSS = 'boss'
}
