import { Component } from '@angular/core';
import { lastValueFrom, map, Subscription } from 'rxjs';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LicenceTypeService } from '../../../services/licence-type.service';
import { LicenceTargetService } from '../../../services/licence-target.service';
import { PushService } from '../../../services/push.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../services/util.service';
import { pushTypes } from '../../../enums/push-types';
import { NavRoutes } from '../../../enums/nav-routes';
import { LicenceTypeBody } from '../../../interfaces/body/licence-type-body';
import { LicenceType } from '../../../models/licence-type';
import { LicenceTargetBody } from '../../../interfaces/body/licence-target-body';
import { LoadingService } from '../../../services/loading.service';
import { tuiIconTrash } from '@taiga-ui/icons';
import { HttpStatusCode } from '@angular/common/http';
import { CarIcons } from '../../../enums/car-icons';
import { UserRole } from '../../../enums/user-role';
import { LessonCategory } from '../../../models/lesson-category';
import { DropDownItem } from '../../../interfaces/drop-down-item';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { LessonCategoryService } from '../../../services/lesson-category.service';

@Component({
  selector: 'app-licence-type-detail-page',
  templateUrl: './licence-type-detail-page.component.html',
  styleUrls: ['./licence-type-detail-page.component.scss'],
})
export class LicenceTypeDetailPageComponent {
  licenceTypeId = '';
  licenceType?: LicenceType;
  oldTargetIds: string[] = []; // used to determine which targets are new
  idsOfDisabledLessonCategories: string[] = [];
  isLoading$ = this.loadingService.getLoadingState();
  sendRequest = false;
  isOpen: boolean = true;
  showDeleteDialog: boolean = false;

  categoryDropdownItems: DropDownItem[] = [];
  categories: LessonCategory[] = [];
  selectedCategories: { id: string; label: string | null }[] = [];
  selectedCategory: { id: string; label: string | null } | undefined;

  licenceTypeForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    icon: new FormControl(''),
    targets: new FormArray([]), // dynamic form array
  });

  // strings for i18n
  pushNotificationSuccess: string = '';
  pushNotificationUniqueError: string = '';
  pushNotificationError: string = '';

  pushNotificationDeletedSuccess: string = '';
  pushNotificationDeletedError: string = '';
  /**
   * BreadcrumbItems for the breadcrumb navigation from the detail to the general student page
   */
  breadcrumbItems = [
    {
      caption: 'licence-type-detail.breadcrumb-item-caption',
      routerLink: `/${NavRoutes.SETTINGS}/${NavRoutes.LICENCE_TYPE}`,
    },
    {
      caption: 'licence-type-detail.breadcrumb-item-caption-detail',
      routerLink: null,
      routerLinkActiveOptions: { exact: true },
    },
  ];
  protected readonly FormGroup = FormGroup;
  protected readonly NavRoutes = NavRoutes;
  protected readonly tuiIconTrash = tuiIconTrash;
  protected readonly CarIcons = CarIcons;
  private backToParentSubscription: Subscription = new Subscription();

  constructor(
    private licenceTypeService: LicenceTypeService,
    private licenceTargetService: LicenceTargetService,
    private pushService: PushService,
    private translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private lessonCategoryService: LessonCategoryService,
  ) {}

  get targets() {
    return this.licenceTypeForm.get('targets') as FormArray;
  }

  ngOnChanges(): void {
    this.licenceTypeForm.markAsUntouched();
  }

  ngOnDestroy(): void {
    this.backToParentSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.oldTargetIds = [];
    this.getLicenceTypeWithRouteParam();
    this.getCategories();

    // translated strings for push-notifications
    this.translateService
      .get([
        'licence-type-detail.push-success-patched',
        'licence-type-detail.push-error-unique-patched',
        'licence-type-detail.push-error-patched',
        'licence-type-detail.push-success-deleted',
        'licence-type-detail.push-error-deleted',
      ])
      .subscribe((translations) => {
        this.pushNotificationSuccess =
          translations['licence-type-detail.push-success-patched'];
        this.pushNotificationError =
          translations['licence-type-detail.push-error-patched'];
        this.pushNotificationUniqueError =
          translations['licence-type-detail.push-error-unique-patched'];
        this.pushNotificationDeletedSuccess =
          translations['licence-type-detail.push-success-deleted'];
        this.pushNotificationDeletedError =
          translations['licence-type-detail.push-error-deleted'];
      });

    if (this.isManager()) {
      this.licenceTypeForm.disable();
    }
  }

  filterOptions(event: any) {
    const selectedItem = this.categoryDropdownItems.find(
      (item) => item.id === event.id,
    );
    if (!selectedItem) return;
    this.selectedCategories.push(selectedItem);
    this.categoryDropdownItems = this.categoryDropdownItems.filter(
      (option) => option.id !== event.id,
    );

    if (!event.previousValue) return;
    const previousCategory:
      | {
          id: string;
          label: string | null;
        }
      | undefined = this.selectedCategories.find(
      (item) => item.id === event.previousValue,
    );

    if (!previousCategory) return;
    this.categoryDropdownItems.push(<DropDownItem>previousCategory);
  }

  stringify(
    items: readonly any[],
  ): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(
      items.map(({ id, label }) => [id, label] as [number, string]),
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }

  /**
   * Emits the close event to the parent component and closes the dialog.
   */
  setCloseEvent() {
    this.sendRequest = false;
    this.router.navigate([`/${NavRoutes.SETTINGS}/${NavRoutes.LICENCE_TYPE}`]);
    this.isOpen = false;
  }

  addTargetFormRow() {
    const targetForm = new FormGroup({
      lessonCategoryId: new FormControl('', [Validators.required]),
      lessonCount: new FormControl('', [
        Validators.required,
        Validators.min(1),
      ]),
      price: new FormControl(null, [Validators.required, Validators.min(1)]),
    });

    this.targets.push(targetForm);
  }

  deleteTargetFormRow(index: number, target: any) {
    this.targets.removeAt(index);

    // update the selected and dropdown category array to show deleted category again
    let deletedCategory = this.selectedCategories.find(
      (category) => category.id === target.value.lessonCategoryId,
    );
    this.selectedCategories = this.selectedCategories.filter(
      (category) => category.id !== target.value.lessonCategoryId,
    );

    if (deletedCategory !== undefined) {
      this.categoryDropdownItems.push(<DropDownItem>deletedCategory);
    }

    // delete the id of the deleted target from the oldTargetIds array
    this.idsOfDisabledLessonCategories.splice(index, 1);
  }

  getLicenceTypeWithRouteParam() {
    this.route.paramMap.subscribe((params) => {
      this.licenceTypeId = params.get('id')!;
      if (this.licenceTypeId != null) {
        this.licenceTypeService
          .getLicenceTypeById(this.licenceTypeId)
          .subscribe(
            (licenceType) => {
              this.licenceType = licenceType;
              this.licenceTypeForm.controls['name'].setValue(
                this.licenceType.name,
              );
              this.licenceTypeForm.controls['icon'].setValue(
                this.licenceType.icon,
              );
              for (let target of this.licenceType.targets) {
                const targetForm = new FormGroup({
                  id: new FormControl(target.id),
                  lessonCategoryId: new FormControl(target.lessonCategory?.id, [
                    Validators.required,
                  ]),
                  lessonCount: new FormControl(target.lessonCount, [
                    Validators.required,
                  ]),
                  price: new FormControl(target.price, [Validators.required]),
                });

                this.idsOfDisabledLessonCategories.push(
                  target.lessonCategory?.id!,
                );
                targetForm.controls['lessonCategoryId'].disable();

                if (this.isManager()) {
                  targetForm.disable();
                }
                this.targets.push(targetForm);
                this.oldTargetIds.push(target.id);
                this.selectedCategories.push({
                  id: target.lessonCategory?.id!,
                  label: target.lessonCategory?.name ?? '',
                });
              }
            },
            (error) => {
              this.router.navigate([NavRoutes.ERROR], {
                queryParams: {
                  code: error.error.code,
                  key: error.error.key,
                  url: error.url,
                },
              });
            },
          );
      }
    });
  }

  deleteLicenceType() {
    if (this.sendRequest) return;
    this.sendRequest = true;
    this.licenceTypeService
      .deleteLicenceType(this.licenceTypeId)
      .subscribe((isDeleted) => {
        if (isDeleted) {
          this.pushService.sendPush(
            pushTypes.SUCCESS,
            this.pushNotificationDeletedSuccess,
          );
          this.setCloseEvent();
        } else {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationDeletedError,
          );
          this.setCloseEvent();
        }
      });
  }

  editLicenceType() {
    if (this.sendRequest) return;
    this.sendRequest = true;
    const tenantId = this.authService.getTenantId();
    if (tenantId == null) {
      this.pushService.sendPush(pushTypes.ERROR, this.pushNotificationError);
      return;
    }
    const licenceTypeBody: LicenceTypeBody = {
      tenantId: tenantId,
      name: this.licenceTypeForm.value.name!,
      icon: this.licenceTypeForm.value.icon ?? '',
    };

    this.licenceTypeService
      .updateLicenceType(licenceTypeBody, this.licenceTypeId)
      .subscribe(async (response: number) => {
        if (response === HttpStatusCode.Ok) {
          for (let target of this.targets.controls) {
            const lessonCategoryId = target.value.lessonCategoryId;

            const licenceTargetBody: LicenceTargetBody = {
              licenceTypeId: this.licenceTypeId,
              lessonCategoryId: '',
              tenantId: tenantId,
              lessonCount: target.value.lessonCount,
              price: target.value.price,
            };

            if (lessonCategoryId) {
              licenceTargetBody.lessonCategoryId =
                lessonCategoryId.id ?? lessonCategoryId;
            }

            if (this.oldTargetIds.includes(target.get('id')?.value)) {
              // update targets
              const updatedLicenceTarget: boolean = await lastValueFrom(
                this.licenceTargetService
                  .updateLicenceTarget(
                    licenceTargetBody,
                    target.get('id')?.value,
                  )
                  .pipe(
                    map((updated: boolean) => {
                      return updated;
                    }),
                  ),
              );
              if (!updatedLicenceTarget) {
                this.sendErrorPushNotification();
                return;
              }
              this.deleteElementFromOldTargetIds(target.get('id')?.value);
            } else {
              // create new targets
              const createdLicenceTarget = await lastValueFrom(
                this.licenceTargetService
                  .createLicenceTarget(licenceTargetBody)
                  .pipe(
                    map((created: boolean) => {
                      return created;
                    }),
                  ),
              );
              if (!createdLicenceTarget) {
                this.sendErrorPushNotification();
                return;
              }
              this.deleteElementFromOldTargetIds(target.value.id);
            }
          }
          // delete targets that are still left in the oldTargetIds array but not in the new targets array
          for (let i = 0; i < this.oldTargetIds.length; i++) {
            const deletedLicenceTarget = await lastValueFrom(
              this.licenceTargetService
                .deleteLicenceTarget(this.oldTargetIds[i])
                .pipe((deleted) => {
                  return deleted;
                }),
            );
            if (!deletedLicenceTarget) {
              this.sendErrorPushNotification();
              return;
            }
          }

          this.oldTargetIds = [];
          this.sendSuccessPushNotification();
        } else {
          this.sendErrorPushNotification(response);
        }
      });
  }

  deleteElementFromOldTargetIds(id: string) {
    const index = this.oldTargetIds.indexOf(id);
    // only delete when the id is in the array
    if (index > -1) {
      this.oldTargetIds.splice(index, 1);
    }
  }

  getFormGroupFromAbstractControl(abstractControl: AbstractControl): FormGroup {
    return abstractControl as FormGroup;
  }

  public isManager(): boolean {
    return this.authService.getUserRole() === UserRole.MANAGER;
  }

  getCategories() {
    const tenantId = this.authService.getTenantId();
    if (tenantId == null) return;
    this.getCategoriesForTenant(tenantId);
  }

  getCategoriesForTenant(tenantId: string) {
    this.lessonCategoryService
      .getCategory({ tenantId: tenantId })
      .subscribe((data) => {
        // only push categories to the dropdown menu which are not already selected
        this.categories = data.filter(
          (category) =>
            !this.selectedCategories.find(
              (selectedCategory) => selectedCategory.id === category.id,
            ),
        );
        this.categoryDropdownItems = this.utilService.generateDropdownItems(
          this.categories,
        );
      });
  }

  private sendErrorPushNotification(status: number = 500) {
    switch (status) {
      case 409:
        this.pushService.sendPush(
          pushTypes.ERROR,
          this.pushNotificationUniqueError,
        );
        break;
      default:
        this.pushService.sendPush(pushTypes.ERROR, this.pushNotificationError);
        break;
    }
    this.setCloseEvent();
  }

  private sendSuccessPushNotification() {
    this.pushService.sendPush(pushTypes.SUCCESS, this.pushNotificationSuccess);
    // If created, emit the event to the parent component and close the dialog
    this.setCloseEvent();
    this.licenceTypeForm.reset();
    this.targets.clear();
  }
}
