<div class="wrapper">
  <tui-loader
    [overlay]="true"
    [showLoader]="!!(isLoading$ | async)"
    [size]="'xl'"
  >
    <ng-container *ngIf="tenant">
      <div class="">
        <h1>{{ tenant.name }}</h1>
      </div>

      <div id="dashboard-wrapper">

        <div class="top">
          <div>
            <tui-axes
              [axisXLabels]="months"
              [horizontalLines]="0"
              [tuiLineChartHint]="hintContent"
              class="axes"
            >
              <tui-line-chart
                [filled]="true"
                [height]="highestArrayValue"
                [value]="(observableValues$ | async) ? (observableValues$ | async)! : []"
                [width]="months.length"
              ></tui-line-chart>
            </tui-axes>
          </div>
        </div>

        <div class="bottom">

          <app-dashboard-card [icon]="tuiIconUsersLarge" [routerLink]="'/'+NavRoutes.STUDENT"
                              [title]="('dashboard.tile-students' | translate)" [value]="customersCount.toString()">

          </app-dashboard-card>

          <app-dashboard-card [icon]="tuiIconUserLarge" [routerLink]="'/'+NavRoutes.USER"
                              [title]="('dashboard.tile-user' | translate)" [value]="usersCount.toString()">

          </app-dashboard-card>

          <app-dashboard-card [icon]="tuiIconMapPinLarge" [routerLink]="'/settings/'+NavRoutes.LOCATION"
                              [title]="('dashboard.tile-locations' | translate)" [value]="locationsCount.toString()">

          </app-dashboard-card>

          <app-dashboard-card [icon]="tuiIconCalendarLarge"
                              [routerLink]="'/'+NavRoutes.CALENDAR" [title]="('dashboard.tile-appointments' | translate)"
                              [value]="appointmentsCount.toString()">

          </app-dashboard-card>
        </div>

      </div>
    </ng-container>
  </tui-loader>
</div>
