import { Component, ViewChild } from "@angular/core";
import {
  Calendar,
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import deLocale from "@fullcalendar/core/locales/de";
import enLocale from "@fullcalendar/core/locales/en-gb";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "../../services/language.service";
import { Languages } from "../../enums/languages";
import { TuiDay } from "@taiga-ui/cdk";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { StudentService } from "../../services/student.service";
import { UserService } from "../../services/user.service";
import { Student } from "../../models/student";
import { DropDownItem } from "../../interfaces/drop-down-item";
import { LessonService } from "../../services/lesson.service";
import { Lesson } from "../../models/lesson";
import { UtilService } from "../../services/util.service";
import { Router } from "@angular/router";
import { NavRoutes } from "../../enums/nav-routes";
import { DateTime } from "luxon";
import { tuiIconRotateCw } from "@taiga-ui/icons";
import { PushService } from "../../services/push.service";
import { LessonStatus } from "../../enums/lesson-status";
import { SuperAdminService } from "../../services/super-admin.service";
import { AuthService } from "../../services/auth.service";
import { UserRole } from "../../enums/user-role";
import stc from "string-to-color";
import { RoleService } from "../../services/role.service";
import { CalendarService } from "../../services/calendar.service";
import { Subscription } from "rxjs";
import { LocationService } from "../../services/location.service";
import { Location } from "../../models/location";
import { Role } from "../../models/role";

type startAndEndDate = {
  start: Date;
  end: Date;
};

/**
 * This component is responsible for the calendar page.
 * It contains the calendar and the form to add events to the calendar.
 * The implementation is just a starting point and can be changed.
 */
@Component({
  selector: "app-calendar-page",
  templateUrl: "./calendar-page.component.html",
  styleUrls: ["./calendar-page.component.scss"],
})
export class CalendarPageComponent {
  // to access the raw calendar object (important if you want to use the calendar api)
  @ViewChild("calendar") calendarComponent!: FullCalendarComponent;
  pushNotificationError!: string;
  //getting the calendar api
  calendarApi!: Calendar;
  events: any[] = [];
  // raw objects for the dropdown menu
  students: Student[] = [];
  // objects for the dropdown menu which are mapped to DropDownItems
  dropDownUserItems: DropDownItem[] = [];
  dropDownStudentItems: DropDownItem[] = [];
  dropDownLocationItems: DropDownItem[] = [];
  isOpen: boolean = false; // to determine if the menubar is open or not
  // for the datepicker, stores the selected day
  value: TuiDay | null = null;
  // object that contains the options for the calendar
  calendarOptions: CalendarOptions = {
    height: "100%",
    plugins: [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin],
    locales: [deLocale, enLocale],
    locale: "de",
    timeZone: "utc",
    titleFormat: { year: "numeric", month: "long", day: "numeric" },
    scrollTime: "10:00:00",
    slotLabelFormat: {
      hour: "numeric",
      minute: "2-digit",
    },
    headerToolbar: {
      left: "prev title next",
      center: "",
      right: "today timeGridDay timeGridThreeDay timeGridWeek",
    },

    // views (buttons to change the view of the calendar)
    initialView: "timeGridThreeDay",
    views: {
      timeGridThreeDay: {
        type: "timeGrid",
        duration: { days: 3 },
        dateIncrement: { days: 1 },
      },
    },

    slotLabelInterval: "00:15:00",
    slotDuration: "00:15:00",

    // calendar interaction
    allDaySlot: false,
    editable: false,
    selectable: true,
    weekends: true,

    // handle clicks on events and dates
    select: this.handleDateSelect.bind(this),
    selectAllow: this.checkIfSelectionIsAllowed.bind(this),
    eventClick: this.handleEventClick.bind(this),
    unselectAuto: false,

    // sync the calendar with the datepicker by setting the date of the calendar to the selected date of the datepicker
    customButtons: {
      next: {
        click: (ev: MouseEvent, element: HTMLElement) => {
          this.calendarApi.next();
          this.syncDatePicker();
          this.populateCalendarAndFilter();
        },
      },
      prev: {
        click: (ev: MouseEvent, element: HTMLElement) => {
          this.calendarApi.prev();
          this.syncDatePicker();
          this.populateCalendarAndFilter();
        },
      },
      today: {
        text: "Heute",
        click: () => {
          this.calendarApi.today();
          this.syncDatePicker();
          this.populateCalendarAndFilter();
        },
      },

      timeGridWeek: {
        text: "Woche",
        click: (ev: MouseEvent, element: HTMLElement) => {
          this.calendarApi.changeView("timeGridWeek");
          this.populateCalendarAndFilter();
        },
      },
      timeGridThreeDay: {
        text: "3 Tage",
        click: (ev: MouseEvent, element: HTMLElement) => {
          this.calendarApi.changeView("timeGridThreeDay");
          this.populateCalendarAndFilter();
        },
      },

      // timeGridThreeDay timeGridWeek in ngOnInit!
    },
  };
  // the form to filter the calendar
  readonly filterForm = new FormGroup({
    teacher: new FormControl(null),
    student: new FormControl(null),
    location: new FormControl(null),
  });
  // to determine if the calendar is filtered by teacher or student
  isFilteredByTeacher = false;
  isFilteredByStudent = false;
  isFilteredByLocation: boolean = false;
  protected readonly tuiIconRotateCw = tuiIconRotateCw;
  private backToParentSubscription: Subscription = new Subscription();
  private selectedTenantSubscription: Subscription = new Subscription();
  private teacherFilter: Subscription = new Subscription();
  private studentFilter: Subscription = new Subscription();
  private locationFilter: Subscription = new Subscription();
  private locations: Location[] = [];

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private studentService: StudentService,
    private userService: UserService,
    private lessonService: LessonService,
    private utilService: UtilService,
    private pushService: PushService,
    private router: Router,
    private superAdminService: SuperAdminService,
    private authService: AuthService,
    private roleService: RoleService,
    private calendarService: CalendarService,
    private locationService: LocationService,
  ) {}

  ngOnInit(): void {
    this.translate
      .get(["calendar.filter-push-not-found"])
      .subscribe((translations) => {
        this.pushNotificationError =
          translations["calendar.filter-push-not-found"];
      });

    // listener for the teacher dropdown
    this.teacherFilter =
      this.filterForm.controls.teacher.valueChanges.subscribe((value) => {
        if (value != null) {
          this.populateCalendarAndFilter(true);
        }
      });

    // listener for the student dropdown
    this.studentFilter =
      this.filterForm.controls.student.valueChanges.subscribe((value) => {
        if (value != null) {
          this.populateCalendarAndFilter(true);
        }
      });

    // listener for the location dropdown
    this.locationFilter =
      this.filterForm.controls.location.valueChanges.subscribe((value) => {
        if (value != null) {
          this.populateCalendarAndFilter(true);
        }
      });

    // subscribe to router events to refresh the calendar when the user navigates back to the calendar page
    this.backToParentSubscription = this.router.events.subscribe((event) => {
      if (this.utilService.isNavigatedBackToParent(event, NavRoutes.CALENDAR)) {
        if (this.calendarApi != null) {
          this.calendarApi.unselect();
        }
        this.populateCalendarAndFilter();
      }
    });

    // populate table again if selected tenant changes
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe((id) => {
          this.populateCalendarAndFilter();
        });
    } else {
      this.populateCalendarAndFilter();
    }

    // change selection in calendar when changed in new event component
    this.calendarService.selectedTime$.subscribe((selectedTime) => {
      if (selectedTime?.startTime == null || selectedTime?.endTime == null)
        return;

      const utcStartTime: Date = this.utilService.getUtcJsDate(
        selectedTime.startTime,
      );
      const utcEndTime: Date = this.utilService.getUtcJsDate(
        selectedTime.endTime,
      );

      // go to selected date
      this.calendarApi.gotoDate(utcStartTime);

      // scroll to selected time (difference between start and end, minus 2 hours to show some time before and after selection)
      const luxonStart = DateTime.fromJSDate(selectedTime?.startTime);
      const luxonEnd = DateTime.fromJSDate(selectedTime?.endTime);
      const durationDifference =
        luxonEnd.diff(luxonStart).as("milliseconds") / 2;

      const scrollDate = luxonStart
        .plus({ milliseconds: durationDifference })
        .minus({ hours: 2 })
        .toJSDate();

      this.calendarApi.scrollToTime(
        scrollDate.getHours() + ":" + scrollDate.getMinutes(),
      );

      // select the selected time range (from sidebar)
      this.calendarApi.select({ start: utcStartTime, end: utcEndTime });
    });

    this.initCalendarLanguage();
  }

  ngAfterViewChecked() {
    // to access the raw calendar object
    this.calendarApi = this.calendarComponent.getApi();
  }

  ngOnDestroy() {
    this.selectedTenantSubscription.unsubscribe();
    this.backToParentSubscription.unsubscribe();
    this.teacherFilter.unsubscribe();
    this.studentFilter.unsubscribe();
  }

  /**
   * Syncs the datepicker with the calendar by setting the date of the calendar to the selected date of the datepicker
   */
  syncDatePicker() {
    this.value = TuiDay.fromLocalNativeDate(this.calendarApi.getDate());
  }

  /**
   * Initializes the language of the calendar with the current language in the languageService
   */
  initCalendarLanguage() {
    this.languageService.getLanguage().subscribe((language) => {
      if (language === Languages.ENGLISH) {
        this.calendarOptions.locale = "en-gb";
      } else {
        this.calendarOptions.locale = language;
      }
    });
  }

  getUserAndStudentForTenant(tenantId?: string): void {
    this.dropDownUserItems = [];
    this.roleService.getRoles({ tenantId }).subscribe((roles) => {
      const teacherRoleId = this.findRoleId(roles, UserRole.TEACHER);
      const tenantAdminRoleId = this.findRoleId(roles, UserRole.TENANT_ADMIN);

      if (teacherRoleId) this.generateUsersForRole(teacherRoleId, tenantId);
      if (tenantAdminRoleId)
        this.generateUsersForRole(tenantAdminRoleId, tenantId);
    });

    this.studentService
      .getStudent({ tenantId, active: true })
      .subscribe((students) => {
        this.students = students;
        this.dropDownStudentItems = this.generateRoleDropdownItems(students);
      });
  }

  getLocations(tenantId: string) {
    this.locationService.getLocations({ tenantId }).subscribe((locations) => {
      this.locations = locations;
      this.dropDownLocationItems = this.generateRoleDropdownItems(locations);
    });
  }

  /**
   * Generates the dropdown items for the role dropdown menu
   */
  generateRoleDropdownItems(items: any[]) {
    let dropDownItems: DropDownItem[] = [];
    items.map((item) => {
      dropDownItems.push({ id: item.id, label: item.name });
    });
    return dropDownItems;
  }

  /**
   * Fires when a date is selected in the calendar.
   * Passes the selected date to the form to add an event to the calendar.
   * Opens the form to add an event to the calendar.
   * @param arg
   */
  handleDateSelect(arg: DateSelectArg | startAndEndDate): void {
    const timeDifference = arg.end.getTime() - arg.start.getTime();
    const slotDuration = 45 * 60 * 1000; // 45 minutes
    // round to next multiple of slotDuration
    const roundedTimeDifference =
      Math.ceil(timeDifference / slotDuration) * slotDuration;
    // add roundedTimeDifference to start time to get the new rounded end time
    const roundedEndTime = new Date(
      arg.start.getTime() + roundedTimeDifference,
    );

    if (arg.end.getTime() !== roundedEndTime.getTime()) {
      // End-time is not rounded yet
      arg.end.setTime(roundedEndTime.getTime());
      this.calendarApi.select(arg);
    } else {
      // End-time is already rounded, continue
      this.isOpen = true;
      this.calendarApi.render();
      this.calendarService.selectedStartTime = arg.start;
      this.calendarService.selectedEndTime = arg.end;
      this.router.navigateByUrl(
        `${NavRoutes.CALENDAR}/${NavRoutes.NEW_LESSON}`,
      );
    }
  }

  checkIfSelectionIsAllowed(selectInfo: any): boolean {
    const startMinutes =
      selectInfo.start.getHours() * 60 + selectInfo.start.getMinutes();
    const endMinutes =
      selectInfo.end.getHours() * 60 + selectInfo.end.getMinutes();

    const isInThePast = this.utilService.isSelectionBeforeToday(
      selectInfo.start,
    );

    const minuteDifference = endMinutes - startMinutes;

    // do not allow selection of more than 135 minutes
    if (minuteDifference > this.calendarService.maxMinutes || isInThePast) {
      return false;
    }

    // do not allow selection of more than 1 dayWbUn/eWe92JdJ
    return selectInfo.start.getDate() === selectInfo.end.getDate();
  }

  /**
   * Fires when an event-item is clicked
   * @param arg the event that was clicked
   */
  handleEventClick(arg: EventClickArg): void {
    this.router.navigateByUrl(`${NavRoutes.CALENDAR}/${arg.event.id}`);
  }

  /**
   *
   * @param day
   */
  onDayClick(day: TuiDay): void {
    this.value = day;
    this.calendarApi.gotoDate(day.toUtcNativeDate());
    this.populateCalendarAndFilter();
  }

  /**
   * Pushes a lesson to the calendar and refreshes the calendar.
   *
   * @param lesson the lesson to push to the calendar
   */
  addEventToCalendar(lesson: Lesson) {
    let color = "#AFCA0B";

    // overwrite color if lesson is inactive
    switch(lesson.status){
      case LessonStatus.ACTIVE:
        color = stc(lesson.userId);
        break;
      case LessonStatus.INACTIVE:
        color = "#818181";
        break;
      case LessonStatus.FINISHED:
        color = "#86DC3D";
        break;
      default:
        color = stc(lesson.userId);
    }

    this.events.push({
      id: lesson.id,
      title: this.utilService.getFormattedName(
        lesson.student?.firstName,
        lesson.student?.lastName,
      ),
      start: lesson.startDate,
      end: lesson.endDate,
      color: color,
      extendedProps: {
        location: lesson.location?.name,
        assignedTo: this.utilService.getFormattedName(
          lesson.user?.firstName,
          lesson.user?.lastName,
        ),
        student: lesson.student,
        teacher: lesson.user,
        status: lesson.status,
      },
    });

    this.calendarOptions = {
      events: this.events,
    };
  }

  populateCalendarAndFilter(triggeredByFilter: boolean = false) {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();

      if (selectedTenantId == null) return;
      this.getEventsForTimeRange(triggeredByFilter, selectedTenantId);
      this.getUserAndStudentForTenant(selectedTenantId); // get user and students for filter dropdowns
      this.getLocations(selectedTenantId);
    } else {
      this.getEventsForTimeRange(
        triggeredByFilter,
        this.authService.getLoggedInUser()?.tenantId,
      );
      this.getUserAndStudentForTenant(
        this.authService.getLoggedInUser()?.tenantId,
      );
      if (this.authService.getLoggedInUser()?.tenantId)
        this.getLocations(this.authService.getLoggedInUser()!.tenantId);
    }
  }

  /**
   * Gets the events for the selected time range of the calendar and adds them to the calendar.
   * Also includes the filters for the teacher and student based on the selected values in the dropdown menu.
   * If the calendar is not initialized yet, the events for the current week are added to the calendar.
   */
  getEventsForTimeRange(triggeredByFilter: boolean = false, tenantId?: string) {
    this.events = [];
    let studentId = undefined;
    let teacherId = undefined;
    let locationId = undefined;
    if (this.filterForm.controls.student.value) {
      studentId = this.filterForm.controls.student.value["id"];
      this.isFilteredByStudent = true;
    }
    if (this.filterForm.controls.teacher.value) {
      teacherId = this.filterForm.controls.teacher.value["id"];
      this.isFilteredByTeacher = true;
    }
    if (this.filterForm.controls.location.value) {
      locationId = this.filterForm.controls.location.value["id"];
      this.isFilteredByLocation = true;
    }
    if (this.calendarApi != null) {
      this.lessonService
        .getLessons({
          tenantId: tenantId,
          skipDate: String(
            DateTime.fromJSDate(this.calendarApi.view.currentStart)
              .minus({ day: 1 })
              .toISODate(),
          ),
          limitDate: String(
            DateTime.fromJSDate(this.calendarApi.view.currentEnd)
              .plus({ day: 1 })
              .toISODate(),
          ),
          studentId: studentId,
          userId: teacherId,
          locationId: locationId,
        })
        .subscribe((lessons) => {
          if (lessons.length > 0) {
            for (let lesson of lessons) {
              this.addEventToCalendar(lesson);
            }
          }
          // Either case, update the calendar with the actual list of events
          this.calendarOptions = {
            events: this.events,
          };
        });
    } else {
      // is executed when the calendar is not initialized yet
      const weekStart = DateTime.now()
        .startOf("week")
        .minus({ day: 1 })
        .toISODate();
      const weekEnd = DateTime.now().endOf("week").plus({ day: 1 }).toISODate();
      this.lessonService
        .getLessons({
          tenantId: tenantId,
          skipDate: String(weekStart),
          limitDate: String(weekEnd),
          studentId: studentId,
          userId: teacherId,
          locationId: locationId,
        })
        .subscribe((lessons) => {
          if (lessons.length > 0) {
            for (let lesson of lessons) {
              this.addEventToCalendar(lesson);
            }
          }
          // Either case, update the calendar with the actual list of events
          this.calendarOptions = {
            events: this.events,
          };
        });
    }
  }

  /**
   * Resets the student filter and refreshes the calendar
   */
  resetStudentFilter(reload: boolean = true) {
    this.filterForm.controls.student.setValue(null);
    if (reload) {
      this.populateCalendarAndFilter();
    }
    this.isFilteredByStudent = false;
  }

  /**
   * Resets the teacher filter and refreshes the calendar
   */
  resetTeacherFilter(reload: boolean = true) {
    this.filterForm.controls.teacher.setValue(null);
    if (reload) {
      this.populateCalendarAndFilter();
    }
    this.isFilteredByTeacher = false;
  }

  /**
   * Returns true if the user is a super admin
   */
  public isSuperAdmin() {
    return this.superAdminService.isSuperAdmin();
  }

  public createNewClickEvent() {
    this.router.navigateByUrl(`${NavRoutes.CALENDAR}/${NavRoutes.NEW_LESSON}`);
  }

  resetLocationFilter(reload: boolean = true) {
    this.filterForm.controls.location.setValue(null);
    if (reload) {
      this.populateCalendarAndFilter();
    }
    this.isFilteredByLocation = false;
  }

  private findRoleId(roles: Role[], userRole: UserRole): string | undefined {
    return roles.find((role) => role.userRole === userRole)?.id;
  }

  private generateUsersForRole(roleId: string, tenantId?: string): void {
    this.userService
      .getUsers({ tenantId, roleId, active: true })
      .subscribe((users) => {
        if (users.length === 0) return;
        let newAdditions = this.generateRoleDropdownItems(users);

        if (roleId === UserRole.TENANT_ADMIN) {
          newAdditions = newAdditions.filter(
            (newAddition) =>
              !this.dropDownUserItems.some(
                (item) => item.id === newAddition.id,
              ),
          );
        }

        this.dropDownUserItems = [...this.dropDownUserItems, ...newAdditions];
      });
  }
}
