import { Component } from '@angular/core';
import { RoleService } from '../../../services/role.service';
import { Role } from '../../../models/role';
import { DropDownItem } from '../../../interfaces/drop-down-item';
import { FormControl, FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { pushTypes } from '../../../enums/push-types';
import { TranslateService } from '@ngx-translate/core';
import { PushService } from '../../../services/push.service';
import { RoleEvent } from '../../../interfaces/role-event';
import { LoadingService } from '../../../services/loading.service';
import { UserRole } from '../../../enums/user-role';

/**
 * Component tab for managing explanation within the settings page
 * Using the role-form component for creating and editing explanation
 * Using the roleService for requesting and manipulating explanation
 */
@Component({
  selector: 'app-explanation',
  templateUrl: './explanation.component.html',
  styleUrls: ['./explanation.component.scss'],
})
export class ExplanationComponent {
  constructor() {}

  ngOnInit(): void {}
}
