<app-menubar
  [title]="'settings.global-user-tab.page-title-new' | translate"
  [description]="'create-user.description' | translate"
  [open]="isOpen"
  (closingEvent)="setCloseEvent()"
>
  <form [formGroup]="newUserForm">
    <app-input-email [fieldName]="'email'" [hint]="'create-user.input-label-mail' | translate"></app-input-email>
    <app-input-text [fieldName]="'firstName'" [hint]="'create-user.input-label-first-name' | translate" [required]="false"></app-input-text>
    <app-input-text [fieldName]="'lastName'" [hint]="'create-user.input-label-last-name' | translate" [required]="false"></app-input-text>
    <app-input-phone [fieldName]="'telephone'" [hint]="'create-user.input-label-telephone' | translate" [required]="false"></app-input-phone>
    <div class="radio-list">
      <app-radio-list [fieldName]="'role'" [items]="globalRoles"></app-radio-list>
    </div>
    <div class="button-wrapper">
      <button
        tuiButton
        size="m"
        [disabled]="!newUserForm.valid"
        type="submit"
        (click)="createNewUser()"
      >
        {{ 'create-user.button-add' | translate }}
      </button>
      <button
        tuiButton
        size="m"
        appearance="secondary"
        (click)="setCloseEvent()"
      >
        {{ 'common.button-cancel' | translate }}
      </button>
    </div>
  </form>
</app-menubar>
