import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PushService } from '../../../services/push.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth.service';
import { pushTypes } from '../../../enums/push-types';
import { LessonCategoryBody } from '../../../interfaces/body/lesson-category-body';
import { LessonCategoryService } from '../../../services/lesson-category.service';
import { Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';

import { Location } from '@angular/common';

@Component({
  selector: 'app-new-lesson-category',
  templateUrl: './new-lesson-category.component.html',
  styleUrls: ['./new-lesson-category.component.scss'],
})
export class NewLessonCategoryComponent {
  @Input() isOpen: boolean = true;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() isCreatedEvent = new EventEmitter<boolean>();

  newLessonCategoryForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  // strings for i18n
  pushNotificationSuccess: string = '';
  pushNotificationError: string = '';
  pushNotificationUniqueError: string = '';

  sendRequest: boolean = false;

  constructor(
    private lessonCategoryService: LessonCategoryService,
    private pushService: PushService,
    private translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    private location: Location,
  ) {}

  ngOnChanges(): void {
    this.newLessonCategoryForm.markAsUntouched();
  }

  ngOnInit(): void {
    // translated strings for push-notifications
    let pushMessage = this.translateService
      .get([
        'new-lesson-category.push-lesson-category-successful-created',
        'new-lesson-category.push-lesson-category-error-created',
        'new-lesson-category.push-lesson-category-error-unique-created',
      ])
      .subscribe((translations) => {
        this.pushNotificationSuccess =
          translations[
            'new-lesson-category.push-lesson-category-successful-created'
          ];
        this.pushNotificationError =
          translations[
            'new-lesson-category.push-lesson-category-error-created'
          ];
        this.pushNotificationUniqueError =
          translations[
            'new-lesson-category.push-lesson-category-error-unique-created'
          ];
      });
  }

  /**
   * Emits the close event to the parent component and closes the dialog.
   */
  setCloseEvent() {
    this.sendRequest = false;
    this.location.back();
    this.closeEvent.emit(true);
  }

  createLessonCategory() {
    if (this.sendRequest) return;
    this.sendRequest = true;
    const tenantId = this.authService.getTenantId();
    if (tenantId == null) {
      this.pushService.sendPush(pushTypes.ERROR, this.pushNotificationError);
      return;
    }
    const licenceTypeBody: LessonCategoryBody = {
      tenantId: tenantId,
      name: this.newLessonCategoryForm.value.name!,
    };

    this.lessonCategoryService
      .createCategory(licenceTypeBody)
      .subscribe((status: number) => {
        if (status === HttpStatusCode.Ok) {
          this.pushService.sendPush(
            pushTypes.SUCCESS,
            this.pushNotificationSuccess,
          );
          // If created, emit the event to the parent component and close the dialog
          this.isCreatedEvent.emit(true);
          this.setCloseEvent();
          this.newLessonCategoryForm.reset();
        } else if (status === HttpStatusCode.Conflict) {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationUniqueError,
          );
          this.setCloseEvent();
        } else {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationError,
          );
          this.setCloseEvent();
        }
      });
  }
}
