import { Injectable } from "@angular/core";
import { TuiDay, TuiTime } from "@taiga-ui/cdk";
import { DateTime } from "luxon";
import { NavigationEnd } from "@angular/router";
import { NavRoutes } from "../enums/nav-routes";
import { DropDownItem } from "../interfaces/drop-down-item";

@Injectable({
  providedIn: "root",
})

/**
 * Service that contains utility functions, which can be used in multiple components for common tasks.
 */
export class UtilService {
  constructor() {}

  /**
   * Concatenates the first and last name to a full name
   * @param firstName
   * @param lastName
   * @returns the full name as string
   */
  getFormattedName(firstName: string, lastName: string): string {
    return firstName + " " + lastName;
  }

  /**
   * Returns the formatted time of a given date in ISO Format
   * @param time the time in ISO Format as string
   */
  getFormattedTimeByDate(time: Date): string {
    return DateTime.fromISO(time.toISOString())
      .toUTC()
      .toISOTime()!
      .slice(0, 5);
  }

  /**
   * Returns the formatted TuiDate of a given date in ISO Format as string
   * @param date The TuiDay object
   * @param time The TuiTime object
   * @returns the formatted date as string
   */
  getFormattedDateByTuiDate(date: TuiDay, time: TuiTime): string {
    return DateTime.utc(
      date.year,
      Number(date.month + 1), // month is 0 based
      date.day,
      time.hours,
      time.minutes,
    ).toISO()!;
  }

  /**
   * Determines if the user navigated back to the parent page from the given url
   * @param event The event that is triggered when the user navigates IMPORTANT! UNSUBSCRIBE THIS EVENT IN THE COMPONENT
   * @param url the url of the parent page
   * @returns true if the user navigated from the child page back to the parent page
   */
  isNavigatedBackToParent(event: any, url: string): boolean {
    return event instanceof NavigationEnd && event.url === `/${url}`;
  }

  /**
   * Calculates the end day of a lesson based on the start and end time.
   * Used to determine if the lesson ends on the same day or the next day (e.g. 23:45 - 00:15 -> ends on the next day).
   * If the end time is before the start time, the lesson ends on the next day. Otherwise it ends on the same day.
   *
   * @param startTime the start time of the lesson
   * @param endTime the end time of the lesson
   * @param startDate the start date of the lesson
   */
  calculateEndDay(
    startTime: TuiTime,
    endTime: TuiTime,
    startDate: TuiDay,
  ): string {
    return this.getFormattedDateByTuiDate(
      endTime > startTime ? startDate : startDate.append({ day: 1 }),
      endTime,
    );
  }

  /**
   * Generates the dropdown items for the role dropdown menu
   */
  generateDropdownItems(items: any[]) {
    let dropDownItems: DropDownItem[] = [];
    items.map((item) => {
      dropDownItems.push({ id: item.id, label: item.name });
    });
    return dropDownItems;
  }

  getUtcJsDate(date: Date): Date {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
      ),
    );
  }

  datesEqualUpToDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  // Helper function because: DateTime.fromJSDate(selectInfo.start).toUTC() < DateTime.now() is not working (todo: check if there is a simpler solution)
  isSelectionBeforeToday(date: Date): boolean {
    const utcDateSelection = DateTime.fromJSDate(date).toUTC();
    const now = DateTime.now();

    if (utcDateSelection.year < now.year) {
      return true;
    }

    if (
      utcDateSelection.year === now.year &&
      utcDateSelection.month < now.month
    ) {
      return true;
    }

    if (
      utcDateSelection.year === now.year &&
      utcDateSelection.month === now.month &&
      utcDateSelection.day < now.day
    ) {
      return true;
    }

    if (
      utcDateSelection.year === now.year &&
      utcDateSelection.month === now.month &&
      utcDateSelection.day === now.day &&
      utcDateSelection.hour < now.hour
    ) {
      return true;
    }

    return (
      utcDateSelection.year === now.year &&
      utcDateSelection.month === now.month &&
      utcDateSelection.day === now.day &&
      utcDateSelection.hour === now.hour &&
      utcDateSelection.minute < now.minute
    );
  }
}
