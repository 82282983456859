import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { ResponseWithRecordsBody } from '../interfaces/body/response-with-recors-body';
import { ApiRoutes } from '../enums/api-routes';
import { LessonCategoryBody } from '../interfaces/body/lesson-category-body';
import { LessonCategory } from '../models/lesson-category';
import { LessonCategoryQueryParams } from '../interfaces/query-param/lesson-category-query-params';
import { PaginationFilterService } from './pagination-filter.service';

@Injectable({
  providedIn: 'root',
})
export class LessonCategoryService extends PaginationFilterService {
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  getCategory(
    queryParams?: LessonCategoryQueryParams,
  ): Observable<LessonCategory[]> {
    const params = new HttpParams()
      .set(
        queryParams?.tenantId !== undefined ? 'tenantId' : '',
        queryParams?.tenantId ?? '',
      )
      .set(
        queryParams?.name !== undefined ? 'name' : '',
        queryParams?.name ?? '',
      )
      .set(this.sort !== undefined ? 'sort' : '', this.sort ?? '')
      .set(this.endIndex !== '' ? 'limit' : '', this.endIndex)
      .set(this.startIndex !== '' ? 'skip' : '', this.startIndex);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + ApiRoutes.LESSON_CATEGORY, {
        params,
      })
      .pipe(
        map((response: ResponseWithRecordsBody) => {
          this.totalAmount = response.total;
          return response.records.map((response) => {
            return LessonCategory.fromJson(response);
          });
        }),
      );
  }

  getCategoryById(id: string): Observable<LessonCategory> {
    return this.http
      .get<any>(this.baseUrl + ApiRoutes.LESSON_CATEGORY + '/' + id)
      .pipe(
        map((response) => {
          return LessonCategory.fromJson(response);
        }),
      );
  }

  createCategory(categoryBody: LessonCategoryBody): Observable<number> {
    return this.http
      .post<any>(this.baseUrl + ApiRoutes.LESSON_CATEGORY, categoryBody, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status;
        }),
        catchError((error) => {
          return of(error.status);
        }),
      );
  }

  updateCategory(
    categoryBody: LessonCategoryBody,
    id: string,
  ): Observable<boolean> {
    return this.http
      .patch<any>(
        this.baseUrl + ApiRoutes.LESSON_CATEGORY + '/' + id,
        categoryBody,
        {
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError((error) => {
          return of(false);
        }),
      );
  }

  deleteCategory(id: string): Observable<boolean> {
    return this.http
      .delete<any>(this.baseUrl + ApiRoutes.LESSON_CATEGORY + '/' + id, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }
}
