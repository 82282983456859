<tui-combo-box
  [stringify]="stringify"
  [formControl]="dropDownFormControl"
  [tuiTextfieldSize]="'m'"
  class="dropdown"
>
  {{hintText}}
  <tui-data-list-wrapper
    *tuiDataList
    [items]="tenants | tuiFilterByInputWith : stringify"
    [itemContent]="stringify | tuiStringifyContent"
  ></tui-data-list-wrapper>
</tui-combo-box>
