import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { StudentService } from "../../../services/student.service";
import { PushService } from "../../../services/push.service";
import { SuperAdminService } from "../../../services/super-admin.service";
import { AuthService } from "../../../services/auth.service";
import { StudentBody } from "../../../interfaces/body/student-body";
import { pushTypes } from "../../../enums/push-types";
import { LocationService } from "../../../services/location.service";
import { lastValueFrom } from "rxjs";
import { Student } from "../../../models/student";
import { User } from "../../../models/user";
import { HttpStatusCode } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { phoneValidator } from "../../../validators/phone-validator";
import { Location } from "../../../models/location";
import { phoneOrEmailValidator } from "../../../validators/phone-or-email-validator";
import { NavRoutes } from "../../../enums/nav-routes";
import { phoneCountryCodeValidator } from "../../../validators/phone-country-code-validator";

type ItemWithId = {
  id: string;
};

@Component({
  selector: "app-new-student",
  templateUrl: "./new-student.component.html",
  styleUrls: ["./new-student.component.scss"],
})
export class NewStudentComponent {
  @Input() isOpen: boolean = false;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() isCreatedEvent = new EventEmitter<boolean>();

  errorPushDescription!: string;
  successPushDescription!: string;
  errorUniqueEmailDescription!: string;
  pushNotificationLicenceError!: string;

  sendRequest: boolean = false;

  locations!: Location[];
  student!: Student;

  readonly newStudentForm = new FormGroup(
    {
      studentNumber: new FormControl(null, [Validators.required]),
      firstName: new FormControl(``, [Validators.required]),
      lastName: new FormControl(``, [Validators.required]),
      phone: new FormControl(``, [Validators.minLength(12)]),
      // phone: new FormControl(``, phoneCountryCodeValidator()),
      email: new FormControl(``, [Validators.email]),
      password: new FormControl(``),
      houseNo: new FormControl(``),
      street: new FormControl(``),
      postalCode: new FormControl(``),
      city: new FormControl(``),
      locationId: new FormControl(``, [Validators.required]),
      licenceTypeId: new FormControl(``, [Validators.required]),
    },
    phoneOrEmailValidator(),
  );

  constructor(
    private studentService: StudentService,
    private locationService: LocationService,
    private pushService: PushService,
    private superAdminService: SuperAdminService,
    private authService: AuthService,
    private translate: TranslateService,
  ) {}

  get hasEmailOrPhoneError() {
    return this.newStudentForm.errors?.["phoneOrEmailRequired"];
  }

  ngOnInit(): void {
    this.getAvailableLocations();

    this.translate
      .get([
        "students.new-student.push-student-error-created",
        "students.new-student.push-student-successful-created",
        "students.new-student.push-error-unique-email",
        "students.new-student.push-notifications-error-licence-type",
      ])
      .subscribe((translations) => {
        this.errorPushDescription =
          translations["students.new-student.push-student-error-created"];
        this.successPushDescription =
          translations["students.new-student.push-student-successful-created"];
        this.errorUniqueEmailDescription =
          translations["students.new-student.push-error-unique-email"];
        this.pushNotificationLicenceError =
          translations[
            "students.new-student.push-notifications-error-licence-type"
          ];
      });
  }

  ngOnChanges(): void {
    this.newStudentForm.markAsUntouched();
  }

  ngOnDestroy(): void {}

  /**
   * Creates a new student with the data from the new student form
   */
  createNewStudent() {
    if (this.sendRequest) return;
    this.sendRequest = true;

    const tenantId = this.authService.getTenantId();
    if (tenantId == null) return;

    // Construct the user body from the form data
    const studentBody: StudentBody = {
      studentNumber: this.newStudentForm.get("studentNumber")?.value!,
      tenantId: tenantId,
      email: this.newStudentForm.get("email")?.value!,
      firstName: this.newStudentForm.get("firstName")?.value!,
      lastName: this.newStudentForm.get("lastName")?.value!,
      phone: this.newStudentForm.get("phone")?.value ?? "",
      active: true,
      locationId: this.newStudentForm.get("locationId")?.value!,
    };

    if (
      this.newStudentForm.get("houseNo")?.value! != null &&
      this.newStudentForm.get("street")?.value! != null &&
      this.newStudentForm.get("postalCode")?.value! != null &&
      this.newStudentForm.get("city")?.value! != null
    ) {
      studentBody.address = {
        tenantId: tenantId,
        street: this.newStudentForm.get("street")?.value!,
        houseNo: this.newStudentForm.get("houseNo")?.value!,
        postalCode: this.newStudentForm.get("postalCode")?.value!,
        city: this.newStudentForm.get("city")?.value!,
      };
    }

    // Create the user with the user service
    this.studentService
      .createStudent(studentBody)
      .subscribe(async (response) => {
        if (response instanceof Student) {
          const studentId = response.id;
          const licenceTypeId: string = (
            this.newStudentForm.controls.licenceTypeId
              .value as unknown as ItemWithId
          ).id;
          if (licenceTypeId == null) {
            this.handleLicenceTypeMissingError();
            return;
          }

          const addedLicenceTypeStatus = await lastValueFrom(
            this.studentService.addLicenceTypeToStudent({
              studentId: studentId,
              licenceTypeId: licenceTypeId,
            }),
          );

          if (addedLicenceTypeStatus !== HttpStatusCode.Created) {
            this.handleLicenceTypeError();
            return;
          }

          this.handleSuccess();
        } else {
          if (response === HttpStatusCode.Conflict) {
            this.handleConflictError();
          } else {
            this.sendErrorPush(this.errorPushDescription);
          }
        }
      });
  }

  handleLicenceTypeMissingError() {
    this.pushService.sendPush(
      pushTypes.ERROR,
      this.pushNotificationLicenceError,
    );
    this.setCloseEvent();
    this.newStudentForm.reset();
  }

  handleLicenceTypeError() {
    this.pushService.sendPush(
      pushTypes.ERROR,
      this.pushNotificationLicenceError,
    );
    this.setCloseEvent();
    this.newStudentForm.reset();
  }

  handleSuccess() {
    this.pushService.sendPush(pushTypes.SUCCESS, this.successPushDescription);
    this.isCreatedEvent.emit(true);
    this.setCloseEvent();
    this.newStudentForm.reset();
  }

  handleConflictError() {
    this.pushService.sendPush(
      pushTypes.ERROR,
      this.errorUniqueEmailDescription,
    );
    this.setCloseEvent();
  }

  sendErrorPush(description: string) {
    this.pushService.sendPush(pushTypes.ERROR, description);
    this.setCloseEvent();
  }

  /**
   * Gets all available locations with the tenantId
   * tenantId is retrieved from the superAdminService if the user is a super-admin
   * otherwise gets the tenantId from the authService from the logged-in user
   */
  getAvailableLocations() {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();
      if (selectedTenantId == null) return;
      this.getLocationsWithTenantId(selectedTenantId);
    } else {
      const tenantId = this.authService.getLoggedInUser()?.tenantId;
      this.getLocationsWithTenantId(tenantId);
    }
  }

  /**
   * Gets all locations with the given tenantId
   */
  getLocationsWithTenantId(tenantId: string | undefined) {
    this.locationService
      .getLocations({ tenantId: tenantId })
      .subscribe((locations) => {
        this.locations = locations;
      });
  }

  /**
   * Emits the close event to the parent component and closes the dialog.
   */
  setCloseEvent() {
    this.sendRequest = false;
    this.closeEvent.emit(true);
    this.isOpen = false;
  }

  getLoggedInUser(): User | null {
    return this.authService.getLoggedInUser();
  }

  protected readonly NavRoutes = NavRoutes;
}
