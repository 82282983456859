/**
 * Api routes enum:
 * contains all the api routes as strings
 */
export enum ApiRoutes {
  VERSION = 'version',
  USER = 'user',
  ROLE = 'role',
  TENANT = 'tenant',
  AUTH = 'auth',
  LOCATION = 'location',
  STUDENT = 'student',
  LESSON = 'lesson',
  LESSON_CATEGORY = 'lesson-category',
  LICENCE_TYPE = 'licence-type',
  LICENCE_TARGET = 'licence-target',
  SIGN = 'sign',

  // Transactions
  TRANSACTION = 'transaction',
  FILE = 'file',
  FILES = 'files',
  WALLET = 'wallet',
  LOCATION_TRANSACTION = 'locationTransactions',
  STUDENT_TRANSACTION = 'studentTransactions',
  TEACHER_TRANSACTION = 'teacherTransactions',
  TEACHER = 'teacher',
  LOCATION_TRANSACTIONS = 'locationTransactions',
  LESSON_PROGESS_COUNT = 'lessonProgressCount',

  // KPI
  KPI = 'kpi',
  DASHBOARD = 'dashboard',
  EMPLOYEES = 'employees',
  CHART_DATA = 'chartData',
  LESSONS = 'lessons',
  LOCATIONS = 'locations',
  STUDENTS = 'students',
  LESSON_PROGRESS_COUNT = 'lessonProgressCount',
}
