<div class="forgot-pw-wrapper">
  <tui-island *ngIf="displayIsland; else noIsland">
    <div class="island-wrapper">
      <div class="headline-wrapper">
        <div *ngIf="type == RESET; else default">
          <h1 class="title">{{ "reset-password.title-reset" | translate }}</h1>
          <p>
            {{ "reset-password.description-reset" | translate }}
          </p>
        </div>

        <!-- if type is not reset (if its by an invite) -->
        <ng-template #default>
          <h1 class="title">{{ "reset-password.title-new" | translate }}</h1>
          <p>
            {{ "reset-password.description-new" | translate }}
          </p>
        </ng-template>
      </div>
      <app-set-new-password
        (submitNewPassword)="submit($event)"
      ></app-set-new-password>
    </div>
  </tui-island>

  <ng-template #noIsland>
    <div class="island-wrapper">
      <div class="headline-wrapper">
        <div *ngIf="type == RESET; else default">
          <h1 class="title">{{ "reset-password.title-reset" | translate }}</h1>
          <p>
            {{ "reset-password.description-reset" | translate }}
          </p>
        </div>

        <!-- if type is not reset (if its by an invite) -->
        <ng-template #default>
          <h1 class="title">{{ "reset-password.title-new" | translate }}</h1>
          <p>
            {{ "reset-password.description-new" | translate }}
          </p>
        </ng-template>
      </div>
      <app-set-new-password
        (submitNewPassword)="submit($event)"
      ></app-set-new-password>
    </div>
  </ng-template>
</div>

<!-- success dialog -->
<ng-template
  [(tuiDialog)]="isOpenSuccess"
  [tuiDialogOptions]="{ label: title, size: 's' }"
>
  <tui-elastic-container>
    <div clasS="center-content" style="flex-direction: column">
      <p class="instruction-text-login">
        {{ "reset-password.pop-up-description" | translate }}
      </p>
      <div class="button-wrapper">
        <button [routerLink]="'/' + NavRoutes.TENANT" tuiButton type="submit">
          {{ "reset-password.pop-up-button" | translate }}
        </button>
      </div>
    </div>
  </tui-elastic-container>
</ng-template>

<!-- success dialog - Not allowed to login-->
<ng-template
  [(tuiDialog)]="isOpenSuccessNoLogin"
  [tuiDialogOptions]="{ label: title, size: 's' }"
>
  <tui-elastic-container>
    <div class="center-content" style="flex-direction: column">
      <p class="instruction-text-no-login">
        {{ "reset-password.pop-up-description-no-login" | translate }}
      </p>
      <div class="download-button-wrapper">
        <!-- Show both Badges     -->
        <a href="https://apps.apple.com/app/id6470642660" target="_blank">
          <img
            class="store-link-img appstore"
            src="/assets/store-badges/app-store-badge.svg"
          />
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=de.apps28.citydrive"
          target="_blank"
        >
          <img
            class="store-link-img"
            src="/assets/store-badges/google-play-badge.png"
          />
        </a>

        <!--        <button tuiButton type="submit" (click)="isOpenSuccessNoLogin = false" >-->
        <!--          {{ 'reset-password.pop-up-button-no-login' | translate }}-->
        <!--        </button>-->
      </div>
    </div>
  </tui-elastic-container>
</ng-template>
