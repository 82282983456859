import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiTime } from '@taiga-ui/cdk';

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class InputTimeComponent {
  @Input() hint: string = '';
  @Input() fieldName: string = '';
  @Input() required: boolean = false;

  @Output() timeChange = new EventEmitter<TuiTime | null>();

  // items = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);
}
