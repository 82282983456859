<div class="setting-wrapper">
  <app-table-header
    [tableTitle]="'lesson-category.page-title' | translate"
    [buttonText]="'lesson-category.button-new-lesson-category' | translate"
    [tenantSelector]="true"
    [showActiveTab]="false"
    [showCreateButton] = "!isManager()"
    (filterEvent)="filterTable($event)"
    (createNewClickEvent)="routeToNewLicenceType()"
  >
  </app-table-header>

  <app-table
    [tableColumnNames]="['lesson-category.table-name' | translate]"
    [columns]="lessonCategoryTableShownColumns"
    [tableData]="lessonCategoryTableData"
    [totalElements]="totalLessonCategories"
    [currentPage]="tablePage"
    [pageSize]="tablePageSize"
    [showDeleteButton]="true"
    (deleteEvent)="deleteRowClickEvent($event)"
    (paginationEvent)="paginationEvent($event)"
    (rowClickEvent)="licenceTypeRowClickEvent($event)"
    (sortEvent)="sortTable($event)"
  ></app-table>

  <router-outlet></router-outlet>

  <app-delete-dialog
    [isOpen]="showDeleteDialog"
    (cancelEvent)="showDeleteDialog = false"
    (deleteEvent)="showDeleteDialog = false; deleteCategory()"
  ></app-delete-dialog>
  <!-- New Sidenav is an own component that cannot be opened with a boolean (you need to route to that component) -->
</div>

