<div class="input-wrapper">
  <tui-input
    [tuiTextfieldIcon]="tuiIconMail"
    formControlName="{{ fieldName }}"
    tuiTextfieldSize="l"
  >
    {{ hint }}
    <span *ngIf="required" class="tui-required"></span>
    <input tuiTextfield type="email"/>
  </tui-input>
  <div class="error-wrapper">
    <tui-error
      [error]="[] | tuiFieldError | async"
      formControlName="{{ fieldName }}"
    ></tui-error>
  </div>
</div>
