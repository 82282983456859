import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { TuiSizeL, TuiSizeM, TuiSizeS } from "@taiga-ui/core";

@Component({
  selector: "app-input-number",
  templateUrl: "./input-number.component.html",
  styleUrls: ["./input-number.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class InputNumberComponent {
  @Input() hint: string = "";
  @Input() fieldName: string = "";
  @Input() postfix: string = "";
  @Input() required: boolean = true;

  @Input() showError: boolean = true;
  @Input() textFieldSize: TuiSizeL | TuiSizeM | TuiSizeS = "l";
}
