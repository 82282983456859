import { Component, inject, Input, OnInit } from "@angular/core";
import { LessonService } from "../../../services/lesson.service";
import { Lesson } from "../../../models/lesson";
import { TableSortEvent } from "../../../interfaces/table-sort-event";
import { TuiTablePagination } from "@taiga-ui/addon-table";
import {Router} from "@angular/router";
import {NavRoutes} from "../../../enums/nav-routes";

@Component({
  selector: "app-student-lesson-table",
  templateUrl: "./student-lesson-table.component.html",
  styleUrls: ["./student-lesson-table.component.scss"],
})
export class StudentLessonTableComponent implements OnInit {
  router = inject(Router);

  @Input() studentId: string | undefined;
  lessons: Lesson[] | undefined;
  columns: string[] = [
    "createdAt",
    "startDate",
    "endDate",
    "lessonCategory.name",
    "count",
    "price",
    "status",
    "licenceType.name",
    "location.name",
    "teacher",
  ];
  headers: string[] = [
    "Erstellt am",
    "Startzeit",
    "Endzeit",
    "Fahrstundentyp",
    "Anzahl Fahrstunden",
    "Preis",
    "Status",
    "Lizenz",
    "Standort",
    "Fahrlehrer",
  ];
  tablePage: number = 0;
  tablePageSize: number = 10;
  totalLessons: number = 0;
  noSortColumns: string[] = [
    "lessonCategory.name",
    "licenceType.name",
    "location.name",
    "teacher",
  ];
  private lessonService = inject(LessonService);

  ngOnInit(): void {
    this.lessonService.setPaginationIndices({ start: 0, limit: 10 });
    this.getLessonsOfStudent();
  }

  getLessonsOfStudent() {
    if (!this.studentId) return;

    this.lessonService
      .getLessons({
        studentId: this.studentId,
      })
      .subscribe((lessons) => {
        this.lessons = lessons;
        lessons.forEach((lesson) => {
          lesson.teacher = lesson.user.firstName + " " + lesson.user.lastName;
        });

        this.totalLessons = this.lessonService.getAmount();
      });
  }

  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.lessonService.setPaginationIndices({
      start: this.tablePage * this.tablePageSize,
      limit: this.tablePageSize,
    });

    this.getLessonsOfStudent();
  }

  public rowClickEvent(lesson: string) {
    this.router.navigateByUrl(`${this.router.url}/${NavRoutes.LESSON_HISTORY}/${lesson}`).then();
  }

  public sortTable(tableSort: TableSortEvent) {
    this.lessonService.setSorting(tableSort);
    this.getLessonsOfStudent();
  }
}
