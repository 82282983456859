<div class="wrapper">
  <div class="title-wrapper">
    <div clasS="left-header">
      <app-tenant-selector-dropdown
        *ngIf="isSuperAdmin()"
      ></app-tenant-selector-dropdown>
      <h1>{{ "payments.page-title" | translate }}</h1>
    </div>

    <app-payment-menu-dropdown></app-payment-menu-dropdown>
  </div>

  <ng-container *ngIf="locationsLoaded; else noLocation">
    <div *ngIf="tenantLocations.length > 0" class="location-tabs">
      <form [formGroup]="filterForm">
        <div class="table-top-row">
          <h2>{{ "common.payments" | translate }}:</h2>
          <div class="filter-row">
            <app-input-date
              [fieldName]="'filterDateFormControl'"
              [hint]="'payments.table.date' | translate"
              [showError]="false"
              [textFieldCleaner]="true"
              style="width: 10rem"
              textFieldSize="s"
            ></app-input-date>
            <app-dropdown
              [dropDownItems]="[
                { id: '', label: 'Alle' },
                {
                  id: TransactionType.STUDENT_PAYMENT,
                  label:
                    transactionTypeToString(TransactionType.STUDENT_PAYMENT)
                    | translate
                },
                {
                  id: TransactionType.STUDENT_PAYOUT,
                  label:
                    transactionTypeToString(TransactionType.STUDENT_PAYOUT)
                    | translate
                },
                {
                  id: TransactionType.STUDENT_OTHER_COSTS,
                  label:
                    transactionTypeToString(TransactionType.STUDENT_OTHER_COSTS)
                    | translate
                },
                {
                  id: TransactionType.TEACHER_PAYOUT,
                  label:
                    transactionTypeToString(TransactionType.TEACHER_PAYOUT)
                    | translate
                },
                {
                  id: TransactionType.MANAGEMENT_PAYOUT,
                  label:
                    transactionTypeToString(TransactionType.MANAGEMENT_PAYOUT)
                    | translate
                },
                {
                  id: TransactionType.BOSS_PAYOUT,
                  label:
                    transactionTypeToString(TransactionType.BOSS_PAYOUT)
                    | translate
                },
                {
                  id: TransactionType.BOSS_PAYIN,
                  label:
                    transactionTypeToString(TransactionType.BOSS_PAYIN)
                    | translate
                },
                {
                  id: TransactionType.BOSS_INVENTORY,
                  label:
                    transactionTypeToString(TransactionType.BOSS_INVENTORY)
                    | translate
                }
              ]"
              [formFieldName]="'filterTransactionTypeFormControl'"
              [hint]="'payments.payment-details.label-payment-type' | translate"
              [required]="false"
              [textFieldSize]="'s'"
              style="width: 15rem"
            ></app-dropdown>
            <button (click)="applyFilters()" size="s" tuiButton type="button">
              <b>{{ "common.apply" | translate }}</b>
            </button>
          </div>
        </div>
      </form>
      <tui-tabs [(activeItemIndex)]="selectedTabIndex">
        <button
          (click)="resetTablePagination(); locationTabClicked(location.id)"
          *ngFor="let location of tenantLocations"
          class="tab-container"
          tuiTab
        >
          <span> {{ location.name }}: &nbsp; </span>
          <span class="white-text">
            {{ locationWithWallet.get(location.id) | number: "1.2-2" }} €
          </span>
        </button>
      </tui-tabs>
    </div>
    <div class="dark-background">
      <app-table
        (deleteEvent)="openDeleteDialog($event)"
        (paginationEvent)="paginationEvent($event)"
        (rowClickEvent)="rowClickEvent($event)"
        (sortEvent)="sortTable($event)"
        [alternativeColorHeader]="true"
        [columns]="transactionTableShownColumns"
        [currentPage]="tablePage"
        [pageSize]="tablePageSize"
        [paymentTable]="true"
        [showDeleteButton]="isSuperAdminOrTenantAdmin"
        [tableColumnNames]="[
          'payments.table.date' | translate,
          'payments.table.amount' | translate,
          'payments.table.user-in-question' | translate,
          'payments.table.payment-type' | translate,
          'payments.table.created-by' | translate
        ]"
        [tableData]="transactionTableData"
        [totalElements]="totalTransactions"
        class="dark-header"
      ></app-table>
    </div>
  </ng-container>
  <ng-template #noLocation>
    <p class="p-increased-fontsize word-break">
      {{ "payments.no-locations-text" | translate }}
      <a
        (click)="setShowNewLocationModal()"
        [routerLink]="'/' + NavRoutes.LOCATION"
      >
        {{ "payments.no-locations-link-text" | translate }}
      </a>
    </p>
  </ng-template>
</div>

<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="showDeleteDialog = false; deleteEvent()"
  [isOpen]="showDeleteDialog"
/>
