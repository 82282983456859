import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, finalize, mergeMap, Observable, of, timer } from 'rxjs';
import { LoadingService } from '../services/loading.service';

@Injectable()
/**
 * Intercepts all Http requests and sets the loading state to true when a request is taking longer than 250ms
 * Otherwise the loading state is set to false
 *
 * Is used in combination with the loading service to display a loading spinner in different components
 */
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Start a timer that emits after 250ms
    const loadingTimer$ = timer(250);

    this.loadingService.setLoadingState(false);
    let completed = false;

    return next.handle(request).pipe(
      mergeMap((event) => {
        // Request completed before 1 second, set loadingState to false
        loadingTimer$.subscribe(() => {
          if (!completed) {
            this.loadingService.setLoadingState(true);
          }
        });
        return of(event);
      }),
      catchError((error) => {
        // Request encountered an error
        this.loadingService.setLoadingState(false);
        throw error;
      }),
      finalize(() => {
        this.loadingService.setLoadingState(false);
        completed = true;
      }),
    );
  }
}
