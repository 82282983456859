import { BaseModel } from './base-model';
import { Tenant } from './tenant';
import { Role } from './role';
import { ObjectWithLocation } from '../interfaces/object-with-location';

/**
 * Model Class for creating User instances
 * extends the BaseModel
 */
export class User extends BaseModel implements ObjectWithLocation {
  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    tenantId: string,
    password: string,
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    roleId: string,
    active: boolean,
    locationId: string,
    inviteAcceptedAt: string,
    tenant?: Tenant,
    role?: Role,
  ) {
    super(id, createdAt, updatedAt);
    this._tenantId = tenantId;
    this._password = password;
    this._email = email;
    this._firstName = firstName;
    this._lastName = lastName;
    this._phone = phone;
    this._roleId = roleId;
    this._active = active;
    this._locationId = locationId;
    this._tenant = tenant;
    this._role = role;
    this._inviteAcceptedAt = inviteAcceptedAt!
  }

  private _tenantId: string;

  get tenantId(): string {
    return this._tenantId;
  }

  set tenantId(value: string) {
    this._tenantId = value;
  }

  private _password: string | null;

  get password(): string | null {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
  }

  private _email: string;

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  private _firstName: string;

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  private _lastName: string;

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  private _phone: string;

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  private _roleId: string;

  get roleId(): string {
    return this._roleId;
  }

  set roleId(value: string) {
    this._roleId = value;
  }

  private _active: boolean;

  get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  private _locationId: string;

  get locationId(): string {
    return this._locationId;
  }

  set locationId(value: string) {
    this._locationId = value;
  }

  private _inviteAcceptedAt: string;

  get inviteAcceptedAt() {
    return this._inviteAcceptedAt
  }

  set inviteAcceptedAt(value: string) {
    this._inviteAcceptedAt = value;
  }

  private _tenant?: Tenant;

  get tenant(): Tenant | undefined {
    return this._tenant;
  }

  set tenant(value: Tenant) {
    this._tenant = value;
  }

  private _role?: Role;

  get role(): Role | undefined {
    return this._role;
  }

  set role(value: Role) {
    this._role = value;
  }

  get name(): string {
    return `${ this._firstName } ${ this._lastName }`;
  }

  static fromJson(json: any): any {
    return new User(
      json.id,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.tenantId,
      json.password,
      json.email,
      json.firstName,
      json.lastName,
      json.phone,
      json.roleId,
      json.active,
      json.locationId,
      json.inviteAcceptedAt,
      json.tenant ?? undefined,
      json.role ?? undefined,
    );
  }

  // todo implement
  toJSON(): any {
    return {
      id: this.id,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
      tenantId: this._tenantId,
      password: this._password,
      email: this._email,
      firstName: this._firstName,
      lastName: this._lastName,
      phone: this._phone,
      roleId: this._roleId,
      tenant: this._tenant,
      role: this._role,
    };
  }
}
