import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneNumber = control.value as string;

    if (phoneNumber === null || phoneNumber === undefined) return null;

    if (phoneNumber.length === 0) {
      return null;
    }

    const startsWithPlusOrZero = /^[+0]/.test(phoneNumber);
    const containsOnlyDigitsPlusAndSpace = /^[+\d\s]+$/.test(phoneNumber);

    if (!startsWithPlusOrZero) {
      return { startsWithPlusOrZero: true };
    }

    if (!containsOnlyDigitsPlusAndSpace) {
      return { containsOnlyDigits: true };
    }

    return null;
  };
}
