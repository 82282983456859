<div class="myForm-wrapper">

  <h1>Konto Einstellungen</h1>

  <!--  FORM  -->
  <form (ngSubmit)="accountFormGroup?.valid && submitForm()" [formGroup]="accountFormGroup!">
    <!--  first row  -->
    <div class="inner-field-wrapper pt">
      <!-- first name -->
      <app-input-text [fieldName]="'firstName'" [hint]="'Vorname'" class="fxg-1"></app-input-text>

      <!-- last name -->
      <app-input-text [fieldName]="'lastName'" [hint]="'Nachname'" class="fxg-1"></app-input-text>
    </div>

    <!--  second row  -->
    <div class="inner-field-wrapper">
      <!-- email -->
      <app-input-email [fieldName]="'email'" [hint]="'Email'" class="fxg-1"></app-input-email>

      <!-- phone -->
      <app-input-phone
        [fieldName]="'phone'"
        [hint]="'create-user.input-label-telephone' | translate"
        [required]="false"
        class="fxg-1"
      ></app-input-phone>
    </div>

    <!--  third row  -->
    <div class="inner-field-wrapper">
      <!-- password -->
      <app-input-password [fieldName]="'password'" class="fxg-1"></app-input-password>
    </div>

    <!-- submit button -->
    <button [disabled]="!accountFormGroup?.valid" size="m" tuiButton type="submit">
      Änderungen speichern
    </button>

  </form>
</div>

