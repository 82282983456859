import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Role } from '../../../../models/role';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { PushService } from '../../../../services/push.service';
import { TranslateService } from '@ngx-translate/core';
import { pushTypes } from '../../../../enums/push-types';
import { GlobalUserBody } from '../../../../interfaces/body/global-user-body';

@Component({
  selector: 'app-new-global-user',
  templateUrl: './new-global-user.component.html',
  styleUrls: ['./new-global-user.component.scss'],
})
export class NewGlobalUserComponent {
  @Input() isOpen: boolean = false;
  @Input() globalRoles!: Role[];
  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() isCreatedEvent = new EventEmitter<boolean>();

  errorPushDescription!: string;
  successPushDescription!: string;

  readonly newUserForm = new FormGroup({
    email: new FormControl(``, [Validators.required]),
    firstName: new FormControl(``),
    lastName: new FormControl(``),
    telephone: new FormControl(``),
    role: new FormControl(null, [Validators.required]),
  });

  constructor(
    private userService: UserService,
    private pushService: PushService,
    private translate: TranslateService,
  ) {}

  // Subscribes to the translations for the push message
  errorPushMessage = this.translate
    .get([
      'create-user.push-error-description',
      'create-user.push-success-description',
    ])
    .subscribe((translations) => {
      this.errorPushDescription =
        translations['create-user.push-error-description'];
      this.successPushDescription =
        translations['create-user.push-success-description'];
    });

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.newUserForm.markAsUntouched();
  }

  /**
   * Creates a new user with the data from the new user form
   */
  createNewUser() {
    // Construct the user body from the form data
    const userBody: GlobalUserBody = {
      email: this.newUserForm.get('email')?.value!,
      firstName: this.newUserForm.get('firstName')?.value!,
      lastName: this.newUserForm.get('lastName')?.value!,
      phone: this.newUserForm.get('telephone')?.value!,
      roleId: this.newUserForm.get('role')?.value!,
    };

    // Create the user with the user service
    this.userService.createGlobalUser(userBody).subscribe((isCreated) => {
      if (isCreated) {
        this.pushService.sendPush(
          pushTypes.SUCCESS,
          this.successPushDescription,
        );
        // If created, emit the event to the parent component and close the dialog
        this.isCreatedEvent.emit(true);
        this.setCloseEvent();
        this.newUserForm.reset();
      } else {
        // If not created, stay in the dialog and show an error message
        this.pushService.sendPush(pushTypes.ERROR, this.errorPushDescription);
      }
    });
  }

  /**
   * Emits the close event to the parent component and closes the dialog.
   */
  setCloseEvent() {
    this.closeEvent.emit(true);
    this.isOpen = false;
  }
}
