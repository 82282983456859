<div class="">
  <router-outlet>

    <div class="bottom-container">
      <a href="https://city-drive.info/impressum/" target="_blank">Impressum</a> <span class="no-underline"> | </span>
      <a href="https://city-drive.info/Datenschutzerklaerung/">Datenschutz</a>
    </div>
  </router-outlet>
</div>

