import { Component, OnInit } from '@angular/core';
import { Student } from '../../../models/student';
import { DropDownItem } from '../../../interfaces/drop-down-item';
import { UserService } from '../../../services/user.service';
import { StudentService } from '../../../services/student.service';
import { User } from '../../../models/user';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionType } from '../../../enums/transaction-type';
import { AuthService } from '../../../services/auth.service';
import { LocationService } from '../../../services/location.service';
import { LocationQueryParams } from '../../../interfaces/query-param/location-query-params';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../../../services/role.service';
import { RoleQueryParams } from '../../../interfaces/query-param/role-query-params';
import { UserRole } from '../../../enums/user-role';
import { UserQueryParams } from '../../../interfaces/query-param/user-query-params';
import { Role } from '../../../models/role';
import { Location } from '../../../models/location';
import { NavRoutes } from '../../../enums/nav-routes';
import { tuiIconFile } from '@taiga-ui/icons';
import { TransactionBody } from '../../../interfaces/body/transaction-body';
import { TransactionService } from '../../../services/transaction.service';
import { PushService } from '../../../services/push.service';
import { pushTypes } from '../../../enums/push-types';
import { HttpStatusCode } from '@angular/common/http';
import { FileBody } from '../../../interfaces/body/file-body.interface';
import { UtilService } from '../../../services/util.service';
import { SuperAdminService } from '../../../services/super-admin.service';
import { emptyDropdownItemValidator } from '../../../validators/empty-dropdown-item-validator';

@Component({
  selector: 'app-new-transaction',
  templateUrl: './new-transaction.component.html',
  styleUrls: ['./new-transaction.component.scss'],
})
export class NewTransactionComponent implements OnInit {
  // strings for i18n
  pushNotificationSuccess: string = '';
  pushNotificationError: string = '';
  pushNotificationNoFile: string = '';
  walletAmount: number | null = null;

  sendRequest: boolean = false;

  // TODO: i18n
  // student transactionType
  dropdownTransactionTypeStudent: DropDownItem[] = [
    { id: TransactionType.STUDENT_PAYMENT, label: 'Barzahlung' },
    {
      id: TransactionType.STUDENT_PAYMENT_ONLINE,
      label: 'Online- oder Kartenzahlung',
    },
    {
      id: TransactionType.STUDENT_PAYOUT,
      label: 'Auszahlung',
    },
    {
      id: TransactionType.STUDENT_OTHER_COSTS,
      label: 'Andere Kosten',
    },
  ];
  // teacher transactionType
  dropdownTransactionTypeTeacher: DropDownItem[] = [
    { id: TransactionType.TEACHER_PAYOUT, label: 'Auszahlung' },
  ];
  // office transactionType
  dropdownTransactionTypeOffice: DropDownItem[] = [
    { id: TransactionType.MANAGEMENT_PAYOUT, label: 'Auszahlung' },
  ];
  // boss transactionType
  dropdownTransactionTypeBoss: DropDownItem[] = [
    { id: TransactionType.BOSS_PAYOUT, label: 'Auszahlung' },
    { id: TransactionType.BOSS_PAYIN, label: 'Einzahlung' },
    { id: TransactionType.BOSS_INVENTORY, label: 'Inventar Bestand' },
  ];
  // get the type of payment from the url in a variable
  public mode: string = '';
  // for the dropdown list of all teachers
  users: User[] = [];
  teacher: any[] = [];
  manager: any[] = [];
  boss: any[] = [];
  // for the dropdown list of all students
  students: Student[] = [];
  selectedStudentId: string = '';
  dropDownStudentItems: DropDownItem[] = [];

  emptyDropdownItem: DropDownItem = {
    id: '',
    label: '',
  };

  locations: Location[] = [];
  selectedLocationId: string = '';
  dropDownLocationItems: DropDownItem[] = [];
  readonly fileControl = new FormControl(null, [Validators.required]);

  // empty dropdown item
  emptyDropDownItem: DropDownItem = { id: '', label: '' };

  // form + validation for the new payment
  readonly newPaymentForm = new FormGroup({
    personInQuestionId: new FormControl(this.emptyDropDownItem, [
      Validators.required,
      emptyDropdownItemValidator(),
    ]),
    comment: new FormControl(null),
    amount: new FormControl(null, [Validators.required]),
    transactionType: new FormControl(this.emptyDropDownItem, [
      Validators.required,
      emptyDropdownItemValidator(),
    ]),
    location: new FormControl(null, [Validators.required]),
  });
  protected readonly tuiIconFile = tuiIconFile;

  constructor(
    private userService: UserService,
    private studentService: StudentService,
    public router: Router,
    private authService: AuthService,
    private locationService: LocationService,
    private translateService: TranslateService,
    private roleService: RoleService,
    private transactionService: TransactionService,
    private pushService: PushService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private superAdminService: SuperAdminService,
  ) {}

  isBossInventory() {
    if (!this.newPaymentForm.get('transactionType')?.value) return false;
    const transactionType: any =
      this.newPaymentForm.get('transactionType')!.value;
    return transactionType.id === TransactionType.BOSS_INVENTORY;
  }

  ngOnInit() {
    this.teacher = [];
    this.walletAmount = null;

    // depending on the URL this component works as selected transactionType component
    this.getModeFromURLParamValue();

    if (this.mode === 'teacherPayment') {
      this.route.params.subscribe((params: any) => {
        const userID = params.id;

        this.userService.getUserById(userID).subscribe((user: User) => {
          return this.newPaymentForm.controls.personInQuestionId.setValue({
            id: user.id,
            label: user.firstName + ' ' + user.lastName,
          });
        });
      });
    }

    if (this.mode === 'studentPayment') {
      // set the student as default, if the url contains a student id
      this.route.params.subscribe((params: any) => {
        if (!params.id) return;
        this.studentService.getStudentById(params.id).subscribe((student) => {
          this.newPaymentForm.controls.personInQuestionId.setValue({
            id: student.id,
            label: student.firstName + ' ' + student.lastName,
          });
        });
      });
    }

    if (this.mode === 'bossPayment') {
      // set the boss as default, if the logged-in user is a boss
      if (
        this.authService.getLoggedInUser()?.role?.userRole ===
        UserRole.TENANT_ADMIN
      ) {
        const bossId = this.authService.getLoggedInUser()?.id;
        if (bossId == null) return;
        this.newPaymentForm.controls.personInQuestionId.setValue({
          id: bossId,
          label:
            this.authService.getLoggedInUser()?.firstName +
            ' ' +
            this.authService.getLoggedInUser()?.lastName,
        });
      }
    }

    this.getDropdownItems();

    // translated strings for push-notifications
    this.translateService
      .get([
        'payments.new-payment.push-notification-success',
        'payments.new-payment.push-notification-error',
        'payments.new-payment.push-notification-no-file',
        'payments.new-payment.student_payment',
        'payments.new-payment.student_payment_online',
        'payments.new-payment.payout',
        'payments.new-payment.payin',
        'payments.new-payment.student_payment_other',
        'payments.new-payment.student_payment_other',
        'payments.new-payment.boss_inventory',
      ])
      .subscribe((translations) => {
        this.pushNotificationSuccess =
          translations['payments.new-payment.push-notification-success'];
        this.pushNotificationError =
          translations['payments.new-payment.push-notification-error'];
        this.pushNotificationNoFile =
          translations['payments.new-payment.push-notification-no-file'];
        // student payments translations
        this.dropdownTransactionTypeStudent[0].label =
          translations['payments.new-payment.student_payment'];
        this.dropdownTransactionTypeStudent[1].label =
          translations['payments.new-payment.student_payment_online'];
        this.dropdownTransactionTypeStudent[2].label =
          translations['payments.new-payment.payout'];
        this.dropdownTransactionTypeStudent[3].label =
          translations['payments.new-payment.student_payment_other'];
        // teacher payments translations
        this.dropdownTransactionTypeTeacher[0].label =
          translations['payments.new-payment.payout'];
        // office payments translations
        this.dropdownTransactionTypeOffice[0].label =
          translations['payments.new-payment.payout'];
        // boss payments translations
        this.dropdownTransactionTypeBoss[0].label =
          translations['payments.new-payment.payout'];
        this.dropdownTransactionTypeBoss[1].label =
          translations['payments.new-payment.payin'];
        this.dropdownTransactionTypeBoss[2].label =
          translations['payments.new-payment.boss_inventory'];
      });
  }

  goBack() {
    this.router.navigate([NavRoutes.PAYMENT]).then();
  }

  // use the url info to set the right values in the forms
  getModeFromURLParamValue() {
    this.route.url.subscribe((urlSegments) => {
      const segments = urlSegments.map((segment) => segment.path);
      const urlString = segments.join('/');

      // student payment
      if (urlString.includes('student')) {
        this.mode = 'studentPayment';
      }
      // teacher payment
      if (urlString.includes('teacher')) {
        this.mode = 'teacherPayment';
      }
      // office payment
      if (urlString.includes('office')) {
        this.mode = 'officePayment';
      }
      // boss payment
      if (urlString.includes('boss')) {
        this.mode = 'bossPayment';
      }
    });
  }

  getWalletAmount(event: any) {
    if (event == null || event.id == null) return;
    // get the wallet amount of the selected student
    if (this.mode === 'studentPayment') {
      this.transactionService
        .getStudentWalletValue(event.id)
        .subscribe((response: any) => {
          this.walletAmount = response.amount;
        });
    }
    this.onStudentChange(event);
    // get the wallet amount of the selected teacher
    if (this.mode === 'teacherPayment') {
      this.transactionService
        .getTeacherWalletValue(event.id)
        .subscribe((response: any) => {
          this.walletAmount = response.amount;
        });
    }
  }

  getStudentForLocation(locationId?: string) {
    this.studentService
      .getStudent({ locationId: locationId, active: true })
      .subscribe((students) => {
        this.students = students;
        this.dropDownStudentItems =
          this.utilService.generateDropdownItems(students);
      });
  }

  onStudentChange(value: DropDownItem | null) {
    // check if value really changed
    if (
      value == null ||
      value == this.emptyDropdownItem ||
      this.selectedStudentId == value['id'] ||
      this.mode !== 'studentPayment'
    )
      return;

    this.studentService.getStudentById(value['id']).subscribe((student) => {
      if (student == null || !student.active) return;
      const location = this.locations.find(
        (location) => location.id === student.locationId,
      );
      if (location == null) return;
      // @ts-ignore
      this.newPaymentForm.controls['location'].setValue({
        id: student.locationId,
        label: location.name,
      });
    });
    this.selectedStudentId = value['id'];
  }

  onLocationChange(value: DropDownItem | null) {
    // check if value really changed
    if (
      value == null ||
      value == this.emptyDropdownItem ||
      this.selectedLocationId == value['id'] ||
      this.mode !== 'studentPayment'
    )
      return;

    this.getStudentForLocation(value['id']);
    this.getTenantForUser();

    if (this.selectedLocationId != '') {
      // reset form if the location changes
      this.resetDropdownFormsUntilLocation();
    }
    this.selectedLocationId = value['id'];
  }

  resetDropdownFormsUntilLocation() {
    this.newPaymentForm.controls.personInQuestionId.setValue(
      this.emptyDropdownItem,
    );
  }

  getTenantForUser() {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();

      if (selectedTenantId == null) return;
      this.getUserForTenantAndLocation(selectedTenantId);
    } else {
      this.getUserForTenantAndLocation(
        this.authService.getLoggedInUser()?.tenantId,
      );
    }
  }

  getUserForTenantAndLocation(tenantId?: string) {
    this.roleService.getRoles({ tenantId: tenantId }).subscribe((roles) => {
      if (roles.length > 0) {
        const teacherRoleId = roles.find(
          (role) => role.userRole === UserRole.TEACHER,
        )?.id;
        if (teacherRoleId) {
          this.userService
            .getUsers({
              tenantId: tenantId,
              roleId: teacherRoleId,
              // locationId: this.selectedLocationId,
              active: true,
            })
            .subscribe((teachers) => {
              // teachers where location id = selected location id or location id = null

              // todo test if this is working
              this.teacher = teachers.filter(
                (teacher) =>
                  teacher.locationId === this.selectedLocationId ||
                  teacher.locationId == null,
              );

              this.teacher = this.utilService.generateDropdownItems(
                this.teacher,
              );
            });
        }
      }
    });
  }

  createNewPayment() {
    if (this.sendRequest) return;
    this.sendRequest = true;

    // check if file is in form, otherwise show error
    if (this.fileControl.value == null) {
      this.pushService.sendPush(pushTypes.ERROR, this.pushNotificationNoFile);
      this.sendRequest = false;
      return;
    }

    // access current tenantId
    const tenantId = this.authService.getTenantId();
    if (tenantId == null) return;

    // convert the dropdown value to the transactionType
    const transactionTypeDropdown: DropDownItem =
      this.newPaymentForm.get('transactionType')?.value!;
    const personInQuestionId: any =
      this.newPaymentForm.get('personInQuestionId')?.value!;
    const location: DropDownItem = this.newPaymentForm.get('location')?.value!;

    // data of the transaction body, received from the form

    const transactionFormData = new FormData();
    if (this.fileControl.value) {
      transactionFormData.append('file', this.fileControl.value);
    }
    transactionFormData.append('tenantId', tenantId);
    transactionFormData.append(
      'amount',
      this.newPaymentForm.get('amount')?.value!,
    );
    transactionFormData.append(
      'comment',
      this.newPaymentForm.get('comment')?.value ?? '',
    );
    transactionFormData.append('transactionType', transactionTypeDropdown.id);
    transactionFormData.append('locationId', location.id.toString());

    if (this.mode === 'studentPayment') {
      transactionFormData.append('studentId', personInQuestionId.id.toString());
    }

    if (this.mode === 'teacherPayment') {
      transactionFormData.append('teacherId', personInQuestionId.id.toString());
    }

    this.transactionService
      .createTransaction(transactionFormData)
      .subscribe((response: any) => {
        if (response.status === HttpStatusCode.Ok) {
          this.pushService.sendPush(
            pushTypes.SUCCESS,
            this.pushNotificationSuccess,
          );
          this.goBack();
        } else {
          this.sendRequest = false;
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationError,
          );
        }
      });
  }

  // filter users to only get the users with the role teacher
  getTeachers() {
    const query: RoleQueryParams = {
      tenantId: this.authService.getTenantId()!,
      userRole: UserRole.TEACHER,
    };
    this.roleService.getRoles(query).subscribe((role: Role[]) => {
      const userQuery: UserQueryParams = {
        active: true,
        roleId: role[0].id,
      };

      this.userService.getUsers(userQuery).subscribe((teacher) => {
        teacher.forEach((teacher) => {
          this.teacher.push({
            id: teacher.id,
            label: teacher.firstName + ' ' + teacher.lastName,
          });
        });
      });
    });
  }

  getManager() {
    const query: RoleQueryParams = {
      tenantId: this.authService.getTenantId()!,
      userRole: UserRole.MANAGER,
    };

    this.roleService.getRoles(query).subscribe((role: Role[]) => {
      const userQuery: UserQueryParams = {
        roleId: role[0].id,
        active: true,
      };

      this.userService.getUsers(userQuery).subscribe((manager) => {
        manager.forEach((manager) => {
          this.manager.push({
            id: manager.id,
            label: manager.firstName + ' ' + manager.lastName,
          });
        });
      });
    });
  }

  getBoss() {
    const query: RoleQueryParams = {
      tenantId: this.authService.getTenantId()!,
      userRole: UserRole.TENANT_ADMIN,
    };
    this.roleService.getRoles(query).subscribe((role: Role[]) => {
      const userQuery: UserQueryParams = {
        roleId: role[0].id,
        active: true,
      };

      this.userService.getUsers(userQuery).subscribe((tenantAdmin) => {
        tenantAdmin.forEach((tenantAdmin) => {
          this.boss.push({
            id: tenantAdmin.id,
            label: tenantAdmin.firstName + ' ' + tenantAdmin.lastName,
          });
        });
      });
    });
  }

  // get the students and format them to the dropdown conditions
  getStudents() {
    const tenantId = this.authService.getTenantId();
    if (!tenantId) return;

    this.studentService
      .getStudent({ active: true, tenantId })
      .subscribe((students) => {
        this.students = students;
        this.dropDownStudentItems = this.generateRoleDropdownItems(students);
      });
  }

  // get the locations to the given tenant and format them to the dropdown conditions
  getLocations() {
    const tenantId = this.authService.getTenantId();
    if (!tenantId) return;

    const queryParam: LocationQueryParams = {
      tenantId: tenantId,
    };
    this.locationService.getLocations(queryParam).subscribe((locations) => {
      this.locations = locations;
      this.dropDownLocationItems = this.generateRoleDropdownItems(locations);
    });
  }

  /**
   * Gets the items from the api to fill the dropdown menu.u
   */
  getDropdownItems() {
    this.getLocations();

    // depending on the url load the wanted users of a specific role

    if (this.mode === 'studentPayment') {
      this.getStudents();
    }
    if (this.mode === 'teacherPayment') {
      this.getTeachers();
      this.newPaymentForm
        .get('transactionType')
        ?.setValue(this.dropdownTransactionTypeTeacher[0]);
    }

    if (this.mode === 'officePayment') {
      this.getManager();
      this.newPaymentForm
        .get('transactionType')
        ?.setValue(this.dropdownTransactionTypeOffice[0]);
    }

    if (this.mode === 'bossPayment') {
      this.getBoss();
    }
  }

  /**
   * Generates the dropdown items for the role dropdown menu
   */
  generateRoleDropdownItems(items: any[]) {
    let dropDownItems: DropDownItem[] = [];
    items.map((item) => {
      dropDownItems.push({ id: item.id, label: item.name });
    });
    return dropDownItems;
  }

  removeFile() {
    this.fileControl.setValue(null);
  }
}
