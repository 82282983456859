<app-menubar
  [title]="'lesson-category-detail.page-title' | translate"
  [description]="'lesson-category-detail.description' | translate"
  [open]="isOpen"
  (closingEvent)="setCloseEvent()"
>
  <tui-loader
    [showLoader]="!!(isLoading$ | async)"
    [size]="'xl'"
    [overlay]="true"
  >
    <form [formGroup]="lessonCategoryForm" class="form-group">
      <span class="text-field-wrapper"
      ><app-input-text
        [fieldName]="'name'"
        [hint]="'lesson-category-detail.input-label-name' | translate"
      ></app-input-text
      ></span>
      <div class="button-wrapper">
        <button
          *ngIf="!isManager()"
          tuiButton
          size="m"
          [disabled]="!lessonCategoryForm.valid"
          type="submit"
          (click)="submitForm()"
        >
          {{ "common.button-save" | translate }}
        </button>
        <button
          *ngIf="!isManager()"
          tuiButton
          size="m"
          type="button"
          [icon]="tuiIconTrash"
          (click)="showDeleteDialog = true"
          appearance="secondary-destructive"
        >
          {{ "common.button-delete" | translate }}
        </button>
        <button
          tuiButton
          type="button"
          size="m"
          appearance="secondary"
          [icon]="tuiIconX"
          (click)="setCloseEvent()"
        >
          {{ "common.button-cancel" | translate }}
        </button>
      </div>
    </form>
  </tui-loader>
</app-menubar>
<app-delete-dialog
  [isOpen]="showDeleteDialog"
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="showDeleteDialog = false; deleteCategory()"
></app-delete-dialog>


