import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'app-input-large-text',
  templateUrl: './input-large-text.component.html',
  styleUrls: ['./input-large-text.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
/**
 *  A component that displays an input field for entering a long text
 *  @Input hint - The hint to display on top of the input field
 *  @Input fieldName - The name of the field in the FormGroup
 *  @Input isExpandable - Whether the input field should be expandable
 */
export class InputLargeTextComponent {
  @Input() hint?: string;
  @Input() fieldName?: string;
  @Input() isExpandable?: boolean = true;
  @Input() readonly: boolean = false;
  @Input() labelOutside: boolean = false;
  @Input() showError: boolean = true;
  @Input() formControl: FormControl | undefined;

  constructor() {}
}
