<ng-container [ngSwitch]="lesson.event.extendedProps.status">
  <ng-container *ngSwitchCase="LessonStatus.ACTIVE">
    <ng-container *ngTemplateOutlet="active"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="LessonStatus.INACTIVE">
    <ng-container *ngTemplateOutlet="cancelled"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="LessonStatus.FINISHED">
    <ng-container *ngTemplateOutlet="finished"></ng-container>
  </ng-container>
</ng-container>

<ng-template #active>
<div class="event-wrapper event-active" [style.background]="activeEventGradient" >
  <div class="event-details">
    <h3 class="tui-text_body-m"><strong>{{ lesson.event.title }}</strong></h3>
    <span>
    </span>
    <span>
      <p class="event-time">
        {{ lesson.timeText }}
        ({{ calculatedDuration() }})
      </p>
    </span>
  </div>
  <div class="event-footer">
    <tui-svg [src]="tuiIconUser"></tui-svg>
    <p>{{ lesson.event.extendedProps.assignedTo }}</p>
  </div>
</div>
</ng-template>




<!-- Cancelled  Lesson -->
<ng-template #cancelled>
  <div class="event-wrapper event-inactive" [style.background]="activeEventGradient">
    <div class="event-details">
      <h3 class="tui-text_body-m"><strong>{{ lesson.event.title }}</strong></h3>
      <span>
    </span>
      <span>
      <p class="event-time">
        {{ lesson.timeText }}
        ({{ calculatedDuration() }})
      </p>
    </span>
      <div  class="tui-space_top-1">
        <h3 class="cancel-title" *ngIf="lesson.event.extendedProps.status === LessonStatus.INACTIVE;">
          {{"calendar.cancelled-lesson-title" | translate | uppercase}}
        </h3>
      </div>
    </div>
    <div class="event-footer">
      <tui-svg [src]="tuiIconUser"></tui-svg>
      <p>{{ lesson.event.extendedProps.assignedTo }}</p>
    </div>
  </div>
</ng-template>

<ng-template #finished>
  <div class="event-wrapper event-active" [style.background]="activeEventGradient">
    <div class="event-details">
      <div class="title-container">
        <h3 class="tui-text_body-m"><strong>{{ lesson.event.title }}</strong></h3>
        <tui-svg src="tuiIconCheckCircleLarge"  />
      </div>

      <span>
    </span>
      <span>
      <p class="event-time">
        {{ lesson.timeText }}
        ({{ calculatedDuration() }})
      </p>
    </span>
      <div  class="tui-space_top-1">
        <h3 class="cancel-title" *ngIf="lesson.event.extendedProps.status === LessonStatus.FINISHED;">
          {{"calendar.finished-lesson-title" | translate | uppercase}}
        </h3>
      </div>
    </div>
    <div class="event-footer">
      <tui-svg [src]="tuiIconUser"></tui-svg>
      <p>{{ lesson.event.extendedProps.assignedTo }}</p>
    </div>
  </div>
</ng-template>
