<div class="head-wrapper">
  <h2 *ngIf="withTitle" class="title">{{ title }}</h2>
  <span class="head-button-wrapper">
    <button
      (click)="deleteClicked.emit()"
      *ngIf="showDeleteButton"
      [icon]="tuiIconTrash"
      appearance="secondary-destructive"
      size="m"
      tuiButton
      type="button"
    >
      {{ deleteButtonText }}
    </button>
    <button
      (click)="saveClicked.emit()"
      [disabled]="saveButtonDisabled"
      [icon]="tuiIconSave"
      appearance="primary"
      class="submit-button"
      size="m"
      tuiButton
      type="submit"
    >
      {{ saveButtonText }}
    </button>
  </span>
</div>
