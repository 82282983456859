import {
  TransactionType,
  transactionTypeToAbbreviation,
  transactionTypeToString,
} from '../enums/transaction-type';
import { BaseModel } from './base-model';
import { TransactionInOut } from '../enums/transaction-in-out';

type NameType = {
  firstName: string;
  lastName: string;
};

export type FileType = {
  name: any;
  id: string;
  fileName: string;
  mimetype: string;
  url?: string;
};

export class Transaction extends BaseModel {
  private _tenantId: string;
  private _amount: number;
  private _comment: string;
  private _transactionType: TransactionType;
  private _studentId: string;
  private _teacherId: string;
  private _managementId: string;
  private _locationId: string;
  private _userId: string;
  private _transactionTypeString: string;
  private _transactionTypeAbbreviation: string;
  private _transactionInOutCashRegister: TransactionInOut | undefined;
  private readonly _user: NameType;
  private _file: FileType | null;
  private readonly _student: NameType | null;
  private readonly _teacher: NameType | null;

  private _userName?: string;
  private _personName?: string;

  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    tenantId: string,
    amount: number,
    comment: string,
    transactionType: TransactionType,
    studentId: string,
    teacherId: string,
    managementId: string,
    locationId: string,
    userId: string,
    files: FileType,
    user: NameType,
    student: NameType | null,
    teacher: NameType | null,
  ) {
    super(id, createdAt, updatedAt);
    this._tenantId = tenantId;
    this._amount = amount;
    this._comment = comment;
    this._transactionType = transactionType;
    this._studentId = studentId;
    this._teacherId = teacherId;
    this._managementId = managementId;
    this._locationId = locationId;
    this._userId = userId;
    // this._userName = user;
    // this._personName = person;
    this._user = user;
    this._student = student;
    this._teacher = teacher;
    this._file = files;

    // used logic from setter or exported method to set the values
    this.transactionInOutCashRegister = amount;
    this._transactionTypeString = transactionTypeToString(transactionType);
    this._transactionTypeAbbreviation =
      transactionTypeToAbbreviation(transactionType);
  }

  get amount(): number {
    return this._amount;
  }

  set amount(value: number) {
    this._amount = value;
  }

  get comment(): string {
    return this._comment;
  }

  set comment(value: string) {
    this._comment = value;
  }

  get transactionType(): TransactionType {
    return this._transactionType;
  }

  set transactionType(value: TransactionType) {
    this._transactionType = value;
  }

  get studentId(): string {
    return this._studentId;
  }

  set studentId(value: string) {
    this._studentId = value;
  }

  get teacherId(): string {
    return this._teacherId;
  }

  set teacherId(value: string) {
    this._teacherId = value;
  }

  get managementId(): string {
    return this._managementId;
  }

  set managementId(value: string) {
    this._managementId = value;
  }

  get locationId(): string {
    return this._locationId;
  }

  set locationId(value: string) {
    this._locationId = value;
  }

  get userId(): string {
    return this._userId;
  }

  set userId(value: string) {
    this._userId = value;
  }

  get file(): FileType | null {
    return this._file;
  }

  set file(value: FileType) {
    this._file = value;
  }

  get tenantId(): string {
    return this._tenantId;
  }

  set tenantId(value: string) {
    this._tenantId = value;
  }

  get userName(): string | undefined {
    return this._user.firstName + ' ' + this._user.lastName;
  }

  get personName(): string | undefined {
    if (this._student == null && this._teacher == null) {
      if (this._user == null) {
        return '';
      }
      return this._user.firstName + ' ' + this._user.lastName;
    }

    if (this._student !== null) {
      return this._student.firstName + ' ' + this._student.lastName;
    }
    if (this._teacher !== null) {
      return this._teacher.firstName + ' ' + this._teacher.lastName;
    }

    return '';
  }

  get transactionTypeString(): string {
    return this._transactionTypeString;
  }

  set transactionTypeString(value: string) {
    this._transactionTypeString = value;
  }

  get transactionTypeAbbreviation(): string {
    return this._transactionTypeAbbreviation;
  }

  set transactionTypeAbbreviation(value: string) {
    this._transactionTypeAbbreviation = value;
  }

  get transactionInOutCashRegister(): TransactionInOut {
    return <TransactionInOut>this._transactionInOutCashRegister;
  }

  // Sets an INCOMING_TRANSACTION or OUTGOING_TRANSACTION value to display in the table for the cash register
  set transactionInOutCashRegister(amount: number) {
    if (amount >= 0) {
      this._transactionInOutCashRegister =
        TransactionInOut.INCOMING_TRANSACTION;
    } else {
      this._transactionInOutCashRegister =
        TransactionInOut.OUTGOING_TRANSACTION;
    }
  }

  static fromJson(json: any): Transaction {
    return new Transaction(
      json.id,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.tenantId,
      json.amount,
      json.comment,
      json.transactionType,
      json.studentId,
      json.teacherId,
      json.managementId,
      json.locationId,
      json.userId,
      json.file,
      json.user,
      json.student,
      json.teacher,
    );
  }

  // static fromJsonWithUserAndPerson(json: any): Transaction {
  //   return new Transaction(
  //     json.transaction.id,
  //     new Date(json.transaction.createdAt),
  //     new Date(json.transaction.updatedAt),
  //     json.transaction.tenantId,
  //     json.transaction.amount,
  //     json.transaction.comment,
  //     json.transaction.transactionType,
  //     json.transaction.studentId,
  //     json.transaction.teacherId,
  //     json.transaction.managementId,
  //     json.transaction.locationId,
  //     json.transaction.userId,
  //     json.user,
  //     json.student,
  //     json.teacher,
  //     json.management,
  //   );
  // }

  toJson(): any {}
}
