<div class="input-wrapper">
  <tui-input-number
    [formControlName]="fieldName"
    [tuiTextfieldPostfix]="postfix"
    [tuiTextfieldSize]="textFieldSize"
  >
    {{ hint }}
    <span *ngIf="required" class="tui-required"></span>
  </tui-input-number>
  <div *ngIf="showError" class="error-wrapper">
    <tui-error
      [error]="[] | tuiFieldError | async"
      formControlName="{{ fieldName }}"
    ></tui-error>
  </div>
</div>
