<div class="wrapper">
  <app-table-header
    (createNewClickEvent)="isOpen = true"
    (filterEvent)="filterTable($event)"
    (searchEvent)="onSearchChange($event)"
    [buttonText]="'user.button-new-user' | translate"
    [hasSearch]="true"
    [showCreateButton]="!isManager()"
    [tableTitle]="'user.page-title' | translate"
    [tenantSelector]="true"
  ></app-table-header>
  <app-table
    (paginationEvent)="paginationEvent($event)"
    (rowClickEvent)="usersRowClickEvent($event)"
    (sortEvent)="sortTable($event)"
    [columns]="userTableShownColumns"
    [currentPage]="tablePage"
    [pageSize]="tablePageSize"
    [tableColumnNames]="[
      'user.table-first-name' | translate,
      'user.table-last-name' | translate,
      'user.table-role' | translate,
      'user.table-telephone' | translate,
      'user.table-mail' | translate,
      'user.table-active' | translate
    ]"
    [tableData]="userTableData"
    [totalElements]="totalUsers"
  ></app-table>
  <!-- injecting children -->
  <router-outlet></router-outlet>
</div>

<!-- slide modal from right -->
<app-new-user
  (closeEvent)="isOpen = false"
  (isCreatedEvent)="createdUserEvent()"
  [isOpen]="isOpen"
></app-new-user>
