import { Component, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { PushService } from "../../services/push.service";
import { pushTypes } from "../../enums/push-types";
import { NavRoutes } from "../../enums/nav-routes";
import { LoginStateStatus } from "../../enums/login-state-status";

@Component({
  selector: "app-reset-password-page",
  templateUrl: "./reset-password-page.component.html",
  styleUrls: ["./reset-password-page.component.scss"],
})

/**
 * Component for the reset password page
 * The user can set a new password after he clicked on the link in the email
 * The hash is validated and, if successful, the new password is submitted to the api
 */
export class ResetPasswordPageComponent {
  displayIsland: boolean = window.innerWidth > 650;
  protected hash!: string | null;
  protected isOpenSuccess: boolean = false;
  protected isOpenSuccessNoLogin: boolean = false;
  protected title: string = "";
  protected error: string = "";
  // for the type of reset (can be ?=passwordReset or ?=invite)
  protected type!: string | null;
  protected RESET = "passwordReset";
  protected readonly NavRoutes = NavRoutes;
  protected readonly window = window;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private pushService: PushService,
  ) {
  }

  ngOnInit(): void {
    this.getHashWithRouteParam();
    this.validateHash();
    this.getTypeWithRouteParam();

    // translations for the push message
    this.translate
      .get(["reset-password.pop-up-title", "reset-password.pop-up-error"])
      .subscribe((translations) => {
        this.title = translations["reset-password.pop-up-title"];
        this.error = translations["reset-password.pop-up-error"];
      });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: { target: { innerWidth: number } }) {
    this.displayIsland = event.target.innerWidth >= 420;
  }

  /**
   * Gets the hash from the route param after the /:hash
   * example.com/reset-password/:hash
   */
  public getHashWithRouteParam(): void {
    this.route.paramMap.subscribe((params) => {
      this.hash = params.get("hash");
    });
  }

  /**
   * Gets the type of reset (via forgot password or invite) from the route
   * (can be type?=passwordReset or type?=invite)
   */
  getTypeWithRouteParam() {
    this.route.queryParams.subscribe((params) => {
      this.type = params["type"];
    });
  }

  /**
   * When route is called, the hash is validated via the api
   * If the hash is invalid, the user is redirected to the error page
   */
  validateHash(): void {
    this.authService.validateResetPasswordHash(this.hash ?? "").subscribe(
      () => {
      },
      (error) => {
        this.router.navigate([NavRoutes.ERROR_OUT], {
          queryParams: {
            code: error.error.code,
            key: error.error.key,
            url: error.url,
          },
        });
      },
    );
  }

  /**
   * Submits the new password to the api
   * If the password is successfully changed, the user is redirected to the login page
   * If not, a push message is shown
   * @param newPassword
   */
  public submit(newPassword: string): void {
    this.authService
      .setPassword({ password: newPassword }, this.hash ?? "")
      .subscribe({
        next: (setPasswordStatus) => {
          switch (setPasswordStatus) {
            case LoginStateStatus.PASSWORD_RESET_SUCCESS:
              // open the success dialog
              this.isOpenSuccess = true;
              break;

            case LoginStateStatus.TEACHER_NOT_ALLOWED_TO_LOGIN:
              this.isOpenSuccessNoLogin = true;
              break;
            case LoginStateStatus.STUDENT_NOT_ALLOWED_TO_LOGIN:
              // open the success dialog - for users who should not be able to login
              this.isOpenSuccessNoLogin = true;
              break;
            case LoginStateStatus.PASSWORD_RESET_FAILED:
              this.isOpenSuccess = false;
              this.pushService.sendPush(pushTypes.ERROR, this.error);
              break;
          }
        },
      });
  }
}
