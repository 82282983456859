import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { BehaviorSubject, lastValueFrom, map, Observable } from 'rxjs';
import { Role } from '../models/role';
import { ResponseWithRecordsBody } from '../interfaces/body/response-with-recors-body';
import { ApiRoutes } from '../enums/api-routes';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserRole } from '../enums/user-role';
import { Tenant } from '../models/tenant';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminService {
  private _selectedTenantId$ = new BehaviorSubject<string | null>(null);
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  /**
   * Returns true if the logged-in user, that is saved in the localStorage is a super admin
   */
  isSuperAdmin(): boolean {
    const userJSON = localStorage.getItem('user');
    const user = User.fromJson(JSON.parse(userJSON || '{}'));
    return user.role.userRole === UserRole.SUPER_ADMIN;
  }

  // Important: you have to end subscription for .getSelectedTenantId() manually on destroy, otherwise => memory leak,
  // if .unsubscribe() is not cancelling the subscription, use takeUntil(this.endNotifier)in the subscription and call this.endNotifier.next() in ngOnDestroy()
  getSelectedTenantId(): Observable<string | null> {
    const selectedTenantId = localStorage.getItem('selectedTenantId');
    if (selectedTenantId != this._selectedTenantId$.value) {
      this._selectedTenantId$.next(selectedTenantId);
    }
    return this._selectedTenantId$ as Observable<string | null>;
  }

  getSelectedTenantIdValue(): string | null {
    return (
      this._selectedTenantId$.value ?? localStorage.getItem('selectedTenantId')
    );
  }

  setSelectedTenantId(tenantId: string) {
    localStorage.setItem('selectedTenantId', tenantId || '');
    this._selectedTenantId$.next(tenantId);
  }

  getGlobalUsers(globalRoles: Role[]): Observable<User[]> {
    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + ApiRoutes.USER)
      .pipe(
        map((response) => {
          const users: User[] = response.records.map((user: any) =>
            User.fromJson(user),
          );
          return users.filter((user) => {
            return globalRoles.some((role) => {
              return role.id === user.roleId;
            });
          });
        }),
      );
  }
}
