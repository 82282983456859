<div class="overall-wrapper">
  <div class="island-wrapper">
    <tui-island>
      <p class="tui-island__category">{{ "error.title" | translate }}</p>
      <h3 class="tui-island__title">
        {{ "error.description" | translate }}
      </h3>
      <p class="tui-island__paragraph">
        {{ "error.details" | translate }}
      </p>

      <ng-container *ngIf="url != undefined">
        <h5 class="headline-text">{{ "error.more-info" | translate }}</h5>
        <h6 class="text-style">{{ "error.url" | translate }}</h6>
        <p class="text-style tui-text_body-xs">
          {{ url }}
        </p>
        <h6 class="text-style">{{ "error.code" | translate }}</h6>
        <p class="text-style tui-text_body-xs">
          {{ errorCode + " " + errorKey }}
        </p>
      </ng-container>

      <p class="tui-island__paragraph tui-island__paragraph_link">
        <button
          tuiButton
          type="button"
          appearance="flat"
          size="m"
          class="tui-island__footer-button"
          routerLink="/"
        >
          {{ "error.button" | translate }}
        </button>
      </p>
    </tui-island>
  </div>
</div>
