import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiTablePagination, tuiTablePaginationOptionsProvider, } from '@taiga-ui/addon-table';
import { TableSortEvent } from '../../interfaces/table-sort-event';
import { LoadingService } from '../../services/loading.service';
import { TransactionInOut } from '../../enums/transaction-in-out';
import { UserRole } from '../../enums/user-role';
import { AuthService } from '../../services/auth.service';
import { TransactionType } from '../../enums/transaction-type';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  providers: [
    tuiTablePaginationOptionsProvider({
      showPages: false,
    }),
  ],
  styleUrls: ['./table.component.scss'],
})

/**
 * A component that displays a data table with pagination at the bottom and sorting in the table header:
 *
 * @param tableData The data that is displayed in the table
 * @param tableColumnNames The names of the columns that are displayed in the table header
 * @param columns The names of the attributes of the data objects that are displayed in the table
 * @param totalElements The total number of elements that are displayed in the table
 * @param currentPage The current page that should be displayed of the table (default is 0)
 * @param pageSize The number of elements that should be displayed per page (default is 10)
 *
 * @Output paginationEvent An event that is emitted when the user clicks on a pagination button (next, previous, page size)
 * @Output rowClickEvent An event that is emitted when the user clicks on a row of the table
 * @Output sortEvent An event that is emitted when the user clicks on a column header to sort the table
 */
export class TableComponent {
  @Input({ required: true }) tableData: any[] = [];
  @Input({ required: true }) tableColumnNames!: string[];
  @Input({ required: true }) columns!: string[];
  @Input() totalElements: number = 0;
  @Input() currentPage: number = 0;
  @Input() pageSize: number = 10;
  @Input() showPagination: boolean = true;
  @Input() showDeleteButton: boolean = false;
  @Input() alternativeColorHeader: boolean = false;
  @Input() paymentTable: boolean = false;
  @Input() noSortColumns: string[] = [];

  @Output() paginationEvent = new EventEmitter<TuiTablePagination>();
  @Output() rowClickEvent = new EventEmitter<any>();
  @Output() sortEvent = new EventEmitter<TableSortEvent>();
  @Output() deleteEvent = new EventEmitter<any>();

  disabledSortColumns: string[] = [];

  isLoading$ = this.loadingService.getLoadingState();
  readonly globalDisabledColumns = [
    'phone',
    'personName',
    'transactionTypeString',
    'userName'
  ];
  protected cursor: string = '';
  protected readonly TransactionInOut = TransactionInOut;
  protected readonly TransactionType = TransactionType;
  private sortDirection: number = 0;

  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    // add column for delete button if it should be displayed
    if (this.showDeleteButton) {
      this.columns.push('delete');
    }
    this.disabledSortColumns = this.globalDisabledColumns.concat(this.noSortColumns);
  }

  ngAfterViewInit(): void {
    // sets rows to clickable if rowClickEvent is observed (that means it is implemented in the parent component)
    if (this.isClickable()) {
      this.cursor = 'pointer';
    }
  }

  /**
   * When the user clicks on the header name  a sorting event is emitted with the column name and the sorting direction
   * @param column The name of the column that is clicked
   */
  onSortChange(column: string) {
    this.sortEvent.emit({
      sortColumn: column,
      sortDirection: this.sortDirection,
    });
  }

  /**
   * When the user clicks on the header name the sorting direction is changing
   * @param direction The direction of the sorting (1 = ascending, -1 = descending)
   */
  onDirectionChange(direction: number) {
    this.sortDirection = direction;
  }

  shouldNotSort(column: string): boolean {
    console.log(this.noSortColumns.includes(column))
    return (
      this.noSortColumns.includes(column)
    );
  }

  /**
   * Checks if the rowClickEvent is observed (= implemented in the parent component) to determine
   * if the table rows are clickable and thus the cursor should be a pointer or not.
   * @returns true if the rowClickEvent is observed, false otherwise
   */
  isClickable(): boolean {
    return this.rowClickEvent.observers.length > 0;
  }

  deleteButtonClicked(event: Event) {
    event.stopPropagation(); // Prevents Event-Bubbling
  }

  public isManager(): boolean {
    return this.authService.getUserRole() === UserRole.MANAGER;
  }


  isIsoOrConvertible(dateInput: any): boolean {
    const isIso8601Date = (input: string): boolean => {
      const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?(Z|[+-]\d{2}:\d{2})?$/;
      return iso8601Pattern.test(input);
    };

    const isConvertibleToIso8601 = (input: any): boolean => {
      const date = new Date(input);
      return !isNaN(date.getTime()) && isIso8601Date(date.toISOString());
    };

    if (isIso8601Date(dateInput)) {
      return true;
    }

    if (!isNaN(parseFloat(dateInput)) && isFinite(dateInput)) {
      return false;
    }

    return isConvertibleToIso8601(dateInput);
  }

  extractNestedProperty(item: any, key: string): any {
    const keys = key.split(".");
    let value = item;

    for (const k of keys) {
      if (value && value.hasOwnProperty(k)) {
        value = value[k];
      } else if (value && value.hasOwnProperty("_" + k)) {
        value = value["_" + k];
      } else {
        console.log(`property ${ k } is not found in the object`);
        return null;
      }
    }

    return value;
  }
}
