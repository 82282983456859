import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { User } from "../../models/user";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { tuiIconSmileLarge, tuiIconTrash, tuiIconX } from "@taiga-ui/icons";
import { PushService } from "../../services/push.service";
import { AuthService } from "../../services/auth.service";
import { pushTypes } from "../../enums/push-types";
import { TranslateService } from "@ngx-translate/core";
import { LoadingService } from "../../services/loading.service";
import { NavRoutes } from "../../enums/nav-routes";
import { HttpStatusCode } from "@angular/common/http";
import { UserBody } from "../../interfaces/body/user-body";
import { UserRole } from "../../enums/user-role";
import { phoneValidator } from "../../validators/phone-validator";
import { Role } from "../../models/role";
import { Subscription } from "rxjs";
import { SuperAdminService } from "../../services/super-admin.service";
import { RoleService } from "../../services/role.service";
import { WalletType } from "../../enums/wallet-type";

@Component({
  selector: "app-user-detail-page",
  templateUrl: "./user-detail-page.component.html",
  styleUrls: ["./user-detail-page.component.scss"],
})

/**
 * Component for the user profile page in which the user can be edited or deleted
 */
export class UserDetailPageComponent {
  // to determine if the dialog is open or not
  isOpen = true;
  showDeleteDialog: boolean = false;
  roles: Role[] = [];
  urlContainsGlobalUsers: boolean = false;

  userId = "";
  user!: User;
  isLoading$ = this.loadingService.getLoadingState();
  radioListActive: any[] = [{ active: true }, { active: false }];
  selectedRole: Role | undefined;
  // Strings for i18n
  pushNotificationPatchedSuccess: string = "";
  pushNotificationPatchedError: string = "";
  pushNotificationDeletedSuccess: string = "";
  pushNotificationDeletedError: string = "";
  pushNotificationInviteError: string = "";
  sendRequest: boolean = false;

  /**
   * BreadcrumbItems for the breadcrumb navigation from the detail to the general student page
   * TODO: change i18n captions
   */
  breadcrumbItems = [
    {
      caption: "dashboard.tile-user",
      routerLink: `/${NavRoutes.USER}`,
    },
    {
      caption: "students.student-detail.breadcrumb-item-caption-detail",
      routerLink: null,
      routerLinkActiveOptions: { exact: true },
    },
  ];
  readonly userForm: FormGroup = new FormGroup({
    email: new FormControl(``, [Validators.required]),
    firstName: new FormControl(``),
    lastName: new FormControl(``),
    phone: new FormControl(``, phoneValidator()),
    role: new FormControl(``, [Validators.required]),
    active: new FormControl(``),
    locationId: new FormControl(null),
  });
  // icons
  protected readonly tuiIconX = tuiIconX;
  protected readonly tuiIconTrash = tuiIconTrash;
  protected readonly UserRole = UserRole;
  protected readonly WalletType = WalletType;
  protected readonly tuiIconSmileLarge = tuiIconSmileLarge;
  private selectedTenantSubscription: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private pushService: PushService,
    private translateService: TranslateService,
    private router: Router,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private superAdminService: SuperAdminService,
    private roleService: RoleService,
  ) {}

  ngOnInit(): void {
    this.getUserWithRouteParam();
    this.getAvailableRoles();

    this.urlContainsGlobalUsers = this.router.url.includes(
      NavRoutes.GLOBAL_USERS,
    );

    // translated strings for push-notifications
    this.translateService
      .get([
        "user-profile.push-success-patched",
        "user-profile.push-error-patched",
        "user-profile.push-success-deleted",
        "user-profile.push-error-deleted",
        "user-profile.push-error-invite",
      ])
      .subscribe((translations) => {
        this.pushNotificationPatchedSuccess =
          translations["user-profile.push-success-patched"];
        this.pushNotificationPatchedError =
          translations["user-profile.push-error-patched"];
        this.pushNotificationDeletedSuccess =
          translations["user-profile.push-success-deleted"];
        this.pushNotificationDeletedError =
          translations["user-profile.push-error-deleted"];
        this.pushNotificationInviteError =
          translations["user-profile.push-error-invite"];
      });

    // disable form for manager
    if (this.isManager()) {
      this.userForm.disable();
    }
  }

  hasUserRole(userRole: UserRole) {
    return this.roles.some((role) => {
      return role.userRole === userRole;
    });
  }

  ngOnChanges(): void {
    this.userForm.markAsUntouched();
  }

  ngOnDestroy(): void {
    this.selectedTenantSubscription.unsubscribe();
  }

  listenToRoleChange() {
    this.userForm.get("role")?.valueChanges.subscribe((value: any) => {
      if (value == null) return;
      this.selectedRole = this.roles.find((role) => role.id === value.id);
    });
  }

  /**
   * Gets all available explanation from the role service
   * (with the tenantId of the user or the selected tenant id if the user is a super-admin)
   */
  getAvailableRoles(): void {
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe((id) => {
          const tenantId = id;
          if (tenantId == null) return;
          this.getRolesWithTenantId(tenantId);
        });
    } else {
      const tenantId = this.authService.getLoggedInUser()?.tenantId;
      this.getRolesWithTenantId(tenantId);
    }
  }

  /**
   * Get all explanation for the given tenant id and set the explanation array of this component
   */
  getRolesWithTenantId(tenantId: string | undefined): void {
    this.roleService.getRoles({ tenantId: tenantId }).subscribe((roles) => {
      this.roles = roles;
    });
  }

  /**
   * Submits the form values to the api in order to update the user
   * Sends a push notification if the user was updated successfully or not
   */
  submitForm(): void {
    if (this.sendRequest) return;
    this.sendRequest = true;

    if (this.userForm.valid && this.userForm.touched) {
      const tenantId = this.authService.getTenantId();
      if (tenantId == null) return;
      const userBody: UserBody = {
        firstName: this.userForm.controls["firstName"].value ?? "",
        lastName: this.userForm.controls["lastName"].value ?? "",
        email: this.userForm.controls["email"].value,
        phone: this.userForm.controls["phone"].value ?? "",
        active: this.userForm.controls["active"].value.active,
        roleId: this.userForm.controls["role"].value.id,
        tenantId: tenantId,
        locationId: this.userForm.controls["locationId"].value,
      };

      this.userService
        .updateUser(userBody, this.userId)
        .subscribe((statusCode) => {
          if (statusCode == HttpStatusCode.Ok) {
            this.pushService.sendPush(
              pushTypes.SUCCESS,
              this.pushNotificationPatchedSuccess,
            );
            this.setCloseEvent();
          } else if (statusCode == HttpStatusCode.Conflict) {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationInviteError,
            );
            this.setCloseEvent();
          } else {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationPatchedError,
            );
            this.setCloseEvent();
          }
        });
    } else if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
    }
  }

  /**
   * Deletes the user with the id from the route parameter.
   * A dialog is shown to confirm the deletion (see html).
   * Sends a push notification if the user was deleted successfully or not
   */
  deleteUser() {
    if (this.sendRequest) return;
    this.sendRequest = true;

    this.userService.deleteUser(this.userId).subscribe((isDeleted) => {
      if (isDeleted) {
        this.pushService.sendPush(
          pushTypes.SUCCESS,
          this.pushNotificationDeletedSuccess,
        );
        this.setCloseEvent();
      } else {
        this.pushService.sendPush(
          pushTypes.ERROR,
          this.pushNotificationDeletedError,
        );
        this.setCloseEvent();
      }
    });
  }

  /**
   * Gets the user from the api with the id from the route parameter
   * Sets the form values to the user values in order to edit them
   */
  public getUserWithRouteParam(): void {
    this.route.paramMap.subscribe((params) => {
      this.userId = params.get("id")!;
      if (this.userId != null) {
        this.userService.getUserById(this.userId).subscribe(
          (user) => {
            this.user = user;
            this.userForm.controls["email"].setValue(this.user.email);
            this.userForm.controls["firstName"].setValue(this.user.firstName);
            this.userForm.controls["lastName"].setValue(this.user.lastName);
            this.userForm.controls["phone"].setValue(this.user.phone);
            this.userForm.controls["role"].setValue({
              id: this.user.role?.id,
              label: this.user.role?.name,
            });
            this.setActiveRadioValue(this.user.active);

            this.selectedRole = this.user.role;
            this.listenToRoleChange();
            this.userForm.markAllAsTouched();
          },
          (error) => {
            this.router.navigate([NavRoutes.ERROR], {
              queryParams: {
                code: error.error.code,
                key: error.error.key,
                url: error.url,
              },
            });
          },
        );
      }
    });
  }

  /**
   * Sets the text and boolean value for the radio buttons depending on the received active value
   * @param active the active value of the user
   */
  setActiveRadioValue(active: boolean) {
    if (active) {
      this.userForm.controls["active"].setValue(this.radioListActive[0]);
    } else {
      this.userForm.controls["active"].setValue(this.radioListActive[1]);
    }
  }

  /**
   * Closes the dialog and navigates back to the user page
   */
  setCloseEvent() {
    this.sendRequest = false;
    if (this.router.url.includes(NavRoutes.SETTINGS)) {
      this.router.navigate([NavRoutes.SETTINGS, NavRoutes.GLOBAL_USERS]);
    } else {
      this.router.navigate([NavRoutes.USER]);
    }
    this.isOpen = false;
  }

  public isManager(): boolean {
    return this.authService.getUserRole() === UserRole.MANAGER;
  }

  sendInviteMessageAgain(id: string) {
    this.authService.resendInviteMessage(id).subscribe(() => {
      this.pushService.sendPush(
        pushTypes.SUCCESS,
        "Einladung wurde erneut gesendet!",
      );
    });
  }
}
