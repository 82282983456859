import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class InputDateComponent {

  @Input() hint: string = '';
  @Input() fieldName: string = '';
  @Input() textFieldSize: TuiSizeL | TuiSizeM | TuiSizeS = 'l';
  @Input() showError: boolean = true;
  @Input() textFieldCleaner: boolean = false;
  @Input() deactivatePastDates: boolean = false;

  @Output() dateChange = new EventEmitter<TuiDay | null>();

  todaysDate: TuiDay = TuiDay.currentLocal();

  constructor() {}

  ngOnInit() {}
}
