<ng-template #tooltip>
  <div>{{ menuItem.tooltip }}</div>
</ng-template>

<button *ngIf="menuItem"
  id="{{ menuItem.tooltip }}"
  tuiIconButton
  type="button"
  appearance="{{ selected || rla.isActive ? 'primary' : 'secondary' }}"
  routerLink="{{ menuItem.link }}"
  routerLinkActive
  #rla="routerLinkActive"
  [tuiHint]="tooltip"
  tuiHintDirection="right"
  tuiHintAppearance="onDark"
  (click)="onClick()"
>
  <tui-svg *ngIf="menuItem.customIcon == null; else customIcons" src="{{ menuItem.icon }}" class=""></tui-svg>

  <ng-template #customIcons [ngSwitch]="menuItem.customIcon" >
      <svg class="custom-icon" *ngSwitchCase="CustomIcons.PAYMENTS" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 256 256"><path d="M128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,152ZM240,56H16a8,8,0,0,0-8,8V192a8,8,0,0,0,8,8H240a8,8,0,0,0,8-8V64A8,8,0,0,0,240,56ZM193.65,184H62.35A56.78,56.78,0,0,0,24,145.65v-35.3A56.78,56.78,0,0,0,62.35,72h131.3A56.78,56.78,0,0,0,232,110.35v35.3A56.78,56.78,0,0,0,193.65,184ZM232,93.37A40.81,40.81,0,0,1,210.63,72H232ZM45.37,72A40.81,40.81,0,0,1,24,93.37V72ZM24,162.63A40.81,40.81,0,0,1,45.37,184H24ZM210.63,184A40.81,40.81,0,0,1,232,162.63V184Z"></path></svg>
      <svg class="custom-icon" *ngSwitchCase="CustomIcons.LICENCE_TYPES" xmlns="http://www.w3.org/2000/svg" width="30" height="30"  viewBox="0 0 256 256"><path d="M200,112a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h40A8,8,0,0,1,200,112Zm-8,24H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm40-80V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Zm-80.26-34a8,8,0,1,1-15.5,4c-2.63-10.26-13.06-18-24.25-18s-21.61,7.74-24.25,18a8,8,0,1,1-15.5-4,39.84,39.84,0,0,1,17.19-23.34,32,32,0,1,1,45.12,0A39.76,39.76,0,0,1,135.75,166ZM96,136a16,16,0,1,0-16-16A16,16,0,0,0,96,136Z"></path></svg>
  </ng-template>
</button>
