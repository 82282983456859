import { LicenceTarget } from './licence-target';
import { BaseModel } from './base-model';

export class LicenceType extends BaseModel {
  private _tenantId: string;
  private _name: string;
  private _icon: string;
  private _targets: LicenceTarget[];

  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    tenantId: string,
    name: string,
    icon: string,
    targets: LicenceTarget[],
  ) {
    super(id, createdAt, updatedAt);
    this._tenantId = tenantId;
    this._name = name;
    this._icon = icon;
    this._targets = targets;
  }

  get tenantId(): string {
    return this._tenantId;
  }

  set tenantId(value: string) {
    this._tenantId = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get targets(): LicenceTarget[] {
    return this._targets;
  }

  set targets(value: LicenceTarget[]) {
    this._targets = value;
  }

  static fromJson(json: any): any {
    return new LicenceType(
      json.id,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.tenantId,
      json.name,
      json.icon,
      json.targets,
    );
  }

  // todo implement
  toJSON(): any {}
}
