<div class="wrapper container">

  <div class="form-container">
    <!-- payment title -->
    <h1>
      {{ mode === 'studentPayment' ? ('payments.payment-type-student' | translate) :
      mode === 'teacherPayment' ? ('payments.payment-type-teacher' | translate) :
        mode === 'officePayment' ? ('payments.payment-type-office' | translate) :
          mode === 'bossPayment' ? ('payments.payment-type-boss' | translate) :
            '' }}
    </h1>

    <form [formGroup]="newPaymentForm" class="form">
      <!--  dropdown of role.The user selects the one who made a payment  -->
      <app-dropdown
        (emitChangeEvent)="getWalletAmount($event)"
        [dropDownItems]="
        mode === 'studentPayment' ? dropDownStudentItems :
        mode === 'teacherPayment' ? teacher :
        mode === 'officePayment' ? manager :
        mode === 'bossPayment' ? boss : []
         "
        [formFieldName]="'personInQuestionId'"
        [hint]="
        mode === 'studentPayment' ? ('students.page-title' | translate) :
        mode === 'teacherPayment' ?('payments.new-payment.teacher-label' | translate) :
        mode === 'officePayment' ?('payments.new-payment.manager-label' | translate) :
        mode === 'bossPayment' ?('payments.new-payment.tenant-admin-label' | translate) : ''
"
      ></app-dropdown>

      <!-- Wallet amount of students and teachers only  -->
      <h2 *ngIf="walletAmount !== null" style="margin-block: 0; margin-left: auto">
        Kontostand: {{ walletAmount | number: '1.2-2' }} €
      </h2>

      <!--  dropdown to select the tenant location  -->
      <app-dropdown
        (emitChangeEvent)="onLocationChange($event)"
        [dropDownItems]="dropDownLocationItems"
        [formFieldName]="'location'"
        [hint]="'location.location-detail.breadcrumb-item-caption' | translate"
      >
      </app-dropdown>

      <!--  type of transaction  -->
      <app-dropdown
        [dropDownItems]="
        mode === 'studentPayment' ? dropdownTransactionTypeStudent :
       mode === 'teacherPayment' ? dropdownTransactionTypeTeacher :
       mode === 'officePayment' ? dropdownTransactionTypeOffice :
       mode === 'bossPayment' ? dropdownTransactionTypeBoss : []
      "
        [formFieldName]="'transactionType'"
        [hint]="'payments.new-payment.type-of-payment' | translate"
      >
      </app-dropdown>

      <!--  input of the amount of payment  -->
      <app-input-payment-amount
        [fieldName]="'amount'"
        [hint]="'payments.new-payment.payment-amount' | translate"
        [minValue]="isBossInventory() ? 0 : 1"
      >
      </app-input-payment-amount>

      <!--  text input where the user writes a comment if wanted  -->
      <app-input-large-text
        [fieldName]="'comment'"
        [hint]="'payments.new-payment.comment' | translate"
      >
      </app-input-large-text>


      <!--   file input ~ example: bill as pdf   -->
      <tui-input-files
        *ngIf="!fileControl.value"
        [formControl]="fileControl"
        class="control"
      >
        <ng-template let-dragged>
          <div
            *ngIf="dragged; else base"
            class="content"
          >
            <tui-marker-icon
              mode="link"
              src="tuiIconDropletLarge"
            ></tui-marker-icon>
            <div>{{ 'payments.new-payment.file-upload-drop' | translate }}</div>
          </div>
          <ng-template #base>
            <div class="content">
              <tui-marker-icon
                mode="link"
                src="tuiIconUploadCloudLarge"
              ></tui-marker-icon>
              <div>
                {{ 'payments.new-payment.file-upload-info' | translate }}
              </div>
            </div>
          </ng-template>
        </ng-template>
      </tui-input-files>

      <tui-files>
        <tui-file
          *ngIf="fileControl.valueChanges | async as file"
          [file]="file"
          [leftContent]="content"
          [showDelete]="fileControl.enabled"
          (removed)="removeFile()"
        >
        </tui-file>
      </tui-files>

      <ng-template #content>
        <tui-svg [src]="tuiIconFile"></tui-svg>
      </ng-template>


      <!--  submit + cancel button  -->
      <div class="button-wrapper buttons">
        <button
          (click)="createNewPayment()"
          [disabled]="!newPaymentForm.valid && !fileControl.valid"
          size="m"
          tuiButton
          type="submit">
          {{ 'payments.new-payment.create-new-payment' | translate }}
        </button>
        <button
          (click)="goBack()"
          appearance="secondary"
          size="m"
          tuiButton
        >
          {{ 'common.button-cancel' | translate }}
        </button>
      </div>
    </form>
  </div>


  <app-payment-menu-dropdown></app-payment-menu-dropdown>

</div>


