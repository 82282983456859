import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})

/**
 * Component for the icon bubble in the tenant detail page
 * @Input icon: the icon to be displayed
 */
export class IconComponent {
  @Input() icon: any;
}
