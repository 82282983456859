import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { tuiIconEdit } from '@taiga-ui/icons';
import { TenantService } from '../../../services/tenant.service';
import { AuthService } from '../../../services/auth.service';
import { PushService } from '../../../services/push.service';
import { pushTypes } from '../../../enums/push-types';
import { DropDownItem } from '../../../interfaces/drop-down-item';
import { Languages } from '../../../enums/languages';
import { LanguageService } from '../../../services/language.service';
import { SuperAdminService } from '../../../services/super-admin.service';

/**
 * Component for the profile tab in the settings page.
 * Displays the tenants profile information and offers the possibility to edit the tenant.
 * TODO: Only displays the user's name at the moment since there are no other tenant information.
 */
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  protected readonly tuiIconEdit = tuiIconEdit;
  isInEditMode: boolean = false;

  // strings for i18n
  pushNotificationPatchedSuccess: string = '';
  pushNotificationPatchedError: string = '';

  germanLanguage!: string;
  englishLanguage!: string;

  languageDropdownItems: DropDownItem[] = [];

  readonly languageForm = new FormGroup({
    language: new FormControl(),
  });

  readonly tenantProfileForm = new FormGroup({
    tenantName: new FormControl(''),
    // ... more tenant information
  });

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private tenantService: TenantService,
    private pushService: PushService,
    private languageService: LanguageService,
    private superAdminService: SuperAdminService,
  ) {}

  ngOnInit(): void {
    this.tenantProfileForm.disable();
    this.getTenant();

    // translated strings for push-notifications
    this.translate
      .get([
        'settings-general.push-success-patched',
        'settings-general.push-error-patched',
        'settings.language-german',
        'settings.language-english',
      ])
      .subscribe((translations) => {
        this.pushNotificationPatchedSuccess =
          translations['settings-general.push-success-patched'];
        this.pushNotificationPatchedError =
          translations['settings-general.push-error-patched'];
        this.germanLanguage = translations['settings.language-german'];
        this.englishLanguage = translations['settings.language-english'];

        this.initLanguageFormValue();
      });

    // subscribes to dropdown value changes
    this.languageForm.controls.language.valueChanges.subscribe((language) => {
      this.languageService.setLanguage(language.id);
    });
  }

  /**
   * Toggles the edit mode of the form to edit or delete the user
   */
  toggleEdit(): void {
    if (this.tenantProfileForm.disabled) {
      this.tenantProfileForm.enable();
    } else {
      this.tenantProfileForm.disable();
    }
    this.isInEditMode = !this.isInEditMode;
  }

  /**
   * Changes the language of the application.
   * Stores the selected language in the local storage.
   * @param selectedLang the selected language by the user
   */
  changeLanguage(selectedLang: string): void {
    if (selectedLang === 'en') {
      this.translate.use('en');
      localStorage.setItem('language', 'en');
    } else {
      this.translate.use('de');
      localStorage.setItem('language', 'de');
    }
  }

  /**
   * Gets the tenant of the logged in user and pre-fills the form with the tenant name.
   * TODO: Only displays the tenants name at the moment since there are no other tenant information.
   */
  getTenant(): void {
    if (!this.isSuperAdmin()) {
      this.tenantService
        .getTenantById(this.authService.getLoggedInUser()?.tenantId!)
        .subscribe((tenant) => {
          this.tenantProfileForm.setValue({
            tenantName: tenant.name,
          });
        });
    }
  }

  /**
   * Uses the auth service to check if the logged-in user is a super admin.
   */
  isSuperAdmin(): boolean | undefined {
    return this.superAdminService.isSuperAdmin();
  }

  /**
   * Edits the tenant of the logged in user by sending a patch request to the api
   * via the tenant service and the tenant id.
   * If the patch request was successful, a success push notification is sent.
   * If the patch request was not successful, a error push notification is sent.
   */
  updateTenant(): void {
    this.tenantService
      .updateTenant(this.authService.getLoggedInUser()?.tenantId!, {
        name: this.tenantProfileForm.controls['tenantName'].value!,
      })
      .subscribe((success) => {
        if (success) {
          this.pushService.sendPush(
            pushTypes.SUCCESS,
            this.pushNotificationPatchedSuccess,
          );
        } else {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationPatchedSuccess,
          );
        }
        // grey out the form again
        this.tenantProfileForm.disable();
      });
  }

  initLanguageFormValue(): void {
    const language = localStorage.getItem('language');
    switch (language) {
      case Languages.ENGLISH: {
        this.languageForm.controls.language.setValue({
          id: Languages.ENGLISH,
          label: this.englishLanguage,
        });
        break;
      }
      case Languages.GERMAN: {
        this.languageForm.controls.language.setValue({
          id: Languages.GERMAN,
          label: this.germanLanguage,
        });
        break;
      }
      default: {
      }
    }
  }
}
