<app-menubar
  [title]="'create-user.title' | translate"
  [description]="'create-user.description' | translate"
  [open]="isOpen"
  (closingEvent)="setCloseEvent()"
>
  <form [formGroup]="newUserForm">
    <app-input-text [fieldName]="'firstName'" [hint]="'create-user.input-label-first-name' | translate" [required]="false"></app-input-text>
    <app-input-text [fieldName]="'lastName'" [hint]="'create-user.input-label-last-name' | translate" [required]="false"></app-input-text>
    <app-input-phone [fieldName]="'telephone'" [hint]="'create-user.input-label-telephone' | translate" [required]="false"></app-input-phone>
    <app-input-email [fieldName]="'email'" [hint]="'create-user.input-label-mail' | translate"></app-input-email>
    <app-role-dropdown [fieldName]="'role'" [formGroup]="newUserForm" (rolesFetchEvent)="setRoles($event)" ></app-role-dropdown>
    <app-location-dropdown
      *ngIf="selectedRole?.userRole !== UserRole.TENANT_ADMIN"
      [fieldName]="'locationId'"
      [formGroup]="newUserForm"
      [objectWithLocation]="getLoggedInUser()"
      [required]="false"
      [locationTooltip]="'create-user.location-tooltip' | translate"
    >
    </app-location-dropdown>
    <div class="button-wrapper">
      <button
        tuiButton
        size="m"
        [disabled]="!newUserForm.valid"
        type="submit"
        (click)="createNewUser()">
        {{ 'create-user.button-add' | translate }}
      </button>
      <button
        tuiButton
        size="m"
        appearance="secondary"
        (click)="setCloseEvent()">
        {{ 'common.button-cancel' | translate }}
      </button>
    </div>
  </form>
</app-menubar>
