/**
 * abstract BaseModel class that will be extended by the other models
 */
export abstract class BaseModel {
  private _id: string;
  private _createdAt: Date;
  private _updatedAt: Date;

  protected constructor(id: string, createdAt: Date, updatedAt: Date) {
    this._id = id;
    this._createdAt = createdAt;
    this._updatedAt = updatedAt;
  }

  // abstract toJSON(): any;
  // abstract fromJSON(json: any): any;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  set updatedAt(value: Date) {
    this._updatedAt = value;
  }
}
