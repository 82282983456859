import { Component } from "@angular/core";
import { Role } from "../../../models/role";
import { User } from "../../../models/user";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { AuthService } from "../../../services/auth.service";
import { PushService } from "../../../services/push.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "../../../services/loading.service";
import { HttpStatusCode } from "@angular/common/http";
import { pushTypes } from "../../../enums/push-types";
import { NavRoutes } from "../../../enums/nav-routes";
import { LessonService } from "../../../services/lesson.service";
import { Lesson } from "../../../models/lesson";
import { DropDownItem } from "../../../interfaces/drop-down-item";
import { Student } from "../../../models/student";
import { UtilService } from "../../../services/util.service";
import { TuiDay, TuiTime } from "@taiga-ui/cdk";
import { LessonBody } from "../../../interfaces/body/lesson-body";
import { LessonStatus } from "../../../enums/lesson-status";
import { tuiIconCalendarLarge, tuiIconTagLarge, tuiIconTrash, tuiIconUserLarge, } from "@taiga-ui/icons";
import { SuperAdminService } from "../../../services/super-admin.service";
import { RoleService } from "../../../services/role.service";
import { UserRole } from "../../../enums/user-role";
import { LicenceType } from "../../../models/licence-type";
import { LessonCategory } from "../../../models/lesson-category";
import { LicenceTypeService } from "../../../services/licence-type.service";
import { CalendarService } from "../../../services/calendar.service";
import { LicenceTarget } from "../../../models/licence-target";
import { DateTime } from "luxon";

@Component({
  selector: "app-event-detail-page",
  templateUrl: "./event-detail-page.component.html",
  styleUrls: ["./event-detail-page.component.scss"],
})
export class EventDetailPageComponent {
  // To display the available explanation in the dropdown
  roles: Role[] = [];

  // to determine if the dialog is open or not
  showDeleteDialog: boolean = false;
  isOpen = true;
  isCanceled = false;

  lesson!: Lesson;
  lessonId: string = "";
  lessonDate: string = "";
  user!: User;
  today: TuiDay = TuiDay.currentLocal();

  // for the dropdown list of all teachers
  teachers: User[] = [];
  teacherName = "";
  dropDownUserItems: DropDownItem[] = [];

  // for the dropdown list of all students
  students: Student[] = [];
  studentName = "";
  selectedStudentId = "";
  dropDownStudentItems: DropDownItem[] = [];

  // licence types
  licenceTypes: LicenceType[] = [];
  licenceTypeDropdownItems: DropDownItem[] = [];

  // licence targets
  licenceTargetsForType: LicenceTarget[] = [];

  // categories
  categories: LessonCategory[] = [];
  categoryDropdownItems: DropDownItem[] = [];

  lessonPrice: number = 0;
  signUrl: string = "";

  isLoading$ = this.loadingService.getLoadingState(); // checks if the request is taking longer than 250ms
  sendRequest = false;

  // Strings for i18n
  pushNotificationPatchedSuccess: string = "";
  pushNotificationPatchedError: string = "";
  pushNotificationCancelSuccess: string = "";
  pushNotificationCancelError: string = "";
  pushNotificationDeletedSuccess: string = "";
  pushNotificationDeletedError: string = "";
  pushNotificationsConflictError: string = "";
  pushNotificationCreatedConflictNotEnoughCredit: string = "";
  readonly lessonForm: FormGroup = new FormGroup({
    startDate: new FormControl(null, [Validators.required]),
    startTime: new FormControl(null, [Validators.required]),
    endTime: new FormControl(null, [Validators.required]),
    location: new FormControl(null),
    category: new FormControl(null, [Validators.required]),
    licenceType: new FormControl(null, [Validators.required]),
    assignedToTeacher: new FormControl(null, [Validators.required]),
    assignedToStudent: new FormControl(null, [Validators.required]),
    userNotification: new FormControl(true),
    studentNotification: new FormControl(true),
    counter: new FormControl(1, [
      Validators.required,
      Validators.min(1),
      Validators.max(3),
    ]),
  });
  readonly comment = new FormControl("");
  readonly cancelForm: FormGroup = new FormGroup({
    cancelReason: new FormControl(null, [Validators.required]),
    userNotification: new FormControl(true),
    studentNotification: new FormControl(true),
  });
  protected readonly LessonStatus = LessonStatus;
  protected readonly tuiIconUserLarge = tuiIconUserLarge;
  protected readonly tuiIconCalendarLarge = tuiIconCalendarLarge;
  protected readonly tuiIconTagLarge = tuiIconTagLarge;
  protected readonly tuiIconTrash = tuiIconTrash;
  protected readonly TuiDay = TuiDay;

  constructor(
    private authService: AuthService,
    private pushService: PushService,
    private translateService: TranslateService,
    private router: Router,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private lessonService: LessonService,
    private userService: UserService,
    private utilService: UtilService,
    private superAdminService: SuperAdminService,
    private roleService: RoleService,
    private licenceTypeService: LicenceTypeService,
    private calendarService: CalendarService,
  ) {
  }

  // get array of numbers filled with numbers from 1 to maxCounter for the counter dropdown
  get counterItems(): number[] {
    return Array(this.calendarService.maxLessonCounterValue)
      .fill(0)
      .map((x, i) => i + 1);
  }

  get isSigned(): boolean {
    return this.lesson.studentSignId != null && this.lesson.studentSignId != "";
  }

  get isActive(): boolean {
    return this.lesson.status !== LessonStatus.INACTIVE;
  }

  get hasNotes(): boolean {
    return this.lesson.note != null && this.lesson.note.length > 0;
  }

  get isStartDateBeforeCurrentDate(): boolean {
    const currentDateTime = DateTime.local();
    const lessonStartDate = DateTime.fromJSDate(this.lesson.startDate).minus({
      hours: 2,
    });

    return lessonStartDate < currentDateTime;
  }

  ngOnInit(): void {
    this.getEventWithRouteParam();

    // translated strings for push-notifications
    this.translateService
      .get([
        "event-detail.push-success-patched",
        "event-detail.push-error-patched",
        "event-detail.push-success-deleted",
        "event-detail.push-error-deleted",
        "event-detail.push-conflict-patched",
        "new-event.push-conflict-credit",
      ])
      .subscribe((translations) => {
        this.pushNotificationPatchedSuccess =
          translations["event-detail.push-success-patched"];
        this.pushNotificationPatchedError =
          translations["event-detail.push-error-patched"];
        this.pushNotificationDeletedSuccess =
          translations["event-detail.push-success-deleted"];
        this.pushNotificationDeletedError =
          translations["event-detail.push-error-deleted"];
        this.pushNotificationCancelSuccess =
          translations["event-detail.push-success-canceled"];
        this.pushNotificationCancelError =
          translations["event-detail.push-error-canceled"];
        this.pushNotificationsConflictError =
          translations["event-detail.push-conflict-patched"];
        this.pushNotificationCreatedConflictNotEnoughCredit =
          translations["new-event.push-conflict-credit"];
      });
  }

  ngOnChanges(): void {
    // reset the form validation if the modal closes
    this.lessonForm.markAsUntouched();
  }

  ngOnDestroy(): void {
  }

  /**
   * Submits the form values to the api in order to update the user
   * Sends a push notification if the user was updated successfully or not
   */
  submitForm(): void {
    if (!this.sendRequest && (this.lessonForm.valid || this.isSigned)) {
      this.sendRequest = true;
      let lessonBody: LessonBody;

      if (!this.isSigned) {
        // construct the lesson body based on the form values
        lessonBody = {
          tenantId: this.lesson.tenantId,
          startDate: this.utilService.getFormattedDateByTuiDate(
            this.lessonForm.controls["startDate"].value! as TuiDay,
            this.lessonForm.controls["startTime"].value! as TuiTime,
          ),
          endDate: this.utilService.calculateEndDay(
            this.lessonForm.controls["startTime"].value! as TuiTime,
            this.lessonForm.controls["endTime"].value! as TuiTime,
            this.lessonForm.controls["startDate"].value! as TuiDay,
          ),
          studentId: this.lesson.studentId,
          userId: this.lessonForm.controls["assignedToTeacher"].value!?.["id"],
          lessonCategoryId:
            this.lessonForm.controls?.["category"].value!?.["id"],
          status: LessonStatus.ACTIVE,
          cancelReason: "",
          locationId: this.lesson.locationId, // location is not editable
          licenceTypeId: this.lesson.licenceTypeId,
          count: this.lessonForm.controls["counter"].value!,
          price: 0,
        };
      } else {
        lessonBody = {
          tenantId: this.lesson.tenantId,
          startDate: this.lesson.startDate,
          endDate: this.lesson.endDate,
          studentId: this.lesson.studentId,
          userId: this.lesson.userId,
          lessonCategoryId: this.lesson.lessonCategoryId,
          status: this.lesson.status,
          cancelReason: this.lesson.cancelReason,
          locationId: this.lesson.locationId,
          licenceTypeId: this.lesson.licenceTypeId,
          count: this.lesson.count,
          price: 0,
          note: this.comment.value ?? "",
        };
      }

      this.lessonService
        .updateLesson(
          lessonBody,
          this.lessonId,
          this.lessonForm.controls["userNotification"].value!,
          this.lessonForm.controls["studentNotification"].value!,
        )
        .subscribe(
          () => {
            // success
            this.setCloseEvent();
            this.pushService.sendPush(
              pushTypes.SUCCESS,
              this.pushNotificationPatchedSuccess,
            );
          },
          (error) => {
            // error
            if (
              error.status == HttpStatusCode.Conflict &&
              (error.error.key == "STUDENT_COLLISION" ||
                error.error.key == "TEACHER_COLLISION")
            ) {
              this.setCloseEvent();
              this.pushService.sendPush(
                pushTypes.ERROR,
                this.pushNotificationsConflictError,
              );
            } else if (
              error.status == HttpStatusCode.Conflict &&
              error.error.key == "NOT_ENOUGH_CREDIT"
            ) {
              this.setCloseEvent();
              this.pushService.sendPush(
                pushTypes.ERROR,
                this.pushNotificationCreatedConflictNotEnoughCredit,
              );
            } else {
              this.setCloseEvent();
              this.pushService.sendPush(
                pushTypes.ERROR,
                this.pushNotificationPatchedError,
              );
            }
          },
        );
    } else if (this.lessonForm.invalid) {
      this.lessonForm.markAllAsTouched();
    }
  }

  submitCancelForm(): void {
    if (this.cancelForm.valid && !this.sendRequest) {
      this.sendRequest = true;
      const lessonBody: LessonBody = {
        tenantId: this.lesson.tenantId,
        startDate: this.lesson.startDate,
        endDate: this.lesson.endDate,
        studentId: this.lesson.studentId,
        userId: this.lesson.userId,
        lessonCategoryId: this.lesson.lessonCategoryId,
        status: LessonStatus.INACTIVE,
        cancelReason: this.cancelForm.controls["cancelReason"].value,
        locationId: this.lesson.locationId,
        licenceTypeId: this.lesson.licenceTypeId,
        count: this.lesson.count,
        price: 0,
      };

      this.lessonService
        .updateLesson(
          lessonBody,
          this.lessonId,
          this.cancelForm.controls["userNotification"].value!,
          this.cancelForm.controls["studentNotification"].value!,
        )
        .subscribe(
          () => {
            this.setCloseEvent();
            this.pushService.sendPush(
              pushTypes.SUCCESS,
              this.pushNotificationCancelSuccess,
            );
          },
          () => {
            this.setCloseEvent();
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationCancelError,
            );
          },
        );
    }
  }

  /**
   * Gets the user from the api with the id from the route parameter
   * Sets the form values to the user values in order to edit them
   */
  public getEventWithRouteParam(): void {
    this.route.paramMap.subscribe((params) => {
      this.lessonId = params.get("id")!;
      if (this.lessonId != null) {
        this.lessonService.getLessonById(this.lessonId).subscribe(
          (lesson) => {
            this.lesson = lesson;
            this.teacherName = this.utilService.getFormattedName(
              this.lesson.user.firstName,
              this.lesson.user.lastName,
            );
            this.studentName = this.utilService.getFormattedName(
              this.lesson.student.firstName,
              this.lesson.student.lastName,
            );
            this.lessonDate =
              this.lesson.startDate.toLocaleDateString() +
              " " +
              this.utilService.getFormattedTimeByDate(this.lesson.startDate) +
              " bis " +
              this.utilService.getFormattedTimeByDate(this.lesson.endDate);
            // fill the form with the lessons values
            this.lessonForm.controls["startDate"].setValue(
              TuiDay.fromLocalNativeDate(this.lesson.startDate),
            );
            this.lessonForm.controls["startTime"].setValue(
              TuiTime.fromString(
                this.utilService.getFormattedTimeByDate(this.lesson.startDate),
              ),
            );
            this.lessonForm.controls["counter"].setValue(this.lesson.count);
            this.lessonForm.controls["endTime"].setValue(
              TuiTime.fromString(
                this.utilService.getFormattedTimeByDate(this.lesson.endDate),
              ),
            );
            this.lessonForm.controls["assignedToTeacher"].setValue({
              id: this.lesson.userId,
              label: this.utilService.getFormattedName(
                this.lesson.user.firstName,
                this.lesson.user.lastName,
              ),
            });
            this.lessonForm.controls["assignedToStudent"].setValue({
              id: this.lesson.studentId,
              label: this.utilService.getFormattedName(
                this.lesson.student.firstName,
                this.lesson.student.lastName,
              ),
            });
            this.lessonForm.controls["licenceType"].setValue({
              id: this.lesson.licenceTypeId,
              label: this.lesson.licenceType.name,
            });

            this.lessonForm.controls["category"].setValue({
              id: this.lesson.lessonCategoryId,
              label: this.lesson.lessonCategory.name,
            });
            this.comment.setValue(this.lesson.note);
            this.lessonPrice = this.lesson.price;

            if (
              this.lesson.studentSignId != null ||
              (this.lesson?.status != null &&
                this.lesson.status === LessonStatus.INACTIVE)
            ) {
              // disable lesson form for inactive or signed lessons
              this.lessonForm.disable();
            } else {
              // disable form fields that are not editable and get the initial dropdown items
              this.lessonForm.controls["assignedToStudent"].disable();
              this.lessonForm.controls["licenceType"].disable();
              this.lessonForm.controls["endTime"].disable();
              this.getInitialDropdownItems();
            }

            if (this.hasNotes) {
              this.comment.setValue(this.lesson.note);
            }

            if (this.isSigned) {
              this.getSignImage();
            }
          },
          (error) => {
            this.router.navigate([NavRoutes.ERROR], {
              queryParams: {
                code: error.error.code,
                key: error.error.key,
                url: error.url,
              },
            });
          },
        );
      }
    });
  }

  getSignImage() {
    if (this.lesson.studentSignId == null) return;
    this.lessonService
      .getSignFilesById(this.lesson.studentSignId)
      .subscribe((response: any) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.signUrl = reader.result as string;
        };
        reader.readAsDataURL(response);
      });
  }

  onCategoryChanges(value: DropDownItem | null) {
    if (value == null) return;

    this.setPriceWithCounter();
  }

  onCounterChange(value: number | null) {
    if (value == null) return;

    this.setEndTimeWithCounter();
    this.setPriceWithCounter();
  }

  onStartDateTimeChange(value: TuiTime | null) {
    if (value == null) return;

    // when the date is today and the time is in the past, set the time to the current time
    if (
      this.utilService.datesEqualUpToDay(
        new Date(),
        (
          this.lessonForm.controls["startDate"].value as TuiDay
        ).toLocalNativeDate(),
      )
    ) {
      if (
        (this.lessonForm.controls["startTime"].value as TuiTime) <
        TuiTime.currentLocal()
      ) {
        this.lessonForm.controls["startTime"].setValue(TuiTime.currentLocal());
      }
    }

    this.setEndTimeWithCounter();
  }

  setPriceWithCounter() {
    const counter = this.lessonForm.controls["counter"].value as number;
    const categoryId = this.lessonForm.controls["category"].value?.id as string;

    const licenceTarget = this.licenceTargetsForType.find(
      (target) => target.lessonCategoryId === categoryId,
    );
    if (licenceTarget == null) return;

    this.lessonPrice = licenceTarget.price * counter;
  }

  getCategoriesForLicenceType(licenceTypeId: string) {
    this.licenceTypeService
      .getLicenceTypeById(licenceTypeId)
      .subscribe((licenceType) => {
        this.categories = [];
        this.categoryDropdownItems = [];
        this.licenceTargetsForType = licenceType.targets;
        for (let target of licenceType.targets) {
          if (target != null) {
            this.categories.push(<LessonCategory>target.lessonCategory);
          }
        }

        const lessonCategoryInList = this.categories.find(
          (category) =>
            category.id === this.lessonForm.controls["category"].value?.["id"],
        );

        if (!lessonCategoryInList) {
          this.lessonForm.controls["category"].setValue(null);
        }

        this.categoryDropdownItems = this.utilService.generateDropdownItems(
          this.categories,
        );
      });
  }

  deleteEvent(): void {
    if (this.sendRequest) return;
    this.sendRequest = true;
    this.lessonService.deleteLesson(this.lesson.id).subscribe((isDeleted) => {
      if (isDeleted) {
        this.pushService.sendPush(
          pushTypes.SUCCESS,
          this.pushNotificationDeletedSuccess,
        );
        this.setCloseEvent();
      } else {
        this.pushService.sendPush(
          pushTypes.ERROR,
          this.pushNotificationDeletedError,
        );
        this.setCloseEvent();
      }
    });
  }

  /**
   * Gets the teachers and students from the api to fill the dropdown menu.
   * The teacher and student objects are mapped to DropDownItems to display them in the dropdown menu
   */
  getInitialDropdownItems() {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();

      if (selectedTenantId == null) return;
      // get user for tenant
      this.getUserForTenantAndLocation(selectedTenantId);
    } else {
      this.getUserForTenantAndLocation(
        this.authService.getLoggedInUser()?.tenantId,
      );
    }

    this.getCategoriesForLicenceType(this.lesson.licenceTypeId);
  }

  getUserForTenantAndLocation(tenantId?: string) {
    this.dropDownUserItems = [];
    this.roleService.getRoles({ tenantId: tenantId }).subscribe((roles) => {
      if (roles.length > 0) {
        const teacherRoleId = roles.find(
          (role) => role.userRole === UserRole.TEACHER,
        )?.id;
        const tenantAdminRoleId = roles.find(
          (role) => role.userRole === UserRole.TENANT_ADMIN,
        )?.id;

        if (teacherRoleId && tenantAdminRoleId) {
          this.userService
            .getUsers({
              tenantId: tenantId,
              roleId: teacherRoleId,
              active: true,
            })
            .subscribe((teachers) => {
              this.teachers = teachers;

              this.teachers = teachers.filter(
                (teacher) =>
                  teacher.locationId === this.lesson.locationId ||
                  teacher.locationId == null,
              );

              this.dropDownUserItems = this.dropDownUserItems.concat(
                this.utilService.generateDropdownItems(this.teachers),
              );
            });
          this.userService
            .getUsers({
              tenantId: tenantId,
              roleId: tenantAdminRoleId,
              active: true,
            })
            .subscribe((admins) => {
              if (admins.length === 0) return;
              this.dropDownUserItems = this.dropDownUserItems.concat(
                this.utilService.generateDropdownItems(admins),
              );
            });
        }
      }
    });
  }

  setEndTimeWithCounter() {
    const startDate = this.lessonForm.controls["startDate"].value as TuiDay;
    const startTime = this.lessonForm.controls["startTime"].value as TuiTime;
    const counter = this.lessonForm.controls["counter"].value as number;

    const startJSDate = new Date(
      startDate.year,
      startDate.month,
      startDate.day,
      startTime.hours,
      startTime.minutes,
    );

    const endJSDate = new Date(startJSDate);

    endJSDate.setMinutes(endJSDate.getMinutes() + counter * 45);

    this.lessonForm.controls["endTime"].setValue(
      new TuiTime(endJSDate.getHours(), endJSDate.getMinutes()),
    );
  }

  /**
   * Generates the dropdown items for the role dropdown menu
   */
  generateRoleDropdownItems(items: any[]) {
    let dropDownItems: DropDownItem[] = [];
    items.map((item) => {
      dropDownItems.push({ id: item.id, label: item.name });
    });
    return dropDownItems;
  }

  /**
   * Closes the dialog and navigates back to the user page
   */
  setCloseEvent() {
    this.sendRequest = false;
    this.router.navigate([NavRoutes.CALENDAR]);
    this.isOpen = false;
  }
}
