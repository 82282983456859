import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpStatusCode } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ApiRoutes } from "../enums/api-routes";
import { PaginationFilterService } from "./pagination-filter.service";
import { catchError, map, Observable, of } from "rxjs";
import { User } from "../models/user";
import { UserBody } from "../interfaces/body/user-body";
import { UserQueryParams } from "../interfaces/query-param/user-query-params";
import { ResponseWithRecordsBody } from "../interfaces/body/response-with-recors-body";
import { GlobalUserBody } from "../interfaces/body/global-user-body";

@Injectable({
  providedIn: "root",
})
/**
 * Service Class for the getting or manipulating user data
 */
export class UserService extends PaginationFilterService {
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * get the user data from the api
   * sets query params for the api call if they are passed in
   * sets the totalAmount property of the parent PaginationFilterService if data is returned
   * maps the response to an array of observable user objects with the User.fromJson method
   * @param queryParams optional query params for the api call
   */
  getUsers(queryParams?: UserQueryParams): Observable<User[]> {
    let params: HttpParams = new HttpParams();

    switch (this.filter.tabIndex) {
      case 1:
        params = params.set("active", "true");
        break;
      case 2:
        params = params.set("active", "false");
        break;
    }

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    if (this.sort !== undefined && this.sort !== "") {
      params = params.set("sort", this.sort);
    }

    if (this.endIndex !== "") {
      params = params.set("limit", this.endIndex);
    }

    if (this.startIndex !== "") {
      params = params.set("skip", this.startIndex);
    }

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + ApiRoutes.USER, { params })
      .pipe(
        map((response) => {
          this.totalAmount = response.total;
          return response.records.map((user: any) => User.fromJson(user));
        }),
      );
  }

  /**
   * get user count from the api
   */
  getUsersCount(): number {
    return this.totalAmount;
  }

  /**
   * get detailed user data from the api with a user id
   * @param id the id of the user to get
   */
  getUserById(id: string): Observable<User> {
    return this.http.get<any>(this.baseUrl + ApiRoutes.USER + "/" + id).pipe(
      map((response) => {
        return User.fromJson(response);
      }),
    );
  }

  /**
   * Sends a request to the api to create a new user
   * @param user the user to create as UserBody
   */
  createUser(user: UserBody): Observable<number> {
    return this.http
      .post<any>(this.baseUrl + ApiRoutes.USER, user, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status;
        }),
        catchError((error) => {
          return of(error.status);
        }),
      );
  }

  createGlobalUser(globalUser: GlobalUserBody): Observable<boolean> {
    return this.http
      .post<any>(this.baseUrl + ApiRoutes.USER, globalUser, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  /**
   * Sends a request to the api to patch a user
   * @param user the user to update as UserBody
   * @param id the id of the user to update
   */
  updateUser(user: UserBody, id: string): Observable<number> {
    return this.http
      .patch<any>(this.baseUrl + ApiRoutes.USER + "/" + id, user, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status;
        }),
        catchError((error) => {
          return of(error.status);
        }),
      );
  }

  /**
   * Deletes a user from the API
   * @param id the id of the user to delete
   * @returns true if the user was deleted successfully, false if not
   */
  deleteUser(id: string): Observable<boolean> {
    return this.http
      .delete<any>(this.baseUrl + ApiRoutes.USER + "/" + id, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }
}
