import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { LessonQueryParams } from '../interfaces/query-param/lesson-query-params';
import { catchError, map, Observable, of } from 'rxjs';
import { LessonCategory } from '../models/lesson-category';
import { ResponseWithRecordsBody } from '../interfaces/body/response-with-recors-body';
import { ApiRoutes } from '../enums/api-routes';
import { LessonCategoryBody } from '../interfaces/body/lesson-category-body';
import { PaginationFilterService } from './pagination-filter.service';
import { LicenceTypeBody } from '../interfaces/body/licence-type-body';
import { LicenceType } from '../models/licence-type';

@Injectable({
  providedIn: 'root',
})
export class LicenceTypeService extends PaginationFilterService {
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  getLicenceType(queryParams?: LessonQueryParams): Observable<LicenceType[]> {
    const params = new HttpParams()
      .set(
        queryParams?.tenantId !== undefined ? 'tenantId' : '',
        queryParams?.tenantId ?? '',
      )
      .set(
        queryParams?.name !== undefined ? 'name' : '',
        queryParams?.name ?? '',
      )
      .set(this.sort !== undefined ? 'sort' : '', this.sort ?? '')
      .set(this.endIndex !== '' ? 'limit' : '', this.endIndex)
      .set(this.startIndex !== '' ? 'skip' : '', this.startIndex);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + ApiRoutes.LICENCE_TYPE, {
        params,
      })
      .pipe(
        map((response) => {
          this.totalAmount = response.total;
          return response.records.map((response) => {
            return LicenceType.fromJson(response);
          });
        }),
      );
  }

  getLicenceTypeById(id: string): Observable<LicenceType> {
    return this.http
      .get<any>(this.baseUrl + ApiRoutes.LICENCE_TYPE + '/' + id)
      .pipe(
        map((response) => {
          return LicenceType.fromJson(response);
        }),
      );
  }

  createLicenceType(
    licenceTypeBody: LicenceTypeBody,
  ): Observable<number | LicenceType> {
    return this.http
      .post<any>(this.baseUrl + ApiRoutes.LICENCE_TYPE, licenceTypeBody, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          if (response.status === HttpStatusCode.Ok) {
            return LicenceType.fromJson(response.body);
          }
          return response.status;
        }),
        catchError((error) => {
          return of(error.status);
        }),
      );
  }

  updateLicenceType(
    licenceTypeBody: LicenceTypeBody,
    id: string,
  ): Observable<number> {
    return this.http
      .patch<any>(
        this.baseUrl + ApiRoutes.LICENCE_TYPE + '/' + id,
        licenceTypeBody,
        {
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          return response.status;
        }),
        catchError((error) => {
          return of(error.status);
        }),
      );
  }

  deleteLicenceType(id: string): Observable<boolean> {
    return this.http
      .delete<any>(this.baseUrl + ApiRoutes.LICENCE_TYPE + '/' + id, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }
}
