import { Component } from '@angular/core';
import { tuiIconTrash, tuiIconX, tuiIconXLarge } from '@taiga-ui/icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PushService } from '../../../services/push.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../services/loading.service';
import { LocationService } from '../../../services/location.service';
import { Location } from '../../../models/location';
import { NavRoutes } from '../../../enums/nav-routes';
import { pushTypes } from '../../../enums/push-types';
import { LocationBody } from '../../../interfaces/body/location-body';
import { UserRole } from '../../../enums/user-role';
import { AuthService } from '../../../services/auth.service';
import { phoneValidator } from '../../../validators/phone-validator';

@Component({
  selector: 'app-location-detail-page',
  templateUrl: './location-detail-page.component.html',
  styleUrls: ['./location-detail-page.component.scss'],
})
/**
 * Component for the user profile page in which the user can be edited or deleted
 */
export class LocationDetailPageComponent {
  locationId = '';
  location!: Location;
  isLoading$ = this.loadingService.getLoadingState();
  sendRequest = false;

  // to determine if the dialog is open or not
  isOpen = true;
  showDeleteDialog: boolean = false;

  // Strings for i18n
  pushNotificationPatchedSuccess: string = '';
  pushNotificationPatchedError: string = '';
  pushNotificationDeletedSuccess: string = '';
  pushNotificationDeletedError: string = '';

  // icons
  protected readonly tuiIconTrash = tuiIconTrash;
  protected readonly tuiIconX = tuiIconX;

  readonly locationForm: FormGroup = new FormGroup({
    name: new FormControl(``, [Validators.required]),
    houseNumber: new FormControl(``, [Validators.required]),
    street: new FormControl(``, [Validators.required]),
    postalCode: new FormControl(``, [
      Validators.required,
      Validators.maxLength(5),
    ]),
    city: new FormControl(``, [Validators.required]),
    phone: new FormControl('', [Validators.required, phoneValidator()]),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private locationService: LocationService,
    private pushService: PushService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.getLocationWithRouteParam();

    // translated strings for push-notifications
    this.translateService
      .get([
        'location.location-detail.push-success-patched',
        'location.location-detail.push-error-patched',
        'location.location-detail.push-success-deleted',
        'location.location-detail.push-error-deleted',
      ])
      .subscribe((translations) => {
        this.pushNotificationPatchedSuccess =
          translations['location.location-detail.push-success-patched'];
        this.pushNotificationPatchedError =
          translations['location.location-detail.push-error-patched'];
        this.pushNotificationDeletedSuccess =
          translations['location.location-detail.push-success-deleted'];
        this.pushNotificationDeletedError =
          translations['location.location-detail.push-error-deleted'];
      });

    // disable form for manager
    if (this.isManager()) {
      this.locationForm.disable();
    }
  }

  /**
   * Submits the form values to the api in order to update the location
   * Sends a push notification if the user was updated successfully or not
   */
  submitForm(): void {
    if (this.sendRequest) return;
    this.sendRequest = true;
    if (this.locationForm.valid && this.locationForm.touched) {
      const locationBody: LocationBody = {
        name: this.locationForm.controls['name'].value,
        address: {
          houseNo: this.locationForm.controls['houseNumber'].value,
          street: this.locationForm.controls['street'].value,
          postalCode: this.locationForm.controls['postalCode'].value,
          city: this.locationForm.controls['city'].value,
        },
        phoneNumber: this.locationForm.value.phone!,
      };

      this.locationService
        .updateLocation(locationBody, this.locationId)
        .subscribe((isPatched) => {
          if (isPatched) {
            this.pushService.sendPush(
              pushTypes.SUCCESS,
              this.pushNotificationPatchedSuccess,
            );
            this.setCloseEvent();
            this.locationForm.disable();
            this.location.name = this.locationForm.controls['name'].value;
          } else {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationPatchedError,
            );
            this.setCloseEvent();
          }
        });
    } else if (this.locationForm.invalid) {
      this.locationForm.markAllAsTouched();
      this.locationForm.markAsDirty();
    }
  }

  /**
   * Deletes the location with the id from the route parameter
   * Sends a push notification if the location was deleted successfully or not
   */
  deleteLocation() {
    if (this.sendRequest) return;
    this.sendRequest = true;
    this.locationService
      .deleteLocation(this.locationId)
      .subscribe((isDeleted) => {
        if (isDeleted) {
          this.pushService.sendPush(
            pushTypes.SUCCESS,
            this.pushNotificationDeletedSuccess,
          );
          this.setCloseEvent();
        } else {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationDeletedError,
          );
          this.setCloseEvent();
        }
      });
  }

  /**
   * Gets the location from the api with the id from the route parameter
   * Sets the form values to the location values in order to edit them
   */
  public getLocationWithRouteParam(): void {
    this.route.paramMap.subscribe((params) => {
      this.locationId = params.get('id')!;
      if (this.locationId != null) {
        this.locationService.getLocationById(this.locationId).subscribe(
          (location) => {
            this.location = location;
            this.locationForm.controls['name'].setValue(this.location.name);
            this.locationForm.controls['street'].setValue(
              this.location.address.street,
            );
            this.locationForm.controls['houseNumber'].setValue(
              this.location.address.houseNo,
            );
            this.locationForm.controls['postalCode'].setValue(
              this.location.address.postalCode,
            );
            this.locationForm.controls['city'].setValue(
              this.location.address.city,
            );
            this.locationForm.controls['phone'].setValue(
              this.location.phoneNumber ?? '',
            );
            this.locationForm.markAllAsTouched();
          },
          (error) => {
            this.router.navigate([NavRoutes.ERROR], {
              queryParams: {
                code: error.error.code,
                key: error.error.key,
                url: error.url,
              },
            });
          },
        );
      }
    });
  }

  /**
   * Closes the dialog and navigates back to the user page
   */
  setCloseEvent() {
    this.sendRequest = false;
    this.router.navigate([NavRoutes.SETTINGS, NavRoutes.LOCATION]);
    this.isOpen = false;
  }

  public isManager(): boolean {
    return this.authService.getUserRole() === UserRole.MANAGER;
  }
}
