<!--<tui-root>-->
<!--    <app-sidenav>-->
<!--    &lt;!&ndash; <app-topbar></app-topbar> &ndash;&gt;-->
<!--      <router-outlet></router-outlet>-->
<!--    </app-sidenav>-->
<!--</tui-root>-->

<app-sidenav>
  <router-outlet></router-outlet>
</app-sidenav>
