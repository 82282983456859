import { Component, inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { phoneValidator } from "../../../validators/phone-validator";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { NavRoutes } from "../../../enums/nav-routes";

export type OneTimeLoginBody = {
  phone: string;
  otp: number;
}

@Component({
  selector: "app-phone-code-validation",
  templateUrl: "./phone-code-validation.component.html",
  styleUrls: ["./phone-code-validation.component.scss"],
})
export class PhoneCodeValidationComponent implements OnInit {
  form?: FormGroup;
  protected router = inject(Router);
  private fb = inject(FormBuilder);
  private authService = inject(AuthService)

  ngOnInit(): void {
    this.form = this.fb.group({
      phone: [null, [Validators.required, Validators.minLength(12), phoneValidator()]],
      otp: [null, [Validators.required, this.numberLengthValidator(8)]],
    });

  }

  numberLengthValidator(requiredLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const stringValue = String(control.value);
      return stringValue.length !== requiredLength ? {
        'numberLength': {
          actualLength: stringValue.length,
          requiredLength
        }
      } : null;
    };
  }

  createOneTimePayloadFromForm(): OneTimeLoginBody {
    return this.form?.value;
  }

  sendOneTimeLogin() {
    if (!this.createOneTimePayloadFromForm()) return

    const payload = this.createOneTimePayloadFromForm()

    this.authService.sendOneTimeLogin(payload).subscribe((res) => {
      if (res && typeof res === 'string') {
        this.navigateToSetPasswordWithHash(res)
      }
    })
  }

  navigateToSetPasswordWithHash(hash: string) {
    this.router.navigate([`${ NavRoutes.SET_NEW_PASSWORD }/${ hash }`]).then()
  }
}
