<!-- BIGGER SCREEN > 650 (WITH ISLAND) -->
<ng-container *ngIf="bigScreen; else smallScreen">
  <div class="phone-wrapper">
    <tui-island>
      <app-phone-code-validation/>
    </tui-island>
  </div>
</ng-container>


<!-- SMALL SCREEN < 650 (WITHOUT ISLAND) -->
<ng-template #smallScreen>
  <div class="phone-wrapper">
    <app-phone-code-validation/>
  </div>
</ng-template>
