import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TenantService } from '../../../services/tenant.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantBody } from '../../../interfaces/body/tenant-body';
import { PushService } from '../../../services/push.service';
import { pushTypes } from '../../../enums/push-types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-tenant',
  templateUrl: './new-tenant.component.html',
  styleUrls: ['./new-tenant.component.scss'],
})
export class NewTenantComponent {
  @Input() isOpen: boolean = false;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() isCreatedEvent = new EventEmitter<boolean>();

  sendRequest: boolean = false;

  readonly newTenantForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  // strings for i18n
  pushNotificationSuccess: string = '';
  pushNotificationError: string = '';

  constructor(
    private tenantService: TenantService,
    private pushService: PushService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(): void {
    this.newTenantForm.markAsUntouched();
  }

  ngOnInit(): void {
    // translated strings for push-notifications
    let pushMessage = this.translateService
      .get([
        'tenant.new-tenant.push-tenant-successful-created',
        'tenant.new-tenant.push-tenant-error-created',
      ])
      .subscribe((translations) => {
        this.pushNotificationSuccess =
          translations['tenant.new-tenant.push-tenant-successful-created'];
        this.pushNotificationError =
          translations['tenant.new-tenant.push-tenant-error-created'];
      });
  }

  /**
   * Emits the close event to the parent component and closes the dialog.
   */
  setCloseEvent() {
    this.closeEvent.emit(true);
    this.isOpen = false;
  }

  createNewTenant() {
    if (this.newTenantForm.valid) {
      if (this.sendRequest) return;
      this.sendRequest = true;

      const tenantBody: TenantBody = {
        name: this.newTenantForm.value.name!,
      };
      this.tenantService
        .createTenant(tenantBody)
        .subscribe((isCreated: boolean) => {
          this.sendRequest = false;
          if (isCreated) {
            this.pushService.sendPush(
              pushTypes.SUCCESS,
              this.pushNotificationSuccess,
            );
            // If created, emit the event to the parent component and close the dialog
            this.isCreatedEvent.emit(true);
            this.setCloseEvent();
            this.newTenantForm.reset();
            // If not created, stay in the dialog
          } else {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationError,
            );
          }
        });
    }
  }
}
