<div class="wrapper">
  <tui-loader
    [overlay]="true"
    [showLoader]="!!(isLoading$ | async)"
    [size]="'xl'"
  >
    <ng-container *ngIf="student">
      <tui-breadcrumbs [size]="'l'">
        <ng-container *ngFor="let item of breadcrumbItems">
          <a *tuiItem [routerLink]="item.routerLink" tuiLink>
            {{ item.caption | translate }}
          </a>
        </ng-container>
      </tui-breadcrumbs>

      <span class="header-container">
        <div class="name-wrapper">
          <tui-svg [src]="tuiIconSmileLarge"></tui-svg>
          <h1>{{ student.name }}</h1>

          <div *ngIf="!student.inviteAcceptedAt">
            Einladung wurde noch nicht angenommen:
            <a
              (click)="sendInviteMessageAgain(student.id)"
              style="cursor: pointer"
            >
              Einladung erneut senden
            </a>
          </div>
        </div>

        <app-edit-button-bar
          (deleteClicked)="showDeleteDialog = true"
          (saveClicked)="submitForm()"
          [deleteButtonText]="
            'students.student-detail.button-delete' | translate
          "
          [saveButtonDisabled]="licenceTypeIdDuplicates() || !studentForm.valid"
          [saveButtonText]="'students.student-detail.button-save' | translate"
          [withTitle]="false"
        >
        </app-edit-button-bar>
      </span>

      <div class="main-container">
        <div class="infobit-wrapper">
          <div class="form-group">
            <h2>
              {{ titleString | translate }}
            </h2>

            <form [formGroup]="studentForm">
              <div class="form-section-wrapper">
                <span class="text-field-wrapper"
                  ><app-input-text
                    [fieldName]="'firstName'"
                    [hint]="'students.input-label-first-name' | translate"
                  ></app-input-text
                ></span>
                <span class="text-field-wrapper">
                  <app-input-text
                    [fieldName]="'lastName'"
                    [hint]="'students.input-label-last-name' | translate"
                  ></app-input-text
                ></span>
              </div>
              <div class="form-section-wrapper">
                <span class="text-field-wrapper"
                  ><app-input-email
                    [fieldName]="'email'"
                    [hint]="'students.input-label-mail' | translate"
                    [required]="false"
                  ></app-input-email
                ></span>
                <span class="text-field-wrapper"
                  ><app-input-phone
                    [fieldName]="'phone'"
                    [hint]="'students.input-label-telephone' | translate"
                    [required]="false"
                    [mobileWithCountryCode]="true"
                  ></app-input-phone
                ></span>
              </div>
              <div class="form-section-wrapper">
                <span class="text-field-wrapper">
                  <app-location-dropdown
                    [fieldName]="'locationId'"
                    [formGroup]="studentForm"
                    [objectWithLocation]="student"
                  >
                  </app-location-dropdown>
                </span>
                <span class="radio-field-wrapper">
                  <tui-radio-labeled
                    [item]="radioListActive[0]"
                    [size]="'l'"
                    class="tui-space_bottom-3"
                    formControlName="active"
                  >
                    {{ "common.radio-active" | translate }}
                  </tui-radio-labeled>
                  <tui-radio-labeled
                    [item]="radioListActive[1]"
                    [size]="'l'"
                    class="tui-space_bottom-3"
                    formControlName="active"
                  >
                    {{ "common.radio-inactive" | translate }}
                  </tui-radio-labeled>
                </span>
              </div>
              <div class="form-section-wrapper">
                <span class="text-field-wrapper">
                  <app-input-number
                    [fieldName]="'studentNumber'"
                    [hint]="'students.input-label-student-number' | translate"
                  >
                  </app-input-number>
                </span>
              </div>

              <!--   licence type + progress arc chart     -->
              <h2 class="headline">
                {{ "students.student-detail.licence-type-title" | translate }}
              </h2>
              <ng-container
                *ngFor="
                  let studentLicenceType of studentsLicenceDetails;
                  let i = index
                "
              >
                <div class="bottom-space max-width-51rem">
                  <app-arc-chart-progress-licence
                    [licenceType]="studentLicenceType"
                    [studentId]="student.id"
                  >
                  </app-arc-chart-progress-licence>
                </div>
              </ng-container>

              <!--     add licence type         -->
              <ng-container
                *ngFor="let licenceType of licenceTypes.controls; let i = index"
                [formArrayName]="'licenceTypes'"
              >
                <div
                  [formGroup]="getFormGroupFromAbstractControl(licenceType)"
                  class="target-container"
                >
                  <div class="max-width-51rem">
                    <app-licence-type-dropdown [fieldName]="'licenceTypeId'">
                    </app-licence-type-dropdown>
                  </div>
                  <button
                    (click)="deleteTargetFormRow(i)"
                    appearance="icon"
                    class="delete-button"
                    icon="tuiIconClose"
                    size="l"
                    tuiButton
                  ></button>
                </div>
              </ng-container>
              <div class="center-button">
                <button
                  (click)="addTargetFormRow()"
                  appearance="secondary"
                  class="max-width-51rem"
                  icon="tuiIconPlus"
                  id="addButton"
                  size="m"
                  tuiButton
                >
                  {{ "students.new-licence-type-button-label" | translate }}
                </button>
              </div>
              <tui-error
                *ngIf="licenceTypeIdDuplicates()"
                [error]="
                  'students.student-detail.licence-type-duplicate-error'
                    | translate
                "
                class="bottom-space"
              ></tui-error>

              <h2 class="headline">
                {{ "students.student-detail.address-title" | translate }}
              </h2>
              <div class="form-section-wrapper">
                <span class="text-field-wrapper">
                  <app-input-text
                    [fieldName]="'street'"
                    [hint]="'address.input-label-street' | translate"
                    [required]="false"
                  >
                  </app-input-text>
                </span>
                <span class="text-field-wrapper">
                  <app-input-text
                    [fieldName]="'houseNo'"
                    [hint]="'address.input-label-house-number' | translate"
                    [required]="false"
                  >
                  </app-input-text>
                </span>
              </div>
              <div class="form-section-wrapper">
                <span class="text-field-wrapper">
                  <app-input-text
                    [fieldName]="'postalCode'"
                    [hint]="'address.input-label-zip-code' | translate"
                    [required]="false"
                  >
                  </app-input-text>
                </span>
                <span class="text-field-wrapper">
                  <app-input-text
                    [fieldName]="'city'"
                    [hint]="'address.input-label-city' | translate"
                    [required]="false"
                  >
                  </app-input-text>
                </span>
              </div>
            </form>

            <button
              [routerLink]="['/payments/student/', student.id]"
              appearance="primary"
              size="m"
              style="width: 41.5%"
              tuiButton
              type="button"
            >
              Zahlung erstellen
            </button>

            <!-- WALLET OVERVIEW -->
            <app-wallet-overview
              [id]="studentId"
              [walletType]="WalletType.STUDENT"
            />
          </div>

          <!-- STUDENT LESSONS OVERVIEW -->
          <app-student-lesson-table [studentId]="studentId" />
        </div>
      </div>
    </ng-container>
  </tui-loader>
</div>

<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="showDeleteDialog = false; deleteStudent()"
  [isOpen]="showDeleteDialog"
>
</app-delete-dialog>
