<div class="wrapper">
  <tui-loader
    [overlay]="true"
    [showLoader]="!!(isLoading$ | async)"
    [size]="'xl'"
  >
    <tui-breadcrumbs [size]="'l'">
      <ng-container *ngFor="let item of breadcrumbItems">
        <a *tuiItem [routerLink]="item.routerLink" tuiLink>
          {{ item.caption | translate }}
        </a>
      </ng-container>
    </tui-breadcrumbs>

    <span class="header-container">
      <div class="name-wrapper">
        <tui-svg [src]="tuiIconClockLarge"></tui-svg>
        <h1>
          {{ "lesson-history.page-title" | translate }}
          {{ "lesson-history.for" | translate }}
          {{ currentLesson?.student?.firstName ?? "" }}
          {{ currentLesson?.student?.lastName ?? "" }}
        </h1>
      </div>
    </span>

    <div class="card-container">
      <span class="label">{{
        "lesson-history.current-planning" | translate
      }}</span>
      <div class="current-planning-card">
        <div class="attribute-col">
          <span class="label-attribute">{{
            "lesson-history.table.start-date" | translate
          }}</span>
          <span
            >{{ currentLesson?.startDate | date: "dd.MM.yyyy, HH:mm" }}
            {{ "lesson-history.hour" | translate }}</span
          >
        </div>

        <div class="attribute-col">
          <span class="label-attribute">{{
            "lesson-history.table.end-date" | translate
          }}</span>
          <span
            >{{ currentLesson?.endDate | date: "dd.MM.yyyy, HH:mm" }}
            {{ "lesson-history.hour" | translate }}</span
          >
        </div>

        <div class="attribute-col">
          <span class="label-attribute">{{
            "lesson-history.table.teacher" | translate
          }}</span>
          <span>{{
            currentLesson?.user?.firstName ??
              "" + currentLesson?.user?.lastName ??
              ""
          }}</span>
        </div>

        <div class="attribute-col">
          <span class="label-attribute">{{
            "lesson-history.table.lesson-type" | translate
          }}</span>
          <span>{{ currentLesson?.lessonCategory?.name ?? "" }}</span>
        </div>

        <div class="attribute-col">
          <span class="label-attribute">{{
            "lesson-history.table.amount" | translate
          }}</span>
          <span>{{ currentLesson?.price | currency: "€" }}</span>
        </div>
      </div>
    </div>
    <div class="log-entity-cols">
      <app-log-entity-card
        [currentLesson]="currentLesson!"
        [logEntityLessons]="lessons"
      />
    </div>
  </tui-loader>
</div>
