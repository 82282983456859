import { Component, Input, OnInit } from '@angular/core';
import { MenuItems, MenuItem } from '../../../enums/menu-items';
import { Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavRoutes } from '../../../enums/nav-routes';

export enum CustomIcons {
  PAYMENTS,
  LICENCE_TYPES,
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})

/**
 * This component represents a button in the sidenav to switch between the different pages.
 * Each button represents a page and has a name, an icon and a tooltip text.
 *
 * @Input type: string - Which Button is clicked (see: menu-items enum).
 * @Input selected: boolean - Checks if the button is selected.
 * @Input name: string - Name of the button.
 * @Output isClicked: EventEmitter<MenuItem> - Emits the clicked button to the parent component (sidenav).
 */
export class ButtonComponent implements OnInit {
  @Input() type: number = 0; // Which Button is clicked (see: menu-items enum).
  @Input() selected: boolean = false; // Checks if the button is selected.
  @Input() name: string = ''; // Name of the button.
  @Output() isClicked: EventEmitter<MenuItem> = new EventEmitter<MenuItem>(); // Emits the clicked button to the parent component.

  protected menuItem!: MenuItem;

  // For the translations
  overview!: string;
  user!: string;
  students!: string;
  payments!: string;
  calendar!: string;
  settings!: string;
  locations!: string;
  licenceTypes!: string;

  ngOnInit() {
    // Subscribes to the translations for the menu items
    this.getAllTranslations();

    // Listen to language changes, because the translations on the sidenav have to be updated directly after the language changed in the dropdown (are not reloaded automatically).
    this.translate.onLangChange.subscribe(() => {
      this.getAllTranslations();
    });
  }

  constructor(private translate: TranslateService) {}

  /**
   * Get all translations for the menu items
   */
  getAllTranslations() {
    this.translate
      .get([
        'sidenav.overview',
        'sidenav.user',
        'sidenav.student',
        'sidenav.payments',
        'sidenav.calendar',
        'sidenav.settings',
        'sidenav.location',
        'sidenav.licenceTypes',
      ])
      .subscribe((translations) => {
        this.overview = translations['sidenav.overview'];
        this.user = translations['sidenav.user'];
        this.students = translations['sidenav.student'];
        this.payments = translations['sidenav.payments'];
        this.calendar = translations['sidenav.calendar'];
        this.settings = translations['sidenav.settings'];
        this.locations = translations['sidenav.location'];
        this.licenceTypes = translations['sidenav.licenceTypes'];
        this.getType();
      });
  }

  /**
   * Determines the type of the button and sets the corresponding values,
   * including the tooltip text, the icon and the link by creating a new MenuItem object.
   */
  getType(): void {
    switch (this.type) {
      case MenuItems.OVERVIEW:
        this.menuItem = new MenuItem(
          this.overview,
          'tuiIconHomeLarge',
          NavRoutes.TENANT,
        );
        break;
      case MenuItems.CUSTOMERS:
        this.menuItem = new MenuItem(
          this.students,
          'tuiIconUsersLarge',
          NavRoutes.STUDENT,
        );
        break;
      case MenuItems.USERS:
        this.menuItem = new MenuItem(
          this.user,
          'tuiIconUserLarge',
          NavRoutes.USER,
        );
        break;
      case MenuItems.LOCATIONS:
        this.menuItem = new MenuItem(
          this.locations,
          'tuiIconMapPinLarge',
          NavRoutes.LOCATION,
        );
        break;
      case MenuItems.CALENDAR:
        this.menuItem = new MenuItem(
          this.calendar,
          'tuiIconCalendarLarge',
          NavRoutes.CALENDAR,
        );
        break;
      case MenuItems.PAYMENTS:
        this.menuItem = new MenuItem(
          this.payments,
          '',
          NavRoutes.PAYMENT,
          CustomIcons.PAYMENTS,
        );
        break;
      case MenuItems.LICENCE:
        this.menuItem = new MenuItem(
          this.licenceTypes,
          '',
          NavRoutes.LICENCE,
          CustomIcons.LICENCE_TYPES,
        );
        break;
      case MenuItems.SETTINGS:
        this.menuItem = new MenuItem(
          this.settings,
          'tuiIconSettingsLarge',
          NavRoutes.SETTINGS,
        );
        break;
    }
  }

  /**
   * Emits true to the parent component if clicked.
   */
  onClick(): void {
    this.isClicked.emit(this.menuItem);
  }

  protected readonly CustomIcons = CustomIcons;
}
