import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { tuiIconAlertOctagonLarge } from '@taiga-ui/icons';
import { LessonStatus } from '../../enums/lesson-status';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  @Input() isOpen: boolean = true;
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() deleteEvent = new EventEmitter<boolean>();
  @Input() deleteHint: string = '';
  @Input() highlightedHint: string = '';

  protected readonly tuiIconAlertOctagonLarge = tuiIconAlertOctagonLarge;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    // if no delete hint set, set default text
    if (this.deleteHint == '') {
      this.translateService
        .get(['common.delete-hint'])
        .subscribe((translations) => {
          this.deleteHint = translations['common.delete-hint'];
        });
    }
  }

  protected readonly LessonStatus = LessonStatus;
}
