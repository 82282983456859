import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiRoutes } from '../enums/api-routes';
import { LoginBody } from '../interfaces/body/login-body';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { SetPasswordBody } from '../interfaces/body/set-password-body';
import { NavRoutes } from '../enums/nav-routes';
import { SuperAdminService } from './super-admin.service';
import { UserRole } from '../enums/user-role';
import { LoginStateStatus } from '../enums/login-state-status';
import { OneTimeLoginBody } from "../views/reset-password-page/phone-code-validation/phone-code-validation.component";
import { PushService } from "./push.service";
import { pushTypes } from "../enums/push-types";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})

/**
 * Service Class for the authentication functionality and api calls
 * allows access to the loggedInUser and the loggedIn flag
 */
export class AuthService {
  private readonly baseUrl = environment.baseUrl;
  private loggedInUser!: User | null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private superAdminService: SuperAdminService,
    private pushMessageService: PushService,
    private translateService: TranslateService
  ) {
  }

  /**
   * returns the loggedIn Status by checking if the access token and the refresh token are set in the local storage
   *
   */
  isLoggedIn(): boolean {
    return (
      localStorage.getItem('access_token') !== null &&
      localStorage.getItem('access_token') !== undefined &&
      localStorage.getItem('refresh_token') !== null &&
      localStorage.getItem('refresh_token') !== undefined
    );
  }

  /**
   * post the login data to the api
   * if the login is successful: the tokens are saved in the local storage, the user is saved in the service and an Observable of true is returned
   * otherwise: the loggedIn flag will be set to false and an Observable of false is returned
   * @param loginBody the login data containing the email and password
   */
  login(loginBody: LoginBody): Observable<LoginStateStatus> {
    return this.http
      .post<any>(this.baseUrl + ApiRoutes.AUTH + '/login', loginBody, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          if (response.status === HttpStatusCode.Created) {
            if (response.body.user.userRole === UserRole.STUDENT) {
              return LoginStateStatus.STUDENT_NOT_ALLOWED_TO_LOGIN;
            }

            this.setTokens(
              response.body?.access_token,
              response.body?.refresh_token,
            );
            this.setLoggedInUser(response.body?.user);
            return LoginStateStatus.LOGIN_SUCCESS;
          }
          return LoginStateStatus.LOGIN_FAILED;
        }),
        catchError((error) => {
          console.log(error);
          console.log(error.error.key);
          console.log(error.error.key === LoginStateStatus.TIME_BASED);
          if (error.error.key === LoginStateStatus.TIME_BASED) {
            return of(LoginStateStatus.TIME_BASED);
          }
          if (error.status === HttpStatusCode.Forbidden) {
            return of(LoginStateStatus.TEACHER_NOT_ALLOWED_TO_LOGIN);
          }
          return of(LoginStateStatus.LOGIN_FAILED);
        }),
      );
  }

  /**
   * Sends a request to the api to send a reset password mail to the given email
   * @param email the email to send the reset password mail to
   */
  sendResetPasswordMail(email: string): Observable<boolean> {
    return this.http
      .post<any>(
        this.baseUrl + ApiRoutes.AUTH + '/resetPassword',
        { email: email },
        {
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Created;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  validateResetPasswordHash(hash: string): Observable<boolean> {
    return this.http
      .get<any>(this.baseUrl + ApiRoutes.AUTH + '/validate/' + hash, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
      );
  }

  isValidationHashValid(hash: string): Observable<boolean> {
    return this.http
      .get<any>(this.baseUrl + ApiRoutes.AUTH + '/validate/' + hash, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
      );
  }

  /**
   * Sends a request to the api to set the password of the user with the given hash from the reset password mail
   * @param passwordBody
   * @param hash
   */
  setPassword(
    passwordBody: SetPasswordBody,
    hash: string,
  ): Observable<LoginStateStatus> {
    return this.http
      .post<any>(
        this.baseUrl + ApiRoutes.AUTH + '/setPassword/' + hash,
        passwordBody,
        {
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          if (
            response.status === HttpStatusCode.Created &&
            this.isAllowedToLogin(response.body?.user.role.userRole)
          ) {
            this.setTokens(
              response.body?.access_token,
              response.body?.refresh_token,
            );
            this.setLoggedInUser(response.body?.user);
            return LoginStateStatus.PASSWORD_RESET_SUCCESS;
          }

          switch (response.body?.user.role.userRole) {
            case UserRole.STUDENT:
              return LoginStateStatus.STUDENT_NOT_ALLOWED_TO_LOGIN;
            case UserRole.TEACHER:
              return LoginStateStatus.TEACHER_NOT_ALLOWED_TO_LOGIN;
          }

          return LoginStateStatus.PASSWORD_RESET_FAILED;
        }),
        catchError(() => {
          return of(LoginStateStatus.PASSWORD_RESET_FAILED);
        }),
      );
  }

  /**
   * Logs out the user by removing the tokens from the local storage and navigating to the login page
   */
  logout() {
    this.loggedInUser = null;
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    this.router.navigateByUrl(NavRoutes.LOGIN);
  }

  sendRefreshToken(): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + ApiRoutes.AUTH + '/refreshToken',
      {
        refreshToken: this.getRefreshToken(),
      },
      {
        observe: 'response',
      },
    );
  }

  resendInviteMessage(studentId?: string, userId?: string) {
    const studentBody = { studentId: studentId };
    const userBody = { userId: userId };

    if (studentId) {
      return this.http.post(
        this.baseUrl + 'auth/resendInviteMessage',
        studentBody,
      );
    } else {
      return this.http.post(this.baseUrl + 'auth/resendInviteMessage', userBody);
    }
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refresh_token');
  }

  getAccessToken(): string | null {
    return localStorage.getItem('access_token');
  }

  setTokens(access_token: string, refresh_token: string) {
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
  }

  setLoggedInUser(user: User) {
    this.loggedInUser = user;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getLoggedInUser(): User | null {
    if (this.loggedInUser) {
      return this.loggedInUser;
    }

    const userJSON = localStorage.getItem('user');
    return User.fromJson(JSON.parse(userJSON || '{}'));
  }

  getUserRole(): UserRole | undefined {
    return this.getLoggedInUser()?.role?.userRole;
  }

  /**
   * @returns the tenant id of the logged-in user or the selected tenant id of the super admin
   */
  getTenantId() {
    if (this.superAdminService.isSuperAdmin()) {
      return this.superAdminService.getSelectedTenantIdValue();
    } else {
      return this.getLoggedInUser()?.tenantId;
    }
  }

  /**
   * @returns if the user is allowed to log-in
   */
  isAllowedToLogin(userRole: UserRole): boolean {
    return !(userRole === UserRole.STUDENT || userRole === UserRole.TEACHER);
  }

  // send a one time login (authentication) to access setPassword page
  sendOneTimeLogin(body: OneTimeLoginBody): Observable<unknown> {
    return this.http.post(this.baseUrl + ApiRoutes.AUTH + '/one-time-login', body, {
      responseType: 'text'
    }).pipe(
      tap(() => this.pushMessageService.sendPush(pushTypes.SUCCESS, this.translateService.instant('otp.success'))), // Success message
      catchError((err: HttpErrorResponse) => {
        if (err.status === 417) {
          this.pushMessageService.sendPush(pushTypes.ERROR, this.translateService.instant('otp.token_expired'))
        } else {
          this.pushMessageService.sendPush(pushTypes.ERROR, this.translateService.instant('otp.error'))
        }
        return of(err)
      })
    )
  }


}
