import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TableFilterEvent } from "../../interfaces/table-filter-event";
import { fadeAnimation } from "../../animations/fade-animation";
import { slideShortAnimation } from "../../animations/slide-short-animation";
import { SuperAdminService } from "../../services/super-admin.service";

@Component({
  selector: "app-table-header",
  templateUrl: "./table-header.component.html",
  styleUrls: ["./table-header.component.scss"],
  animations: [fadeAnimation, slideShortAnimation],
})

/**
 * A header above the data table that contains:
 * a title, search input, filters (searchInput & Tabs) and a create new x button
 *
 * @Input tableTitle - The title of the table
 * @Input buttonText - The text of the create new x button
 * @Output filterEvent - Emits an event when the search input or tab-index is changed (TableFilterEvent: searchTerm, tabIndex)
 * @Output createNewClickEvent - Emits an event when the create new x button is clicked
 */
export class TableHeaderComponent {
  @Input() tableTitle: string = "";
  @Input() buttonText: string = "";
  @Input() tenantSelector: boolean = false;
  @Input() showActiveTab: boolean = true;
  @Input() showCreateButton: boolean = true;
  @Input() hasSearch: boolean = false;

  @Output() filterEvent = new EventEmitter<TableFilterEvent>();
  @Output() createNewClickEvent = new EventEmitter<void>();
  @Output() searchEvent = new EventEmitter<string>();

  searchTerm: FormControl = new FormControl(null);
  activeItemIndex: number = 0;

  constructor(private superAdminService: SuperAdminService) {
  }

  /**
   * Emits an event when the search input or tab-index is changed (only emits when the search term is not empty)
   * TableFilterEvent: searchTerm, tabIndex
   */
  public filterTable() {
    if (this.searchTerm.value && this.searchTerm.value.trim().length > 0) {
      this.filterEvent.emit({
        searchTerm: this.searchTerm.value,
        tabIndex: this.activeItemIndex,
      });
    } else {
      this.filterEvent.emit({
        searchTerm: "",
        tabIndex: this.activeItemIndex,
      });
    }
  }

  /**
   * Emits an event when the create new x button is clicked
   */
  public createNewClick() {
    this.createNewClickEvent.emit();
  }

  /**
   * Returns true if the user is a super admin
   */
  public isSuperAdmin() {
    return this.superAdminService.isSuperAdmin();
  }

  onSearch($event: string) {
    this.searchEvent.emit($event);
  }
}
