import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";

export function phoneOrEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;

    if (!formGroup) {
      return null;
    }

    const emailField = formGroup.get("email");
    const phoneField = formGroup.get("phone");

    if (!emailField?.value && !phoneField?.value) {
      emailField?.setErrors({ phoneOrEmailRequired: true });
      phoneField?.setErrors({ phoneOrEmailRequired: true });
      return { phoneOrEmailRequired: true };
    }

    emailField?.setErrors(null);
    phoneField?.setErrors(null);

    return null;
  };
}
