<!-- The Modal -->
<div class="card-wrapper">
      <div class="headline-wrapper">
        <h1><u>{{ 'settings.role-tab.role-tab-title' | translate }}:</u></h1>
      </div>

      <div class="max-width-51rem">
        <h2>{{ 'settings.role-tab.role-boss-title' | translate }}:</h2>
        <p class="p-increased-fontsize role-paragraph"> {{ 'settings.role-tab.role-boss-text' | translate }}</p>
      </div>

    <div class="max-width-51rem">
      <h2>{{ 'settings.role-tab.role-office-title' | translate }}:</h2>
      <p class="p-increased-fontsize role-paragraph"> {{'settings.role-tab.role-office-text' | translate}} </p>
    </div>

  <div class="max-width-51rem">
    <h2>{{ 'settings.role-tab.role-teacher-title' | translate }}:</h2>
    <p class="p-increased-fontsize role-paragraph"> {{'settings.role-tab.role-teacher-text' | translate}} </p>
  </div>

  <div class="max-width-51rem">
    <h2>{{ 'settings.role-tab.role-student-title' | translate }}:</h2>
    <p class="p-increased-fontsize role-paragraph"> {{'settings.role-tab.role-student-text' | translate}}</p>
  </div>
  <hr>
  <div class="headline-wrapper2">
    <h1><u> {{ 'settings.role-tab.role-tab-location-title' | translate }}: </u></h1>
  </div>
  <div class="max-width-51rem p-increased-fontsize role-paragraph">
    <p class="less-space-top"> {{ 'settings.role-tab.location-introduction-text' | translate }}</p>
    <p><u><b>{{ 'settings.role-tab.role-boss-title' | translate }}</b></u> {{'settings.role-tab.location-boss-text' | translate}}</p>
    <p><u><b>{{ 'settings.role-tab.role-student-title' | translate }}</b></u>  {{'settings.role-tab.location-student-text' | translate}}</p>
    <p><u><b>{{ 'settings.role-tab.role-office-title' | translate }}</b> </u> {{'settings.role-tab.location-office-text' | translate}} </p>
    <p><u><b>{{ 'settings.role-tab.role-teacher-title' | translate }}</b></u> {{'settings.role-tab.location-teacher-text' | translate}} </p>
  </div>




</div>



