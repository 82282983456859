<div class="icon-dropdown-wrapper">

  <!-- Todo: Change to tui-select-->
  <tui-hosted-dropdown [content]="content" [(open)]="open">
    <button
      tuiButton
      type="button"
      appearance="outline"
    >
<!--      <span class="icon-text">{{("licence-type.table-icon" | translate) + ':' }}</span>-->
      <app-car-icon [icon]="selectedIcon ?? CarIcons.CAR">
      </app-car-icon>
    </button>
  </tui-hosted-dropdown>
  <ng-template #content>
    <tui-data-list class="icon-dropdown-button">
      <button tuiOption *ngFor="let icon of icons" (click)="iconClicked(icon); ">
        <app-car-icon [icon]="icon"></app-car-icon>
      </button>
    </tui-data-list>
  </ng-template>
</div>

