import { BaseModel } from './base-model';
import { Address } from './address';

export class Location extends BaseModel {
  private _tenantId: string;
  private _name: string;
  private _addressId: string;
  private _phoneNumber: string;
  private _address: Address;

  constructor(
    id: string,
    createdAt: Date,
    updatedAt: Date,
    tenantId: string,
    name: string,
    addressId: string,
    phoneNumber: string,
    address: Address,
  ) {
    super(id, createdAt, updatedAt);
    this._tenantId = tenantId;
    this._name = name;
    this._addressId = addressId;
    this._phoneNumber = phoneNumber;
    this._address = address;
  }

  get tenantId(): string {
    return this._tenantId;
  }

  set tenantId(value: string) {
    this._tenantId = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get address(): Address {
    return this._address;
  }

  set address(value: Address) {
    this._address = value;
  }

  get addressId(): string {
    return this._addressId;
  }

  set addressId(value: string) {
    this._addressId = value;
  }

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  set phoneNumber(value: string) {
    this._phoneNumber = value;
  }

  static fromJson(json: any): any {
    return new Location(
      json.id,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.tenantId,
      json.name,
      json.addressId,
      json.phoneNumber,
      json.address,
    );
  }

  // todo implement
  toJSON(): any {}
}
