import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { SuperAdminService } from '../services/super-admin.service';
import { NavRoutes } from '../enums/nav-routes';

export const globalUserGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const user = authService.getLoggedInUser();

  if (user?.role?.global) {
    return true;
  }

  return router.navigateByUrl(`${NavRoutes.ERROR}`);
};
