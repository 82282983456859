import { Component } from '@angular/core';
import { MenuItem, MenuItems } from '../../enums/menu-items';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { tuiIconLogOut } from '@taiga-ui/icons';
import { TranslateService } from '@ngx-translate/core';
import { NavRoutes } from '../../enums/nav-routes';
import { SuperAdminService } from '../../services/super-admin.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})

/**
 * The sidenavigation on left side of the page.
 * Contains the menu items and the logout button on the bottom.
 */
export class SidenavComponent {
  protected readonly NavRoutes = NavRoutes;
  protected readonly MenuItems = MenuItems;
  protected readonly tuiIconLogOut = tuiIconLogOut;
  isOpen: boolean = false;
  userName: string = '';
  private overview: string = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private superAdminService: SuperAdminService,
  ) {}

  ngOnInit() {
    this.translate.get(['sidenav.overview']).subscribe((translations) => {
      this.overview = translations['sidenav.overview'];
    });
    this.getUserName();
  }

  // Initializes the selected item with the overview item (currently not used, but maybe handy in the future).
  selectedItem: MenuItem = new MenuItem(this.overview, 'tuiIconHomeLarge', '/');

  /**
   * Sets the selected item to the clicked item.
   * @param clickedItem The clicked item.
   */
  setSelectedItem(clickedItem: MenuItem) {
    this.selectedItem = clickedItem;
  }

  /**
   * Logs the user out and navigates to the login page.
   */
  logOut() {
    this.authService.logout();
    this.router.navigateByUrl(NavRoutes.LOGIN);
  }

  // To hide the menu if permission is not high enough.
  isSuperAdmin(): boolean | undefined {
    return this.superAdminService.isSuperAdmin();
  }

  getUserName() {
    const user = this.authService.getLoggedInUser();
    if (user) {
      this.userName = user.firstName;
    }
  }
}
