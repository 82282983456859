import { CustomIcons } from '../common/sidenav/button/button.component';

/**
 * Class for creating menu items instances.
 * @param tooltip The tooltip text of the menu item.
 * @param icon The icon of the menu item.
 * @param link The link of the menu item.
 */
export class MenuItem {
  tooltip!: string;
  icon?: string;
  link!: string;
  customIcon?: CustomIcons | null;

  constructor(
    tooltip: string,
    icon: string,
    link: string,
    customIcon?: CustomIcons,
  ) {
    this.tooltip = tooltip;
    this.icon = icon;
    this.link = link;
    this.customIcon = customIcon;
  }
}

/**
 * Enum for all menu items in order to switch between them in the sidenav.
 */
export enum MenuItems {
  OVERVIEW,
  USERS,
  CUSTOMERS,
  CALENDAR,
  SETTINGS,
  LOCATIONS,
  LICENCE,
  PAYMENTS,
}
