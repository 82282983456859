import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { LocationQueryParams } from '../interfaces/query-param/location-query-params';
import { PaginationFilterService } from './pagination-filter.service';
import { ApiRoutes } from '../enums/api-routes';
import { ResponseWithRecordsBody } from '../interfaces/body/response-with-recors-body';
import { catchError, map, Observable, of } from 'rxjs';
import { Location } from '../models/location';
import { LocationBody } from '../interfaces/body/location-body';

@Injectable({
  providedIn: 'root',
})
/**
 * Service Class for the getting or manipulating location data
 */
export class LocationService extends PaginationFilterService {
  private readonly baseUrl = environment.baseUrl;

  public showNewLocationModal: boolean = false;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * get the location data from the api
   * sets query params for the api call if they are passed in
   * sets the totalAmount property of the parent PaginationFilterService if data is returned
   * maps the response to an array of observable location objects with the Location.fromJson method
   * @param queryParams optional query params for the api call
   */
  getLocations(queryParams?: LocationQueryParams): Observable<Location[]> {
    const params = new HttpParams()
      .set(
        queryParams?.tenantId !== undefined ? 'tenantId' : '',
        queryParams?.tenantId ?? '',
      )
      .set(
        queryParams?.name !== undefined ? 'name' : '',
        queryParams?.name ?? '',
      )
      .set(this.sort !== undefined ? 'sort' : '', this.sort ?? '')
      .set(this.endIndex !== '' ? 'limit' : '', this.endIndex)
      .set(this.startIndex !== '' ? 'skip' : '', this.startIndex);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + ApiRoutes.LOCATION, {
        params,
      })
      .pipe(
        map((response: any) => {
          this.totalAmount = response.total;
          return response.records.map((location: any) => {
            return Location.fromJson(location);
          });
        }),
      );
  }

  /**
   * get location count from the api
   */
  getLocationsCount(): number {
    return this.totalAmount;
  }

  /**
   * get detailed location data from the api with a location id
   * @param id the id of the location to get
   */
  getLocationById(id: string) {
    return this.http
      .get<any>(this.baseUrl + ApiRoutes.LOCATION + '/' + id)
      .pipe(
        map((response) => {
          return Location.fromJson(response);
        }),
      );
  }

  /**
   * Sends a request to the api to create a new location
   * @param location to create as LocationBody
   */
  createLocation(location: LocationBody) {
    return this.http
      .post<any>(this.baseUrl + ApiRoutes.LOCATION, location, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  /**
   * Sends a request to the api to patch a location
   * @param location the user to update
   * @param id
   */
  updateLocation(location: LocationBody, id: string) {
    return this.http
      .patch<any>(this.baseUrl + ApiRoutes.LOCATION + '/' + id, location, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  /**
   * Sends a request to the api to delete a location
   * @param id the id of the location to delete
   * @returns true if the user was deleted successfully, false if not
   */
  deleteLocation(id: string) {
    return this.http
      .delete<any>(this.baseUrl + ApiRoutes.LOCATION + '/' + id, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }
}
