import { Component, EventEmitter, Output } from '@angular/core';
import { TenantService } from '../../services/tenant.service';
import { Tenant } from '../../models/tenant';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SuperAdminService } from '../../services/super-admin.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tenant-selector-dropdown',
  templateUrl: './tenant-selector-dropdown.component.html',
  styleUrls: ['./tenant-selector-dropdown.component.scss'],
})
/**
 * Component for SuperAdmins to select a tenant to view the data of
 */
export class TenantSelectorDropdownComponent {
  dropDownFormControl = new FormControl();
  tenants: Tenant[] = [];
  hintText: string = '';

  dropDownSubscription: Subscription = new Subscription();

  constructor(
    private tenantService: TenantService,
    private translate: TranslateService,
    private superAdminService: SuperAdminService,
  ) {}

  ngOnInit(): void {
    this.getTenants();
    this.onDropDownChange();
    this.translate.get(['tenant-dropdown-hint']).subscribe((translations) => {
      this.hintText = translations['tenant-dropdown-hint'];
    });
  }

  ngOnDestroy(): void {
    this.dropDownSubscription.unsubscribe();
  }

  /**
   * Get all tenants from the tenant service and set the dropdown value to the selected tenant in the superAdminService or the first tenant in the list
   */
  getTenants(): void {
    this.tenantService.getTenants().subscribe((tenants) => {
      this.tenants = tenants;
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();
      const selectedTenant = this.tenants.find(
        (tenant) => tenant.id == selectedTenantId,
      );
      if (selectedTenantId != null && selectedTenant != null) {
        this.dropDownFormControl.setValue(selectedTenant);
      } else {
        this.dropDownFormControl.setValue(this.tenants[0]);
      }
    });
  }

  /**
   * React to changes of the dropdown value and sets the selected tenant in the superAdminService
   * value in the superAdminService is only changed if the selected tenant is not the current selected tenant in the service (prevents endless loop)
   */
  onDropDownChange(): void {
    this.dropDownSubscription = this.dropDownFormControl.valueChanges.subscribe(
      (tenant) => {
        if (tenant == null) return;
        const selectedTenantId = tenant.id;
        if (
          selectedTenantId !== this.superAdminService.getSelectedTenantIdValue()
        ) {
          this.superAdminService.setSelectedTenantId(tenant.id);
        }
      },
    );
  }

  /*
   * Returns the name of the tenant to display in the dropdown menu
   */
  readonly stringify = (tenant: Tenant): string => {
    if (tenant.name != undefined) {
      return `${tenant.name}`;
    } else {
      return '';
    }
  };
}
