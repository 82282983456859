import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { tuiIconUser } from '@taiga-ui/icons';
import { LessonStatus } from '../../enums/lesson-status';
import { Student } from '../../models/student';
import { User } from '../../models/user';
import * as stc from 'string-to-color';

type LessonEvent = {
  teacherId: string;
  timeText: string;
  backgroundColor: string;
  event: {
    id: string;
    title: string;
    start: Date;
    end: Date;
    extendedProps: {
      assignedTo: string;
      student: Student;
      teacher: User;
      status: LessonStatus;
    };
  };
};

type StringTypeToColor = typeof stc;

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})

/**
 * A component that displays an event in the calendar
 * @Input arg - The event to display (contains the title, start and end dates, a timestamp, and extendedProps which contains a location)
 */
export class EventItemComponent {
  @Input() lesson!: LessonEvent;
  activeColorHex!: string;
  activeEventGradient = 'linear-gradient(180deg, #AFCA0BCC 0%, #0000001A 100%)';
  lessonId!: string;

  protected readonly tuiIconUser = tuiIconUser;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnInit(): void {
    this.lessonId = this.lesson.event.id;
    this.updateEventColor();
  }

  updateEventColor() {
    this.activeColorHex = this.lesson.backgroundColor;
    this.activeEventGradient = `linear-gradient(180deg, ${this.activeColorHex}CC 0%, #0000001A 100%)`;
  }

  /**
   * Calculates the duration of the event.
   * If the duration is less than 24 hours, the duration is displayed in hours and minutes.
   * If the duration is more than 24 hours, the duration is displayed in days, hours and minutes.
   *
   * @returns {string} - The duration of the event in the format 'xh ym' or 'xd xh ym'
   */
  calculatedDuration(): string {
    if (!this.lesson.event.start || !this.lesson.event.end) return '';
    let duration =
      this.lesson.event.end.getTime() - this.lesson.event.start.getTime();
    let hours = Math.floor(duration / 1000 / 60 / 60);
    let minutes = Math.floor(duration / 1000 / 60) - hours * 60;

    if (hours < 24) {
      return hours + 'h ' + minutes + 'm';
    } else {
      let days = Math.floor(hours / 24);
      hours = hours - days * 24;
      return days + 'd ' + hours + 'h ' + minutes + 'm';
    }
  }

  protected readonly LessonStatus = LessonStatus;
}
