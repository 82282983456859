import { Component } from '@angular/core';
import { Router } from '@angular/router';

type PaymentMenuDropdownItem = {
  title: string;
  route: string;
};

@Component({
  selector: 'app-payment-menu-dropdown',
  templateUrl: './payment-menu-dropdown.component.html',
  styleUrls: ['./payment-menu-dropdown.component.scss'],
})
export class PaymentMenuDropdownComponent {
  open = false;

  PaymentMenuDropdownItems: PaymentMenuDropdownItem[] = [
    { title: 'payments.payment-type-student', route: '/payments/student' },
    { title: 'payments.payment-type-teacher', route: '/payments/teacher' },
    { title: 'payments.payment-type-office', route: '/payments/office' },
    { title: 'payments.payment-type-boss', route: '/payments/boss' },
  ];

  constructor(private router: Router) {
  }

  onClick(route: string) {
    this.router.navigate([route]).then();
  }
}
