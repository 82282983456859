<tui-select
  (tuiValueChanges)="onChange($event)"
  *tuiLet="optionsArray as items"
  [formControlName]="fieldName"
  [tuiTextfieldLabelOutside]="true"
  [valueContent]="selectedOptionsArray ? stringify(selectedOptionsArray) : loading"
  class="b-form"
>
  <ng-template let-value="value" tuiValueLabel>
    {{ value.label }}
  </ng-template>
  <ng-template tuiDataList>
    <tui-data-list *ngIf="items; else loading">
      <button
        *ngFor="let item of items"
        [value]="item?.id"
        tuiOption
      >
        {{ item?.label }}
      </button>
    </tui-data-list>
  </ng-template>
  <ng-template #loading>
    <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
  </ng-template>
</tui-select>
