import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { UserBody } from '../../../interfaces/body/user-body';
import { HttpStatusCode } from '@angular/common/http';
import { pushTypes } from '../../../enums/push-types';
import { PushService } from '../../../services/push.service';
import { TranslateService } from '@ngx-translate/core';
import { phoneValidator } from '../../../validators/phone-validator';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  accountFormGroup: FormGroup | undefined;
  currentUser: User | null | undefined;
  pushNotificationPatchedSuccess: string = '';
  pushNotificationPatchedError: string = '';
  pushNotificationInviteError: string = '';

  constructor(
    private fb: FormBuilder,
    private pushService: PushService,
    private translateService: TranslateService,
    private auth: AuthService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    // get current user
    this.currentUser = this.auth.getLoggedInUser();
    // init form
    this.initForm();

    this.userService.getUserById(this.currentUser!.id).subscribe((user) => {
      this.currentUser = user;
      this.auth.setLoggedInUser(user);
      // update form
      this.initForm();
    });

    //   i18n
    this.translateService
      .get([
        'user-profile.push-success-patched',
        'user-profile.push-error-patched',
        'user-profile.push-success-deleted',
        'user-profile.push-error-deleted',
        'user-profile.push-error-invite',
      ])
      .subscribe((translations) => {
        this.pushNotificationPatchedSuccess =
          translations['user-profile.push-success-patched'];
        this.pushNotificationPatchedError =
          translations['user-profile.push-error-patched'];
        this.pushNotificationInviteError =
          translations['user-profile.push-error-invite'];
      });
  }

  initForm() {
    this.accountFormGroup = this.fb.group({
      firstName: [this.currentUser?.firstName, [Validators.required]],
      lastName: [this.currentUser?.lastName, [Validators.required]],
      email: [this.currentUser?.email, [Validators.required]],
      phone: [this.currentUser?.phone, [phoneValidator()]],
      password: ['', []],
    });
  }

  // submit the form
  submitForm() {
    // if form invalid, do nothing
    if (this.accountFormGroup?.invalid) return;

    const tenantId = this.auth.getTenantId();
    if (tenantId == null) return;

    // access form data
    const updateUser: UserBody = {
      firstName: this.accountFormGroup?.get('firstName')?.value,
      lastName: this.accountFormGroup?.get('lastName')?.value,
      email: this.accountFormGroup?.get('email')?.value,
      phone: this.accountFormGroup?.get('phone')?.value,
      active: this.currentUser!.active,
      roleId: this.currentUser!.roleId,
      tenantId: tenantId,
    };

    if (!this.currentUser?.id) return;

    const hasPassword = this.accountFormGroup?.get('password')?.value;

    if (hasPassword !== null || undefined) {
      updateUser.password = hasPassword;
    }

    // update User
    this.userService
      .updateUser(updateUser, this.currentUser?.id)
      .subscribe((statusCode) => {
        if (statusCode == HttpStatusCode.Ok) {
          this.pushService.sendPush(
            pushTypes.SUCCESS,
            this.pushNotificationPatchedSuccess,
          );
        } else if (statusCode == HttpStatusCode.Conflict) {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationInviteError,
          );
        } else {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationPatchedError,
          );
        }
      });
  }
}
