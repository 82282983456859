<div class="wrapper">
  <tui-loader
    [overlay]="true"
    [showLoader]="!!(isLoading$ | async)"
    [size]="'xl'"
  >
    <!--  Header  -->
    <div class="title-container">
      <div class="title">
        <tui-svg (click)="goBack()" src="tuiIconArrowLeftCircleLarge"></tui-svg>
        <h1>{{ "payments.payment-details.page-title" | translate }}</h1>
      </div>

      <button
        (click)="openDeleteDialog()"
        *ngIf="isSuperAdminOrTenantAdmin"
        appearance="secondary-destructive"
        size="m"
        tuiButton
        type="button"
      >
        {{ "common.button-delete" | translate }}
      </button>
    </div>

    <div class="container-wrapper">
      <div class="details-with-amount">
        <div class="top">
          <div class="circle">
            <tui-svg src="tuiIconUserLarge"></tui-svg>
          </div>
          <p class="text-column">
            <span class="transaction-text"
              >{{ transaction?.personName ?? "" }}
            </span>
            <br />
            <span class="transaction-attribute-label">{{
              "payments.payment-details.label-user-in-question" | translate
            }}</span>
          </p>
        </div>
        <div class="bottom">
          <span class="transaction-attribute-label">{{
            "payments.payment-details.label-amount" | translate
          }}</span>
          <span class="transaction-text"
            >{{ getPositiveAmount(transaction?.amount ?? 0) }} €</span
          >
        </div>
      </div>

      <div class="details-with-files">
        <div class="detail-row">
          <p>
            <span class="transaction-attribute-label"
              >{{
                "payments.payment-details.label-payment-type" | translate
              }}
              :</span
            >
            <br />
            <span class="transaction-text">{{
              transaction?.transactionTypeString ?? "" | translate
            }}</span>
          </p>
        </div>
        <div class="detail-row">
          <p>
            <span class="transaction-attribute-label"
              >{{
                "payments.payment-details.label-created-at" | translate
              }}
              :</span
            >
            <br />
            <span class="transaction-text"
              >{{ transaction?.createdAt | date: "dd.MM.yyyy - HH:mm" }}
              {{ "table.time-indicator" | translate }}</span
            >
          </p>
        </div>

        <div class="detail-row">
          <p>
            <span class="transaction-attribute-label"
              >{{
                "payments.payment-details.label-created-by" | translate
              }}
              :</span
            >
            <br />
            <span class="transaction-text"
              >{{ transaction?.userName ?? "" }}
            </span>
          </p>
        </div>

        <div class="detail-row">
          <div class="payment-column-wrapper">
            <p style="margin-bottom: 0.2rem">
              <span class="transaction-attribute-label"
                >{{
                  "payments.payment-details.label-comment" | translate
                }}
                :</span
              >
            </p>
            <div style="max-width: 20rem !important">
              <tui-textarea
                [formControl]="commentControl"
                [tuiTextfieldLabelOutside]="true"
              >
              </tui-textarea>
              <button
                (click)="updateComment()"
                appearance="primary"
                class="tui-space_bottom-3 tui-space_top-3"
                size="s"
                tuiButton
                type="button"
              >
                {{ "common.button-save" | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          *ngIf="this.otherFile != undefined"
          class="detail-row no-border-bottom"
        >
          <p>
            <span class="transaction-attribute-label"
              >{{ "payments.payment-details.label-files" | translate }}:</span
            >
            <br />
          </p>
          <div class="file-row">
            <div
              (click)="getOtherFileWithFileId(this.otherFile)"
              class="file-container"
            >
              <div class="circle">
                <tui-svg src="tuiIconFileTextLarge"></tui-svg>
              </div>
              {{ this.otherFile.fileName }}
            </div>
          </div>
        </div>

        <div
          *ngIf="this.imageFile != undefined"
          class="detail-row no-border-bottom"
        >
          <p>
            <span class="transaction-attribute-label"
              >{{ "payments.payment-details.label-images" | translate }}:</span
            >
            <br />
          </p>
          <div class="image-row">
            <div
              (click)="openImageDialogWithFile(this.imageFile)"
              class="image-container"
            >
              <img
                [src]="this.imageFile.url"
                alt="file"
                height="100"
                width="100"
              />
              <br />
              <span>{{ this.imageFile.fileName }}</span>
            </div>
          </div>
        </div>

        <!--    FileUpload - not needed anymore?  -->
        <!--        <div class="detail-row no-border-bottom">-->

        <!--          <p>-->
        <!--            <span class="transaction-attribute-label">{{"payments.payment-details.label-upload" | translate}}:</span>-->
        <!--            <br>-->
        <!--          </p>-->

        <!--          <div class="file-input-wrapper">-->

        <!--            &lt;!&ndash;   file input ~ example: bill as pdf   &ndash;&gt;-->
        <!--            <tui-input-files-->
        <!--              [formControl]="fileControl"-->
        <!--              [multiple]="true"-->
        <!--              class="control"-->
        <!--            >-->
        <!--              <ng-template>-->
        <!--                <div class="content">-->
        <!--                  <tui-marker-icon-->
        <!--                    mode="link"-->
        <!--                    src="tuiIconUploadCloudLarge"-->
        <!--                  ></tui-marker-icon>-->
        <!--                  <div>-->
        <!--                    {{ 'payments.new-payment.file-upload-info' | translate }}-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </ng-template>-->
        <!--            </tui-input-files>-->

        <!--            <tui-files [class.space-top]="hasFiles">-->
        <!--              <tui-file-->
        <!--                *ngFor="let file of fileControl.valueChanges | async"-->
        <!--                [file]="file"-->
        <!--                [leftContent]="content"-->
        <!--                [showDelete]="fileControl.enabled"-->
        <!--                (removed)="removeFile(file)"-->
        <!--              >-->
        <!--              </tui-file>-->
        <!--            </tui-files>-->

        <!--            <ng-template #content>-->
        <!--              <tui-svg [src]="tuiIconFile"></tui-svg>-->
        <!--            </ng-template>-->

        <!--            <button-->
        <!--              (click)="uploadFiles()"-->
        <!--              [disabled]="!fileControl.valid"-->
        <!--              size="l"-->
        <!--              tuiButton-->
        <!--              type="submit">-->
        <!--              {{ 'payments.payment-details.button-upload' | translate }}-->
        <!--            </button>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </tui-loader>
</div>

<!--    <div class="max-width-51rem">-->
<!--      <div class="title"></div>-->
<!--      <div class="detail-container">-->

<!--        <div class="payment-column-wrapper">-->
<!--          <p>-->
<!--            <span class="transaction-attribute-label">{{"payments.payment-details.label-created-at" | translate}}:</span>-->
<!--            <br>-->
<!--            <span class="transaction-text">{{transaction?.createdAt | date: 'dd.MM.yyyy - HH:mm' }} {{"table.time-indicator" | translate}}</span>-->
<!--          </p>-->
<!--        </div>-->

<!--        <div class="payment-column-wrapper">-->
<!--          <p>-->
<!--            <span class="transaction-attribute-label">{{"payments.payment-details.label-payment-type" | translate}}:</span>-->
<!--            <br>-->
<!--            <span class="transaction-text">{{transaction?.transactionTypeString ?? '' | translate}}</span>-->
<!--          </p>-->
<!--        </div>-->

<!--        <div class="payment-column-wrapper">-->
<!--          <p>-->
<!--            <span class="transaction-attribute-label">{{"payments.payment-details.label-amount" | translate}}:</span>-->
<!--            <br>-->
<!--            <span class="transaction-text">{{ getPositiveAmount(transaction?.amount ?? 0)}} €</span>-->
<!--          </p>-->
<!--        </div>-->

<!--        <div class="payment-column-wrapper">-->
<!--          <p>-->
<!--            <span class="transaction-attribute-label">{{"payments.payment-details.label-user-in-question" | translate}}:</span>-->
<!--            <br>-->
<!--            <span class="transaction-text">{{ transaction?.personName ?? ''}} </span>-->
<!--          </p>-->
<!--        </div>-->

<!--        <div class="payment-column-wrapper">-->
<!--          <p>-->
<!--            <span class="transaction-attribute-label">{{"payments.payment-details.label-created-by" | translate}}:</span>-->
<!--            <br>-->
<!--            <span class="transaction-text">{{ transaction?.userName ?? ''}} </span>-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="payment-column-wrapper">-->
<!--        <p>-->
<!--          <span class="transaction-attribute-label">{{"payments.payment-details.label-comment" | translate}}:</span>-->
<!--          <br>-->
<!--          <span class="transaction-text">{{ (transaction?.comment == '' || transaction?.comment == null) ? '-': transaction?.comment}} </span>-->
<!--        </p>-->
<!--      </div>-->

<!--      &lt;!&ndash;  Files    &ndash;&gt;-->
<!--      <div *ngIf="this.otherFiles.length > 0" class="payment-file-column-wrapper">-->
<!--        <p>-->
<!--          <span class="transaction-attribute-label">{{"payments.payment-details.label-files" | translate}}:</span>-->
<!--          <br>-->
<!--        </p>-->
<!--        <div class="file-row">-->
<!--          <div *ngFor="let otherFile of otherFiles" class="file-container" (click)="getOtherFileWithFileId(otherFile)">-->
<!--            {{otherFile.fileName}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash;  Images    &ndash;&gt;-->
<!--      <div *ngIf="this.imageFiles.length > 0" class="payment-file-column-wrapper" >-->
<!--        <p>-->
<!--          <span class="transaction-attribute-label">{{"payments.payment-details.label-images" | translate}}:</span>-->
<!--          <br>-->
<!--        </p>-->
<!--        <div class="file-row">-->
<!--          <div *ngFor="let imageFile of imageFiles" class="image-container" (click)="openImageDialogWithFile(imageFile)">-->
<!--            <img [src]="imageFile.url" width="100" height="100">-->
<!--            <br>-->
<!--            <span>{{imageFile.fileName}}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<ng-template
  [(tuiDialog)]="openImageDialog"
  [tuiDialogOptions]="{ size: 'fullscreen', dismissible: true }"
  let-observer
>
  <div class="center-content">
    <img [src]="shownImageUrl" class="sized-container" />
  </div>
</ng-template>

<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="showDeleteDialog = false; deleteEvent()"
  [isOpen]="showDeleteDialog"
/>
