import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiRoutes } from '../enums/api-routes';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Tenant } from '../models/tenant';

type StudentCount = {
  customerCount: number;
};

type EmployeeCount = {
  userCount: number;
};

type LocationCount = {
  locationCount: number;
};

type ChartData = {
  chartData: number[];
};

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  readonly dashboardUrl = `${environment.baseUrl}${ApiRoutes.KPI}/${ApiRoutes.DASHBOARD}/`;

  getChartData(tenantId: string): Observable<number[]> {
    const params = new HttpParams().set('tenantId', tenantId);
    return this.http
      .get<ChartData>(this.dashboardUrl + ApiRoutes.CHART_DATA, { params })
      .pipe(
        map((response: ChartData) => {
          return response.chartData;
        }),
      );
  }

  getLocationsCount(tenantId: string): Observable<number> {
    const params = new HttpParams().set('tenantId', tenantId);
    return this.http
      .get<LocationCount>(this.dashboardUrl + ApiRoutes.LOCATIONS, { params })
      .pipe(
        map((response: LocationCount) => {
          return response.locationCount;
        }),
      );
  }

  getEmployeesCount(tenantId: string): Observable<number> {
    const params = new HttpParams().set('tenantId', tenantId);
    return this.http
      .get<EmployeeCount>(this.dashboardUrl + ApiRoutes.EMPLOYEES, { params })
      .pipe(
        map((response: EmployeeCount) => {
          return response.userCount;
        }),
      );
  }

  getStudentsCount(tenantId: string): Observable<number> {
    const params = new HttpParams().set('tenantId', tenantId);
    return this.http
      .get<StudentCount>(this.dashboardUrl + ApiRoutes.STUDENTS, { params })
      .pipe(
        map((response: StudentCount) => {
          return response.customerCount;
        }),
      );
  }

  getTotalLessonsCount(tenantId: string): Observable<number> {
    const params = new HttpParams().set('tenantId', tenantId);
    return this.http
      .get<any>(this.dashboardUrl + ApiRoutes.LESSONS, { params })
      .pipe(
        map((response) => {
          return response.lessonsCount;
        }),
      );
  }
}
