import { Component } from '@angular/core';
import { tuiIconTrash, tuiIconX } from '@taiga-ui/icons';
import { Location } from '../../../models/location';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from '../../../services/location.service';
import { PushService } from '../../../services/push.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../services/loading.service';
import { AuthService } from '../../../services/auth.service';
import { LocationBody } from '../../../interfaces/body/location-body';
import { pushTypes } from '../../../enums/push-types';
import { NavRoutes } from '../../../enums/nav-routes';
import { UserRole } from '../../../enums/user-role';
import { LessonCategoryBody } from '../../../interfaces/body/lesson-category-body';
import { LessonCategoryService } from '../../../services/lesson-category.service';
import { LessonCategory } from '../../../models/lesson-category';

@Component({
  selector: 'app-lesson-category-detail-page',
  templateUrl: './lesson-category-detail-page.component.html',
  styleUrls: ['./lesson-category-detail-page.component.scss'],
})
export class LessonCategoryDetailPageComponent {
  categoryId = '';
  lessonCategory!: LessonCategory;
  isLoading$ = this.loadingService.getLoadingState();
  sendRequest = false;

  // to determine if the dialog is open or not
  isOpen = true;
  showDeleteDialog: boolean = false;

  // icons
  protected readonly tuiIconX = tuiIconX;
  protected readonly tuiIconTrash = tuiIconTrash;

  readonly lessonCategoryForm: FormGroup = new FormGroup({
    name: new FormControl(``, [Validators.required]),
  });

  // Strings for i18n
  pushNotificationPatchedSuccess: string = '';
  pushNotificationPatchedError: string = '';
  pushNotificationDeletedSuccess: string = '';
  pushNotificationDeletedError: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private lessonCategoryService: LessonCategoryService,
    private pushService: PushService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.getLocationWithRouteParam();

    // translated strings for push-notifications
    this.translateService
      .get([
        'lesson-category.push-success-patched',
        'lesson-category.push-error-patched',
        'lesson-category.push-success-deleted',
        'lesson-category.push-error-deleted',
      ])
      .subscribe((translations) => {
        this.pushNotificationPatchedSuccess =
          translations['lesson-category.push-success-patched'];
        this.pushNotificationPatchedError =
          translations['lesson-category.push-error-patched'];
        this.pushNotificationDeletedSuccess =
          translations['lesson-category.push-success-deleted'];
        this.pushNotificationDeletedError =
          translations['lesson-category.push-error-deleted'];
      });

    // disable form for manager
    if (this.isManager()) {
      this.lessonCategoryForm.disable();
    }
  }

  /**
   * Submits the form values to the api in order to update the location
   * Sends a push notification if the user was updated successfully or not
   */
  submitForm(): void {
    if (
      this.lessonCategoryForm.valid &&
      this.lessonCategoryForm.touched &&
      !this.sendRequest
    ) {
      this.sendRequest = true;
      const lessonCategoryBody: LessonCategoryBody = {
        name: this.lessonCategoryForm.controls['name'].value,
      };

      this.lessonCategoryService
        .updateCategory(lessonCategoryBody, this.categoryId)
        .subscribe((isPatched) => {
          if (isPatched) {
            this.pushService.sendPush(
              pushTypes.SUCCESS,
              this.pushNotificationPatchedSuccess,
            );
            this.setCloseEvent();
            this.lessonCategoryForm.disable();
            this.lessonCategory.name =
              this.lessonCategoryForm.controls['name'].value;
          } else {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationPatchedError,
            );
            this.setCloseEvent();
          }
        });
    } else if (this.lessonCategoryForm.invalid) {
      this.lessonCategoryForm.markAllAsTouched();
      this.lessonCategoryForm.markAsDirty();
    }
  }

  /**
   * Deletes the location with the id from the route parameter
   * Sends a push notification if the location was deleted successfully or not
   */
  deleteCategory() {
    if (this.sendRequest) return;
    this.sendRequest = true;
    this.lessonCategoryService
      .deleteCategory(this.categoryId)
      .subscribe((isDeleted) => {
        if (isDeleted) {
          this.pushService.sendPush(
            pushTypes.SUCCESS,
            this.pushNotificationDeletedSuccess,
          );
          this.setCloseEvent();
        } else {
          this.pushService.sendPush(
            pushTypes.ERROR,
            this.pushNotificationDeletedError,
          );
          this.setCloseEvent();
        }
      });
  }

  /**
   * Gets the location from the api with the id from the route parameter
   * Sets the form values to the location values in order to edit them
   */
  public getLocationWithRouteParam(): void {
    this.route.paramMap.subscribe((params) => {
      this.categoryId = params.get('id')!;
      if (this.categoryId != null) {
        this.lessonCategoryService.getCategoryById(this.categoryId).subscribe(
          (category) => {
            this.lessonCategory = category;
            this.lessonCategoryForm.controls['name'].setValue(
              this.lessonCategory.name,
            );
          },
          (error) => {
            this.router.navigate([NavRoutes.ERROR], {
              queryParams: {
                code: error.error.code,
                key: error.error.key,
                url: error.url,
              },
            });
          },
        );
      }
    });
  }

  /**
   * Closes the dialog and navigates back to the user page
   */
  setCloseEvent() {
    this.sendRequest = false;
    this.router.navigate([NavRoutes.SETTINGS, NavRoutes.LESSON_CATEGORY]);
    this.isOpen = false;
  }

  public isManager(): boolean {
    return this.authService.getUserRole() === UserRole.MANAGER;
  }
}
