import { Component } from '@angular/core';
import { Role } from '../../../models/role';
import { RoleService } from '../../../services/role.service';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { SuperAdminService } from '../../../services/super-admin.service';
import { NavRoutes } from '../../../enums/nav-routes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-users',
  templateUrl: './global-users.component.html',
  styleUrls: ['./global-users.component.scss'],
})
export class GlobalUsersComponent {
  public isOpen: boolean = false;
  public globalRoles!: Role[];
  public globalUsers: User[] = [];

  // names for corresponding object properties
  readonly userTableShownColumns: string[] = ['email', 'active'];

  constructor(
    private roleService: RoleService,
    private superAdminService: SuperAdminService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getSuperGlobalRole();
  }

  getSuperGlobalRole() {
    this.roleService
      .getRoles({ global: true, draft: false })
      .subscribe((roles) => {
        this.globalRoles = roles;
        this.superAdminService
          .getGlobalUsers(this.globalRoles)
          .subscribe((users) => {
            this.globalUsers = users;
          });
      });
  }

  usersRowClickEvent(id: string) {
    this.router.navigateByUrl(
      `${NavRoutes.SETTINGS}/${NavRoutes.GLOBAL_USERS}/${id}`,
    );
  }
}
