import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiPoint } from '@taiga-ui/core';
import { TuiContextWithImplicit } from '@taiga-ui/cdk';
import { Months } from '../../enums/months';
import { TenantService } from '../../services/tenant.service';
import { Tenant } from '../../models/tenant';
import { LoadingService } from '../../services/loading.service';
import { NavRoutes } from '../../enums/nav-routes';
import { TranslateService } from '@ngx-translate/core';
import {
  tuiIconCalendarLarge,
  tuiIconMapPinLarge,
  tuiIconSettingsLarge,
  tuiIconUserLarge,
  tuiIconUsersLarge,
} from '@taiga-ui/icons';
import { Observable, of } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';

/**
 * The dashboard page component which is used as the detail view for a tenant
 */
@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent {
  public tenant!: Tenant;
  public months: Months[] = Object.values(Months);
  public values: TuiPoint[] = [];
  public observableValues$: Observable<TuiPoint[]> = of([]);
  public customersCount: number = 0;
  public locationsCount: number = 0;
  public appointmentsCount: number = 0;
  public usersCount: number = 0;
  public isLoading$ = this.loadingService.getLoadingState();
  public highestArrayValue: number = 100;
  public appointmentsTitle: string = '';
  protected readonly NavRoutes = NavRoutes;
  protected readonly tuiIconUsersLarge = tuiIconUsersLarge;
  protected readonly tuiIconUserLarge = tuiIconUserLarge;
  protected readonly tuiIconMapPinLarge = tuiIconMapPinLarge;
  protected readonly tuiIconSettingsLarge = tuiIconSettingsLarge;
  protected readonly tuiIconCalendarLarge = tuiIconCalendarLarge;
  private tenantId!: string | null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tenantService: TenantService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private dashboardService: DashboardService,
  ) {
  }

  readonly hintContent = ({
                            $implicit,
                          }: TuiContextWithImplicit<readonly TuiPoint[]>): string =>
    `${ $implicit[0][1] } ${ this.appointmentsTitle }`;

  ngOnInit(): void {
    this.getTenantWithRouteParam();

    // translated strings for push-notifications
    this.translateService
      .get(['dashboard.tile-appointments'])
      .subscribe((translations) => {
        this.appointmentsTitle = translations['dashboard.tile-appointments'];
      });
  }

  /**
   * Gets the counts of students, users, locations and appointments
   */
  getDashboardData(): void {
    if (this.tenantId == null) return;

    this.dashboardService
      .getStudentsCount(this.tenantId)
      .subscribe((students) => {
        this.customersCount = students;
      });

    this.dashboardService
      .getEmployeesCount(this.tenantId)
      .subscribe((users) => {
        this.usersCount = users;
      });

    this.dashboardService
      .getLocationsCount(this.tenantId)
      .subscribe((locations) => {
        this.locationsCount = locations;
      });

    this.dashboardService
      .getTotalLessonsCount(this.tenantId)
      .subscribe((lessons) => {
        this.appointmentsCount = lessons;
      });

    this.dashboardService.getChartData(this.tenantId).subscribe((chartData) => {
      this.values = chartData.map((value, index) => [index, value]);
      this.highestArrayValue = this.getHighestArrayValue();
      this.observableValues$ = of(this.values);
    });
  }

  /**
   * Gets the tenant from the route parameter by id
   * If there is an error, navigate to the error page and pass the error code and key
   */
  public getTenantWithRouteParam(): void {
    this.route.paramMap.subscribe((params) => {
      this.tenantId = params.get('id');
      if (this.tenantId != null) {
        this.tenantService.getTenantById(this.tenantId).subscribe(
          (tenant) => {
            this.tenant = tenant;
          },
          // if there is an error, navigate to the error page and pass the error code and key
          (error) => {
            this.router.navigate([NavRoutes.ERROR], {
              queryParams: {
                code: error.error.code,
                key: error.error.key,
                url: error.url,
              },
            });
          },
        );
        this.getDashboardData();
      }
    });
  }

  /**
   * Gets the highest value from the array of values to set the y-axis of the chart
   */
  public getHighestArrayValue(): number {
    const columnValues = this.values.map((value) => value[1]);
    return Math.max(...columnValues);
  }
}
