<div class="table-header">
  <h1>{{ 'settings.global-user-tab.page-title' | translate }}</h1>
  <button
    id="new-company"
    tuiButton
    type="button"
    size="m"
    appearance="primary"
    (click)="isOpen = true"
  >
    {{ 'settings.global-user-tab.create-button' | translate }}
  </button>
</div>
<app-table
  [tableColumnNames]="[
        'user.table-mail' | translate,
        'user.table-active' | translate,
      ]"
  [columns]="userTableShownColumns"
  [tableData]="globalUsers"
  [showPagination]="false"
  (rowClickEvent)="usersRowClickEvent($event)"
></app-table>
<!-- injecting children -->
<router-outlet></router-outlet>

<app-new-global-user
  [isOpen]="isOpen"
  (isCreatedEvent)="this.getSuperGlobalRole();"
  [globalRoles]="globalRoles"
  (closeEvent)="isOpen = false"
></app-new-global-user>
