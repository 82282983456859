import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * Initialize translate service for i18n support by ngx-translate.
   * See: https://github.com/ngx-translate/core
   *
   * Reads the current language from the local storage if available.
   */
  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.languageService.initLanguage();
  }
}
