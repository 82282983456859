import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LessonBody } from "../../../interfaces/body/lesson-body";
import { pushTypes } from "../../../enums/push-types";
import { SuperAdminService } from "../../../services/super-admin.service";
import { PushService } from "../../../services/push.service";
import { AuthService } from "../../../services/auth.service";
import { LessonService } from "../../../services/lesson.service";
import { UserService } from "../../../services/user.service";
import { StudentService } from "../../../services/student.service";
import { Student } from "../../../models/student";
import { User } from "../../../models/user";
import { DropDownItem } from "../../../interfaces/drop-down-item";
import { Lesson } from "../../../models/lesson";
import { HttpStatusCode } from "@angular/common/http";
import { TuiDay, TuiTime } from "@taiga-ui/cdk";
import { UtilService } from "../../../services/util.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { UserRole } from "../../../enums/user-role";
import { RoleService } from "../../../services/role.service";
import { LocationService } from "../../../services/location.service";
import { Location } from "../../../models/location";
import { LicenceType } from "../../../models/licence-type";
import { LessonCategory } from "../../../models/lesson-category";
import { LicenceTypeService } from "../../../services/licence-type.service";
import { DateTime } from "luxon";
import { Router } from "@angular/router";
import { NavRoutes } from "../../../enums/nav-routes";
import { CalendarService } from "../../../services/calendar.service";
import { LicenceTarget } from "../../../models/licence-target";
import { emptyDropdownItemValidator } from "../../../validators/empty-dropdown-item-validator";
import { TransactionService } from "../../../services/transaction.service";

enum FormStatus {
  NOTHING_FILLED,
  LOCATION_FILLED,
  STUDENT_AND_LOCATION_FILLED,
  LICENSE_TYPE_FILLED,
  ALL_DROPDOWNS_FILLED,
}

@Component({
  selector: "app-new-event",
  templateUrl: "./new-event.component.html",
  styleUrls: ["./new-event.component.scss"],
})

/**
 * The side modal component for creating a new event.
 * It contains a form for the event data and a button to create the event.
 *
 * @param isOpen: boolean - whether the sidebar is open or not
 * @param closeEvent: EventEmitter<boolean> - emits the close event to the parent component
 * @param isCreatedEvent: EventEmitter<boolean> - emits the isCreated event to the parent component
 */
export class NewEventComponent {
  @Input() isOpen: boolean = true;
  startJSDate: Date = new Date();
  endJSDate!: Date | undefined;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() createdLesson = new EventEmitter<Lesson>();
  currentDay = TuiDay.fromLocalNativeDate(new Date());
  currentTime = TuiTime.fromString(
    this.utilService.getFormattedTimeByDate(this.startJSDate),
  );

  emptyDropdownItem: DropDownItem = {
    id: "",
    label: "",
  };

  sendRequest: boolean = false;

  // the form to add events to the calendar
  readonly eventForm = new FormGroup({
    startDate: new FormControl(this.currentDay, [Validators.required]),
    startTime: new FormControl(this.currentTime, [Validators.required]),
    endTime: new FormControl(this.currentTime, [Validators.required]),
    location: new FormControl(this.emptyDropdownItem, [
      Validators.required,
      emptyDropdownItemValidator(),
    ]),
    category: new FormControl(this.emptyDropdownItem, [
      Validators.required,
      emptyDropdownItemValidator(),
    ]),
    assignedToTeacher: new FormControl(this.emptyDropdownItem, [
      Validators.required,
      emptyDropdownItemValidator(),
    ]),
    assignedToStudent: new FormControl(this.emptyDropdownItem, [
      Validators.required,
      emptyDropdownItemValidator(),
    ]),
    licenceType: new FormControl(this.emptyDropdownItem, [
      Validators.required,
      emptyDropdownItemValidator(),
    ]),
    userNotification: new FormControl(true),
    studentNotification: new FormControl(true),
    counter: new FormControl(1, [
      Validators.required,
      Validators.min(1),
      Validators.max(3),
    ]),
  });

  // needed for create event
  tenantId: string = "";
  currentUser: User = this.authService.getLoggedInUser()!;

  // for the dropdown list of all teachers
  teachers: User[] = [];
  selectedTeacherId = "";
  dropDownUserItems: DropDownItem[] = [];

  // for the dropdown list of all students
  students: Student[] = [];
  selectedStudentId: string = "";
  dropDownStudentItems: DropDownItem[] = [];

  // locations
  locations: Location[] = [];
  selectedLocationId: string = "";
  locationDropdownItems: DropDownItem[] = [];

  // licence types
  licenceTypes: LicenceType[] = [];
  selectedLicenceTypeId: string = "";
  licenceTypeDropdownItems: DropDownItem[] = [];

  // licence targets
  licenceTargetsForType: LicenceTarget[] = [];

  // categories
  categories: LessonCategory[] = [];
  categoryDropdownItems: DropDownItem[] = [];

  lessonPrice: number = 0;
  studentWalletValue: number | undefined;

  // Strings for i18n
  pushNotificationCreatedSuccess: string = "";
  pushNotificationCreatedConflictCollision: string = "";
  pushNotificationCreatedConflictNotEnoughCredit: string = "";
  pushNotificationCreatedError: string = "";
  protected readonly TuiDay = TuiDay;
  private selectedTenantSubscription: Subscription = new Subscription();

  constructor(
    private lessonService: LessonService,
    private userService: UserService,
    private studentService: StudentService,
    private pushService: PushService,
    private authService: AuthService,
    private utilService: UtilService,
    private translateService: TranslateService,
    private superAdminService: SuperAdminService,
    private roleService: RoleService,
    private locationService: LocationService,
    private licenceTypeService: LicenceTypeService,
    private router: Router,
    private calendarService: CalendarService,
    private walletService: TransactionService,
  ) {}

  // get array of numbers filled with numbers from 1 to maxCounter for the counter dropdown
  get counterItems(): number[] {
    return Array(this.calendarService.maxLessonCounterValue)
      .fill(0)
      .map((x, i) => i + 1);
  }

  ngOnInit(): void {
    this.getInitialDropdownItems();
    this.getStartAndEndTime();
    this.disableOrEnableFormsDependingOnStatus(FormStatus.NOTHING_FILLED);

    // translated strings for push-notifications
    this.translateService
      .get([
        "new-event.push-success-created",
        "new-event.push-conflict-collision",
        "new-event.push-error-created",
        "new-event.push-conflict-credit",
      ])
      .subscribe((translations) => {
        this.pushNotificationCreatedSuccess =
          translations["new-event.push-success-created"];
        this.pushNotificationCreatedConflictCollision =
          translations["new-event.push-conflict-collision"];
        this.pushNotificationCreatedConflictNotEnoughCredit =
          translations["new-event.push-conflict-credit"];
        this.pushNotificationCreatedError =
          translations["new-event.push-error-created"];
      });
  }

  ngOnChanges(): void {
    // clear the form
    this.eventForm.markAsUntouched();
  }

  ngOnDestroy(): void {
    this.selectedTenantSubscription.unsubscribe();
  }

  getStartAndEndTime() {
    if (
      this.calendarService.selectedStartTime != null &&
      this.calendarService.selectedEndTime != null
    ) {
      // set time with the selected time from the calendar component
      this.setStartAndEndTimeWithSelection();
    } else {
      // generate a new time for the event
      this.setNewStartAndEndTime();
    }
  }

  /**
   * Sets the start and end time of the event form with the selected time from the calendar component
   */
  setStartAndEndTimeWithSelection() {
    this.startJSDate = this.calendarService.selectedStartTime;
    this.endJSDate = this.calendarService.selectedEndTime;

    this.eventForm.controls.startDate.setValue(
      TuiDay.fromUtcNativeDate(this.startJSDate),
    );

    if (this.endJSDate?.getTime() == this.startJSDate.getTime()) {
      this.setNewStartAndEndTime();
    } else {
      const startTimeWithoutOffset = DateTime.fromJSDate(
        this.startJSDate,
      ).toUTC();
      const endTimeWithoutOffset = DateTime.fromJSDate(this.endJSDate).toUTC();

      this.eventForm.controls.startTime.setValue(
        new TuiTime(startTimeWithoutOffset.hour, startTimeWithoutOffset.minute),
      );
      if (this.endJSDate) {
        this.eventForm.controls.endTime.setValue(
          new TuiTime(endTimeWithoutOffset.hour, endTimeWithoutOffset.minute),
        );
      }

      const multiplesOf45 = this.calculate45MinuteMultiples(
        this.startJSDate,
        this.endJSDate!,
      );
      this.eventForm.controls.counter.setValue(multiplesOf45);
    }
  }

  calculate45MinuteMultiples(start: Date, end: Date): number {
    const timeDifferenceMs = end.getTime() - start.getTime();
    const timeDifferenceMinutes = timeDifferenceMs / (60 * 1000); // timedifference in minutes
    return Math.floor(timeDifferenceMinutes / 45);
  }

  /**
   * Creates a new start and end time for the event form based on the current time
   */
  setNewStartAndEndTime() {
    this.startJSDate = new Date();
    this.startJSDate = this.roundToNearestQuarterHour(this.startJSDate);
    const newStartTime = new TuiTime(
      this.startJSDate.getHours(),
      this.startJSDate.getMinutes(),
    );
    this.eventForm.controls.startTime.setValue(newStartTime);

    this.endJSDate = new Date(this.startJSDate);
    this.endJSDate.setMinutes(this.endJSDate.getMinutes() + 45);
    const newEndTime = new TuiTime(
      this.endJSDate.getHours(),
      this.endJSDate.getMinutes(),
    );
    this.eventForm.controls.endTime.setValue(newEndTime);
  }

  /**
   * Rounds the given date to the nearest quarter-hour
   */
  roundToNearestQuarterHour(date: Date) {
    const roundedDate = new Date(date);

    const minutes = roundedDate.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;

    roundedDate.setMinutes(roundedMinutes);
    roundedDate.setSeconds(0);

    return roundedDate;
  }

  /**
   * Disables or enables the dropdown forms depending on the status of the form
   */
  disableOrEnableFormsDependingOnStatus(status: FormStatus) {
    switch (status) {
      case FormStatus.NOTHING_FILLED:
        this.eventForm.controls.category.disable();
        this.eventForm.controls.assignedToTeacher.disable();
        this.eventForm.controls.licenceType.disable();
        this.eventForm.controls["endTime"].disable();
        break;
      case FormStatus.LOCATION_FILLED:
        this.eventForm.controls.assignedToTeacher.enable();
        break;
      case FormStatus.STUDENT_AND_LOCATION_FILLED:
        this.eventForm.controls.licenceType.enable();
        this.getTeacherOfLastLesson();
        break;
      case FormStatus.LICENSE_TYPE_FILLED:
        this.eventForm.controls.category.enable();
        break;
      case FormStatus.ALL_DROPDOWNS_FILLED:
        break;
    }
  }

  /**
   * Resets all parts of the form except the location dropdown and the time
   */
  resetDropdownFormsUntilLocation() {
    this.eventForm.controls.category.setValue(this.emptyDropdownItem);
    this.eventForm.controls.assignedToTeacher.setValue(this.emptyDropdownItem);
    this.eventForm.controls.assignedToStudent.setValue(this.emptyDropdownItem);
    this.eventForm.controls.licenceType.setValue(this.emptyDropdownItem);
  }

  onStudentChange(value: DropDownItem | null) {
    // check if value really changed
    if (
      value == null ||
      value == this.emptyDropdownItem ||
      this.selectedStudentId == value["id"]
    )
      return;

    this.getWalletAmount(value);

    this.studentService.getStudentById(value["id"]).subscribe((student) => {
      if (student == null) return;
      const location = this.locations.find(
        (location) => location.id === student.locationId,
      );
      if (location == null) return;
      this.eventForm.controls["location"].setValue({
        id: student.locationId,
        label: location.name,
      });
    });
    this.selectedStudentId = value["id"];
    this.getLicenceTypesForStudent(value["id"]);
    this.disableOrEnableFormsDependingOnStatus(
      FormStatus.STUDENT_AND_LOCATION_FILLED,
    );
  }

  getWalletAmount($event: DropDownItem | null) {
    this.selectedStudentId = $event?.id ?? "";
    this.walletService
      .getStudentWalletValue(this.selectedStudentId)
      .subscribe((res) => {
        this.studentWalletValue = res.amount;
      });
  }

  onTeacherChange(value: DropDownItem | null) {
    this.selectedTeacherId = value?.id as string;
  }

  onLocationChange(value: DropDownItem | null) {
    // check if value really changed
    if (
      value == null ||
      value == this.emptyDropdownItem ||
      this.selectedLocationId == value["id"]
    )
      return;

    this.getStudentForLocation(value["id"]);
    this.getTenantForUser();

    if (this.selectedLocationId != "") {
      // reset form if the location changes
      this.resetDropdownFormsUntilLocation();
    }
    this.disableOrEnableFormsDependingOnStatus(FormStatus.LOCATION_FILLED);

    this.selectedLocationId = value["id"];
  }

  onLicenceTypeChange(value: DropDownItem | null) {
    if (
      value == null ||
      value == this.emptyDropdownItem ||
      this.selectedLicenceTypeId == value["id"]
    )
      return;

    const selectedLicenceType = this.licenceTypes.find((licenceType) => {
      return licenceType.id === value["id"];
    });

    if (selectedLicenceType == null) return;

    this.getCategoriesForLicenceType(selectedLicenceType.id);

    this.selectedLicenceTypeId = value["id"];

    this.disableOrEnableFormsDependingOnStatus(FormStatus.LICENSE_TYPE_FILLED);
  }

  onCategoryChange(value: DropDownItem | null) {
    this.eventForm.controls.category.valueChanges.subscribe((value) => {
      if (value == null || value == this.emptyDropdownItem) return;

      this.setPriceWithCounter();
    });
  }

  onCounterChange(value: number | null) {
    if (value == null) return;

    this.setEndTimeWithCounter();
    this.setPriceWithCounter();
  }

  onStartTimeChange(value: TuiTime | null) {
    if (value == null) return;

    // when the date is today and the time is in the past, set the time to the current time
    if (this.utilService.datesEqualUpToDay(new Date(), this.startJSDate)) {
      if (
        (this.eventForm.controls.startTime.value as TuiTime) <
        TuiTime.currentLocal()
      ) {
        this.eventForm.controls.startTime.setValue(TuiTime.currentLocal());
      }
    }

    this.setEndTimeWithCounter();
  }

  onStartDateChange(value: TuiDay | null) {
    if (value == null || this.startJSDate == null || this.endJSDate == null)
      return;

    this.startJSDate = new Date(
      value.year,
      value.month,
      value.day,
      this.startJSDate.getHours(),
      this.startJSDate.getMinutes(),
    );

    this.endJSDate = new Date(
      value.year,
      value.month,
      value.day,
      this.endJSDate.getHours(),
      this.endJSDate.getMinutes(),
    );

    // when the date is today and the time is in the past, set the time to the current time
    if (this.utilService.datesEqualUpToDay(new Date(), this.startJSDate)) {
      if (
        (this.eventForm.controls.startTime.value as TuiTime) <
        TuiTime.currentLocal()
      ) {
        this.eventForm.controls.startTime.setValue(TuiTime.currentLocal());
      }
    }

    // notify the calendar to change the selection
    this.notifyCalendarSelectionChange();
  }

  setEndTimeWithCounter() {
    const startDate = this.eventForm.controls.startDate.value as TuiDay;
    const startTime = this.eventForm.controls.startTime.value as TuiTime;
    const counter = this.eventForm.controls.counter.value as number;

    if (this.startJSDate)
      this.startJSDate = new Date(
        startDate.year,
        startDate.month,
        startDate.day,
        startTime.hours,
        startTime.minutes,
      );

    this.endJSDate = new Date(this.startJSDate);

    this.endJSDate.setMinutes(this.endJSDate.getMinutes() + counter * 45);

    // notify the calendar to change the selection
    this.notifyCalendarSelectionChange();

    this.eventForm.controls.endTime.setValue(
      new TuiTime(this.endJSDate.getHours(), this.endJSDate.getMinutes()),
    );
  }

  notifyCalendarSelectionChange() {
    if (this.startJSDate == null || this.endJSDate == null) return;

    this.calendarService.selectedTime$.next({
      startTime: this.startJSDate,
      endTime: this.endJSDate,
    });
  }

  setPriceWithCounter() {
    const counter = this.eventForm.controls.counter.value as number;
    const categoryId = this.eventForm.controls.category.value?.id as string;

    const licenceTarget = this.licenceTargetsForType.find(
      (target) => target.lessonCategoryId === categoryId,
    );
    if (licenceTarget == null) return;

    this.lessonPrice = licenceTarget.price * counter;
  }

  /**
   * Gets the teachers and students from the api to fill the dropdown menu.
   * The teacher and student objects are mapped to DropDownItems to display them in the dropdown menu
   */
  getInitialDropdownItems() {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();

      if (selectedTenantId == null) return;

      this.getStudentForTenant(selectedTenantId);
      this.getLocationsForTenant(selectedTenantId);
    } else {
      this.getStudentForTenant(this.authService.getLoggedInUser()?.tenantId);
      this.getLocationsForTenant(this.authService.getLoggedInUser()?.tenantId);
    }
  }

  getTenantForUser() {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();

      if (selectedTenantId == null) return;
      this.getUserForTenantAndLocation(selectedTenantId);
    } else {
      this.getUserForTenantAndLocation(
        this.authService.getLoggedInUser()?.tenantId,
      );
    }
  }

  getUserForTenantAndLocation(tenantId?: string) {
    this.dropDownUserItems = [];
    this.roleService.getRoles({ tenantId: tenantId }).subscribe((roles) => {
      if (roles.length > 0) {
        const teacherRoleId = roles.find(
          (role) => role.userRole === UserRole.TEACHER,
        )?.id;

        const tenantAdminRoleId = roles.find(
          (role) => role.userRole === UserRole.TENANT_ADMIN,
        )?.id;

        if (teacherRoleId && tenantAdminRoleId) {
          this.userService
            .getUsers({
              tenantId: tenantId,
              roleId: teacherRoleId,
              // locationId: this.selectedLocationId,
              active: true,
            })
            .subscribe((teachers) => {
              this.teachers = [];
              if (teachers.length === 0) return;

              // teachers where location id = selected location id or location id = null
              this.teachers = teachers.filter(
                (teacher) =>
                  teacher.locationId === this.selectedLocationId ||
                  teacher.locationId == null,
              );

              this.dropDownUserItems = this.dropDownUserItems.concat(
                this.utilService.generateDropdownItems(this.teachers),
              );
            });

          this.userService
            .getUsers({
              tenantId: tenantId,
              roleId: tenantAdminRoleId,
              active: true,
            })
            .subscribe((admins) => {
              if (admins.length === 0) return;
              this.dropDownUserItems = this.dropDownUserItems.concat(
                this.utilService.generateDropdownItems(admins),
              );
            });
        }
      }
    });
  }

  getStudentForTenant(tenantId?: string) {
    console.log(tenantId);

    this.studentService
      .getStudent({ tenantId: tenantId, active: true })
      .subscribe((students) => {
        this.students = students;
        this.dropDownStudentItems =
          this.utilService.generateDropdownItems(students);
      });
  }

  getStudentForLocation(locationId?: string) {
    console.log(locationId);
    this.studentService
      .getStudent({ locationId: locationId, active: true })
      .subscribe((students) => {
        this.students = students;
        this.dropDownStudentItems =
          this.utilService.generateDropdownItems(students);
      });
  }

  getLocationsForTenant(tenantId?: string) {
    this.locationService
      .getLocations({ tenantId: tenantId })
      .subscribe((locations) => {
        this.locations = locations;
        this.locationDropdownItems =
          this.utilService.generateDropdownItems(locations);
      });
  }

  getLicenceTypesForStudent(studentId: string) {
    this.studentService.getStudentById(studentId).subscribe((student) => {
      this.licenceTypes = student.licences;
      this.licenceTypeDropdownItems = this.utilService.generateDropdownItems(
        student.licences,
      );

      if (this.licenceTypes.length === 1) {
        this.eventForm.controls.licenceType.setValue({
          id: this.licenceTypes[0].id,
          label: this.licenceTypes[0].name,
        });
      }
    });
  }

  getCategoriesForLicenceType(licenceTypeId: string) {
    this.licenceTypeService
      .getLicenceTypeById(licenceTypeId)
      .subscribe((licenceType) => {
        this.categories = [];
        this.categoryDropdownItems = [];
        this.eventForm.controls.category.setValue(this.emptyDropdownItem);
        this.licenceTargetsForType = licenceType.targets;
        for (let target of licenceType.targets) {
          if (target != null) {
            this.categories.push(<LessonCategory>target.lessonCategory);
          }
        }
        this.categoryDropdownItems = this.utilService.generateDropdownItems(
          this.categories,
        );
      });
  }

  getTeacherOfLastLesson() {
    this.lessonService
      .getLessons({
        studentId: this.eventForm.controls.assignedToStudent.value!?.["id"],
        limit: 1,
        sort: "startDate+DESC",
        status: "active",
      })
      .subscribe((lessons) => {
        if (lessons.length == 0) return;
        const teacherName = this.teachers.find(
          (teacher) => teacher.id === lessons[0].user.id,
        )?.name;
        if (teacherName == null) return;
        this.eventForm.controls.assignedToTeacher.setValue({
          id: lessons[0].user.id,
          label: teacherName,
        });
      });
  }

  /**
   * Generates an event object for the calendar based on the given parameters
   * from the form
   */
  createNewLesson() {
    if (this.sendRequest) return;
    this.sendRequest = true;

    // if the time is between two dates, the end date is the next day (current day + 1)
    let calculatedEnd =
      this.eventForm.controls.endTime.value! >
      this.eventForm.controls.startTime.value!
        ? this.eventForm.controls.startDate.value!
        : this.eventForm.controls.startDate.value!.append({ day: 1 });

    const tenantId = this.authService.getTenantId();
    if (tenantId == null) return;

    let lessonBody: LessonBody = {
      tenantId: tenantId,
      startDate: this.utilService.getFormattedDateByTuiDate(
        this.eventForm.controls.startDate.value! as TuiDay,
        this.eventForm.controls.startTime.value! as TuiTime,
      ),
      endDate: this.utilService.getFormattedDateByTuiDate(
        calculatedEnd as TuiDay,
        this.eventForm.controls.endTime.value! as TuiTime,
      ),
      studentId: this.eventForm.controls.assignedToStudent.value!?.["id"],
      userId: this.eventForm.controls.assignedToTeacher.value!?.["id"],
      lessonCategoryId: this.eventForm.controls.category.value!?.["id"],
      status: "active",
      cancelReason: "",
      locationId: this.eventForm.controls.location.value!?.["id"],
      licenceTypeId: this.eventForm.controls.licenceType.value!?.["id"],
      count: this.eventForm.controls.counter.value!,
      price: 0,
    };

    this.lessonService
      .createLesson(
        lessonBody,
        this.eventForm.controls.userNotification.value!,
        this.eventForm.controls.studentNotification.value!,
      )
      .subscribe(
        (lesson) => {
          this.pushService.sendPush(
            pushTypes.SUCCESS,
            this.pushNotificationCreatedSuccess,
          );
          this.createdLesson.emit(lesson);
          this.closeEvent.emit(true);
          // close the form
          this.setCloseEvent();
        },
        (error) => {
          if (
            error.status == HttpStatusCode.Conflict &&
            (error.error.key == "STUDENT_COLLISION" ||
              error.error.key == "TEACHER_COLLISION")
          ) {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationCreatedConflictCollision,
            );
          } else if (
            error.status == HttpStatusCode.Conflict &&
            error.error.key == "NOT_ENOUGH_CREDIT"
          ) {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationCreatedConflictNotEnoughCredit,
            );
          } else {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.pushNotificationCreatedError,
            );
          }
        },
      );
  }

  /**
   * Emits the close event to the parent component and closes the dialog.
   */
  setCloseEvent() {
    this.sendRequest = false;
    this.router.navigate([NavRoutes.CALENDAR]);
    this.isOpen = false;
  }
}
