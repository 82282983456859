<app-menubar
  [title]="'location.location-detail.title' | translate"
  [description]="'location.location-detail.description' | translate"
  [open]="isOpen"
  (closingEvent)="setCloseEvent()"
>
  <tui-loader
    [showLoader]="!!(isLoading$ | async)"
    [size]="'xl'"
    [overlay]="true"
  >
    <form [formGroup]="locationForm" class="form-group">
      <span class="text-field-wrapper"
        ><app-input-text
          [fieldName]="'name'"
          [hint]="'location.location-detail.input-label-name' | translate"
        ></app-input-text
      ></span>
      <div class="duo-form-section-wrapper">
        <span class="text-field-wrapper">
          <app-input-text
            [fieldName]="'street'"
            [hint]="'address.input-label-street' | translate"
          >
          </app-input-text>
        </span>
        <span class="text-field-wrapper number">
          <app-input-text
            [fieldName]="'houseNumber'"
            [hint]="'address.input-label-house-number' | translate"
          >
          </app-input-text>
        </span>
      </div>
      <div class="duo-form-section-wrapper">
        <span class="text-field-wrapper">
          <app-input-text
            [fieldName]="'postalCode'"
            [hint]="'address.input-label-zip-code' | translate"
          >
          </app-input-text>
        </span>
        <span class="text-field-wrapper">
          <app-input-text
            [fieldName]="'city'"
            [hint]="'address.input-label-city' | translate"
          >
          </app-input-text>
        </span>
      </div>
      <app-input-phone [fieldName]="'phone'" [hint]="'location.location-detail.input-label-phone' | translate"></app-input-phone>
      <div class="button-wrapper">
        <button
          *ngIf="!isManager()"
          tuiButton
          size="m"
          [disabled]="!locationForm.valid"
          type="submit"
          (click)="submitForm()"
        >
          {{ "location.location-detail.button-save" | translate }}
        </button>
        <button
          *ngIf="!isManager()"
          tuiButton
          size="m"
          type="button"
          [icon]="tuiIconTrash"
          (click)="showDeleteDialog = true"
          appearance="secondary-destructive"
        >
          {{ "location.location-detail.button-delete" | translate }}
        </button>
        <button
          tuiButton
          type="button"
          size="m"
          appearance="secondary"
          [icon]="tuiIconX"
          (click)="setCloseEvent()"
        >
          {{ "user-profile.button-cancel" | translate }}
        </button>
      </div>
    </form>
  </tui-loader>
</app-menubar>

<app-delete-dialog
  [isOpen]="showDeleteDialog"
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="showDeleteDialog = false; deleteLocation()"
></app-delete-dialog>
