import { Component } from "@angular/core";
import { TableFilterEvent } from "../../interfaces/table-filter-event";
import { UserService } from "../../services/user.service";
import { TuiTablePagination } from "@taiga-ui/addon-table";
import { User } from "../../models/user";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { TableSortEvent } from "../../interfaces/table-sort-event";
import { NavRoutes } from "../../enums/nav-routes";
import { SuperAdminService } from "../../services/super-admin.service";
import { Subscription } from "rxjs";
import { UtilService } from "../../services/util.service";
import { UserRole } from "../../enums/user-role";

@Component({
  selector: "app-user-page",
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.scss"],
})

/**
 * Component page for the user page.
 * Serves as a container for the user table and the create user side modal.
 */
export class UserPageComponent {
  userTableData: any[] = [];

  // names for corresponding object properties
  readonly userTableShownColumns: string[] = [
    "firstName",
    "lastName",
    'role.name',
    "phone",
    "email",
    "active",
  ];
  tablePage: number = 0;
  tablePageSize: number = 10;
  totalUsers: number = 0;
  isOpen: boolean = false;
  searchValue: string = "";
  private selectedTenantSubscription: Subscription = new Subscription();
  private backToParentSubscription: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private superAdminService: SuperAdminService,
    private utilService: UtilService,
  ) {
  }

  ngOnInit(): void {
    this.userService.setPaginationIndices({ start: 0, limit: 10 });
    this.userService.resetFilter();
    // subscribe to router events to refresh the calendar when the user navigates back to the calendar page
    this.backToParentSubscription = this.router.events.subscribe((event) => {
      if (this.utilService.isNavigatedBackToParent(event, NavRoutes.USER)) {
        this.populateTable();
      }
    });

    // populate table again if selected tenant changes
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe((id) => {
          this.populateTable();
        });
    } else {
      this.populateTable();
    }
  }

  // unsubscribe to avoid memory leaks
  ngOnDestroy(): void {
    this.resetTablePagination();

    this.selectedTenantSubscription.unsubscribe();
    this.backToParentSubscription.unsubscribe();
  }

  /**
   * Populates the user table with data:
   * if the user is not a super-admin load all users for his tenant
   * if the user is a super-admin react to changes of the selected tenantId and load the users for the selected tenant
   * IMPORTANT: end subscription this.superAdminService .getSelectedTenantId() on destroy, otherwise => memory leak
   */
  public populateTable() {
    let searchValueParam =
      this.searchValue !== "" ? this.searchValue : undefined;

    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();

      if (selectedTenantId == null) return;
      this.getUsersForTenant(selectedTenantId, searchValueParam);
    } else {
      this.getUsersForTenant(this.authService.getLoggedInUser()?.tenantId);
    }
  }

  /**
   * gets all users and amount of users for the given tenant
   * @param tenantId The id of the tenant
   * @param searchValue
   */
  public getUsersForTenant(tenantId?: string, searchValue?: string) {
    this.userService
      .getUsers({ tenantId: tenantId, search: searchValue })
      .subscribe((users: User[]) => {
        this.userTableData = users;
        this.totalUsers = this.userService.getAmount();
      });
  }

  /**
   * Filters the table by the given filter event
   * @param filterEvent containing search term and tab index (all, active, inactive)
   */
  public filterTable(filterEvent: TableFilterEvent): void {
    this.userService.setFilter(filterEvent);
    this.resetTablePagination();
    this.populateTable();
  }

  /**
   * when the pagination event is triggered:
   * sets the table pagination to the selected page and size and updates the table data
   */
  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.userService.setPaginationIndices({
      start: this.tablePage * this.tablePageSize,
      limit: this.tablePageSize,
    });
    this.populateTable();
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    this.userService.setSorting(tableSort);
    this.populateTable();
  }

  /**
   * resets the table pagination to the first page with 10 entries
   */
  public resetTablePagination() {
    this.userService.setPaginationIndices({ start: 0, limit: 10 });
    this.tablePage = 0;
    this.tablePageSize = 10;
  }

  /**
   * Navigates to the user detail page on click
   * of a row by using users id
   * @param id The id of the selected user in the table
   */
  public usersRowClickEvent(id: string): void {
    this.router.navigateByUrl(`${ NavRoutes.USER }/${ id }`);
  }

  /**
   * If event is emitted from the create-user component,
   * the table is updated and the modal is closed
   */
  public createdUserEvent() {
    this.populateTable();
    this.isOpen = false;
  }

  public isManager(): boolean {
    return this.authService.getUserRole() === UserRole.MANAGER;
  }

  onSearchChange($event: string) {
    this.resetTablePagination();
    this.searchValue = $event;
    //   populate table with search param
    this.populateTable();
  }
}
