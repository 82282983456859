import { Component, Input } from '@angular/core';
import {
  DropDownItem,
  PermissionDropdownItem,
} from '../../interfaces/drop-down-item';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PermissionLevel } from '../../enums/permission-level';

@Component({
  selector: 'app-permission-row',
  templateUrl: './permission-row.component.html',
  styleUrls: ['./permission-row.component.scss'],
  viewProviders: [
    {
      // important to use the form group of the parent component
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
/**
 * A component that displays a row with dropdowns for changing the permission like view, create, update, delete for each entity.
 * The Permission can be changed to one of the following levels:
 * - NONE: No permission
 * - OWN: Permission to own entities
 * - ALL: Permission to all entities
 *
 * @Input fieldNamePrefix: The prefix for the form control names of the FormGroup for example 'user'
 * @Input title: The title of the permission row for example
 * @Input description: The description that is displayed below the title
 *
 */
export class PermissionRowComponent {
  @Input() fieldNamePrefix!: string;
  @Input() title: string = '';
  @Input() description: string = '';
  viewFormControl: string = ''; // form control name for the dropdown view permission
  createFormControl: string = ''; // form control name for the dropdown create permission
  updateFormControl: string = ''; // form control name for the dropdown update permission
  deleteFormControl: string = ''; // form control name for the dropdown delete permission
  allPermissions: string = '';
  defaultPermissions: string = '';
  ownPermissions: string = '';
  permissionLevels!: PermissionDropdownItem[];

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.viewFormControl = this.fieldNamePrefix + 'View';
    this.createFormControl = this.fieldNamePrefix + 'Create';
    this.updateFormControl = this.fieldNamePrefix + 'Update';
    this.deleteFormControl = this.fieldNamePrefix + 'Delete';

    this.translateService
      // For each translation key, the translation is retrieved and stored in the corresponding variable
      .get([
        'permission-row.all-permissions',
        'permission-row.own-permissions',
        'permission-row.default-permissions',
      ])
      .subscribe((translations) => {
        this.allPermissions = translations['permission-row.all-permissions'];
        this.ownPermissions = translations['permission-row.own-permissions'];
        this.defaultPermissions =
          translations['permission-row.default-permissions'];

        this.permissionLevels = [
          { id: PermissionLevel.NONE, label: this.defaultPermissions },
          { id: PermissionLevel.ALL, label: this.allPermissions },
          { id: PermissionLevel.OWN, label: this.ownPermissions },
        ];
      });
  }

  /*
   * Returns the label of the given item for displaying it in the dropdown
   */
  readonly stringify = (item: PermissionDropdownItem): string => {
    if (item.label != undefined) {
      return `${item.label}`;
    } else {
      return '';
    }
  };
}
