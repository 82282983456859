<div class="" style="width: 60px; height: 60px">
  <svg viewBox="0 0 67 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.3475 2.87038C49.3773 2.87038 62.4174 15.9105 62.4174 31.9403C62.4174 47.9701 49.3773 61.0102 33.3475 61.0102C17.3177 61.0102 4.27759 47.9701 4.27759 31.9403C4.27759 15.9105 17.3177 2.87038 33.3475 2.87038Z"/>
    <path d="M43.4287 1.29264C51.2589 4.18617 57.9502 9.93089 61.8018 17.3417C64.1182 21.7955 65.4303 26.7014 65.6457 31.7151C65.6554 31.9421 65.8478 32.1171 66.0728 32.1075C66.2845 32.0979 66.4557 31.9286 66.4653 31.7151C66.9982 21.2645 61.6267 10.8274 53.0385 4.9057C50.1969 2.90871 47.0302 1.42154 43.6807 0.511544C43.4614 0.455752 43.2382 0.5885 43.1824 0.807823C43.1305 1.00983 43.2382 1.21761 43.4306 1.29264"/>
    <path d="M33.7093 63.6535C24.819 63.3245 16.2942 59.3728 10.4148 52.747C3.61391 45.2092 0.795418 34.4104 2.90977 24.4832C3.46577 21.8109 4.33729 19.2136 5.50317 16.7472C5.59744 16.5413 5.50509 16.297 5.29924 16.2047C5.11069 16.12 4.88945 16.1874 4.78171 16.3624C-1.10153 26.1512 -0.841809 39.1701 5.16841 48.878C10.9939 58.6879 22.3237 64.9867 33.7093 64.4711C33.9363 64.4615 34.1114 64.271 34.1017 64.044C34.0921 63.8324 33.9228 63.6612 33.7093 63.6515"/>
    <foreignObject width="100%" height="100%">
      <div xmlns="http://www.w3.org/1999/xhtml" class="foreign-object">
        <tui-svg [src]="icon"></tui-svg>
      </div>
    </foreignObject>
  </svg>
</div>

