<a *ngIf="locationDropdownItems.length === 0" [routerLink]="'/'+NavRoutes.SETTINGS+'/'+NavRoutes.LOCATION" (click)="setShowNewLocationModal()" >
  <tui-notification
    status="warning"
    size="m"
  >
    {{'students.input-label-no-location' | translate}}
  </tui-notification>
</a>



<app-dropdown
  [formFieldName]="fieldName"
  [dropDownItems]="locationDropdownItems"
  [hint]="'students.input-label-location' | translate"
  [required]="required"
  [tooltipText]="locationTooltip"
  [showError]="true"
>
</app-dropdown>
