/**
 * Enum for permission levels:
 * Contains the permission levels: NONE, ALL, OWN as number values
 * Used for the permission dropdowns in the settings.
 */
export enum PermissionLevel {
  NONE = 0,
  ALL = 1,
  OWN = 2,
}
