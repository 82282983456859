<tui-hosted-dropdown
  tuiDropdownAlign="right"
  [content]="dropdown"
  [(open)]="open"
>
  <button
    class="button-width"
    appearance="primary"
    iconRight="tuiIconChevronDown"
    size="m"
    tuiButton
    type="button"
    [pseudoActive]="open || null"
  >
    {{ "payments.new-payment-button" | translate }}
  </button>
</tui-hosted-dropdown>
<ng-template
  #dropdown
>
  <tui-data-list>
    <tui-opt-group>
      <button
        class="button-width"
        *ngFor="let item of PaymentMenuDropdownItems"
        tuiOption
        (click)="onClick(item.route)"
      >
        {{ item.title | translate }}
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-template>
