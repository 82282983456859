<div *ngIf="form">
  <div class="island-wrapper">
    <!-- TITLE -->
    <div class="headline-wrapper">
      <div>
        <h1 class="title">{{ "phone.authenticate" | translate }}</h1>
        <p>
          {{ "phone.authenticate_desc" | translate }}
        </p>
      </div>
    </div>

    <!-- FORM -->
    <form
      (ngSubmit)="form.valid && sendOneTimeLogin()"
      [formGroup]="form"
      class="form"
    >
      <!-- PHONE NUMBER -->
      <app-input-phone
        [hint]="'students.input-label-telephone' | translate"
        [mobileWithCountryCode]="true"
        fieldName="phone"
      />

      <!-- ONE TIME PASSWORD -->
      <app-input-text [hint]="'phone.code' | translate" fieldName="otp" />

      <!-- SUBMIT BUTTON -->
      <button [disabled]="form.invalid" tuiButton type="submit">
        {{ "general.send" | translate }}
      </button>
    </form>
  </div>
</div>
