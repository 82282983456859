import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})

/**
 * Page that is displayed when an error occurs
 * Reads the error code, key and url from the url and displays them on the page for more detailed information
 */
export class ErrorPageComponent {
  errorCode: string = '';
  errorKey: string = '';
  url: string = '';

  constructor(private Activatedroute: ActivatedRoute) {}

  ngOnInit() {
    // gets the params from the url and assigns them to the variables to be used in the html
    this.Activatedroute.queryParams.subscribe((params) => {
      this.errorCode = params['code'];
      this.errorKey = params['key'];
      this.url = params['url'];
    });
  }
}
