<div class="card-wrapper">
  <ng-template *ngIf="!isSuperAdmin()">
    <h3 class="title">
      {{ "settings.profile-tab.info-company-overall" | translate }}
    </h3>

    <form class="infobit-wrapper" [formGroup]="tenantProfileForm">
    <span class="text-field-wrapper">
      <app-input-text
        [fieldName]="'tenantName'"
        [hint]="'settings-general.input-label-name' | translate"
      ></app-input-text
      ></span>
      <button
        tuiButton
        appearance="secondary"
        [icon]="tuiIconEdit"
        size="l"
        (click)="toggleEdit()"
        type="button"
      >
        {{ "settings-general.button-edit" | translate }}
      </button>
    </form>

    <span class="head-button-wrapper">
    <button
      tuiButton
      type="submit"
      size="m"
      [disabled]="!tenantProfileForm.valid"
      appearance="primary"
      class="submit-button"
      (click)="updateTenant()"
    >
      {{ "common.button-save" | translate }}
    </button>
  </span>
    <hr />
  </ng-template>
  <!--  <h3 class="title">-->
  <!--    {{ "settings.profile-tab.info-company-address" | translate }}-->
  <!--  </h3>-->
  <!--  <div class="infobit-wrapper">-->
  <!--    <app-info-bit-->
  <!--      [title]="'settings.profile-tab.info-company-street' | translate"-->
  <!--      [info]="'Martinistr.'"-->
  <!--    ></app-info-bit>-->
  <!--    <app-info-bit-->
  <!--      [title]="'settings.profile-tab.info-company-house-number' | translate"-->
  <!--      [info]="'43'"-->
  <!--    ></app-info-bit>-->
  <!--    <app-info-bit-->
  <!--      [title]="'settings.profile-tab.info-company-zip' | translate"-->
  <!--      [info]="'28195'"-->
  <!--    ></app-info-bit>-->
  <!--    <app-info-bit-->
  <!--      [title]="'settings.profile-tab.info-company-city' | translate"-->
  <!--      [info]="'Bremen'"-->
  <!--    ></app-info-bit>-->
  <!--  </div>-->
  <!--  <hr />-->

  <!-- only for debug purposes -->
  <div class="infobit-wrapper">
    <form [formGroup]="languageForm" class="dropdown-wrapper">
      <h1 class="title">{{ "settings.language-title" | translate }}</h1>
      <div class="dropdown-wrapper">
        <app-dropdown
          [hint]="'settings.language-title' | translate"
          [formFieldName]="'language'"
          [dropDownItems]="[
            { id: 'de', label: 'settings.language-german' | translate },
            { id: 'en', label: 'settings.language-english' | translate }
          ]"
        ></app-dropdown>
      </div>
    </form>
  </div>
</div>
