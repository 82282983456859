<div *ngFor="let item of items">
  <tui-radio-labeled
    formControlName="{{fieldName}}"
    class="tui-space_bottom-3"
    [item]="item.id"
    size="l"
  >
    <div>{{item.name}}</div>
    <div class="description">{{item.description}}</div>
  </tui-radio-labeled>
</div>
