import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})

/**
 * The tab-page for changing the password in the settings page.
 * TODO: Currently not in use, because the backend does not support it yet.
 */
export class PasswordComponent {
  constructor() {}

  // readonly passwordForm = new FormGroup({
  //   currentPassword: new FormControl(``, [Validators.required]),
  //   newPassword: new FormControl(``, [Validators.required]),
  //   newPasswordConfirmation: new FormControl(``, [Validators.required]),
  // });
  //
  // submitPasswordReset() {
  //   if (this.passwordForm.valid) {
  //     this.authService
  //       .setPassword(
  //         this.passwordForm.controls.currentPassword.value!,
  //         this.passwordForm.controls.newPassword.value!,
  //       )
  //       .subscribe((isSet) => {
  //         if (isSet) {
  //           console.log('YES');
  //         }
  //       });
  //   }
  // }
  //
  // detectChanges(): string {
  //   return this.passwordForm.controls.currentPassword.value!;
  // }
  //
  // // Check if the password has at least one lowercase letter
  // hasOneLowercaseLetter(): boolean {
  //   return !!this.passwordForm.controls.newPassword.value?.match(/[a-z]/g);
  // }
  //
  // // Check if the password has at least one uppercase letter
  // hasOneUppercaseLetter(): boolean {
  //   return !!this.passwordForm.controls.newPassword.value?.match(/[A-Z]/g);
  // }
  //
  // // Check if the password has at least one number
  // hasOneNumber(): boolean {
  //   return !!this.passwordForm.controls.newPassword.value?.match(/[0-9]/g);
  // }
  //
  // // Check if the password is at least 8 characters long
  // isLongEnough(): boolean {
  //   return this.passwordForm.controls.newPassword.value!.length >= 8;
  // }
  //
  // // Check if the password and the password confirmation are matching
  // isMatching(): boolean {
  //   return (
  //     this.passwordForm.controls.newPassword.value ===
  //     this.passwordForm.controls.newPasswordConfirmation.value
  //   );
  // }
}
