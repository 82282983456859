import { Component, Input } from '@angular/core';
import { CarIcons } from '../../enums/car-icons';

@Component({
  selector: 'app-car-icon',
  templateUrl: './car-icon.component.html',
  styleUrls: ['./car-icon.component.scss'],
})
export class CarIconComponent {
  protected readonly CarIcons = CarIcons;

  @Input() icon!: CarIcons | string;
}
