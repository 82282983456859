import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DropDownItem } from '../../interfaces/drop-down-item';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
/**
 * A component that displays a dropdown menu with custom items
 * @Input dropDownItems - The items to display in the dropdown menu
 * @Input formFieldName - The name of the field in the FormGroup
 * @Input hint - The hint to display on top of the dropdown menu
 */
export class DropdownComponent {
  @Input({ required: true }) dropDownItems!: DropDownItem[];
  @Input() formFieldName!: string;
  @Input() hint: string = '';
  @Input() required: boolean = true;
  @Input() tooltipText: string = '';
  @Input() showError: boolean = false;
  @Input() textFieldSize: TuiSizeL | TuiSizeM | TuiSizeS = 'l';

  @Output() emitChangeEvent = new EventEmitter();

  ngOnInit(): void {}

  emitChange(event: any) {
    this.emitChangeEvent.emit(event);
  }

  /*
   * Returns the label of the item to display in the dropdown menu
   */
  readonly stringify = (item: DropDownItem): string => {
    if (item.label != undefined) {
      return `${item.label}`;
    } else {
      return '';
    }
  };
}
