<app-menubar
  [title]="'new-lesson-category.title' | translate"
  [description]="'new-lesson-category.description' | translate"
  [open]="isOpen"
  (closingEvent)="setCloseEvent()"
>
  <form [formGroup]="newLessonCategoryForm">
    <app-input-text [fieldName]="'name'" [hint]="'new-licence-type.input-label-name' | translate" ></app-input-text>
    <div class="button-wrapper">
      <button
        tuiButton
        size="m"
        [disabled]="!newLessonCategoryForm.valid"
        type="submit"
        (click)="createLessonCategory()"
      >
        {{ 'common.button-save' | translate }}
      </button>
      <button
        tuiButton
        size="m"
        appearance="secondary"
        type="button"
        (click)="setCloseEvent()"
      >
        {{ 'common.button-cancel' | translate }}
      </button>
    </div>
  </form>
</app-menubar>


