import { Component } from "@angular/core";
import { catchError, of, Subscription } from "rxjs";
import { SuperAdminService } from "../../services/super-admin.service";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { TransactionService } from "../../services/transaction.service";
import { LocationService } from "../../services/location.service";
import { Location } from "../../models/location";
import { NavRoutes } from "../../enums/nav-routes";
import { Transaction } from "../../models/transaction";
import { TuiTablePagination } from "@taiga-ui/addon-table";
import { TableFilterEvent } from "../../interfaces/table-filter-event";
import { TableSortEvent } from "../../interfaces/table-sort-event";
import {
  TransactionType,
  transactionTypeToString,
} from "../../enums/transaction-type";
import { FormControl, FormGroup } from "@angular/forms";
import { DateTime } from "luxon";
import { UserRole } from "../../enums/user-role";
import { PushService } from "../../services/push.service";
import { pushTypes } from "../../enums/push-types";

@Component({
  selector: "app-transactions-page",
  templateUrl: "./transactions-page.component.html",
  styleUrls: ["./transactions-page.component.scss"],
})

/**
 * The component for Payments contains the Payment Table
 */
export class TransactionsPageComponent {
  filterForm: FormGroup = new FormGroup({
    filterDateFormControl: new FormControl(),
    filterTransactionTypeFormControl: new FormControl(),
  });

  showDeleteDialog: boolean = false;

  selectedTabIndex: number = 0;
  tenantLocations: Location[] = [];
  locationsLoaded: boolean = false;

  // table data
  transactionTableData: Transaction[] = [];
  totalTransactions: number = 0;
  tablePage: number = 0;
  tablePageSize: number = 10;

  readonly transactionTableShownColumns: string[] = [
    "createdAt",
    "amount",
    "personName",
    "transactionTypeString",
    "userName",
  ];

  locationWithWallet: Map<string, number> = new Map<string, number>();
  transactionDeleteId: string | undefined;
  protected readonly NavRoutes = NavRoutes;
  protected readonly TransactionType = TransactionType;
  protected readonly transactionTypeToString = transactionTypeToString;
  private selectedTenantSubscription: Subscription = new Subscription();

  constructor(
    private superAdminService: SuperAdminService,
    private authService: AuthService,
    private router: Router,
    private locationService: LocationService,
    private transactionService: TransactionService,
    private pushService: PushService,
  ) {}

  get isSuperAdminOrTenantAdmin() {
    const userRole = this.authService.getUserRole();
    return (
      userRole === UserRole.SUPER_ADMIN || userRole === UserRole.TENANT_ADMIN
    );
  }

  ngOnInit(): void {
    this.transactionService.setPaginationIndices({ start: 0, limit: 10 });
    this.transactionService.transactionFilter = undefined;

    // populate table again if selected tenant changes
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe(() => {
          this.getDataForTenant();
        });
    } else {
      this.getDataForTenant();
    }
  }

  ngOnDestroy(): void {
    this.resetTablePagination();
    this.selectedTenantSubscription.unsubscribe();
  }

  locationTabClicked(locationId: string) {
    this.getTransactionsAndWalletForLocations(locationId);
  }

  getDataForTenant() {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();

      if (selectedTenantId == null) return;
      this.getLocationsForTenant(selectedTenantId);
    } else {
      this.getLocationsForTenant(this.authService.getLoggedInUser()?.tenantId);
    }
  }

  getLocationsForTenant(tenantId: string | undefined) {
    this.locationService
      .getLocations({ tenantId: tenantId })
      .subscribe((locations) => {
        this.tenantLocations = locations;
        this.locationsLoaded = this.tenantLocations.length > 0;

        if (this.locationsLoaded) {
          // fill map with location ids
          this.tenantLocations.forEach((location) => {
            this.locationWithWallet.set(location.id, 0);
          });
          this.getTransactionsAndWalletForLocations(
            this.tenantLocations[this.selectedTabIndex].id,
          );
        }
      });
  }

  getTransactionsAndWalletForLocations(locationId: string) {
    // get wallet values for all locations
    this.tenantLocations.forEach((location) => {
      this.getWalletValueForLocation(location.id);
    });

    // get transactions for selected / first location
    this.getTransactionForLocation(locationId);
  }

  getTransactionForLocation(locationId: string) {
    let selectedTenantId = undefined;
    if (this.superAdminService.isSuperAdmin()) {
      selectedTenantId = this.superAdminService.getSelectedTenantIdValue();
      if (selectedTenantId == null) return;
    }
    this.transactionService
      .getLocationTransactions(locationId, selectedTenantId)
      .subscribe((data) => {
        if (data != null) {
          this.transactionTableData = data;
          this.totalTransactions = this.transactionService.getAmount();
        }
      });
  }

  getWalletValueForLocation(locationId: string) {
    let selectedTenantId = undefined;
    if (this.superAdminService.isSuperAdmin()) {
      selectedTenantId = this.superAdminService.getSelectedTenantIdValue();
      if (selectedTenantId == null) return;
    }
    this.transactionService
      .getLocationWalletValue(locationId, selectedTenantId)
      .subscribe((data) => {
        if (data != null) {
          if (data.amount != this.locationWithWallet.get(locationId)) {
            // fill map with wallet value for location
            this.locationWithWallet.set(locationId, data.amount);
          }
        } else {
          this.locationWithWallet.set(locationId, 0);
        }
      });
  }

  isSuperAdmin() {
    return this.superAdminService.isSuperAdmin();
  }

  /**
   * when the pagination event is triggered:
   * sets the table pagination to the selected page and size and updates the table data
   */
  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.transactionService.setPaginationIndices({
      start: this.tablePage * this.tablePageSize,
      limit: this.tablePageSize,
    });
    this.getTransactionsAndWalletForLocations(
      this.tenantLocations[this.selectedTabIndex].id,
    );
  }

  public rowClickEvent(id: string): void {
    this.router.navigateByUrl(`${NavRoutes.PAYMENT}/${id}`).then();
  }

  /**
   * resets the table pagination to the first page with 10 entries
   */
  public resetTablePagination() {
    this.transactionService.setPaginationIndices({ start: 0, limit: 10 });
    this.tablePage = 0;
    this.tablePageSize = 10;
  }

  /**
   * Filters the table by the given filter event
   * @param filterEvent containing search term and tab index (all, active, inactive)
   */
  public filterTable(filterEvent: TableFilterEvent): void {
    this.transactionService.setFilter(filterEvent);
    this.resetTablePagination();
    this.getTransactionsAndWalletForLocations(
      this.tenantLocations[this.selectedTabIndex].id,
    );
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    if (tableSort.sortColumn === "transactionTypeString") {
      tableSort.sortColumn = "transactionType";
    }

    this.transactionService.setSorting(tableSort);
    this.getTransactionsAndWalletForLocations(
      this.tenantLocations[this.selectedTabIndex].id,
    );
  }

  setShowNewLocationModal() {
    this.locationService.showNewLocationModal = true;
  }

  public applyFilters() {
    // Filter for TransactionType
    const transactionType =
      this.filterForm.controls["filterTransactionTypeFormControl"].value;
    this.transactionService.transactionFilter = {
      transactionType:
        transactionType != null && transactionType.id != ""
          ? transactionType.id
          : undefined,
    };

    // Filter for amount
    const date = this.filterForm.controls["filterDateFormControl"].value;
    this.transactionService.transactionFilter.filterDate =
      date !== null
        ? DateTime.fromJSDate(new Date(date.year, date.month, date.day))
        : undefined;

    this.resetTablePagination();
    this.getTransactionsAndWalletForLocations(
      this.tenantLocations[this.selectedTabIndex].id,
    );
  }

  openDeleteDialog(transactionId: string) {
    this.transactionDeleteId = transactionId;
    this.showDeleteDialog = true;
  }

  deleteEvent() {
    if (!this.transactionDeleteId || !this.isSuperAdminOrTenantAdmin) return;

    this.transactionService
      .deleteTransactionById(this.transactionDeleteId)
      .pipe(
        catchError(() => {
          this.deleteTransactionErrorMsg();
          return of(null);
        }),
      )
      .subscribe(() => {
        this.deleteTransactionSuccessfulMsg();
        this.getDataForTenant();
      });
  }

  deleteTransactionSuccessfulMsg() {
    this.pushService.sendPush(pushTypes.SUCCESS, "Zahlung wurde gelöscht!");
  }

  deleteTransactionErrorMsg() {
    this.pushService.sendPush(
      pushTypes.ERROR,
      "Zahlung konnte nicht gelöscht werden!",
    );
  }
}
