import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-phone-code-validation-layout',
  templateUrl: './phone-code-validation-layout.component.html',
  styleUrls: ['./phone-code-validation-layout.component.scss']
})
export class PhoneCodeValidationLayoutComponent {
  private screenWidth?: number;

  constructor() {
    this.screenWidth = window.innerWidth;
  }

  get bigScreen(): boolean {
    return this.screenWidth! > 650 ?? true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number; }; }) {
    this.screenWidth = event.target.innerWidth;
  }

}
