import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { SuperAdminService } from '../../../services/super-admin.service';
import { LocationService } from '../../../services/location.service';
import { Location } from '../../../models/location';
import { TableFilterEvent } from '../../../interfaces/table-filter-event';
import { TuiTablePagination } from '@taiga-ui/addon-table';
import { TableSortEvent } from '../../../interfaces/table-sort-event';
import { NavRoutes } from '../../../enums/nav-routes';
import { UtilService } from '../../../services/util.service';
import { UserRole } from '../../../enums/user-role';

@Component({
  selector: 'app-locations-page',
  templateUrl: './locations-page.component.html',
  styleUrls: ['./locations-page.component.scss'],
})

/**
 * Component page for the locations.
 * Serves as a container for the locations table and the create locations side modal.
 */
export class LocationsPageComponent {
  locationTableData: any[] = [];
  readonly locationTableShownColumns: string[] = ['name', 'phoneNumber'];
  tablePage: number = 0;
  tablePageSize: number = 10;
  totalLocations: number = 0;
  isOpen: boolean = false;
  selectedTenantSubscription: Subscription = new Subscription();
  private backToParentSubscription: Subscription = new Subscription();

  constructor(
    private locationService: LocationService,
    private authService: AuthService,
    private router: Router,
    private superAdminService: SuperAdminService,
    private utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.locationService.setPaginationIndices({ start: 0, limit: 10 });

    // subscribe to router events to refresh the calendar when the user navigates back to the calendar page
    this.backToParentSubscription = this.router.events.subscribe((event) => {
      if (this.utilService.isNavigatedBackToParent(event, NavRoutes.LOCATION)) {
        this.populateTable();
      }
    });

    // populate table again if selected tenant changes
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe((id) => {
          this.populateTable();
        });
    } else {
      this.populateTable();
      this.populateTable();
    }

    if (this.locationService.showNewLocationModal) {
      this.isOpen = true;
    }
  }

  ngOnDestroy(): void {
    this.resetTablePagination();
    this.selectedTenantSubscription.unsubscribe();
    this.backToParentSubscription.unsubscribe();

    this.locationService.showNewLocationModal = false;
  }

  /**
   * Populates the user table with data:
   * if the user is not a super-admin load all locations for his tenant
   * if the user is a super-admin react to changes of the selected tenantId and load the locations for the selected tenant
   * IMPORTANT: end subscription this.superAdminService .getSelectedTenantId() on destroy, otherwise => memory leak
   */
  public populateTable() {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();

      if (selectedTenantId == null) return;
      this.getLocationsForTenant(selectedTenantId);
    } else {
      this.getLocationsForTenant(this.authService.getLoggedInUser()?.tenantId);
    }
  }

  /**
   * gets all locations and amount of locations for the given tenant
   * @param tenantId The id of the tenant
   */
  public getLocationsForTenant(tenantId?: string) {
    this.locationService
      .getLocations({ tenantId: tenantId })
      .subscribe((locations: Location[]) => {
        this.locationTableData = locations;
        this.totalLocations = this.locationService.getAmount();
      });
  }

  /**
   * todo: implement filtering for api table data
   */
  public filterTable(filterEvent: TableFilterEvent): void {
    // this.locationService.setFilter(filterEvent);
    // this.resetTablePagination();
    // this.populateTable();
  }

  /**
   * when the pagination event is triggered:
   * sets the table pagination to the selected page and size and updates the table data
   */
  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.locationService.setPaginationIndices({
      start: this.tablePage * this.tablePageSize,
      limit: this.tablePageSize,
    });
    this.populateTable();
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    this.locationService.setSorting(tableSort);
    this.populateTable();
  }

  /**
   * resets the table pagination to the first page with 10 entries
   */
  public resetTablePagination() {
    this.locationService.setPaginationIndices({ start: 0, limit: 10 });
    this.tablePage = 0;
    this.tablePageSize = 10;
  }

  /**
   * Navigates to the location detail page on click
   * of a row by using locations id
   * @param id The id of the selected location in the table
   */
  public locationRowClickEvent(id: string): void {
    this.router.navigateByUrl(
      `${NavRoutes.SETTINGS}/${NavRoutes.LOCATION}/${id}`,
    );
  }

  /**
   * If event is emitted from the create-location component,
   * the table is updated and the modal is closed
   */
  public createdLocationEvent() {
    this.populateTable();
    this.isOpen = false;
  }

  public isManager(): boolean {
    return this.authService.getUserRole() === UserRole.MANAGER;
  }
}
