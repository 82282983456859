import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DropDownItem } from '../../interfaces/drop-down-item';
import { SuperAdminService } from '../../services/super-admin.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { LessonCategory } from '../../models/lesson-category';
import { LessonCategoryService } from '../../services/lesson-category.service';

type ObjectWithCategoryId = {
  categoryId: string;
};

@Component({
  selector: 'app-category-dropdown',
  templateUrl: './category-dropdown.component.html',
  styleUrls: ['./category-dropdown.component.scss'],
})
export class CategoryDropdownComponent {
  @Input() fieldName: string = '';
  @Input() formGroup: FormGroup | undefined;
  @Input() required = true;
  @Input() objectWithCategoryId: ObjectWithCategoryId | undefined;
  @Input() reloadCategories: BehaviorSubject<boolean> | undefined;

  categoryDropdownItems: DropDownItem[] = [];
  categories: LessonCategory[] = [];

  constructor(
    private superAdminService: SuperAdminService,
    private lessonCategoryService: LessonCategoryService,
    private authService: AuthService,
    private utilService: UtilService,
  ) {}

  ngOnInit() {
    this.getCategories();

    // force reload of categories if reloadCategories is set (not used anymore)
    if (this.reloadCategories != null) {
      this.reloadCategories.subscribe((reload) => {
        if (reload) {
          this.getCategories();
          if (this.reloadCategories == null) return;
          this.reloadCategories.next(false);
        }
      });
    }
  }

  ngOnDestroy() {}

  /**
   * Get Categories with the tenantId
   * gets the tenantId from the superAdminService if the user is a super-admin
   * otherwise gets the tenantId from the authService from the logged-in user
   */
  getCategories() {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();

      if (selectedTenantId == null) return;
      this.getCategoriesForTenant(selectedTenantId);
    } else {
      const tenantId = this.authService.getTenantId();
      if (tenantId == null) return;
      this.getCategoriesForTenant(tenantId);
    }
  }

  /**
   * Gets all explanation from the api to fill the dropdown menu
   */
  getCategoriesForTenant(tenantId: string) {
    this.lessonCategoryService
      .getCategory({ tenantId: tenantId })
      .subscribe((data) => {
        this.categories = data;
        this.categoryDropdownItems =
          this.utilService.generateDropdownItems(data);

        this.formGroup?.controls[this.fieldName].setValue({
          id: this.objectWithCategoryId?.categoryId,
          label: this.categories.find(
            (category) => category.id === this.objectWithCategoryId?.categoryId,
          )?.name,
        });
      });
  }
}
