import { Component, Input } from '@angular/core';
import { LocationService } from '../../services/location.service';
import { SuperAdminService } from '../../services/super-admin.service';
import { Location } from '../../models/location';
import { DropDownItem } from '../../interfaces/drop-down-item';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { ObjectWithLocation } from '../../interfaces/object-with-location';
import { UtilService } from '../../services/util.service';
import { NavRoutes } from '../../enums/nav-routes';

@Component({
  selector: 'app-location-dropdown',
  templateUrl: './location-dropdown.component.html',
  styleUrls: ['./location-dropdown.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class LocationDropdownComponent {
  @Input() fieldName: string = '';
  @Input() formGroup: FormGroup | undefined;
  @Input() objectWithLocation: ObjectWithLocation | null | undefined;
  @Input() required: boolean = true;
  @Input() locationTooltip: string = '';

  locations: Location[] = [];
  locationDropdownItems: DropDownItem[] = [];
  private selectedTenantSubscription: Subscription = new Subscription();

  constructor(
    private locationService: LocationService,
    private superAdminService: SuperAdminService,
    private authService: AuthService,
    private utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.getLocations();
  }

  ngOnDestroy(): void {
    this.selectedTenantSubscription.unsubscribe();
  }

  /**
   * Get Locations with the tenantId
   * gets the tenantId from the superAdminService if the user is a super-admin
   * otherwise gets the tenantId from the authService from the logged-in user
   */
  getLocations() {
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe((id) => {
          if (id != null) {
            this.getLocationsForTenant(id);
          }
        });
    } else {
      const tenantId = this.authService.getTenantId();
      if (tenantId == null) return;
      this.getLocationsForTenant(tenantId);
    }
  }

  /**
   * Gets all locations for the tenant from the api to fill the dropdown menu
   */
  getLocationsForTenant(tenantId: string) {
    this.locationService
      .getLocations({ tenantId: tenantId })
      .subscribe((data) => {
        this.locations = data;
        this.locationDropdownItems =
          this.utilService.generateDropdownItems(data);
        if (this.objectWithLocation == null) return;
        this.formGroup?.controls[this.fieldName].setValue({
          id: this.objectWithLocation?.locationId,
          label: this.locations.find(
            (location) => location.id === this.objectWithLocation?.locationId,
          )?.name,
        });
      });
  }

  setShowNewLocationModal() {
    this.locationService.showNewLocationModal = true;
  }

  protected readonly NavRoutes = NavRoutes;
}
