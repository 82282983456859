import { Component, Input } from "@angular/core";
import { TransactionService } from "../../services/transaction.service";
import { WalletType } from "../../enums/wallet-type";
import { Transaction } from "../../models/transaction";
import { TableSortEvent } from "../../interfaces/table-sort-event";
import { TuiTablePagination } from "@taiga-ui/addon-table";
import { SuperAdminService } from "../../services/super-admin.service";
import { Router } from "@angular/router";
import { NavRoutes } from "../../enums/nav-routes";

@Component({
  selector: "app-wallet-overview",
  templateUrl: "./wallet-overview.component.html",
  styleUrls: ["./wallet-overview.component.scss"],
})
export class WalletOverviewComponent {
  @Input({ required: true }) id!: string;
  @Input({ required: true }) walletType!: WalletType;

  wallet: number = 0;

  // table data
  transactionTableData: Transaction[] = [];
  totalTransactions: number = 0;
  tablePage: number = 0;
  tablePageSize: number = 10;
  showPagination: boolean = true;

  readonly transactionTableShownColumns: string[] = [
    "createdAt",
    "amount",
    "transactionTypeString",
  ];
  protected readonly WalletType = WalletType;

  constructor(
    private transactionService: TransactionService,
    private superAdminService: SuperAdminService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.transactionService.setPaginationIndices({ start: 0, limit: 10 });
    if (this.id != null) {
      this.getDataForWalletType();
    }
  }

  getDataForWalletType() {
    switch (this.walletType) {
      case WalletType.STUDENT:
        this.getTransactionsAndWalletForStudent(this.id);
        break;
      case WalletType.TEACHER:
        this.getTransactionsAndWalletForTeacher(this.id);
        this.showPagination = true;
        break;
    }
  }

  ngOnDestroy(): void {}

  getTransactionsAndWalletForStudent(studentId: string) {
    let selectedTenantId = undefined;
    if (this.superAdminService.isSuperAdmin()) {
      selectedTenantId = this.superAdminService.getSelectedTenantIdValue();
      if (selectedTenantId == null) return;
    }
    this.transactionService
      .getStudentWalletValue(studentId, selectedTenantId)
      .subscribe((data) => {
        if (data != null) {
          this.wallet = data.amount;
        }
      });

    this.transactionService
      .getStudentTransactions(studentId, selectedTenantId)
      .subscribe((data) => {
        if (data != null) {
          this.transactionTableData = data;
          this.totalTransactions = this.transactionService.getAmount();
        }
      });
  }

  getTransactionsAndWalletForTeacher(teacherId: string) {
    let selectedTenantId = undefined;
    if (this.superAdminService.isSuperAdmin()) {
      selectedTenantId = this.superAdminService.getSelectedTenantIdValue();
      if (selectedTenantId == null) return;
    }

    this.transactionService
      .getTeacherWalletValue(teacherId, selectedTenantId)
      .subscribe((data) => {
        if (data != null) {
          this.wallet = data.amount;
        }
      });

    this.transactionService
      .getTeacherTransactions(teacherId, selectedTenantId)
      .subscribe((data) => {
        if (data != null) {
          this.transactionTableData = data;
          this.totalTransactions = this.transactionService.getAmount();
        }
      });
  }

  public rowClickEvent(id: string): void {
    this.router.navigateByUrl(`${this.router.url}/${NavRoutes.PAYMENT}/${id}`);
  }

  /**
   * when the pagination event is triggered:
   * sets the table pagination to the selected page and size and updates the table data
   */
  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.transactionService.setPaginationIndices({
      start: this.tablePage * this.tablePageSize,
      limit: this.tablePageSize,
    });
    this.getDataForWalletType();
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    if (tableSort.sortColumn === "transactionTypeString") {
      tableSort.sortColumn = "transactionType";
    }

    this.transactionService.setSorting(tableSort);
    this.getDataForWalletType();
  }

  /**
   * resets the table pagination to the first page with 10 entries
   */
  public resetTablePagination() {
    this.transactionService.setPaginationIndices({ start: 0, limit: 10 });
    this.tablePage = 0;
    this.tablePageSize = 10;
  }
}
