import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import {
  TUI_SANITIZER,
  TuiAlertModule,
  TuiButtonModule,
  TuiCalendarModule,
  TuiDataListModule,
  TuiDialogModule,
  TuiDropdownModule,
  TuiErrorModule,
  TuiExpandModule,
  TuiFormatDatePipeModule,
  TuiGroupModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiModeModule,
  TuiNotificationModule,
  TuiRootModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiTooltipModule,
} from "@taiga-ui/core";
import { TuiArcChartModule, TuiAxesModule, TuiLineChartModule, TuiLineDaysChartModule, } from "@taiga-ui/addon-charts";
import {
  TuiAccordionModule,
  TuiBadgeModule,
  TuiBreadcrumbsModule,
  TuiCalendarRangeModule,
  TuiCheckboxLabeledModule,
  TuiCheckboxModule,
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiElasticContainerModule,
  TuiFieldErrorPipeModule,
  TuiFilterByInputPipeModule,
  TuiInputDateModule,
  TuiInputDateRangeModule,
  TuiInputFilesModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputPasswordModule,
  TuiInputPhoneInternationalModule,
  TuiInputPhoneModule,
  TuiInputTimeModule,
  TuiIslandModule,
  TuiMarkerIconModule,
  TuiProgressModule,
  TuiPushModule,
  TuiRadioBlockModule,
  TuiRadioLabeledModule,
  TuiRadioListModule,
  TuiRadioModule,
  TuiSelectModule,
  TuiSortCountriesPipeModule,
  TuiStepperModule,
  TuiStringifyContentPipeModule,
  TuiStringifyPipeModule,
  TuiTabsModule,
  TuiTagModule,
  TuiTextareaModule,
  TuiToggleModule,
} from "@taiga-ui/kit";
import { TuiTableModule, TuiTablePaginationModule, } from "@taiga-ui/addon-table";
import {
  TuiActiveZoneModule,
  TuiAutoFocusModule,
  TuiLetModule,
  TuiObscuredModule,
  TuiPortalModule,
  TuiValueChangesModule,
} from "@taiga-ui/cdk";
import { TuiSidebarModule } from "@taiga-ui/addon-mobile";

import { FullCalendarModule } from "@fullcalendar/angular";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TenantPageComponent } from "./views/tenant-page/tenant-page.component";
import { FormGroupDirective, FormsModule, ReactiveFormsModule, } from "@angular/forms";
import { TableComponent } from "./common/table/table.component";
import { SidenavComponent } from "./common/sidenav/sidenav.component";
import { TopbarComponent } from "./common/topbar/topbar.component";
import { ButtonComponent } from "./common/sidenav/button/button.component";
import { SettingsPageComponent } from "./views/settings-page/settings-page.component";
import { ProfileComponent } from "./views/settings-page/profile/profile.component";
import { ExplanationComponent } from "./views/settings-page/explanation/explanation.component";
import { PasswordComponent } from "./views/settings-page/password/password.component";
import { InfoBitComponent } from "./common/info-bit/info-bit.component";
import { LocationsPageComponent } from "./views/settings-page/locations-page/locations-page.component";
import { CalendarPageComponent } from "./views/calendar-page/calendar-page.component";
import { TransactionsPageComponent } from "./views/transactions-page/transactions-page.component";
import { UserPageComponent } from "./views/user-page/user-page.component";
import { LogInPageComponent } from "./views/log-in-page/log-in-page.component";
import { DashboardPageComponent } from "./views/dashboard-page/dashboard-page.component";
import { TableHeaderComponent } from "./common/table-header/table-header.component";
import { BaseLayoutComponent } from "./layouts/base-layout/base-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { MenubarComponent } from "./common/menubar/menubar.component";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, } from "@angular/common/http";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { EventItemComponent } from "./common/event-item/event-item.component";
import { InputPasswordComponent } from "./common/input-password/input-password.component";
import { InputTextComponent } from "./common/input-text/input-text.component";
import { ForgotPasswordComponent } from "./views/log-in-page/forgot-password/forgot-password.component";
import { ResetPasswordPageComponent } from "./views/reset-password-page/reset-password-page.component";
import { SetNewPasswordComponent } from "./common/set-new-password/set-new-password.component";
import { InputPhoneComponent } from "./common/input-phone/input-phone.component";
import { InputEmailComponent } from "./common/input-email/input-email.component";
import { NewUserComponent } from "./views/user-page/new-user/new-user.component";
import { RadioListComponent } from "./common/radio-list/radio-list.component";
import { UserDetailPageComponent } from "./views/user-detail-page/user-detail-page.component";
import { DropdownComponent } from "./common/dropdown/dropdown.component";
import { ErrorPageComponent } from "./views/error-page/error-page.component";
import { InputLargeTextComponent } from "./common/input-large-text/input-large-text.component";
import { PermissionRowComponent } from "./common/permission-row/permission-row.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NewTenantComponent } from "./views/tenant-page/new-tenant/new-tenant.component";
import { LoadingInterceptor } from "./interceptors/loading.interceptor";
import { TenantSelectorDropdownComponent } from "./common/tenant-selector-dropdown/tenant-selector-dropdown.component";
import { NgOptimizedImage } from "@angular/common";
import { DashboardCardComponent } from "./common/dashboard-card/dashboard-card.component";
import { IconComponent } from "./common/icon/icon.component";
import { NewLocationComponent } from "./views/settings-page/new-location/new-location.component";
import { LocationDetailPageComponent } from "./views/settings-page/location-detail-page/location-detail-page.component";
import { EditButtonBarComponent } from "./common/edit-button-bar/edit-button-bar.component";
import { DeleteDialogComponent } from "./common/delete-dialog/delete-dialog.component";
import { StudentsPageComponent } from "./views/students-page/students-page.component";
import { NewStudentComponent } from "./views/students-page/new-student/new-student.component";
import { StudentDetailPageComponent } from "./views/student-detail-page/student-detail-page.component";
import { TuiCurrencyPipeModule } from "@taiga-ui/addon-commerce";
import { InputNumberComponent } from "./common/input-number/input-number.component";
import { LocationDropdownComponent } from "./common/location-dropdown/location-dropdown.component";
import { RoleDropdownComponent } from "./common/role-dropdown/role-dropdown.component";
import { NewEventComponent } from "./views/calendar-page/new-event/new-event.component";
import { EventDetailPageComponent } from "./views/calendar-page/event-detail-page/event-detail-page.component";
import { BreadcrumbComponent } from "./common/breadcrumb/breadcrumb.component";
import {
  InputPaymentAmountComponent
} from "./common/input-payment-amount/input-payment-amount/input-payment-amount.component";
import { InputDateComponent } from "./common/input-date/input-date.component";
import { InputTimeComponent } from "./common/input-time/input-time.component";
import { CdkListbox } from "@angular/cdk/listbox";
import { CategoryDropdownComponent } from "./common/category-dropdown/category-dropdown.component";
import { TuiLanguageName } from "@taiga-ui/i18n";
import { tuiLanguageSwitcher } from "@taiga-ui/i18n/switch";
import { GlobalUsersComponent } from "./views/settings-page/global-users/global-users.component";
import { NewGlobalUserComponent } from "./views/settings-page/global-users/new-global-user/new-global-user.component";
import { LicenceTypePageComponent } from "./views/settings-page/licence-type-page/licence-type-page.component";
import { CarIconComponent } from "./common/car-icon/car-icon.component";
import { NewLicenceTypeComponent } from "./views/settings-page/new-licence-type/new-licence-type.component";
import { IconDropdownComponent } from "./common/icon-dropdown/icon-dropdown.component";
import { NewLessonCategoryComponent } from "./views/settings-page/new-lesson-category/new-lesson-category.component";
import {
  LicenceTypeDetailPageComponent
} from "./views/settings-page/licence-type-detail-page/licence-type-detail-page.component";
import { LessonCategoryPageComponent } from "./views/settings-page/lesson-category-page/lesson-category-page.component";
import { LicenceTypeDropdownComponent } from "./common/licence-type-dropdown/licence-type-dropdown.component";
import {
  LessonCategoryDetailPageComponent
} from "./views/settings-page/lesson-category-detail-page/lesson-category-detail-page.component";
import {
  ArcChartProgressLicenceComponent
} from "./common/arc-chart-progress-licence/arc-chart-progress-licence.component";
import { PaymentMenuDropdownComponent } from "./common/payment-menu-dropdown/payment-menu-dropdown.component";
import { NewTransactionComponent } from "./views/transactions-page/new-transaction/new-transaction.component";
import { WalletOverviewComponent } from "./common/wallet-overview/wallet-overview.component";
import { TransactionDetailPageComponent } from "./views/transaction-detail-page/transaction-detail-page.component";
import { AccountComponent } from "./views/settings-page/account/account.component";
import { LicenceTypSelectComponent } from "./common/licence-typ-select/licence-typ-select.component";
import {
  StudentLessonTableComponent
} from "./views/student-detail-page/student-lesson-table/student-lesson-table.component";
import {
  TeacherLessonsTableComponent
} from "./views/user-detail-page/teacher-lessons-table/teacher-lessons-table.component";
import { TableSearchInputComponent } from "./common/table-search-input/table-search-input.component";
import { LessonHistoryPageComponent } from "./views/lesson-history-page/lesson-history-page.component";
import { LogEntityCardComponent } from "./views/lesson-history-page/log-entity-card/log-entity-card.component";
import {
  PhoneCodeValidationComponent
} from './views/reset-password-page/phone-code-validation/phone-code-validation.component';
import {
  PhoneCodeValidationLayoutComponent
} from './views/reset-password-page/phone-code-validation/phone-code-validation-layout/phone-code-validation-layout.component';

// needed for ngx-translate (i18n)
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    TenantPageComponent,
    TableHeaderComponent,
    TableComponent,
    SidenavComponent,
    TopbarComponent,
    ButtonComponent,
    SettingsPageComponent,
    ProfileComponent,
    ExplanationComponent,
    PasswordComponent,
    InfoBitComponent,
    LocationsPageComponent,
    CalendarPageComponent,
    TransactionsPageComponent,
    UserPageComponent,
    StudentsPageComponent,
    LogInPageComponent,
    InfoBitComponent,
    DashboardPageComponent,
    BaseLayoutComponent,
    AuthLayoutComponent,
    MenubarComponent,
    EventItemComponent,
    InputPasswordComponent,
    InputTextComponent,
    ForgotPasswordComponent,
    ResetPasswordPageComponent,
    SetNewPasswordComponent,
    InputPhoneComponent,
    InputEmailComponent,
    NewUserComponent,
    RadioListComponent,
    UserDetailPageComponent,
    DropdownComponent,
    ErrorPageComponent,
    InputLargeTextComponent,
    PermissionRowComponent,
    NewTenantComponent,
    TenantSelectorDropdownComponent,
    EditButtonBarComponent,
    DeleteDialogComponent,
    DashboardCardComponent,
    IconComponent,
    NewLocationComponent,
    LocationDetailPageComponent,
    NewStudentComponent,
    StudentDetailPageComponent,
    InputNumberComponent,
    LocationDropdownComponent,
    RoleDropdownComponent,
    NewEventComponent,
    EventDetailPageComponent,
    BreadcrumbComponent,
    InputPaymentAmountComponent,
    InputDateComponent,
    InputTimeComponent,
    CategoryDropdownComponent,
    GlobalUsersComponent,
    NewGlobalUserComponent,
    LicenceTypePageComponent,
    CarIconComponent,
    NewLicenceTypeComponent,
    IconDropdownComponent,
    NewLessonCategoryComponent,
    LicenceTypeDetailPageComponent,
    LessonCategoryPageComponent,
    LicenceTypeDropdownComponent,
    LessonCategoryDetailPageComponent,
    ArcChartProgressLicenceComponent,
    PaymentMenuDropdownComponent,
    NewTransactionComponent,
    WalletOverviewComponent,
    TransactionDetailPageComponent,
    AccountComponent,
    LicenceTypSelectComponent,
    StudentLessonTableComponent,
    TeacherLessonsTableComponent,
    TableSearchInputComponent,
    LessonHistoryPageComponent,
    LogEntityCardComponent,
    PhoneCodeValidationComponent,
    PhoneCodeValidationLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TuiRootModule,
    TuiDialogModule,
    TuiAlertModule,
    TuiButtonModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiExpandModule,
    TuiGroupModule,
    TuiRadioBlockModule,
    TuiTabsModule,
    FormsModule,
    TuiBreadcrumbsModule,
    ReactiveFormsModule,
    TuiTableModule,
    TuiTablePaginationModule,
    TuiSvgModule,
    TuiTooltipModule,
    TuiHintModule,
    TuiInputModule,
    TuiHostedDropdownModule,
    ReactiveFormsModule,
    TuiDataListModule,
    TuiDropdownModule,
    TuiGroupModule,
    TuiObscuredModule,
    TuiActiveZoneModule,
    TuiTabsModule,
    FullCalendarModule,
    TuiAccordionModule,
    TuiSidebarModule,
    TuiIslandModule,
    TuiLinkModule,
    TuiInputPasswordModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiTabsModule,
    TuiIslandModule,
    TuiAxesModule,
    TuiLineDaysChartModule,
    TuiInputDateRangeModule,
    TuiLineChartModule,
    TuiStepperModule,
    TuiModeModule,
    TuiPortalModule,
    TuiCalendarRangeModule,
    TuiInputDateModule,
    TuiInputTimeModule,
    TuiAutoFocusModule,
    TuiSelectModule,
    TuiLineChartModule,
    TuiBadgeModule,
    TuiElasticContainerModule,
    HttpClientModule,
    TuiFormatDatePipeModule,
    TuiLoaderModule,
    TuiInputPhoneModule,
    TuiPushModule,
    TuiComboBoxModule,
    TuiDataListWrapperModule,
    TuiRadioListModule,
    TuiRadioLabeledModule,
    TuiFilterByInputPipeModule,
    TuiStringifyContentPipeModule,
    TuiCheckboxLabeledModule,
    TuiLetModule,
    TuiTextareaModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TuiToggleModule,
    TuiRadioModule,
    NgOptimizedImage,
    TuiCurrencyPipeModule,
    TuiInputNumberModule,
    TuiCalendarModule,
    CdkListbox,
    TuiTagModule,
    TuiNotificationModule,
    TuiCheckboxModule,
    TuiProgressModule,
    TuiScrollbarModule,
    TuiArcChartModule,
    TuiInputFilesModule,
    TuiMarkerIconModule,
    TuiValueChangesModule,
    ReactiveFormsModule,
    TuiStringifyPipeModule,
    TuiInputPhoneInternationalModule,
    TuiSortCountriesPipeModule,
  ],

  providers: [
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    tuiLanguageSwitcher(async (language: TuiLanguageName): Promise<unknown> => {
      switch (language) {
        case `german`:
          return import(`@taiga-ui/i18n/languages/german`);
        case `english`:
          return import(`@taiga-ui/i18n/languages/english`);
        default:
          return import(`@taiga-ui/i18n/languages/german`);
      }
    }),
    // INTERCEPTORS:
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    FormGroupDirective,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
