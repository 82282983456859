import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode, } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
/**
 * Intercepts all Http requests and sets the authorization header to the access token
 * If the access token is expired and the status code is 401 the refresh token is sent to the api and the tokens in the local storage are updated
 */
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // adds authorization header with access token if available
    const requestWithToken = this.addAuthHeader(request);

    // checks the response status code to see if the access token is expired
    return next.handle(requestWithToken).pipe(
      catchError((error) => {
        if (error.status !== 401) {
          return throwError(error);
        }
        let isRefreshToken = this.authService.getRefreshToken()

        if (isRefreshToken !== null) {
          return this.sendRefreshToken(request, next).pipe(
            switchMap(() => next.handle(this.addAuthHeader(request)))
          );
        }
        console.log('no refreshToken case')
        return throwError(error);
        // return next.handle(requestWithToken);
      }),
    );
  }

  /**
   * adds the Authorization header to the request
   */
  addAuthHeader(request: HttpRequest<unknown>) {
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.authService.getAccessToken(),
      },
    });
  }

  /**
   * sends the refresh token to the api and sets the new tokens in the local storage if the refresh token is valid
   * resends the request with the new access token
   * otherwise the user will be logged out
   */
  sendRefreshToken(request: HttpRequest<unknown>, next: HttpHandler) {
    return this.authService.sendRefreshToken().pipe(
      switchMap((response) => {
        if (response.status === HttpStatusCode.Created) {
          this.authService.setTokens(
            response.body.access_token,
            response.body.refresh_token,
          );
          this.authService.setLoggedInUser(response.body.user);
          const requestWithNewToken = this.addAuthHeader(request);
          return next.handle(requestWithNewToken);
        } else {
          this.authService.logout();
          throw response;
        }
      }),
      catchError((error) => {
        this.authService.logout();
        throw error;
      }),
    );
  }
}
