import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

/**
 * @example
 * <app-input-password
 *    [fieldName]="'password'"
 *    [hint]="'Passwort eingeben'"
 *  ></app-input-password>
 */
@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})

/**
 * A component that displays an input field for entering a password
 * @Input hint - The hint to display on top of the input field
 * @Input fieldName - The name of the field in the FormGroup
 */
export class InputPasswordComponent {
  @Input() hint: string = 'Passwort';
  @Input() fieldName: string = 'currentPassword';
}
