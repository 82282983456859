import { Component } from '@angular/core';
import {tuiIconClockLarge} from "@taiga-ui/icons";
import {LoadingService} from "../../services/loading.service";
import {NavRoutes} from "../../enums/nav-routes";
import {LessonService} from "../../services/lesson.service";
import {Lesson} from "../../models/lesson";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-lesson-history-page',
  templateUrl: './lesson-history-page.component.html',
  styleUrls: ['./lesson-history-page.component.scss']
})
export class LessonHistoryPageComponent {
  lessonId = '';
  studentId = '';
  isLoading$ = this.loadingService.getLoadingState();
  lessons : Lesson[] = [];
  currentLesson: Lesson | null = null

  protected readonly tuiIconClockLarge = tuiIconClockLarge;

  breadcrumbItems = [
    {
      caption: 'students.student-detail.breadcrumb-item-caption',
      routerLink: `/${NavRoutes.STUDENT}`,
    },
    {
      caption: 'students.student-detail.breadcrumb-item-caption-detail',
      routerLink: `/${NavRoutes.STUDENT}/${this.studentId}`,
    },
    {
      caption: 'lesson-history.page-title',
      routerLink: null,
      routerLinkActiveOptions: { exact: true },
    },
  ];

  constructor(
    private readonly loadingService: LoadingService,
    private readonly lessonService: LessonService,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.lessonId = params.get("lessonId")!;
      this.studentId = params.get("id")!;
    });

    this.getVcLogEntitiesLesson();
  }

  getVcLogEntitiesLesson() {
    this.lessonService.getLessonById(this.lessonId, {vcIsLogEntity: true}).subscribe(
      (lesson) => {
        this.lessons = lesson.vcLogEntities!;
        this.currentLesson = lesson;
      }
    );
  }
}
