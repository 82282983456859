<app-menubar
  [title]="'location.new-location.title' | translate"
  [description]="'location.new-location.description' | translate"
  [open]="isOpen"
  (closingEvent)="setCloseEvent()"
>

<!--
 "input-label-street": "Street",
      "input-label-house-number": "House Number",
      "input-label-zip-code": "Postal Code",
      "input-label-city": "City",
-->
  <form [formGroup]="newLocationForm" (ngSubmit)="createNewLocation()">
    <app-input-text [fieldName]="'name'" [hint]="'location.location-detail.input-label-name' | translate" ></app-input-text>
    <div class="inline-inputs">
      <div class="big-input">
        <app-input-text [fieldName]="'street'" [hint]="'address.input-label-street' | translate" ></app-input-text>
      </div>
      <div class="small-input">
        <app-input-text [fieldName]="'houseNo'" [hint]="'address.input-label-house-number' | translate" ></app-input-text>
      </div>
    </div>
    <app-input-text [fieldName]="'postalCode'" [hint]="'address.input-label-zip-code' | translate" ></app-input-text>
    <app-input-text [fieldName]="'city'" [hint]="'address.input-label-city' | translate" ></app-input-text>
    <app-input-phone [fieldName]="'phone'" [hint]="'location.location-detail.input-label-phone' | translate"></app-input-phone>

    <div class="button-wrapper">
      <button
        tuiButton
        size="m"
        [disabled]="!newLocationForm.valid"
        type="submit"
      >
        {{ "common.button-add" | translate }}
      </button>
      <button
        tuiButton
        size="m"
        appearance="secondary"
        type="button"
        (click)="setCloseEvent()"
      >
        {{ 'common.button-cancel' | translate }}
      </button>
    </div>
  </form>
</app-menubar>
