import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { PushService } from "../../../services/push.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../../services/auth.service";
import { pushTypes } from "../../../enums/push-types";
import { LicenceTypeService } from "../../../services/licence-type.service";
import { LicenceTypeBody } from "../../../interfaces/body/licence-type-body";
import { LicenceTargetService } from "../../../services/licence-target.service";
import { LicenceType } from "../../../models/licence-type";
import { LicenceTargetBody } from "../../../interfaces/body/licence-target-body";
import { NavRoutes } from "../../../enums/nav-routes";
import { CarIcons } from "../../../enums/car-icons";
import { Router } from "@angular/router";
import { UtilService } from "../../../services/util.service";
import { lastValueFrom, map, Subscription } from "rxjs";
import { tuiIconTrash } from "@taiga-ui/icons";
import { SuperAdminService } from "../../../services/super-admin.service";
import { LessonCategoryService } from "../../../services/lesson-category.service";
import { DropDownItem } from "../../../interfaces/drop-down-item";
import { LessonCategory } from "../../../models/lesson-category";
import { TuiContextWithImplicit, TuiStringHandler } from "@taiga-ui/cdk";

@Component({
  selector: "app-new-licence-type",
  templateUrl: "./new-licence-type.component.html",
  styleUrls: ["./new-licence-type.component.scss"],
})
export class NewLicenceTypeComponent {
  @Input() isOpen: boolean = false;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() isCreatedEvent = new EventEmitter<boolean>();

  newLicenceTypeForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    icon: new FormControl(""),
    targets: new FormArray([]), // dynamic form array
  });

  // strings for i18n
  pushNotificationSuccess: string = "";
  pushNotificationUniqueError: string = "";
  pushNotificationError: string = "";

  categoryDropdownItems: DropDownItem[] = [];
  categories: LessonCategory[] = [];
  selectedCategories: { id: string; label: string | null }[] = [];

  sendRequest: boolean = false;

  /**
   * BreadcrumbItems for the breadcrumb navigation from the detail to the general student page
   */
  breadcrumbItems = [
    {
      caption: "new-licence-type.breadcrumb-item-caption",
      routerLink: `/${NavRoutes.SETTINGS}/${NavRoutes.LICENCE_TYPE}`,
    },
    {
      caption: "new-licence-type.breadcrumb-item-caption-detail",
      routerLink: null,
      routerLinkActiveOptions: { exact: true },
    },
  ];
  protected readonly FormGroup = FormGroup;
  protected readonly NavRoutes = NavRoutes;
  protected readonly tuiIconTrash = tuiIconTrash;
  private backToParentSubscription: Subscription = new Subscription();

  constructor(
    private licenceTypeService: LicenceTypeService,
    private licenceTargetService: LicenceTargetService,
    private pushService: PushService,
    private translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    private utilService: UtilService,
    private superAdminService: SuperAdminService,
    private lessonCategoryService: LessonCategoryService,
  ) {}

  get targets() {
    return this.newLicenceTypeForm.get("targets") as FormArray;
  }

  ngOnChanges(): void {
    this.newLicenceTypeForm.markAsUntouched();
  }

  ngOnDestroy(): void {
    this.backToParentSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.getCategories();

    // subscribe to router events to refresh the calendar when the user navigates back to the calendar page
    this.backToParentSubscription = this.router.events.subscribe((event) => {
      if (
        this.utilService.isNavigatedBackToParent(
          event,
          `${NavRoutes.LICENCE}/${NavRoutes.LICENCE_TYPE}/${NavRoutes.NEW_LICENCE_TYPE}`,
        )
      ) {
        this.getCategories();
      }
    });

    // translated strings for push-notifications
    this.translateService
      .get([
        "new-licence-type.push-licence-type-successful-created",
        "new-licence-type.push-licence-type-error-unique-created",
        "new-licence-type.push-licence-type-error-created",
      ])
      .subscribe((translations) => {
        this.pushNotificationSuccess =
          translations["new-licence-type.push-licence-type-successful-created"];
        this.pushNotificationError =
          translations["new-licence-type.push-licence-type-error-created"];
        this.pushNotificationUniqueError =
          translations[
            "new-licence-type.push-licence-type-error-unique-created"
          ];
      });
  }

  filterOptions(event: any) {
    const selectedItem = this.categoryDropdownItems.find(
      (item) => item.id === event.id,
    );
    if (!selectedItem) return;
    this.selectedCategories.push(selectedItem);
    this.categoryDropdownItems = this.categoryDropdownItems.filter(
      (option) => option.id !== event.id,
    );

    if (!event.previousValue) return;
    const previousCategory:
      | {
          id: string;
          label: string | null;
        }
      | undefined = this.selectedCategories.find(
      (item) => item.id === event.previousValue,
    );

    if (!previousCategory) return;
    this.categoryDropdownItems.push(<DropDownItem>previousCategory);
  }

  stringify(
    items: readonly any[],
  ): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(
      items.map(({ id, label }) => [id, label] as [number, string]),
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || "";
  }

  /**
   * Emits the close event to the parent component and closes the dialog.
   */
  setCloseEvent() {
    this.sendRequest = false;
    this.router.navigateByUrl(
      `/${NavRoutes.SETTINGS}/${NavRoutes.LICENCE_TYPE}`,
    );
    this.closeEvent.emit(true);
    this.isOpen = false;
  }

  addTargetFormRow() {
    const targetForm = new FormGroup({
      lessonCategoryId: new FormControl("", [Validators.required]),
      lessonCount: new FormControl("", [
        Validators.required,
        Validators.min(1),
      ]),
      price: new FormControl(null, [Validators.required, Validators.min(1)]),
    });

    // this.categoryDropdownItems = this.categoryDropdownItems.filter()

    this.targets.push(targetForm);
  }

  deleteTargetFormRow(index: number, target: any) {
    if (!target.value.lessonCategoryId) {
      this.targets.removeAt(index);
      return;
    }

    // update the selected and dropdown category array to show deleted category again
    let deletedCategory = this.selectedCategories.find(
      (category) => category.id === target.value.lessonCategoryId,
    );
    this.selectedCategories = this.selectedCategories.filter(
      (category) => category.id !== target.value.lessonCategoryId,
    );
    this.categoryDropdownItems.push(<DropDownItem>deletedCategory);

    this.targets.removeAt(index);
  }

  createLicenceType() {
    if (this.sendRequest) return;
    this.sendRequest = true;

    const tenantId = this.authService.getTenantId();
    if (tenantId == null) {
      this.pushService.sendPush(pushTypes.ERROR, this.pushNotificationError);
      return;
    }
    const licenceTypeBody: LicenceTypeBody = {
      tenantId: tenantId,
      name: this.newLicenceTypeForm.value.name!,
      icon: this.newLicenceTypeForm.value.icon ?? CarIcons.CAR,
    };

    this.licenceTypeService
      .createLicenceType(licenceTypeBody)
      .subscribe(async (response: number | LicenceType) => {
        if (response instanceof LicenceType) {
          if (this.targets.controls.length === 0) {
            this.sendSuccessPushNotification();
            return;
          }
          for (let target of this.targets.controls) {
            const licenceTargetBody: LicenceTargetBody = {
              licenceTypeId: response.id,
              tenantId: tenantId,
              lessonCategoryId: target.value.lessonCategoryId,
              lessonCount: target.value.lessonCount,
              price: target.value.price,
            };

            const createdLicenceTarget = await lastValueFrom(
              this.licenceTargetService
                .createLicenceTarget(licenceTargetBody)
                .pipe(
                  map((created: boolean) => {
                    return created;
                  }),
                ),
            );
            if (!createdLicenceTarget) {
              this.sendErrorPushNotification();
              return;
            }
          }
          this.sendSuccessPushNotification();
        } else {
          this.sendErrorPushNotification(response);
        }
      });
  }

  public lessonCategoryIdDuplicate(): boolean {
    if (this.targets.controls.length === 0) {
      return false;
    }
    const lessonCategoryIds: string[] = [];
    for (let target of this.targets.controls) {
      if (lessonCategoryIds.includes(target.value.lessonCategoryId.id)) {
        if (target.value.lessonCategoryId.id != null) {
          return true;
        }
      }
      lessonCategoryIds.push(target.value.lessonCategoryId.id);
    }

    return false;
  }

  getFormGroupFromAbstractControl(abstractControl: AbstractControl): FormGroup {
    return abstractControl as FormGroup;
  }

  getCategories() {
    const tenantId = this.authService.getTenantId();
    if (tenantId == null) return;
    this.getCategoriesForTenant(tenantId);
  }

  /**
   * Gets all explanation from the api to fill the dropdown menu
   */
  getCategoriesForTenant(tenantId: string) {
    this.lessonCategoryService
      .getCategory({ tenantId: tenantId })
      .subscribe((data) => {
        // only push categories to the dropdown menu which are not already selected
        this.categories = data.filter(
          (category) =>
            !this.selectedCategories.find(
              (selectedCategory) => selectedCategory.id === category.id,
            ),
        );

        this.categoryDropdownItems = this.utilService.generateDropdownItems(
          this.categories,
        );
      });
  }

  private sendErrorPushNotification(status: number = 500) {
    switch (status) {
      case 409:
        this.pushService.sendPush(
          pushTypes.ERROR,
          this.pushNotificationUniqueError,
        );
        break;
      default:
        this.pushService.sendPush(pushTypes.ERROR, this.pushNotificationError);
        break;
    }
    this.setCloseEvent();
  }

  private sendSuccessPushNotification() {
    this.pushService.sendPush(pushTypes.SUCCESS, this.pushNotificationSuccess);
    // If created, emit the event to the parent component and close the dialog
    this.isCreatedEvent.emit(true);
    this.setCloseEvent();
    this.newLicenceTypeForm.reset();
    this.targets.clear();
  }
}
