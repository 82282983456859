<div class="setting-wrapper">
  <tui-breadcrumbs [size]="'l'">
    <ng-container *ngFor="let item of breadcrumbItems">
      <a
        *tuiItem
        [routerLink]="item.routerLink"
        tuiLink>
        {{ item.caption | translate }}
      </a>
    </ng-container>
  </tui-breadcrumbs>
  <span class="name-wrapper">
      <h1>{{ 'new-licence-type.title' | translate }}</h1>
       <p class="instruction-text">{{ 'new-licence-type.description' | translate }}</p>
  </span>
  <div class="card-wrapper">
    <div class="form-wrapper">
      <form [formGroup]="newLicenceTypeForm" class="form-group">
        <div class="form-section-wrapper">
             <span class="text-field-wrapper">
              <app-input-text
                [fieldName]="'name'"
                [hint]="'new-licence-type.input-label-name' | translate"
              ></app-input-text>
            </span>
          <span class="icon-field-wrapper">
               <app-icon-dropdown
                 [formFieldName]="'icon'"
                 [formGroup]="newLicenceTypeForm"
               ></app-icon-dropdown>
            </span>
        </div>

        <!--   add new licence type form   -->
        <ng-container
          *ngFor="let target of targets.controls; let i = index"
          [formArrayName]="'targets'"
        >
          <div
            [formGroup]="getFormGroupFromAbstractControl(target)"
            class="target-container"
          >
            <div class="dropdown-field-wrapper bottom-space-wrapper">

              <app-licence-typ-select
                (valueChanges)="filterOptions($event)"
                [fieldName]="'lessonCategoryId'"
                [optionsArray]="categoryDropdownItems"
                [selectedOptionsArray]="selectedCategories"
              ></app-licence-typ-select>
            </div>

            <!-- lesson price -->
            <app-input-payment-amount
              [fieldName]="'price'"
              [hint]="'licence-type-detail.input-label-lesson-price' | translate"
              [minValue]="1"
              class="medium-input-item"
            >
            </app-input-payment-amount>


            <app-input-number
              [fieldName]="'lessonCount'"
              [hint]="'new-licence-type.input-label-lesson-count' | translate"
              class="small-input-item"
            ></app-input-number>
            <div class="center-button">
              <button
                (click)="deleteTargetFormRow(i, target)"
                appearance="icon"
                class="delete-button"
                icon="tuiIconClose"
                size="l"
                tuiButton
              >
              </button>
            </div>
          </div>
        </ng-container>


        <!--   add new licence type button   -->
        <div class="center-button">
          <button
            (click)="addTargetFormRow()"
            *ngIf="targets.controls.length < 6"
            appearance="secondary"
            icon="tuiIconPlus"
            id="addButton"
            size="m"
            tuiButton
          >
            {{ "new-licence-type.button-new-lesson" | translate }}
          </button>
          <tui-error *ngIf="targets.controls.length == 6"
                     [error]="'new-licence-type.max-categories-error' | translate"></tui-error>
        </div>
        <!--      link to create new category      -->
        <a
          *ngIf="targets.controls.length < 6"
          [routerLink]="'/'+NavRoutes.SETTINGS+'/'+NavRoutes.LICENCE_TYPE+'/'+NavRoutes.NEW_LICENCE_TYPE+'/'+NavRoutes.NEW_LESSON_CATEGORY"
          style="padding-bottom: 1rem">{{ "new-licence-type.new-lesson-category-link" | translate }}</a>

        <div class="button-wrapper">
          <button
            (click)="createLicenceType()"
            [disabled]="!newLicenceTypeForm.valid "
            size="m"
            tuiButton
            type="submit"
          >
            {{ "common.button-save" | translate }}
          </button>
          <button
            (click)="setCloseEvent()"
            appearance="secondary"
            size="m"
            tuiButton
            type="button"
          >
            {{ "common.button-cancel" | translate }}
          </button>
        </div>
      </form>

    </div>
    <!-- BUTTONS -->

  </div>
  <router-outlet></router-outlet>
</div>
