<div class="setting-wrapper">
  <app-table-header
    (createNewClickEvent)="createNewClickEvent()"
    (filterEvent)="filterTable($event)"
    [buttonText]="'licence-type.button-new-licence-type' | translate"
    [showActiveTab]="false"
    [showCreateButton]="!isManager()"
    [tableTitle]="'licence-type.page-title' | translate"
    [tenantSelector]="true"
  ></app-table-header>
  <app-table
    (paginationEvent)="paginationEvent($event)"
    (rowClickEvent)="licenceTypeRowClickEvent($event)"
    (sortEvent)="sortTable($event)"
    [columns]="licenceTypeTableShownColumns"
    [currentPage]="tablePage"
    [pageSize]="tablePageSize"
    [tableColumnNames]="['licence-type.table-icon' | translate, 'licence-type.table-name' | translate]"
    [tableData]="licenceTypeTableData"
    [totalElements]="totalLicenceTypes"
  ></app-table>
  <!-- injecting children -->
  <router-outlet></router-outlet>
</div>


<!-- NOT USED ANYMORE -> instead own page that can be accessed by the router-->
<!-- Slidethingy on the right -->
<!--<app-new-licence-type [isOpen]="licenceTypeOpen" (isCreatedEvent)="createdLicenceTypeEvent()" (closeEvent)="licenceTypeOpen = false"></app-new-licence-type>-->

