<div class="input-wrapper">
  <tui-textarea
    formControlName="{{fieldName}}"
    tuiTextfieldSize="l"
    [expandable]="isExpandable!"
    [readOnly]="readonly"
    [tuiTextfieldLabelOutside]="labelOutside"
  >
    {{ hint }}
  </tui-textarea>
  <div class="error-wrapper" *ngIf="showError">
    <tui-error
      formControlName="{{ fieldName }}"
      [error]="[] | tuiFieldError | async"
    ></tui-error>
  </div>
</div>
