import { Component } from '@angular/core';
import { TableFilterEvent } from '../../../interfaces/table-filter-event';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SuperAdminService } from '../../../services/super-admin.service';
import { UtilService } from '../../../services/util.service';
import { LicenceTypeService } from '../../../services/licence-type.service';
import { AuthService } from '../../../services/auth.service';
import { LicenceType } from '../../../models/licence-type';
import { TuiTablePagination } from '@taiga-ui/addon-table';
import { TableSortEvent } from '../../../interfaces/table-sort-event';
import { NavRoutes } from '../../../enums/nav-routes';
import { CarIcons } from '../../../enums/car-icons';
import { UserRole } from '../../../enums/user-role';

@Component({
  selector: 'app-licence-type-page',
  templateUrl: './licence-type-page.component.html',
  styleUrls: ['./licence-type-page.component.scss'],
})
export class LicenceTypePageComponent {
  licenceTypeTableData: any[] = [];
  readonly licenceTypeTableShownColumns: string[] = ['icon', 'name'];
  tablePage: number = 0;
  tablePageSize: number = 10;
  totalLicenceTypes: number = 0;
  licenceTypeOpen: boolean = false;
  selectedTenantSubscription: Subscription = new Subscription();
  protected readonly CarIcons = CarIcons;
  private backToParentSubscription: Subscription = new Subscription();

  constructor(
    private licenceTypeService: LicenceTypeService,
    private authService: AuthService,
    private router: Router,
    private superAdminService: SuperAdminService,
    private utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.licenceTypeService.setPaginationIndices({ start: 0, limit: 10 });

    // subscribe to router events to refresh the calendar when the user navigates back to the calendar page
    this.backToParentSubscription = this.router.events.subscribe((event) => {
      if (
        this.utilService.isNavigatedBackToParent(event, NavRoutes.LICENCE_TYPE)
      ) {
        this.populateTable();
      }
    });

    // populate table again if selected tenant changes
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe((id) => {
          this.populateTable();
        });
    } else {
      this.populateTable();
    }
  }

  ngOnDestroy(): void {
    this.resetTablePagination();
    this.selectedTenantSubscription.unsubscribe();
    this.backToParentSubscription.unsubscribe();
  }

  public populateTable() {
    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();
      if (selectedTenantId == null) return;

      this.getLicenceTypeForTenant(selectedTenantId);
    } else {
      this.getLicenceTypeForTenant(
        this.authService.getLoggedInUser()?.tenantId,
      );
    }
  }

  public getLicenceTypeForTenant(tenantId?: string) {
    this.licenceTypeService
      .getLicenceType({ tenantId: tenantId })
      .subscribe((licenceTypes: LicenceType[]) => {
        this.licenceTypeTableData = licenceTypes;
        this.totalLicenceTypes = this.licenceTypeService.getAmount();
      });
  }

  public filterTable(filterEvent: TableFilterEvent): void {
    // this.locationService.setFilter(filterEvent);
    // this.resetTablePagination();
    // this.populateTable();
  }

  /**
   * when the pagination event is triggered:
   * sets the table pagination to the selected page and size and updates the table data
   */
  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.licenceTypeService.setPaginationIndices({
      start: this.tablePage * this.tablePageSize,
      limit: this.tablePageSize,
    });
    this.populateTable();
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    this.licenceTypeService.setSorting(tableSort);
    this.populateTable();
  }

  /**
   * resets the table pagination to the first page with 10 entries
   */
  public resetTablePagination() {
    this.licenceTypeService.setPaginationIndices({ start: 0, limit: 10 });
    this.tablePage = 0;
    this.tablePageSize = 10;
  }

  public licenceTypeRowClickEvent(id: string): void {
    this.router.navigateByUrl(
      `${NavRoutes.SETTINGS}/${NavRoutes.LICENCE_TYPE}/${id}`,
    );
  }

  /**
   * If event is emitted from the create-location component,
   * the table is updated and the modal is closed
   */
  public createdLicenceTypeEvent() {
    this.populateTable();
    this.licenceTypeOpen = false;
  }

  public createNewClickEvent() {
    // this.licenceTypeOpen = true;
    this.router.navigateByUrl(
      `${NavRoutes.SETTINGS}/${NavRoutes.LICENCE_TYPE}/${NavRoutes.NEW_LICENCE_TYPE}`,
    );
  }

  public isManager(): boolean {
    return this.authService.getUserRole() === UserRole.MANAGER;
  }
}
