<div class="input-wrapper">
  <tui-input-password formControlName="{{ fieldName }}" tuiTextfieldSize="l">
    {{ hint }}
    <span class="tui-required"></span>
  </tui-input-password>

  <div class="error-wrapper">
    <tui-error
      formControlName="{{ fieldName }}"
      [error]="[] | tuiFieldError | async"
    ></tui-error>
  </div>
</div>
