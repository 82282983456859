import { Component, Input } from '@angular/core';
import { LicenceType } from '../../models/licence-type';
import { LoadingService } from '../../services/loading.service';
import { LessonProgressCountBody } from '../../interfaces/body/lesson-progess-count-body';
import { StudentService } from '../../services/student.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-arc-chart-progress-licence',
  templateUrl: './arc-chart-progress-licence.component.html',
  styleUrls: ['./arc-chart-progress-licence.component.scss'],
})
export class ArcChartProgressLicenceComponent {
  @Input({ required: true }) licenceType!: LicenceType;
  @Input({ required: true }) studentId!: string;

  lessonProgressForLicenceType: LessonProgressCountBody[] = [];
  isOpen = false;
  isLoading$ = this.loadingService.getLoadingState();

  constructor(
    private loadingService: LoadingService,
    private studentService: StudentService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.getProgressCount();
  }

  ngOnDestroy(): void {
  }

  getPercentageForValue(done: number, max: number): number[] {
    return [done / max * 100]
  }

  getProgressCount() {
    const tenantId = this.authService.getTenantId();
    if (tenantId == null) return;

    this.studentService
      .getLessonProgressCount({
        studentId: this.studentId,
        licenceTypeId: this.licenceType.id,
        tenantId: tenantId,
      })
      .subscribe((lessonProgressCount) => {
        this.lessonProgressForLicenceType = lessonProgressCount;
      });
  }
}
