import { Component } from "@angular/core";
import { TableFilterEvent } from "../../interfaces/table-filter-event";
import { TuiTablePagination } from "@taiga-ui/addon-table";
import { TableSortEvent } from "../../interfaces/table-sort-event";
import { SuperAdminService } from "../../services/super-admin.service";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { StudentService } from "../../services/student.service";
import { Student } from "../../models/student";
import { NavRoutes } from "../../enums/nav-routes";

@Component({
  selector: "app-students-page",
  templateUrl: "./students-page.component.html",
  styleUrls: ["./students-page.component.scss"],
})

/**
 * Component page for customer
 * Currently not implemented
 */
export class StudentsPageComponent {
  tablePage: number = 0;
  tablePageSize: number = 10;
  totalStudents: number = 0;
  isOpen: boolean = false;
  studentsTableData: any[] = [];
  studentsTableShownColumns: string[] = [
    "studentNumber",
    "firstName",
    "lastName",
    "phone",
    "email",
    "active",
  ];
  searchValue: string = "";
  protected open: boolean = false;
  private selectedTenantSubscription: Subscription = new Subscription();

  constructor(
    private superAdminService: SuperAdminService,
    private authService: AuthService,
    private router: Router,
    private studentService: StudentService,
  ) {
  }

  ngOnInit(): void {
    this.studentService.setPaginationIndices({ start: 0, limit: 10 });
    this.studentService.resetFilter();
    // sort students by student number ascending by default
    this.studentService.setSorting({
      sortColumn: "studentNumber",
      sortDirection: 1,
    });

    // populate table again if selected tenant changes
    if (this.superAdminService.isSuperAdmin()) {
      this.selectedTenantSubscription = this.superAdminService
        .getSelectedTenantId()
        .subscribe(() => {
          this.populateTable();
        });
    } else {
      this.populateTable();
    }
  }

  ngOnDestroy(): void {
    this.resetTablePagination();
    this.selectedTenantSubscription.unsubscribe();
  }

  /**
   * Populates the students table with data:
   * if the user is not a super-admin load all students for his tenant
   * if the user is a super-admin react to changes of the selected tenantId and load the students for the selected tenant
   * IMPORTANT: end subscription this.superAdminService .getSelectedTenantId() on destroy, otherwise => memory leak
   */
  populateTable(): void {
    let searchValueParam =
      this.searchValue !== "" ? this.searchValue : undefined;

    if (this.superAdminService.isSuperAdmin()) {
      const selectedTenantId =
        this.superAdminService.getSelectedTenantIdValue();

      if (selectedTenantId === null) return;

      this.getStudentsForTenant(selectedTenantId, searchValueParam);
    } else {
      this.getStudentsForTenant(
        this.authService.getLoggedInUser()?.tenantId,
        searchValueParam,
      );
    }
  }

  /**
   * gets all students and amount of users for the given tenant
   * @param tenantId The id of the tenant
   * @param searchValue The search keyword
   */
  getStudentsForTenant(
    tenantId: string | undefined,
    searchValue: string | undefined,
  ): void {
    this.studentService
      .getStudent({ tenantId: tenantId, search: searchValue })
      .subscribe((students: Student[]) => {
        this.studentsTableData = students;
        this.totalStudents = this.studentService.getAmount();
      });
  }

  /**
   * Filters the table by the given filter event
   * @param filterEvent containing search term and tab index (all, active, inactive)
   */
  public filterTable(filterEvent: TableFilterEvent): void {
    this.studentService.setFilter(filterEvent);
    this.resetTablePagination();
    this.populateTable();
  }

  /**
   * when the pagination event is triggered:
   * sets the table pagination to the selected page and size and updates the table data
   */
  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.studentService.setPaginationIndices({
      start: this.tablePage * this.tablePageSize,
      limit: this.tablePageSize,
    });

    this.populateTable();
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    this.studentService.setSorting(tableSort);
    this.populateTable();
  }

  /**
   * resets the table pagination to the first page with 10 entries
   */
  public resetTablePagination() {
    this.studentService.setPaginationIndices({ start: 0, limit: 10 });
    this.tablePage = 0;
    this.tablePageSize = 10;
  }

  /**
   * Navigates to the student detail page on click
   * of a row by using student id
   * @param id The id of the selected user in the table
   */
  public studentRowClickEvent(id: string): void {
    this.router.navigateByUrl(`${ NavRoutes.STUDENT }/${ id }`);
  }

  public createdStudentEvent() {
    this.populateTable();
    this.isOpen = false;
  }

  onSearchChange($event: string) {
    this.resetTablePagination();
    this.searchValue = $event;
    //   populate table with search param
    this.populateTable();
  }
}
